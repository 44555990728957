import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, defer, EMPTY, of, throwError } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import { addDays, format, startOfMonth, subMonths } from "date-fns";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import { getAllRequests, searchBuilder } from "../../helper";
import { createExcel } from "./settlementExcel";
import { setProdData } from "./helper";

export const key = "settlement";

export enum actionTypes {
  EMPTY_DATA = "rtm/productionData/settlement/EMPTY_DATA",
  FIRST_LOAD = "rtm/productionData/settlement/FIRST_LOAD",
  SET_RAGIONE_SOCIALE_DATA = "rtm/productionData/settlement/SET_RAGIONE_SOCIALE_DATA",
  RECIEVED_RAGIONE_SOCIALE_DATA = "rtm/productionData/settlement/RECIEVED_RAGIONE_SOCIALE_DATA",
  SET_PVI_DATA = "rtm/productionData/settlement/SET_PVI_DATA",
  RECIEVED_PVI_DATA = "rtm/productionData/settlement/RECIEVED_PVI_DATA",
  SELECTED_RAGIONE_SOCIALE = "rtm/productionData/settlement/SELECTED_RAGIONE_SOCIALE",
  SELECTED_PVI = "rtm/productionData/settlement/SELECTED_PVI",
  SELECTED_CURVES = "rtm/productionData/settlement/SELECTED_CURVES",
  SELECTED_PERIOD = "rtm/productionData/settlement/SELECTED_PERIOD",
  FETCHING_PRODUCTION_DATA = "rtm/productionData/settlement/FETCHING_PRODUCTION_DATA",
  SET_PRODUCTION_DATA = "rtm/productionData/settlement/SET_PRODUCTION_DATA",
  SET_GRANULARITY = "rtm/productionData/settlement/SET_GRANULARITY",
  EXPORT_DATA = "rtm/productionData/settlement/EXPORT_DATA",
  EXPORT_DATA_DOWNLOADED = "rtm/productionData/settlement/EXPORT_DATA_DOWNLOADED",
  CANCEL = "rtm/productionData/settlement/CANCEL",
}

type Actions =
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.EMPTY_DATA }
  | { type: actionTypes.FIRST_LOAD }
  | { type: actionTypes.SET_RAGIONE_SOCIALE_DATA }
  | { type: actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA; data: any }
  | { type: actionTypes.SET_PVI_DATA }
  | { type: actionTypes.RECIEVED_PVI_DATA; data: any }
  | { type: actionTypes.SELECTED_RAGIONE_SOCIALE; sociale: any }
  | { type: actionTypes.SELECTED_PVI; pvis: any }
  | { type: actionTypes.SELECTED_CURVES; curves: CurveTypes }
  | { type: actionTypes.SELECTED_PERIOD; period: Periodype }
  | { type: actionTypes.FETCHING_PRODUCTION_DATA }
  | { type: actionTypes.SET_PRODUCTION_DATA; data: any }
  | { type: actionTypes.SET_GRANULARITY; gran: GranularityType }
  | { type: actionTypes.EXPORT_DATA }
  | { type: actionTypes.EXPORT_DATA_DOWNLOADED }
  | { type: actionTypes.EMPTY_DATA };

export type RagioneSocialeType = {
  isFetching: boolean;
  data: any;
};
const ragioneSocialeState = {
  isFetching: false,
  data: [],
};
function ragioneSocialeReducer(
  state: RagioneSocialeType = ragioneSocialeState,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.SET_RAGIONE_SOCIALE_DATA:
      return { ...state, isFetching: true };
    case actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA:
      return { ...state, isFetching: false, data: action.data };
    case actionTypes.CANCEL:
    case actionTypes.EMPTY_DATA:
      return ragioneSocialeState;
    default:
      return state;
  }
}

export type PVIType = {
  isFetching: boolean;
  data: any;
};
const pviState = {
  isFetching: false,
  data: [],
};
function pviReducer(state: PVIType = pviState, action: Actions) {
  switch (action.type) {
    case actionTypes.SELECTED_RAGIONE_SOCIALE:
    case actionTypes.SET_PVI_DATA:
      return { ...state, isFetching: true };
    case actionTypes.RECIEVED_PVI_DATA:
      return { ...state, isFetching: false, data: action.data };
    case actionTypes.CANCEL:
    case actionTypes.EMPTY_DATA:
      return pviState;
    default:
      return state;
  }
}

export type CurvesTypes =
  | "production"
  | "forecast"
  | "unbalance"
  | "spread"
  | "net"
  | "settlement";
export type CurveTypes = Record<
  "production" | "forecast" | "unbalance" | "settlement" | "spread" | "net",
  string[]
> | null;
export type Periodype = {
  from: string | null;
  to: string | null;
};
export type GranularityType = "hourly" | "daily" | null;
export type FiltersType = {
  ragioneSociale: string | null;
  pvis: string[] | null;
  curves: CurveTypes;
  period: Periodype;
  granularity: GranularityType;
};

const mMinus3 = subMonths(new Date(), 3);
const fromMonth = startOfMonth(mMinus3);
const dPlus1 = addDays(new Date(), 1);
const filtersState = {
  ragioneSociale: null,
  pvis: null,
  curves: ["settlement", "unbalance"] as any,
  period: {
    from: format(fromMonth, "yyyy-MM-dd"),
    to: format(dPlus1, "yyyy-MM-dd"),
  },
  granularity: "daily" as any,
};
const filtersReducer = (state: FiltersType = filtersState, action: Actions) => {
  switch (action.type) {
    case actionTypes.SELECTED_RAGIONE_SOCIALE:
      return { ...state, ragioneSociale: action.sociale, pvis: null };
    case actionTypes.SELECTED_PVI:
      return { ...state, pvis: action.pvis };
    case actionTypes.SELECTED_CURVES:
      return { ...state, curves: action.curves };
    case actionTypes.SELECTED_PERIOD:
      return { ...state, period: action.period };
    case actionTypes.SET_GRANULARITY:
      return { ...state, granularity: action.gran };
    case actionTypes.EMPTY_DATA:
      return filtersState;
    default:
      return state;
  }
};

function productionDataReducer(state: any = [], action: Actions) {
  switch (action.type) {
    case actionTypes.SET_PRODUCTION_DATA:
      return {
        settlement: setProdData("settlement", action.data),
        unbalance: setProdData("unbalance", action.data),
        net: setProdData("net", action.data),
        spread: setProdData("spread", action.data),
      };
    case actionTypes.EMPTY_DATA:
      return [];
    default:
      return state;
  }
}

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.SET_PRODUCTION_DATA:
    case actionTypes.EXPORT_DATA_DOWNLOADED:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FETCHING_PRODUCTION_DATA:
    case actionTypes.EXPORT_DATA:
      return true;
    default:
      return state;
  }
}

export type reducerType = {
  isFetching: boolean;
  ragioneSociale: RagioneSocialeType;
  pvi: PVIType;
  filters: FiltersType;
  productionData: any;
};

export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  ragioneSociale: ragioneSocialeReducer,
  pvi: pviReducer,
  filters: filtersReducer,
  productionData: productionDataReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA,
});

export const firstLoadAction = (): Actions => ({
  type: actionTypes.FIRST_LOAD,
});

export const getRagioneSocialeAction = (): Actions => ({
  type: actionTypes.SET_RAGIONE_SOCIALE_DATA,
});
const setRagioneSocialeDataAction = (data: any): Actions => ({
  type: actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA,
  data,
});

const ragioneSocialeEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FIRST_LOAD ||
        action.type === actionTypes.SET_RAGIONE_SOCIALE_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state) => {
          return getAllRequests({
            deps,
            api: "core/ragionesociale",
            dispatchError: dispatchNetworkError,
            filters: {
              sort: "RagioneSociale asc",
            },
          });
        }),
        RxOp.map(setRagioneSocialeDataAction)
      )
    )
  );

export const getPVIAction = (): Actions => ({
  type: actionTypes.SET_PVI_DATA,
});
const setPVIDataAction = (data: any): Actions => ({
  type: actionTypes.RECIEVED_PVI_DATA,
  data,
});

const pviEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.SET_PVI_DATA ||
        action.type === actionTypes.SELECTED_RAGIONE_SOCIALE
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state) => {
          return getAllRequests({
            deps,
            api: "core/ragionesocialepvi",
            filters: {
              partitaIva: R.path(
                ["productionData", "filters", "ragioneSociale"],
                state
              ),
            },
            dispatchError: dispatchNetworkError,
          });
        }),
        RxOp.map(setPVIDataAction)
      )
    )
  );
export const setSelectedSocialeAction = (sociale: any): Actions => ({
  type: actionTypes.SELECTED_RAGIONE_SOCIALE,
  sociale,
});
export const setSelectedPVIAction = (pvis: any): Actions => ({
  type: actionTypes.SELECTED_PVI,
  pvis,
});
export const setSelectedCurvesAction = (curves: CurveTypes): Actions => ({
  type: actionTypes.SELECTED_CURVES,
  curves,
});
export const setPeriodAction = (period: Periodype): Actions => ({
  type: actionTypes.SELECTED_PERIOD,
  period,
});
export const setGranularityAction = (gran: GranularityType): Actions => ({
  type: actionTypes.SET_GRANULARITY,
  gran,
});

const fetchingProductionData = (): Actions => ({
  type: actionTypes.FETCHING_PRODUCTION_DATA,
});
const setProductionData = (data: any): Actions => {
  return {
    type: actionTypes.SET_PRODUCTION_DATA,
    data,
  };
};

const productionDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.SELECTED_RAGIONE_SOCIALE ||
        action.type === actionTypes.SELECTED_PERIOD ||
        action.type === actionTypes.SELECTED_PVI
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const info = R.path(
            ["productionData", key, "filters"],
            state
          ) as FiltersType;

          const periodNull = R.pipe<any, any, any, any, any>(
            R.path(["period"]),
            R.values,
            R.map(R.isNil),
            R.any(R.equals(true))
          )(info);

          const anyNull = R.pipe<any, any, any, any, any, any>(
            R.pick(["ragioneSociale", "pvis", "curves", "granularity"]),
            R.map((x) => R.isEmpty(x) || R.isNil(x)),
            R.values,
            R.concat(R.__, [periodNull]),
            R.any(R.equals(true))
          )(info);

          return anyNull ? EMPTY : of(state);
        }),
        RxOp.mergeMap((state: any) => {
          const info = R.path(
            ["productionData", key, "filters"],
            state
          ) as FiltersType;

          const pvis = R.splitEvery(30, info.pvis as any);

          return of(pvis).pipe(
            RxOp.flatMap((x) => x),
            RxOp.flatMap((pvi) => {
              const queryString = searchBuilder({
                filters: { identifier: pvi },
              });
              const toDateInclusive = R.pipe(
                (x: any) => addDays(x, 1),
                (x: any) => format(x, "yyyy-MM-dd")
              )(new Date(info.period.to as string));
              return defer(
                deps.request.get(
                  `producer/settlement/timeserie/${info.period.from}/${toDateInclusive}?${queryString}`,
                  t.any
                )
              );
            }, 2),
            RxOp.map(E.fold<any, any, any>((x) => x, (x) => x)),
            RxOp.toArray(),
            RxOp.switchMap((x: any) => {
              const anyFail = R.filter((r: any) => r.status >= 400, x);
              if (R.isEmpty(anyFail)) {
                return [x];
              }
              return throwError(anyFail[0].response);
            }),
            RxOp.map(setProductionData),
            RxOp.catchError((err: any) => {
              return of(err).pipe(RxOp.map(dispatchNetworkError));
            }),
            RxOp.startWith(fetchingProductionData())
          );
        }),
        RxOp.catchError((err: any) => {
          return of(err).pipe(RxOp.map(dispatchNetworkError));
        })
      )
    )
  );

export const exportDataAction = (): Actions => ({
  type: actionTypes.EXPORT_DATA,
});

const exportDataDownloaded = (): Actions => ({
  type: actionTypes.EXPORT_DATA_DOWNLOADED,
});

const exportDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_DATA),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state) => {
          return createExcel({
            fileName: `Settlement`,
            data: R.path(["productionData", key, "productionData"], state),
            filters: R.path(
              ["productionData", key, "filters"],
              state
            ) as FiltersType,
            pvis: R.path(["productionData", key, "pvi", "data"], state),
            ragioneSociale: R.path(
              ["productionData", key, "ragioneSociale", "data"],
              state
            ),
          });
        }),
        RxOp.map(exportDataDownloaded)
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  ragioneSocialeEpic,
  pviEpic,
  productionDataEpic,
  exportDataEpic
);

export const Selectors = {
  all: (s: any): reducerType => s.productionData[key],
};
export const getCurveName = (name: CurvesTypes) => {
  switch (name) {
    case "settlement":
      return "Power";
    case "spread":
      return "Fee";
    case "net":
      return "Netto";
    case "production":
      return "Production";
    case "forecast":
      return "Forecast";
    case "unbalance":
      return "Sbilancio";
    default:
      return name;
  }
};

export const getGranName = (gran: GranularityType): any => {
  switch (gran) {
    case "daily":
      return "Daily";
    case "hourly":
      return "Hourly";
    default:
      return gran;
  }
};
