import React from "react";

type ConfigModalType = {
  openModal: boolean;
  modalTitle: string;
  modalBody: any;
  disableApplyBtn: boolean;
  bodyClassName?: any;
  closeModal: () => void;
  commitChanges: () => void;
};

const ConfigModal = ({
  openModal,
  modalTitle,
  modalBody,
  bodyClassName = "",
  disableApplyBtn,
  closeModal,
  commitChanges
}: ConfigModalType) => (
  <div>
    <div
      className={`modal mapping-modal fade show ${openModal ? "d-block" : ""}`}
      id="configModal"
      role="dialog"
      aria-hidden="true"
    >
      <div className={`overlay ${openModal ? "fadeIn" : ""}`} />
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-close-btn">
            <button
              type="button"
              className="btn btn-default"
              onClick={closeModal}
            >
              <i className="fas fa-times-circle"></i>
            </button>
          </div>
          <div className="modal-header text-center">
            <div className="modal-title">{modalTitle}</div>
          </div>
          <div className={`modal-body ${bodyClassName}`}>
            <div className="modal-body-container">{modalBody}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger rounded-pill mx-2"
              onClick={commitChanges}
              disabled={disableApplyBtn}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConfigModal;
