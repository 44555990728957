export type SimpleSetReducerTypes = {
  setAction: string;
  prop: string;
  initialState: any;
};

export const simpleSetReducerCreator = <Actions extends any>({
  setAction,
  prop,
  initialState = ""
}: SimpleSetReducerTypes) =>
  function SetReducer(state = initialState, action: Actions) {
    switch (action.type) {
      case setAction:
        return action[prop];
      default:
        return state;
    }
  };

export const fetchingReducerCreator = <Actions extends { type: string }>({
  fetching,
  received,
  cancel
}: {
  fetching: string;
  received: string;
  cancel?: string | undefined;
}) =>
  function isfetching(state = false, action: Actions) {
    switch (action.type) {
      case fetching:
        return true;
      case received:
      case cancel:
        return false;
      default:
        return state;
    }
  };

export const toggleReducerCreator = <Actions extends any>({
  toggle,
  initialState = false
}: {
  toggle: string;
  initialState: boolean;
}) =>
  function toggleReducer(state = initialState, action: Actions) {
    switch (action.type) {
      case toggle:
        return !state;
      default:
        return state;
    }
  };
