import { combineEpics, ofType } from "redux-observable";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { Observable, EMPTY, of } from "rxjs";
import * as RxOp from "rxjs/operators";
import { Dependancies } from "../../storeTypes";
import { locationChangeCheck } from "./helper/trackPageView";
import { eventTracker } from "./helper/trackEvent";
import { actionTypes as ErrorHandler } from "../errorHandler";

const trackPageViewEpic = (
  action$: Observable<LocationChangeAction>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    RxOp.switchMap((x: any) =>
      of(locationChangeCheck(x.payload.location.pathname))
    ),
    RxOp.tap((name: any) =>
      name ? deps.insights.trackPageView({ name }) : name
    ),
    RxOp.switchMapTo(EMPTY)
  );

const trackExceptionEpic = (action$: any, _: any, deps: Dependancies) =>
  action$.pipe(
    ofType(ErrorHandler.SET_ERROR),
    RxOp.tap((error: any) =>
      deps.insights.trackException({
        exception: new Error(error.details.message),
      })
    ),
    RxOp.switchMapTo(EMPTY)
  );

const trackEventEpic = (
  action$: Observable<LocationChangeAction>,
  state$: any,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state: any) => of(eventTracker({ action, state }))),
        RxOp.tap((event: any) =>
          event ? deps.insights.trackEvent(event) : event
        ),
        RxOp.switchMapTo(EMPTY)
      )
    )
  );

export const epic = combineEpics(
  trackPageViewEpic,
  trackExceptionEpic,
  trackEventEpic
);
