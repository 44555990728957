import React from "react";
import * as R from "ramda";

export type SortValType = {
  column: string | null;
  direction: "desc" | "asc" | null;
};

type SortComponentTypes = {
  propName: string;
  dataSort?: boolean;
  sortColumn: SortValType;
  sortColumnFunc: (sort: SortValType) => any;
};

const SortComponent = ({
  dataSort = true,
  propName,
  sortColumn,
  sortColumnFunc
}: SortComponentTypes) => {
  const setSort = (column: string) => {
    if (R.prop("column", sortColumn)) {
      const direction = R.equals(R.prop("direction", sortColumn), "desc")
        ? "asc"
        : "desc";
      return sortColumnFunc({ column, direction });
    }
    return sortColumnFunc({ column, direction: "desc" });
  };

  const displaySort = () => {
    if (
      R.prop("column", sortColumn) &&
      R.equals(R.prop("column", sortColumn), propName)
    ) {
      const direction = R.equals(R.prop("direction", sortColumn), "desc")
        ? "down"
        : "up";
      return <i className={`fa fa-sort-${direction}`} />;
    }

    return <i className="fa fa-sort" />;
  };
  return (
    <div
      className="sort"
      hidden={R.not(dataSort)}
      onClick={() => setSort(propName)}
    >
      {displaySort()}
    </div>
  );
};

export default SortComponent;
