import * as R from "ramda";
import { getTime, format } from "date-fns";
import {
  CurvesTypes,
  getCurveName
} from "../redux/modules/productionData/production";

const allNill = R.pipe<any, any, any, any>(
  R.map(R.pathOr(null, ["value"])),
  R.map(R.isNil),
  R.all(R.equals(true))
);
const anyFalse = R.pipe<any, any, any>(
  R.map(R.pathOr(null, ["certain"])),
  R.any(R.equals(false))
);

const getDailyValue = (data: any, certain: boolean) =>
  R.pipe<any, any, any, any>(
    x => (anyFalse(x) ? R.map(row => ({ ...row, certain: false }), x) : x),
    R.filter(R.propEq("certain", certain)),
    x => {
      return R.isEmpty(x) || allNill(x)
        ? null
        : x.reduce((a: any, b: any) => {
            return a + b.value || 0;
          }, 0);
    }
  )(data);

const getDailyInfo = (data: any, certain: boolean) =>
  R.pipe<any, any, any, any, any>(
    R.map((row: any) =>
      R.assoc("date", format(new Date(row.dateTimeOffset), "yyyy-MM-dd"), row)
    ),
    R.groupBy(R.prop<any, any>("date")),
    R.toPairs,
    R.map(([date, val]) => {
      return {
        x: getTime(new Date(date)),
        y: getDailyValue(val, certain),
        borderColor: "Yellow"
      };
    })
  )(data);

const getHourlyInfo = (data: any, certain: boolean) =>
  R.map((x: any) => {
    const cert = R.pathOr(false, ["certain"], x);
    return {
      x: getTime(new Date(x.dateTimeOffset)),
      y: R.equals(cert, certain) ? R.pathOr(null, ["value"], x) : null
    };
  }, data);

export const getCurveColumnColor = (name: CurvesTypes) => {
  switch (name) {
    case "settlement":
      return "#ffbfbf";
    case "unbalance":
    default:
      return "#e6e6e6";
  }
};
export const getCurveColor = (name: CurvesTypes) => {
  switch (name) {
    case "settlement":
      return "#e10019";
    case "spread":
      return "#761613";
    case "net":
      return "#cfa2a7";
    case "production":
      return "#ed6675";
    case "forecast":
      return "#9e9294";
    case "unbalance":
    default:
      return "#000000";
  }
};

export const getProduzioneSeries = ({
  data,
  curves,
  granularity
}: {
  data: any;
  curves: any;
  granularity: any;
}) => {
  return R.ifElse(
    x => R.isEmpty(x) || R.isNil(x),
    () => [],
    R.pipe<any, any, any, any, any>(
      R.toPairs,
      R.map(([curve, val]) => {
        const colObj = {
          type: "column",
          marker: {
            enabled: false,
            symbol: "circle"
          },
          name: getCurveName(curve),
          color: getCurveColor(curve),
          turboThreshold: 1000000,
          curveName: curve
        };
        const lineObj = {
          type: "line",
          marker: {
            enabled: false,
            symbol: "circle"
          },
          name: getCurveName(curve),
          color: getCurveColor(curve),
          turboThreshold: 1000000,
          curveName: curve,
        };
        return R.equals(curve, "unbalance")
          ? [
              {
                ...colObj,
                data: R.equals(granularity, "daily")
                  ? getDailyInfo(val.timeseries, true)
                  : getHourlyInfo(val.timeseries, true)
              },
              {
                ...colObj,
                opacity: 0.5,
                name: getCurveName(curve) + " (provvisoria)",
                data: R.equals(granularity, "daily")
                  ? getDailyInfo(val.timeseries, false)
                  : getHourlyInfo(val.timeseries, false)
              }
            ]
          : [
              {
                ...lineObj,
                dashStyle: "Solid",
                data: R.equals(granularity, "daily")
                  ? getDailyInfo(val.timeseries, true)
                  : getHourlyInfo(val.timeseries, true)
              },
              {
                ...lineObj,
                dashStyle: "Dash",
                name: getCurveName(curve) + " (provvisoria)",
                data: R.equals(granularity, "daily")
                  ? getDailyInfo(val.timeseries, false)
                  : getHourlyInfo(val.timeseries, false)
              }
            ];
      }),
      R.flatten,
      R.reject((row: any) => !R.contains(row.curveName, curves || []))
    )
  )(data);
};

export const getSettlementSeries = ({
  data,
  curves,
  granularity
}: {
  data: any;
  curves: any;
  granularity: any;
}) => {
  return R.ifElse(
    x => R.isEmpty(x) || R.isNil(x),
    () => [],
    R.pipe<any, any, any, any, any>(
      R.toPairs,
      R.map(([curve, val]) => {
        const colObj = {
          type: "column",
          marker: {
            enabled: false,
            symbol: "circle"
          },
          color: getCurveColor(curve),
          turboThreshold: 1000000,
          curveName: curve
        };
        return [
          {
            ...colObj,
            name: getCurveName(curve),
            data: R.equals(granularity, "daily")
              ? getDailyInfo(val.timeseries, true)
              : getHourlyInfo(val.timeseries, true)
          },
          {
            ...colObj,
            opacity: 0.5,
            name: getCurveName(curve) + " (provvisoria)",
            data: R.equals(granularity, "daily")
              ? getDailyInfo(val.timeseries, false)
              : getHourlyInfo(val.timeseries, false)
          }
        ];
      }),
      R.flatten,
      R.reject((row: any) => !R.contains(row.curveName, curves || []))
    )
  )(data);
};

export const getAllPlants = R.ifElse(
  x => R.isEmpty(x) || R.isNil(x),
  () => [],
  R.pipe(
    R.toPairs,
    R.map(([curve, val]) => {
      return R.isNil(curve) || R.isEmpty(val)
        ? []
        : R.pipe(
            R.pathOr([], ["statistics"]),
            R.map(R.pathOr(null, ["pvi"]))
          )(val);
    }),
    R.flatten,
    R.uniq
  )
);

export const getAllPlantsNew = (x: any) => {
  const val = R.ifElse(
    x => R.isEmpty(x) || R.isNil(x),
    () => [],
    R.pipe(
      R.toPairs,
      R.map(([curve, val]) => {
        return R.isNil(curve) || R.isEmpty(val)
          ? []
          : R.pipe(
              R.pathOr([], ["statistics"]),
              R.map(x => {return {pvi: R.pathOr(null, ["pvi"])(x), partitaIva: R.pathOr(null, ["partitaIva"])(x)}}),
            )(val);
      }),
      R.flatten,
      R.uniq
    )
  )(x);
  return val;
}
type GetCurveDataType = { data: any; plant: any; curve: CurvesTypes };
export const getCurveData = ({ data, plant, curve }: GetCurveDataType) =>
  R.pipe(
    R.pathOr(null, [curve]),
    R.pathOr([], ["statistics"]),
    R.filter(R.propEq("pvi", plant)),
    R.map(R.pathOr(null, ["sum"])),
    x => {
      return R.isEmpty(x) || R.isNil(x) || R.all(R.isNil)(x)
        ? null
        : x.reduce((a: any, b: any) => a + b || null, 0);
    }
  )(data);

type GetAllDataSum = { data: any; curve: CurvesTypes; prop: "sum" | "absSum" };
export const getAllDataSum = ({ data, curve, prop }: GetAllDataSum) =>
  R.pipe(
    R.pathOr(null, [curve]),
    R.pathOr([], ["statistics"]),
    R.map(R.pathOr(null, [prop])),
    x =>
      R.isEmpty(x) || R.isNil(x) || R.all(R.isNil)(x)
        ? null
        : x.reduce((a: any, b: any) => a + (b || 0), 0)
  )(data);

export const allPvis = R.pipe<any, any, any>(
  R.map(R.path(["pvi", "pvi"])),
  R.values
);

export const ragioneSocialeVal = ({
  filters,
  data
}: {
  filters: any;
  data: any;
}) =>
  R.pipe<any, any, any>(
    R.filter(R.propEq("partitaIva", filters)),
    x => {
      return R.isEmpty(x)
        ? []
        : [{ value: x[0].partitaIva, title: x[0].ragioneSociale }];
    }
  )(data);
