import React from "react";
import * as R from "ramda";
import { format } from "date-fns";
import DateRange from "../../components/dateRange";

type TextInputType = {
  value: { fromDateTime: any; toDateTime: any };
  update: (a: any) => void;
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
};

const TextInput = ({
  update,
  value,
  labelName = null,
  requiredField = false
}: TextInputType) => {
  const fromDateTime = R.equals(null, value.fromDateTime)
    ? null
    : new Date(value.fromDateTime);
  const toDateTime = R.equals(null, value.toDateTime)
    ? null
    : new Date(value.toDateTime);
  return (
    <>
      {labelName ? (
          <label className="form-label" htmlFor="select">
          {requiredField ? (
            <span className="d-flex">
              <div>{labelName}</div>
              <div className="required">&nbsp;(Required)</div>
            </span>
          ) : (
            labelName
          )}
        </label>
      ) : null}
      <DateRange
        startDate={fromDateTime}
        onStartDateChange={(e: Date) => {
          return update({
            ...value,
            fromDateTime: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
          });
        }}
        endDate={toDateTime}
        onEndDateChange={(e: Date) =>
          update({
            ...value,
            toDateTime: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
          })
        }
      />
    </>
  );
};
export default TextInput;
