import React from "react";
import * as R from "ramda";
import SingleSelect from "../../components/singleSelectMain";
import { option } from "../../components/singleSelect/helper";

type SelectInputType = {
  value: any;
  update: (a: any) => void;
  options: option[];
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
  containsFilter?: boolean;
};

const SelectInput = ({
  update,
  value,
  options,
  labelName = null,
  readOnly = false,
  requiredField = false,
  containsFilter = false
}: SelectInputType) => {
  return (
    <>
      {labelName ? (
        <div className="form-label">
          {requiredField ? (
            <div>
              <div>{labelName}</div>
              <div className="required">&nbsp;(Required)</div>
            </div>
          ) : (
            <div>{labelName}</div>
          )}
        </div>
      ) : null}
      <SingleSelect
        containsFilter={containsFilter}
        value={value}
        update={(x: option[]) => {
          const res = R.pathOr(null, [0, "value"], x);
          return update(res);
        }}
        options={options}
        disabled={readOnly}
        clearSelection
      />
    </>
  );
};
export default SelectInput;
