import React from "react";
import * as R from "ramda";
import { format } from "date-fns";
import DatePicker from "../datePicker";

type DatePickerFilterTypes = {
  propKey: string;
  filters: any;
  onChange: any;
  outPutFormat: string;
};

const DatePickerFilter = ({
  propKey,
  filters,
  onChange,
  outPutFormat
}: DatePickerFilterTypes) => {
  const val = R.prop(propKey, filters);
  const res = R.equals(null, val) ? null : new Date(val);
  return (
    <DatePicker
      date={res}
      onDateChange={(d: any) => {
        return onChange({
          ...filters,
          [propKey]: R.equals(null, d) ? null : format(d, outPutFormat)
        });
      }}
    />
  );
};

export default DatePickerFilter;
