import React from "react";
import * as R from "ramda";

export const NoData = ({
  havePermission,
  cols
}: {
  havePermission: boolean;
  cols: number;
}) => {
  return (
    <tr className="noData">
      <td colSpan={havePermission ? R.add(1, cols) : cols}>
        Nessun Dato Disponibile
      </td>
    </tr>
  );
};
export default NoData;
