import React from "react";

type ConfirmationModalType = {
  openModal: boolean;
  modalTitle: string;
  modalBody: any;
  closeModal: () => void;
  cofirmModal: () => void;
};

const ConfirmationModal = ({
  openModal,
  modalTitle,
  modalBody,
  closeModal,
  cofirmModal
}: ConfirmationModalType) => (
  <div
    className={`modal z-index-modal fade show ${openModal ? "d-block" : ""}`}
    id="configModal"
    role="dialog"
    aria-hidden="true"
  >
    <div
      className={`overlay ${openModal ? "fadeIn" : ""}`}
      onClick={closeModal}
    />
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header text-center">
          <h5 className="modal-title">{modalTitle}</h5>
        </div>
        <div className="modal-body text-center">{modalBody}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            onClick={cofirmModal}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmationModal;
