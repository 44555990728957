import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  match,
  NavLink,
} from "react-router-dom";
import { format } from "date-fns";
import {
  Selectors,
  checkBidRunningAction,
  requestBidInfoDataAction,
  clearBidInfoDataAction,
  ProcessBiddingListStateType,
  setProcessBiddingListPageAction,
  setProcessBiddingListFiltersAction,
  ProcessBiddingListFilterStateType,
  requestProcessBiddingListAction,
  clearAllDataAction,
  ProcessRunningType,
  openProcessModalAction,
  closeProcessModalAction,
  createProcessModalAction,
  ProcessModalInfoType,
  ProcessModalType,
  updateProcessModalAction,
  exportComputedBiddingAction,
  exportIpexAction,
  sentBidCloseAction,
  BidRunType,
} from "../redux/modules/management/processBidding";
import { Selectors as CridaSelectors } from "../redux/modules/crida";
import { useSelector } from "react-redux";
import { Selectors as EnumsSelectors } from "../redux/modules/enums";
import { NoData } from "../components/tableComponents/noData";
import Loader from "../components/loader";
import Pagination from "../components/tableComponents/paginationNew";
import SelectInput from "./components/selectInput";
import SelectInputModal from "./components/selectInputModal";
import DateInput from "./components/dateInput";
import DateRangeInput from "./components/dateRange";
import { getStateOptions, disableApplyBtn, getStateColStyle } from "./helper";
import ConfigModal from "../config/components/configModal";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";

type ProcessiBiddingType = {
  processBiddingList: ProcessBiddingListStateType;
  isFetching: any;
  bidInfoData: any;
  processRunning: ProcessRunningType;
  processModal: ProcessModalType;
  enums: any;
  newBidSucceed: boolean;
  bidRunStatus: BidRunType;
  match: match;
  checkBidRunningAction: () => void;
  requestBidInfoDataAction: (a: string) => void;
  clearBidInfoDataAction: () => void;
  setProcessBiddingListPageAction: (a: number) => void;
  setProcessBiddingListFiltersAction: (
    a: ProcessBiddingListFilterStateType
  ) => void;
  requestProcessBiddingListAction: () => void;
  clearAllDataAction: () => void;
  openProcessModalAction: () => void;
  closeProcessModalAction: () => void;
  updateProcessModalAction: (a: ProcessModalInfoType) => void;
  createProcessModalAction: (a: ProcessModalInfoType) => void;
  exportComputedBiddingAction: () => void;
  exportIpexAction: () => void;
  sentBidCloseAction: () => void;
};

const ProcessiBidding = ({
  processBiddingList,
  isFetching,
  bidInfoData,
  processRunning,
  processModal,
  enums,
  newBidSucceed,
  bidRunStatus,
  match,
  checkBidRunningAction,
  requestBidInfoDataAction,
  clearBidInfoDataAction,
  setProcessBiddingListPageAction,
  setProcessBiddingListFiltersAction,
  requestProcessBiddingListAction,
  clearAllDataAction,
  openProcessModalAction,
  closeProcessModalAction,
  updateProcessModalAction,
  createProcessModalAction,
  exportComputedBiddingAction,
  exportIpexAction,
  sentBidCloseAction,
}: ProcessiBiddingType) => {
  useEffect(() => {
    return () => clearAllDataAction();
  }, []);
  return (
    <div className="pageContainer">
      <Switch>
        <Route
          path={`${match.url}/Riepilogo`}
          render={(m: any) => (
            <Summary
              enums={enums}
              processModal={processModal}
              history={m.history}
              tableInfo={processBiddingList}
              processRunning={processRunning}
              bidRunStatus={bidRunStatus}
              checkBidRunning={checkBidRunningAction}
              requestProcessBiddingList={requestProcessBiddingListAction}
              setPage={setProcessBiddingListPageAction}
              setFilters={setProcessBiddingListFiltersAction}
              openProcessModal={openProcessModalAction}
              closeProcessModal={closeProcessModalAction}
              updateProcessModal={updateProcessModalAction}
              createProcessModal={createProcessModalAction}
            />
          )}
        />

        <Route
          path={`${match.url}/Offerta/:bidId`}
          render={(m: any) => (
            <Dettaglio
              data={bidInfoData}
              match={m.match}
              requestBidInfoData={requestBidInfoDataAction}
              clearBidInfoData={clearBidInfoDataAction}
              exportComputedBidding={exportComputedBiddingAction}
              exportIpex={exportIpexAction}
            />
          )}
        />
        <Redirect to={`${match.url}/Riepilogo`} />
      </Switch>
      <Loader load={isFetching || processBiddingList.fetching} />
      <NotifcationModal
        open={newBidSucceed}
        close={sentBidCloseAction}
        title="New Bid Successful"
        message="New Bid Upload Successful"
      />
    </div>
  );
};

export default R.compose<any, any, any>(
  withRouter,
  connect(
    (s: any) => ({ ...Selectors.all(s), enums: EnumsSelectors.enums(s) }),
    {
      checkBidRunningAction,
      requestBidInfoDataAction,
      clearBidInfoDataAction,
      setProcessBiddingListPageAction,
      setProcessBiddingListFiltersAction,
      requestProcessBiddingListAction,
      clearAllDataAction,
      openProcessModalAction,
      closeProcessModalAction,
      createProcessModalAction,
      updateProcessModalAction,
      exportComputedBiddingAction: exportComputedBiddingAction,
      exportIpexAction,
      sentBidCloseAction,
    }
  )
)(ProcessiBidding);

type SummaryFiltersType = {
  stateOptions: any;
  filters: ProcessBiddingListFilterStateType;
  processRunning: ProcessRunningType;
  update: (a: ProcessBiddingListFilterStateType) => void;
  openProcessModal: () => void;
};
const SummaryFilters = ({
  stateOptions,
  filters,
  processRunning,
  update,
  openProcessModal,
}: SummaryFiltersType) => {
  const updateFilters = ({ key, val }: { key: string; val: any }) =>
    update({ ...filters, [key]: val });
  return (
    <div className="d-flex">
      <div className="width100 d-flex justify-content-center align-items-center">
        <div className="auditFilters">
          <div className="filter">
            <DateRangeInput
              update={update}
              labelName="SELEZIONA PERIODO"
              value={filters}
            />
          </div>
          <div className="filter">
            <SelectInput
              update={(val) => updateFilters({ key: "state", val })}
              labelName="State"
              value={
                R.isNil(filters.state)
                  ? []
                  : [
                      {
                        value: filters.state,
                        title: getStateOptions(filters.state as any),
                      },
                    ]
              }
              options={R.map(
                (x) => ({ value: x, title: getStateOptions(x) as any }),
                stateOptions
              )}
            />
          </div>
          <div className="spacer" />
        </div>
      </div>
      <div className="spacer" />
      <button
        disabled={R.equals(processRunning.isRunning, true)}
        className={`btn btn-danger rounded-pill mx-2 ${
          R.equals(processRunning.isRunning, true) ? "disabled" : ""
        }`}
        onClick={() => openProcessModal()}
      >
        Start New
      </button>
    </div>
  );
};

type SummaryTableType = {
  history: any;
  processRunning: ProcessRunningType;
  bidRunStatus: BidRunType;
  tableInfo: ProcessBiddingListStateType;
  setPage: (a: number) => void;
};
const SummaryTable = ({
  history,
  processRunning,
  bidRunStatus,
  tableInfo,
  setPage,
}: SummaryTableType) => {
  return (
    <>
      <div className="tableContainer auditList">
        <div className="scroll">
          <table className="table">
            <thead>
              <tr>
                <th>Mercato</th>
                <th>Giorno Bid</th>
                <th>Orario</th>
                <th>User</th>
                <th>BidEvo Target</th>
                <th colSpan={2}>State</th>
              </tr>
            </thead>
            <tbody>
              {R.isEmpty(tableInfo.data) || R.isNil(tableInfo.data) ? (
                <NoData cols={6} />
              ) : (
                tableInfo.data.map((row: any) => {
                  const disabled =
                    R.equals(processRunning.biddingRunId, row.id) &&
                    R.equals(processRunning.isRunning, true);
                  return (
                    <tr key={row.id}>
                      <td>{R.pathOr(null, ["parameters", "market"], row)}</td>
                      <td>
                        {format(new Date(row.parameters.date), "dd-MM-yyyy")}
                      </td>
                      <td>
                        {format(new Date(row.startedAt), "dd-MM-yyyy HH:mm:ss")}
                      </td>
                      <td>{R.pathOr(null, ["startedBy"], row)}</td>
                      <td>
                        {R.pathOr(
                          null,
                          ["parameters", "biddingBidEvoTarget"],
                          row
                        )}
                      </td>
                      <td
                        className={`${
                          disabled ? "disabled" : ""
                        } ${getStateColStyle(
                          R.equals(processRunning.biddingRunId, row.id)
                            ? bidRunStatus
                            : R.pathOr(null, ["state"], row)
                        )}`}
                      >
                        {getStateOptions(
                          R.equals(processRunning.biddingRunId, row.id)
                            ? bidRunStatus
                            : (R.pathOr(null, ["state"], row) as any)
                        )}
                      </td>
                      <td style={{ padding: "0 !important" }}>
                        <div
                          className={`${
                            disabled
                              ? "bidding-arrow disabled"
                              : "bidding-arrow"
                          }`}
                          onClick={() =>
                            history.push(
                              `/Gestione/ProcessiBidding/Offerta/${row.id}`
                            )
                          }
                        >
                          <i className="fas fa-info"></i>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="pagingContainer">
          <Pagination
            count={tableInfo.count}
            pageSize={tableInfo.pageSize}
            page={tableInfo.page}
            onPageChange={setPage}
          />
        </div>
      </div>
    </>
  );
};

const cridaMarkets = (markets: any, crida: boolean) => {
  const res = crida
    ? R.reject(
        (x: any) => R.any(R.equals(x))(["MI4", "MI5", "MI6", "MI7"]),
        markets
      )
    : markets;

  return res.map((x: any) => ({ value: x, title: x }));
};

const NewProcessInfo = ({
  data,
  enums,
  update,
}: {
  data: ProcessModalInfoType;
  enums: any;
  update: (a: ProcessModalInfoType) => any;
}) => {

  const cridaDateValue = useSelector(CridaSelectors.cridaDate);
  const cridaDate = new Date(cridaDateValue);
  const crida = data.date === null ? false : new Date(data.date) >= cridaDate;

  const updateFilters = ({ key, val }: { key: string; val: any }) =>
    update({ ...data, [key]: val });
  useEffect(() => {
    return () =>
      update({
        date: null,
        market: null,
        biddingBidEvoTarget: null,
        applyCoefficientiUp: null,
      });
  }, []);
  return (
    <>
      <div className="form-group">
        <DateInput
          update={(val) => updateFilters({ key: "date", val })}
          requiredField
          labelName="Date"
          value={data.date}
        />
      </div>
      <div className="form-group">
        <SelectInputModal
          update={(val) => updateFilters({ key: "market", val })}
          requiredField
          labelName="Market"
          labelClass="text-capitalized-modal"
          options={cridaMarkets(enums.Mercato, crida)}
          readOnly={data.date === null}
          value={
            R.isNil(data.market)
              ? []
              : [
                  {
                    value: data.market,
                    title: data.market,
                  },
                ]
          }
        />
      </div>
      <div className="form-group">
        <SelectInputModal
          update={(val) => updateFilters({ key: "biddingBidEvoTarget", val })}
          requiredField
          labelName="Bidding BidEvo Target"
          labelClass="text-capitalized-modal"
          options={R.map(
            (x) => ({ value: x, title: x }),
            enums.BiddingBidEvoTarget
          )}
          value={
            R.isNil(data.biddingBidEvoTarget)
              ? []
              : [
                  {
                    value: data.biddingBidEvoTarget,
                    title: data.biddingBidEvoTarget,
                  },
                ]
          }
        />
      </div>
      <div className="form-group">
        <SelectInputModal
          update={(val) => updateFilters({ key: "applyCoefficientiUp", val })}
          requiredField
          labelName="Apply Coefficienti Up"
          labelClass="text-capitalized-modal"
          options={R.map(
            (x) => ({ value: x, title: x }),
            enums.ApplyCoefficientiUp
          )}
          value={
            R.isNil(data.applyCoefficientiUp)
              ? []
              : [
                  {
                    value: data.applyCoefficientiUp,
                    title: data.applyCoefficientiUp,
                  },
                ]
          }
        />
      </div>
    </>
  );
};

type SummaryType = {
  history: any;
  enums: any;
  processModal: ProcessModalType;
  processRunning: ProcessRunningType;
  bidRunStatus: BidRunType;
  tableInfo: ProcessBiddingListStateType;
  checkBidRunning: () => void;
  setPage: (a: number) => void;
  setFilters: (a: ProcessBiddingListFilterStateType) => void;
  requestProcessBiddingList: () => void;
  openProcessModal: () => void;
  closeProcessModal: () => void;
  updateProcessModal: (a: ProcessModalInfoType) => void;
  createProcessModal: (a: ProcessModalInfoType) => void;
};
const Summary = ({
  history,
  enums,
  processModal,
  processRunning,
  bidRunStatus,
  tableInfo,
  checkBidRunning,
  setPage,
  setFilters,
  requestProcessBiddingList,
  openProcessModal,
  closeProcessModal,
  updateProcessModal,
  createProcessModal,
}: SummaryType) => {
  useEffect(() => {
    R.when(R.isNil, () => checkBidRunning())(processRunning.biddingRunId);
    R.when(R.isEmpty, () => requestProcessBiddingList())(tableInfo.data);
  }, []);

  return (
    <>
      <PageOnLoad />
      <h1>Processi Bidding</h1>
      <SummaryFilters
        stateOptions={enums.BiddingRunState}
        filters={tableInfo.filters}
        processRunning={processRunning}
        update={setFilters}
        openProcessModal={openProcessModal}
      />
      <SummaryTable
        tableInfo={tableInfo}
        processRunning={processRunning}
        bidRunStatus={bidRunStatus}
        history={history}
        setPage={setPage}
      />

      <ConfigModal
        openModal={processModal.open}
        modalTitle="Create New Process"
        modalBody={
          <NewProcessInfo
            data={processModal.info}
            update={updateProcessModal}
            enums={enums}
          />
        }
        bodyClassName="modalOverflowVisisble"
        disableApplyBtn={disableApplyBtn(processModal.info)}
        closeModal={() => closeProcessModal()}
        commitChanges={() => createProcessModal(processModal.info)}
      />
    </>
  );
};

type DettaglioHeadersType = { data: any };
const DettaglioHeaders = ({ data }: DettaglioHeadersType) => {
  return (
    <>
      <NavLink to="/Gestione/ProcessiBidding" className="backButton">
        <i className="fas fa-arrow-circle-left" />
        Processi Bidding
      </NavLink>
      <h1>Dettaglio Check</h1>
      <div className="d-flex">
        <div className="detailsHeaderTitle">
          <div className="form-group text-uppercase">Mercato</div>
          <div className="form-group text-uppercase">
            <b>{R.pathOr(null, ["parameters", "market"], data)}</b>
          </div>
        </div>
        <div className="detailsHeaderTitle">
          <div className="form-group text-uppercase">Giorno Bid</div>
          <div className="form-group text-uppercase">
            <b>
              {format(
                new Date(R.pathOr(null, ["parameters", "date"], data) as any),
                "dd-MM-yyyy"
              )}
            </b>
          </div>
        </div>
        <div className="detailsHeaderTitle">
          <div className="form-group text-uppercase">BidEvo Target</div>
          <div className="form-group text-uppercase">
            <b>{R.pathOr(null, ["parameters", "biddingBidEvoTarget"], data)}</b>
          </div>
        </div>
      </div>
      <hr className="horizontalRule" />
    </>
  );
};

type DettaglioDownloadsType = {
  data: any;
  exportComputedBidding: () => void;
  exportIpex: () => void;
};
const DettaglioDownloads = ({
  data,
  exportComputedBidding,
  exportIpex,
}: DettaglioDownloadsType) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center margin-bottom-18">
        <button
          className={`btn btn-danger rounded-pill mx-2 ${
            R.isEmpty(data) || R.isNil(data) ? "disabled" : ""
          }`}
          disabled={R.isEmpty(data) || R.isNil(data)}
          onClick={() => exportComputedBidding()}
        >
          Downlad Dettagli
        </button>
        <button
          className={`btn btn-danger rounded-pill mx-2 ${
            R.isEmpty(data) || R.isNil(data) ? "disabled" : ""
          }`}
          disabled={R.isEmpty(data) || R.isNil(data)}
          onClick={() => exportIpex()}
        >
          Download IPEX XML
        </button>
      </div>
    </>
  );
};
type DettaglioLogsType = { data: any };
const DettaglioLogs = ({ data }: DettaglioLogsType) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>State</th>
            <th className="th-Messaggio">Messaggio</th>
            <th>TimeStamp</th>
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(data) || R.isNil(data) ? (
            <NoData cols={3} />
          ) : (
            data.map((row: any, i: number) => {
              return (
                <tr key={i}>
                  <td
                    className={getStateColStyle(
                      R.pathOr(null, ["logLevel"], row)
                    )}
                  >
                    {R.pathOr(null, ["logLevel"], row)}
                  </td>
                  <td>{R.pathOr(null, ["message"], row)}</td>
                  <td>
                    {format(new Date(row.timestamp), "dd-MM-yyyy HH:mm:ss")}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};

type DettaglioType = {
  data: any;
  match: match;
  requestBidInfoData: (a: string) => void;
  clearBidInfoData: () => void;
  exportComputedBidding: () => void;
  exportIpex: () => void;
};
const Dettaglio = ({
  data,
  match,
  requestBidInfoData,
  clearBidInfoData,
  exportComputedBidding,
  exportIpex,
}: DettaglioType) => {
  const bidId = R.path<any>(["params", "bidId"], match);
  useEffect(() => {
    requestBidInfoData(bidId);
    return () => clearBidInfoData();
  }, []);

  return (
    <>
      <PageOnLoad />
      <DettaglioHeaders data={data} />
      <DettaglioDownloads
        data={R.pathOr(null, ["logs"], data)}
        exportComputedBidding={exportComputedBidding}
        exportIpex={exportIpex}
      />
      <DettaglioLogs data={R.pathOr(null, ["logs"], data)} />
    </>
  );
};
