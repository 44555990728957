import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Selectors,
  exportDataAction,
  uploadDataAction,
  setFiltersTableData,
  FilterTablesStateType,
  uploadSucceededCloseAction,
  getUpProviderConfig,
  resetUpProviderConfig,
  CoefficientiUP,
} from "../redux/modules/management/coefficientiUP";
import {
  Selectors as EnumsSelectors,
  requestDynamicEnumsAction,
} from "../redux/modules/enums";
import DateRangeInput from "./components/dateRange";
import Section from "./components/section";
import Loader from "../components/loader";
import NotifcationModal from "../components/notifcationModal";
import DrapDropFileUpload from "../components/dragFile";
import PageOnLoad from "../components/pageOnLoad";
import { pipe } from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import {
  toOption,
  anyInProgess,
  NetworkRequest,
  inProgress,
  notStarted,
} from "../utils/request";

type CoefficientiUPType = {
  filters: FilterTablesStateType;
  isFetching: boolean;
  uploadSucceed: boolean;
  enums: any;
  dynamicEnums: any;
  exportDataAction: () => void;
  uploadDataAction: (a: any) => void;
  setFiltersTableData: (a: FilterTablesStateType) => void;
  uploadSucceededCloseAction: () => void;
  requestDynamicEnumsAction: () => void;
};

const CoefficientiUPView = ({
  filters,
  isFetching,
  uploadSucceed,
  dynamicEnums,
  exportDataAction,
  uploadDataAction,
  setFiltersTableData,
  uploadSucceededCloseAction,
  requestDynamicEnumsAction,
}: CoefficientiUPType) => {
  const dispatch = useDispatch();
  useEffect(() => {
    R.when(R.isEmpty, () => requestDynamicEnumsAction())(dynamicEnums.up);

    dispatch(getUpProviderConfig);
    return () => {
      dispatch(resetUpProviderConfig);
    };
  }, []);

  const { upConfig } = useSelector(Selectors.all);

  return (
    <>
      <PageOnLoad />
      <div className="pageContainer margin-bottom-18">
        <h1>Coefficienti UP</h1>
        <div className="pageContent">
          <CoefficientiUPFilters
            filters={filters}
            setFiltersTable={setFiltersTableData}
          />
          <ZonaCodice
            filters={filters}
            upEnums={dynamicEnums.up}
            setFiltersTable={setFiltersTableData}
            availableUp={pipe(
              toOption(upConfig),
              O.fold(() => [], (x) => x.table)
            )}
          />
          <DownloadUpload
            filters={filters}
            exportData={exportDataAction}
            uploadData={uploadDataAction}
          />
          <Loader
            load={anyInProgess([boolToNetworkInProgress(isFetching), upConfig])}
          />
          <NotifcationModal
            open={uploadSucceed}
            close={uploadSucceededCloseAction}
            title="Upload Successful"
            message="CoefficientiUP Upload Successful"
          />
        </div>
      </div>
    </>
  );
};

export default R.compose(
  connect(
    (s: any) => ({
      state: s,
      ...Selectors.all(s),
      enums: EnumsSelectors.enums(s),
      dynamicEnums: EnumsSelectors.dynamicEnums(s),
    }),
    {
      exportDataAction,
      uploadDataAction,
      setFiltersTableData,
      uploadSucceededCloseAction,
      requestDynamicEnumsAction,
    }
  )
)(CoefficientiUPView);

type CoefficientiUPFiltersType = {
  filters: FilterTablesStateType;
  setFiltersTable: (a: FilterTablesStateType) => void;
};
const CoefficientiUPFilters = ({
  filters,
  setFiltersTable,
}: CoefficientiUPFiltersType) => {
  return (
    <div className="d-flex">
      <div className="width100 d-flex justify-content-center align-items-center">
        <div className="auditFilters">
          <div className="filter">
            <DateRangeInput
              update={setFiltersTable}
              labelName="SELEZIONA PERIODO"
              value={filters}
            />
          </div>
          <div className="spacer" />
        </div>
      </div>
      <div className="spacer" />
    </div>
  );
};

type ZonaCodiceType = {
  filters: FilterTablesStateType;
  upEnums: any;
  setFiltersTable: (a: FilterTablesStateType) => void;
  availableUp: CoefficientiUP[];
};
const ZonaCodice = ({
  filters,
  upEnums,
  setFiltersTable,
  availableUp,
}: ZonaCodiceType) => {
  const avail = availableUp.map((x) => x.up);
  const groups = R.pipe<any[], any, any, any>(
    R.filter((x) => avail.includes(x.codiceUp)),
    R.groupBy(R.prop<any, any>("zona")),
    R.toPairs
  )(upEnums);

  return groups.length < 1 ? (
    <p className="d-flex justify-content-center py-5 text-uppercase">
      Non ci sono UP nella tabella di configurazione UP Provider
    </p>
  ) : (
    groups.map(([zona, codiceList]: any) => (
      <Section
        zona={zona}
        key={zona}
        codiceList={codiceList}
        selectedCodiceList={filters.codiceUp}
        update={(codiceUp: any) => setFiltersTable({ ...filters, codiceUp })}
      />
    ))
  );
};

type DownloadUploadType = {
  filters: FilterTablesStateType;
  exportData: () => void;
  uploadData: (a: any) => void;
};
const DownloadUpload = ({
  filters,
  exportData,
  uploadData,
}: DownloadUploadType) => {
  const [files, setFiles] = useState<any[]>([]);

  return (
    <div>
      <div className="margin-bottom-18" />
      <p className="d-flex justify-content-center">
        <button
          className="btn btn-danger rounded-pill mx-2"
          onClick={() => exportData()}
          disabled={filters.from === null || filters.to === null}
        >
          Esporta il file XLSX
        </button>
      </p>
      <div className="">
        Prima di caricare il file assicurati che sia ancora valido
      </div>

      <DrapDropFileUpload
        fileList={files}
        update={setFiles}
        validFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        upload={uploadData}
      />
    </div>
  );
};

function boolToNetworkInProgress(b: boolean): NetworkRequest<any, any> {
  return b ? inProgress : notStarted;
}
