import React, { useState } from "react";
import * as R from "ramda";
import {
  DeleteRowSendType,
  CancelChangesType
} from "../redux/modules/configs/pviProvider";
import { SortValType } from "../components/tableComponents/sort";
import Protected from "../protectedComponent";
import permissions from "../permissions";
import DrapDropFileUpload from "../components/dragFile";
import { PVIType } from "../redux/modules/configs/coreTypes";

export const modCheck = R.pipe<any, any, any, any>(
  R.filter(R.prop<any, any>("mod")),
  R.length,
  R.gt(R.__, 0)
);

export const rowClass = (modType: string) => {
  switch (modType) {
    case "Add Mapping":
      return "green-background";
    case "Update Mapping":
      return "yellow-background";
    case "Delete":
      return "red-background";
    default:
      return "";
  }
};

export type ConfigModalInfoType = {
  type: string;
  modalType: "Add Mapping" | "Update Mapping";
  value: any;
  modalRow: any;
  enums: any;
  configEnums?: any;
  dynamicEnums?: any;
  configModalInfo?: any;
  corePVI?: PVIType[]; 
  update: (a: any) => void;
};

type GetConfigModalInfoType = {
  modalRow: any;
  modalType: "Add Mapping" | "Update Mapping";
  enums: any;
  configEnums?: any;
  dynamicEnums?: any;
  corePVI?: PVIType[]; 
  update: (a: any) => void;
  func: (a: ConfigModalInfoType) => any;
};
export const getConfigModalInfo = ({
  modalRow,
  update,
  enums,
  configEnums = null,
  dynamicEnums = null,
  modalType,
  corePVI,
  func
}: GetConfigModalInfoType) => {
  return R.toPairs(modalRow).map(([type, value]) => (
    <div className="form-group" key={type}>
      {func({
        type,
        value,
        modalRow,
        update,
        modalType,
        enums,
        configEnums,
        corePVI,
        dynamicEnums
      })}
    </div>
  ));
};

export type TableType = {
  state: any;
  data: any;
  enums?: any;
  configEnums?: any;
  openModal: (a: any) => void;
  deleteRow: (a: DeleteRowSendType) => void;
  cancelModifiction: (a: CancelChangesType) => void;
};

export const filterOptionsFunc = ({
  data,
  filters
}: {
  data: any;
  filters: any;
}) => {
  const allFitlers = R.filter((x: any) => x, filters) as any;
  return R.filter((x: any) => R.whereEq(allFitlers, x), data);
};

export const filterTextFunc = ({
  key,
  data,
  filters
}: {
  key: string;
  data: any;
  filters: any;
}) =>
  R.ifElse(
    () => R.isNil(filters[key]),
    x => x,
    R.filter((r: any) => {
      return R.contains(R.toUpper(filters[key]), R.toUpper(r[key].toString()));
    })
  )(data);

  export const filterMatchTextFunc = ({
    key,
    data,
    filters
  }: {
    key: string;
    data: any;
    filters: any;
  }) =>
    R.ifElse(
      () => R.isNil(filters[key]),
      x => x,
      R.filter((r: any) => {
        return R.equals(R.toUpper(filters[key]), R.toUpper(r[key].toString()));
      })
    )(data);

type SortbyFuncType = { sort: SortValType; data: any };

export const sortbyFunc = ({ sort, data }: SortbyFuncType) => {
  const order = R.equals("desc", sort.direction)
    ? R.descend(R.prop(sort.column || ""))
    : (R.ascend(R.prop(sort.column || "")) as any);
  return R.isNil(sort.column) ? data : R.sort(order, data);
};

type DownloadUploadType = {
  exportData: () => void;
  uploadData: (a: any) => void;
};
export const DownloadUpload = ({
  exportData,
  uploadData
}: DownloadUploadType) => {
  const [files, setFiles] = useState<any[]>([]);

  return (
    <div className="container margin-bottom-18">
      <div className="margin-bottom-18" />
      <p className="d-flex justify-content-center">
        <button
          className="btn btn-danger rounded-pill mx-2"
          onClick={() => exportData()}
        >
          Esporta il file XLSX
        </button>
      </p>

      <Protected
        permission={[permissions.configurationWrite]}
        component={() => (
          <DrapDropFileUpload
            fileList={files}
            update={setFiles}
            validFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            upload={uploadData}
          />
        )}
      />
    </div>
  );
};
