import React from "react";
import * as R from "ramda";
import TextFilter from "../../components/tableComponents/textFilters";
import Sort, { SortValType } from "../../components/tableComponents/sort";

export type TextTableHeaderType = {
  title: string;
  propName?: string;
  sortFilter?: SortValType | null;
  setSortFilter?: (a: SortValType) => void;
  filters?: any;
  setFilter?: (a: any) => void;
};

export const TextTableHeader = ({
  title,
  propName = "",
  sortFilter = null,
  setSortFilter = () => true,
  filters = null,
  setFilter = () => true
}: TextTableHeaderType) => {
  return (
    <th className="tableFilterHeader">
      <span>
        {title}
        {sortFilter ? (
          <Sort
            propName={propName}
            sortColumn={
              sortFilter || {
                column: null,
                direction: null
              }
            }
            sortColumnFunc={setSortFilter}
          />
        ) : null}
      </span>
      {R.pathOr(null, [propName], filters) ? (
        <TextFilter propKey={propName} filters={filters} onChange={setFilter} />
      ) : null}
    </th>
  );
};

export default TextTableHeader;
