import * as R from "ramda";
import { format, max, min } from "date-fns";

type StringCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const stringCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: StringCellType) =>
  sheet
    .cell(rowIndex, colIndex)
    .string(R.pathOr("", path, rowData))
    .style(style);

type DateCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
  fmt: string;
};

const dateCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {},
  fmt
}: DateCellType) => {
  const val = R.pathOr(null, path, rowData);
  const date = R.ifElse(
    R.isNil,
    () => "",
    R.pipe(
      R.split(/\+|Z/g),
      R.head,
      v => format(new Date(v), fmt)
    )
  )(val);
  return sheet
    .cell(rowIndex, colIndex)
    .string(date)
    .style(style);
};

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
  defaultVal?: number;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {},
  defaultVal = 0
}: NumberCellType) =>
  sheet
    .cell(rowIndex, colIndex)
    .number(R.pathOr(defaultVal, path, rowData))
    .style(style);

type SetRowType = { sheet: any; rowData: any; rowIndex: number };
const setRowData = ({ sheet, rowData, rowIndex }: SetRowType) => {
  const info = { sheet, rowData, rowIndex };

  stringCell({
    ...info,
    colIndex: 1,
    path: ["codiceUp"]
  });
  stringCell({
    ...info,
    colIndex: 2,
    path: ["zona"]
  });
  dateCell({
    ...info,
    colIndex: 3,
    path: ["dateTimeOffset"],
    fmt: "dd-MM-yyyy"
  });
  dateCell({
    ...info,
    colIndex: 4,
    path: ["dateTimeOffset"],
    fmt: "HH"
  });
  numberCell({
    ...info,
    colIndex: 5,
    path: ["coefficienteA"],
    defaultVal: 1.0
  });
  numberCell({
    ...info,
    colIndex: 6,
    path: ["coefficienteB"],
    defaultVal: 0.0
  });

  return sheet;
};

const dateSplitFormat = R.pipe<any, any, any, any>(
  R.split(/\+|Z/g),
  R.head,
  x => format(new Date(x), "dd-MM-yyyy")
);

type CreateType = {
  sheet: any;
  data: any;
};
export const createData = ({ sheet, data }: CreateType) => {
  const orderedData = R.pipe<any, any, any, any, any, any, any>(
    R.map((x: any) => R.assoc("date", dateSplitFormat(x.dateTimeOffset), x)),
    R.groupBy((x: any) => `${x.date}-${x.codiceUp}-${x.zona}`),
    R.map(
      R.sortBy(
        R.compose<any, any, any, any>(
          R.prop<any>("codiceUp"),
          R.prop<any>("zona"),
          R.prop<any>("date")
        )
      )
    ),
    R.values,
    R.flatten,
    R.sortBy(R.prop<any>("date"))
  )(data);

  const allDates = R.pipe<any, any, any, any, any>(
    R.map(R.prop("dateTimeOffset")),
    R.map(R.split(/\+|Z/g)),
    R.map(R.head),
    R.map((x: any) => new Date(x))
  )(data);

  const minDate = min(allDates);
  const maxDate = max(allDates);

  sheet.cell(1, 2).string(format(minDate, "dd-MM-yyyy"));
  sheet.cell(2, 2).string(format(maxDate, "dd-MM-yyyy"));
  orderedData.map((rowData: any, i: any) =>
    setRowData({
      sheet,
      rowData,
      rowIndex: R.add(i, 4)
    })
  );
  return sheet;
};
