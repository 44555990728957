import React from "react";
import { useSelector } from "react-redux";
import { Selectors } from "./redux/modules/authentication";
import Main from "./Main";
import { Login } from "./Login";
import "./App.css";

function App() {
  const auth = useSelector(Selectors.auth);

  switch (auth.tag) {
    case "initial":
      return (
        <div className="spinnerContainer">
          <div className="fa fa-spinner fa-spin spinner"></div>
        </div>
      );
    case "authenticated":
      return <Main profile={auth.profile}></Main>;
    case "login-failed":
      return <Login error={auth.error}></Login>;
    case "login-required":
      return <Login></Login>;
  }
}

export default App;
