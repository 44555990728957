import xl from "excel4node";
import * as R from "ramda";

const style = {
  alignment: {
    horizontal: ["center"]
  },
  font: {
    bold: true
  },
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#99CC00",
    fgColor: "#99CC00"
  }
};

type cellType = "string" | "number" | "enum" | "date";

export type infoType = {
  propName: string;
  colName: string;
  cell: cellType;
  enumName?: any;
};

type StringCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const stringCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: StringCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .string(val)
        .style(style);
};

type DateCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
  format?: string;
};

const dateCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {},
  format = "dd/MM/yyyy"
}: DateCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .date(val)
        .style({ ...style, numberFormat: format });
};

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: NumberCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .number(val)
        .style(style);
};

export const sheetHeader = ({
  sheet,
  info
}: {
  sheet: any;
  info: infoType[];
}) =>
  info.map((row: infoType, i: number) =>
    sheet
      .cell(1, R.add(1, i))
      .string(row.colName)
      .style(style)
  );

export const sheetColTypes = ({
  sheet,
  info,
  enums
}: {
  sheet: any;
  info: infoType[];
  enums: any;
}) =>
  info.map((row: infoType, i: number) => {
    const alphaCol = xl.getExcelAlpha(R.add(1, i));
    if (R.equals(row.cell, "enum")) {
      return sheet.addDataValidation({
        type: "list",
        allowBlank: true,
        showDropDown: true,
        sqref: `${alphaCol}2:${alphaCol}1000`,
        formulas: [`=${row.enumName}!$A$1:$A$${enums[row.enumName].length}`]
      });
    }
    return sheet;
  });

type CellCreationType = {
  type: cellType;
  info: any;
};
const cellCreation = ({ type, info }: CellCreationType) => {
  switch (type) {
    case "string":
    case "enum":
    case "date":
      return stringCell({ ...info });
    case "number":
      return numberCell({ ...info });
    default:
      return null;
  }
};

type SetRowType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  info: infoType[];
};
const setRowData = ({ sheet, rowData, rowIndex, info }: SetRowType) => {
  info.map((row: infoType, col: number) =>
    cellCreation({
      type: row.cell,
      info: {
        sheet,
        rowData,
        rowIndex,
        colIndex: R.add(1, col),
        path: [row.propName]
      }
    })
  );

  return sheet;
};

type SheetBodyType = {
  sheet: any;
  info: infoType[];
  data: any;
};
export const sheetBody = ({ sheet, info, data }: SheetBodyType) =>
  data.map((rowData: any, i: number) =>
    setRowData({ sheet, rowData, rowIndex: R.add(2, i), info })
  );

type SheetEnumType = {
  wb: any;
  name: string;
  data: any;
};

export const sheetEnum = ({ wb, name, data }: SheetEnumType) => {
  var sheet = wb.addWorksheet(name, { hidden: true });
  data.map((val: any, i: number) => sheet.cell(R.add(i, 1), 1).string(val));

  return wb;
};

type CreateExcelType = {
  fileName: string;
  sheetName: string;
  data: any;
  enums: any;
  info: infoType[];
};

export const createExcel = ({
  fileName,
  sheetName,
  data,
  enums,
  info
}: CreateExcelType) => {
  var wb = new xl.Workbook({
    workbookView: {}
  });
  var sheet = wb.addWorksheet(sheetName);
  const enumList = R.pipe<any, any, any, any>(
    R.filter(R.propEq("cell", "enum")),
    R.map(R.prop("enumName")),
    R.uniq
  )(info);

  R.pipe<any, any, any, any>(
    R.pick(enumList),
    R.toPairs,
    R.map(([name, data]): any => {
      if (R.contains(name, enumList)) {
        sheetEnum({ wb, name, data });
      }
      return true;
    })
  )(enums);

  sheetHeader({ sheet, info });
  sheetBody({ sheet, info, data });
  sheetColTypes({ sheet, info, enums });

  return wb.writeToBuffer().then(function(buffer: any) {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([buffer], { type: "application/octet-stream" })
    );
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  });
};

export default createExcel;
