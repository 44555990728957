import React from "react";
import { format as tzFormat } from "date-fns-tz";
import DateRange from "../components/dateRange";
import * as R from "ramda";
import {
  refreshRagioneSociale,
  clearData,
  Selectors,
  selectMGPDate,
  selectRagioneSociale,
  selectPVIs,
  exportExcel,
} from "../redux/modules/Esiti";
import { Selectors as CridaSelectors } from "../redux/modules/crida";
import { useDispatch, useSelector } from "react-redux";
import { pipe } from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import { toOption, fold, anyInProgess } from "../utils/request";
import MultiSelect from "../components/multiselect";
import Loader from "../components/loader";
import NoData from "../components/tableComponents/noData";
import Pagination from "../components/tableComponents/paginationNew";
import SingleSelect from "../components/singleSelectMain";
import PageOnLoad from "../components/pageOnLoad";
import { isAfter } from "date-fns";

type MarketColsType = {
  market: string;
  unit: string;
  prop: string;
  crida: boolean | null;
};
const marketCols: MarketColsType[] = [
  { market: "MGP Q", unit: "MWh", prop: "mgP_Q", crida: true },
  { market: "MGP P", unit: "€/MWh", prop: "mgP_P", crida: true },
  { market: "MI1 Q", unit: "MWh", prop: "mI1_Q", crida: true },
  { market: "MI1 P", unit: "€/MWh", prop: "mI1_P", crida: true },
  { market: "MI2 Q", unit: "MWh", prop: "mI2_Q", crida: true },
  { market: "MI2 P", unit: "€/MWh", prop: "mI2_P", crida: true },
  { market: "MI3 Q", unit: "MWh", prop: "mI3_Q", crida: true },
  { market: "MI3 P", unit: "€/MWh", prop: "mI3_P", crida: true },
  { market: "MI4 Q", unit: "MWh", prop: "mI4_Q", crida: false },
  { market: "MI4 P", unit: "€/MWh", prop: "mI4_P", crida: false },
  { market: "MI5 Q", unit: "MWh", prop: "mI5_Q", crida: false },
  { market: "MI5 P", unit: "€/MWh", prop: "mI5_P", crida: false },
  { market: "MI6 Q", unit: "MWh", prop: "mI6_Q", crida: false },
  { market: "MI6 P", unit: "€/MWh", prop: "mI6_P", crida: false },
  { market: "MI7 Q", unit: "MWh", prop: "mI7_Q", crida: false },
  { market: "MI7 P", unit: "€/MWh", prop: "mI7_P", crida: false },
];

export const EsitiComponent = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(refreshRagioneSociale);
    return () => {
      dispatch(clearData);
    };
  }, [dispatch]);

  const esiti = useSelector(Selectors.all);
  const cridaDate = useSelector(CridaSelectors.cridaDate);
  const [page, setPage] = React.useState<number>(1);
  const pageSize = 24;

  const crida = checkCrida(
    esiti.dateSelect.start,
    esiti.dateSelect.end,
    cridaDate
  );

  React.useEffect(() => {
    setPage(1);
  }, [esiti.data]);

  return (
    <>
      <PageOnLoad />
      <div className="pageContainer">
        <Loader load={anyInProgess([esiti.ragioneSociale, esiti.pvis])} />

        <h1>Esiti</h1>
        <div className="pageContent">
          <div className="container noPadding main-panel">
            <div className="left-panel">
              <label className="form-group full-width">
                <span className="form-label">Ragione Sociale</span>

                {pipe(
                  esiti.selectedRagioneSociale,
                  O.fold(
                    () => <div className="text-secondary"></div>,
                    (r) => (
                      <SingleSelect
                        containsFilter
                        value={[
                          { value: r.partitaIva, title: r.ragioneSociale },
                        ]}
                        update={(x: any[]) =>
                          dispatch(
                            selectRagioneSociale(R.pathOr(
                              null,
                              [0, "info"],
                              x
                            ) as any)
                          )
                        }
                        options={pipe(
                          toOption(esiti.ragioneSociale),
                          O.fold(
                            () => [],
                            (ragione) =>
                              R.map(
                                (x: any) => ({
                                  value: x.partitaIva,
                                  title: x.ragioneSociale,
                                  info: x,
                                }),
                                ragione
                              )
                          )
                        )}
                      />
                    )
                  )
                )}
              </label>
              <div className="form-group">
                {pipe(
                  toOption(esiti.pvis),
                  O.fold(
                    () => (
                      <div>
                        <div className="selectionContainerEmpty">
                          Select a ragione
                        </div>
                        <hr />
                      </div>
                    ),
                    (pvis) => (
                      <MultiSelect
                        options={pvis.map((x) => ({
                          key: x.nome,
                          pvi: x.pvi,
                          checked: !!esiti.selectedPVIs.find(
                            (p) => x.nome === p.nome
                          ),
                        }))}
                        onChange={(checkedPvis) =>
                          pipe(
                            checkedPvis,
                            A.filterMap((x) =>
                              x.checked ? O.some(x.key) : O.none
                            ),
                            (selected) =>
                              pvis.filter((x) => selected.includes(x.nome)),
                            selectPVIs,
                            dispatch
                          )
                        }
                      ></MultiSelect>
                    )
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        {fold(esiti.data, {
          notStarted: () => (
            <div className="selectionContainerEmpty">Select a pvi</div>
          ),
          inProgress: () => (
            <div className="text-center">
              <div className="fa fa-spinner fa-spin"></div>
            </div>
          ),
          fail: (e) => (
            <div className="selectionContainerEmpty">Select a pvi</div>
          ),
          success: (data) => {
            const tableData = R.pipe(
              R.drop((page - 1) * pageSize),
              R.take(pageSize)
            )(data);

            return (
              <div>
                <div className="spacer"></div>
                <div className="container d-flex flex-column">
                  <div className="form-group text-uppercase">
                    <div className="center-vertical">
                      <div>
                        <span className="form-label">Seleziona Periodo</span>
                        <DateRange
                          startDate={esiti.dateSelect.start}
                          onStartDateChange={(d) =>
                            dispatch(selectMGPDate(d, esiti.dateSelect.end))
                          }
                          endDate={esiti.dateSelect.end}
                          onEndDateChange={(d) =>
                            dispatch(selectMGPDate(esiti.dateSelect.start, d))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-uppercase">
                    <div className="center-vertical">
                      <button
                        className="btn btn-danger rounded-pill mx-2"
                        onClick={() => dispatch(exportExcel)}
                      >
                        Esporta il file XLSX
                      </button>
                    </div>
                  </div>
                  <div className="spacer"></div>
                </div>

                <div className="tableContainer">
                  <div className="scroll">
                    <table className="esitiTable">
                      <thead>
                        <tr>
                          <th>
                            Impianto <br /> &nbsp;
                          </th>
                          <th>
                            UP <br /> &nbsp;
                          </th>
                          <th>
                            Data <br /> &nbsp;
                          </th>
                          <th>
                            Ora <br /> &nbsp;
                          </th>
                          {marketCols.map((x) =>
                            x.crida || x.crida === crida ? (
                              <th>
                                {x.market} <br /> ({x.unit})
                              </th>
                            ) : null
                          )}
                          <th>
                            PV <br /> &nbsp;
                          </th>
                          <th>
                            RispettoCapacity <br /> &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {R.isEmpty(data) || R.isNil(data) ? (
                          <NoData cols={20} />
                        ) : (
                          tableData.map((row: any, i: number) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {getPlantName({
                                    plant: row.pvi,
                                    list: esiti.selectedPVIs,
                                  })}
                                </td>
                                <td>
                                  {getUpName({
                                    up: row.pvi,
                                    list: esiti.selectedPVIs,
                                  })}
                                </td>
                                <td>
                                  {tzFormat(
                                    new Date(
                                      R.head(R.split("+", row.dateTimeOffset))
                                    ),
                                    "dd-MM-yyyy",
                                    {
                                      timeZone: "Europe/Rome",
                                    }
                                  )}
                                </td>
                                <td>
                                  {tzFormat(
                                    new Date(
                                      R.head(R.split("+", row.dateTimeOffset))
                                    ),
                                    "HH",
                                    {
                                      timeZone: "Europe/Rome",
                                    }
                                  )}
                                </td>
                                {marketCols.map((x) =>
                                  x.crida || x.crida === crida ? (
                                    <td>{row[x.prop]}</td>
                                  ) : null
                                )}
                                <td>{row.pv}</td>
                                <td>{(row.isCapacityMarketSatisfied == null ? "" : row.isCapacityMarketSatisfied.toString())}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="pagingContainer">
                    <Pagination
                      count={data.length}
                      pageSize={pageSize}
                      page={page}
                      onPageChange={setPage}
                    />
                  </div>
                </div>
              </div>
            );
          },
        })}
        <div className="spacer"></div>
      </div>
    </>
  );
};

export default EsitiComponent;

export const getPlantName = ({ plant, list }: { plant: string; list: any }) =>
  R.pipe<any, any, any>(
    R.filter(R.propEq("pvi", plant)),
    R.pathOr(plant, [0, "nome"])
  )(list);

export const getUpName = ({ up, list }: { up: string; list: any }) =>
  R.pipe<any, any, any>(
    R.filter(R.propEq("pvi", up)),
    R.pathOr(up, [0, "codiceUp"])
  )(list);

const checkCrida = (start: Date, end: Date, cridaDate: string) => {
  const crida = new Date(cridaDate);

  return isAfter(start, crida);
};
