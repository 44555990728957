import { EMPTY, Observable, of, defer, from } from "rxjs";
import * as RxOp from "rxjs/operators";
import {
  NetworkRequest,
  notStarted,
  success,
  inProgress,
  fail
} from "../../utils/request";
import { pagination, getAllPages } from "../../utils/pagination";
import { Dependancies } from "../storeTypes";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/TaskEither";
import * as A from "fp-ts/lib/Array";
import * as t from "io-ts";
import { pipe } from "fp-ts/lib/pipeable";
import {
  RagioneSociale,
  PVI,
  RagioneSocialePVI,
  EsitoBiddingComputedPVI
} from "./ragioneSocialePvi/types";
import { combineEpics, ofType } from "redux-observable";
import { format, toDate } from "date-fns-tz";
import { addDays, startOfDay } from "date-fns";
import createExcel from "./esiti/createExcel";

export const key = "Esiti";

export const refreshRagioneSociale = {
  type: "rtm/ESITI/REFRESH_RAGIONE_SOCIALE"
} as const;

const refreshRagioneSocialeFail = (error: string) =>
  ({
    type: "rtm/ESITI/REFRESH_RAGIONE_SOCIALE_FAILED",
    error
  } as const);

const setRagioneSocialeOptions = (ragioneSociale: RagioneSociale[]) =>
  ({
    type: "rtm/ESITI/SET_RAGIONE_SOCIALE_OPTIONS",
    ragioneSociale
  } as const);

export const selectRagioneSociale = (ragioneSociale: RagioneSociale) =>
  ({
    type: "rtm/ESITI/SELECT_RAGIONE_SOCIALE",
    ragioneSociale
  } as const);

const fectchPVIsFail = (error: string) =>
  ({
    type: "rtm/ESITI/FETCH_PVI_FAIL",
    error
  } as const);

const setPvis = (pvis: PVI[]) =>
  ({
    type: "rtm/ESITI/SET_PVIS",
    pvis
  } as const);

export const selectPVIs = (pvis: PVI[]) =>
  ({
    type: "rtm/ESITI/SELECT_PVI",
    pvis
  } as const);

export const selectMGPDate = (start: Date, end: Date) =>
  ({
    type: "rtm/ESITI/SELECT_MGP_DATE",
    start,
    end
  } as const);

export const fetchDataError = (error: string) =>
  ({
    type: "rtm/ESITI/FETCH_DATA_ERROR",
    error
  } as const);
export const setData = (data: EsitoBiddingComputedPVI[]) =>
  ({
    type: "rtm/ESITI/SET_DATA",
    data
  } as const);

export const clearData = {
  type: "rtm/ESITI/CLEAR_DATA"
} as const;

export const exportExcel = {
  type: "rtm/ESITI/EXPORT_EXCEL"
} as const;

type Action =
  | typeof clearData
  | typeof exportExcel
  | typeof refreshRagioneSociale
  | ReturnType<
      | typeof selectRagioneSociale
      | typeof refreshRagioneSocialeFail
      | typeof setRagioneSocialeOptions
      | typeof setPvis
      | typeof fectchPVIsFail
      | typeof selectPVIs
      | typeof selectMGPDate
      | typeof setData
      | typeof fetchDataError
    >;

type DateSelect = { start: Date; end: Date };

export type State = {
  ragioneSociale: NetworkRequest<string, RagioneSociale[]>;
  selectedRagioneSociale: O.Option<RagioneSociale>;
  pvis: NetworkRequest<string, PVI[]>;
  selectedPVIs: PVI[];
  dateSelect: DateSelect;
  data: NetworkRequest<string, EsitoBiddingComputedPVI[]>;
};

export const initialState: State = {
  ragioneSociale: notStarted,
  selectedRagioneSociale: O.none,
  pvis: notStarted,
  selectedPVIs: [],
  dateSelect: {
    start: new Date(),
    end: addDays(new Date(), 1)
  },
  data: notStarted
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case "rtm/ESITI/CLEAR_DATA":
      return initialState;
    case "rtm/ESITI/REFRESH_RAGIONE_SOCIALE":
      return { ...state, ragioneSociale: inProgress };
    case "rtm/ESITI/REFRESH_RAGIONE_SOCIALE_FAILED":
      return { ...state, ragioneSociale: fail(action.error) };
    case "rtm/ESITI/SET_RAGIONE_SOCIALE_OPTIONS":
      return {
        ...state,
        ragioneSociale: success(action.ragioneSociale),
        selectedRagioneSociale: pipe(
          state.selectedRagioneSociale,
          O.alt(() => A.head(action.ragioneSociale))
        )
      };
    case "rtm/ESITI/SELECT_RAGIONE_SOCIALE":
      return {
        ...state,
        selectedRagioneSociale: O.some(action.ragioneSociale),
        pvis: inProgress,
        selectedPVIs: []
      };
    case "rtm/ESITI/FETCH_PVI_FAIL":
      return { ...state, pvis: fail(action.error) };
    case "rtm/ESITI/SET_PVIS":
      return {
        ...state,
        pvis: success(action.pvis),
        selectedPVIs: action.pvis.slice(0, 1)
      };
    case "rtm/ESITI/SELECT_PVI":
      return {
        ...state,
        selectedPVIs: action.pvis
      };
    case "rtm/ESITI/SELECT_MGP_DATE":
      return {
        ...state,
        dateSelect: { start: action.start, end: action.end }
      };
    case "rtm/ESITI/SET_DATA":
      return {
        ...state,
        data: success(action.data)
      };
    case "rtm/ESITI/FETCH_DATA_ERROR":
      return {
        ...state,
        data: fail(action.error)
      };
    case "rtm/ESITI/EXPORT_EXCEL":
      return state;
  }
}

const refreshRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    ofType("rtm/ESITI/REFRESH_RAGIONE_SOCIALE"),
    RxOp.exhaustMap(
      getAllPages((skip = 0, limit = 999) =>
        deps.request.get(
          `core/ragionesociale?skip=${skip}&limit=${limit}&sort=RagioneSociale%20asc`,
          pagination(RagioneSociale)
        )
      )
    ),
    RxOp.map(
      E.fold<string, RagioneSociale[], Action>(
        refreshRagioneSocialeFail,
        setRagioneSocialeOptions
      )
    )
  );
const setRagioneSocialeOptionsEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap(a =>
      a.type === "rtm/ESITI/SET_RAGIONE_SOCIALE_OPTIONS"
        ? pipe(
            from(a.ragioneSociale),
            RxOp.take(1)
          )
        : EMPTY
    ),
    RxOp.exhaustMap(a =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${a.partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      )
    ),
    RxOp.map(E.map(x => x.map(x => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const selectRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap(a =>
      a.type === "rtm/ESITI/SELECT_RAGIONE_SOCIALE" ? of(a) : EMPTY
    ),
    RxOp.exhaustMap(a =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${
              a.ragioneSociale.partitaIva
            }&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      )
    ),
    RxOp.map(E.map(x => x.map(x => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const getUserSelections = (
  state$: Observable<any>
): Observable<O.Option<[PVI[], DateSelect]>> =>
  pipe(
    state$,
    RxOp.first(),
    RxOp.map(Selectors.all),
    RxOp.map(s =>
      pipe(
        s.selectedPVIs.length > 0 ? O.some(s.selectedPVIs) : O.none,
        O.map(pvis => [pvis, s.dateSelect])
      )
    )
  );

const getEsitoBiddingTimeserie = (
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    getUserSelections(state$),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Select a PVI")),
        ([pvis, date]) =>
          defer(
            getAllPages((skip = 0, limit = 999) =>
              deps.request.get(
                `bidding/esitoBiddingComputedPVI?skip=${skip}&limit=${limit}&${pvis
                  .map(p => `pvi=${p.pvi}`)
                  .join("&")}&${`start=${pipe(
                  toDate(date.start),
                  startOfDay,
                  d =>
                    format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                      timeZone: "Europe/Rome"
                    }),
                  encodeURIComponent
                )}&end=${pipe(
                  toDate(date.end),
                  (x: any) => addDays(x, 1),
                  startOfDay,
                  d =>
                    format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                      timeZone: "Europe/Rome"
                    }),
                  encodeURIComponent
                )}`}`,
                pagination(t.any)
              )
            )
          )
      )
    )
  );

const userSelectionEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap(a =>
      [
        "rtm/ESITI/SELECT_MGP_DATE",
        "rtm/ESITI/SET_PVIS",
        "rtm/ESITI/SELECT_PVI",
        "rtm/ESITI/SELECT_RAGIONE_SOCIALE"
      ].includes(a.type)
        ? of(a)
        : EMPTY
    ),
    RxOp.mergeMapTo(getEsitoBiddingTimeserie(state$, deps)),
    RxOp.map(
      E.fold<string, EsitoBiddingComputedPVI[], Action>(fetchDataError, setData)
    )
  );

const exportDataEpic = (action$: Observable<any>, state$: Observable<any>) =>
  action$.pipe(
    RxOp.filter(action => action.type === "rtm/ESITI/EXPORT_EXCEL"),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map(state => {
          const info = Selectors.all(state) as any;
          return createExcel({
            fileName: "Esiti",
            info
          });
        })
      )
    ),
    RxOp.mergeMapTo(EMPTY)
  );

export const epic = combineEpics(
  refreshRagioneSocialeEpic,
  setRagioneSocialeOptionsEpic,
  selectRagioneSocialeEpic,
  userSelectionEpic,
  exportDataEpic
);

export const Selectors = { all: (s: any): State => s[key] };
