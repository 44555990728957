import React from "react";
import * as R from "ramda";
import main from "../styles/header.module.scss";
import { User } from "ark-auth/dist/types";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/modules/authentication";
import { unlessChild } from "../helper";

export const UserProfile = (props: { profile: User }) => {
  const dispatch = useDispatch();
  const [showProfile, setShowProfile] = React.useState(false);
  return (
    <div
      tabIndex={0}
      className={main.profile}
      onFocus={() => setShowProfile(true)}
      onBlur={unlessChild(() => setShowProfile(false))}
    >
      <img
        className={main.userImage}
        src={props.profile.picture}
        alt="profile"
      />
      <span>{props.profile.name || props.profile.email}</span>
      <div
        className={`fa fa-chevron-${showProfile ? "up" : "down"} ${
          main.chevron
        }`}
        onClick={() => setShowProfile(R.not)}
      ></div>
      {showProfile ? (
        <>
          <div className={main.profileDialog}>
            <div className={`fas fa-sort-up ${main.dialogArrow}`}></div>
            <div className={main.imageContainer}>
              {props.profile.email}
            </div>
            <div className="d-flex">
              <div className={main.spacer}></div>
              <button
                className={main.button}
                onClick={() => dispatch(logoutAction)}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UserProfile;
