import XLSX from "xlsx";
import { Observable, of } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";

export const readFile = ({ file }: { file: any }) =>
  Observable.create((obs: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const dataResult = e.target.result;
      const workbook = XLSX.read(dataResult, { type: "array" });
      const sheets = workbook.SheetNames;
      const res = [] as any;
      sheets.map(sheet => {
        return res.push({
          sheet,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
            header: [
              "codiceUp",
              "zona",
              "date",
              "hour",
              "coefficienteA",
              "coefficienteB"
            ],
            defval: null
          })
        });
      });
      obs.next(res);
      obs.complete();
    };
    reader.readAsArrayBuffer(file);
  });

type FormJsonType = { file: any };
export const formJson = ({ file }: FormJsonType) =>
  of(file).pipe(
    RxOp.flatMap(file => file),
    RxOp.flatMap(file => readFile({ file })),
    RxOp.flatMap((x: any) => x),
    RxOp.map(R.pathOr(null, ["data"])),
    RxOp.toArray()
  );
