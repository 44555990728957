import React from "react";
import * as R from "ramda";
import MultiSelect from "../components/multiselect";
import { pipe } from "fp-ts/lib/pipeable";
import { useDispatch, useSelector } from "react-redux";
import {
  Selectors,
  refreshRagioneSociale,
  selectRagioneSociale,
  clearData,
  selectPVIs,
  selectMGPDate,
  modifyOnline,
  downloadXML,
} from "../redux/modules/Combustibili";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import { toOption, anyInProgess } from "../utils/request";
import Datepicker from "react-datepicker";
import { format, subMonths } from "date-fns";
import { it } from "date-fns/locale";
import EditCombustibili from "./EditCombustibili";
import { fold } from "../utils/request";
import Protected from "../protectedComponent";
import permissions from "../permissions";
import NoData from "../components/tableComponents/noData";
import Loader from "../components/loader";
import { PVI } from "../redux/modules/ragioneSocialePvi/types";
import SingleSelect from "../components/singleSelectMain";
import PageOnLoad from "../components/pageOnLoad";

export default function Combustibili() {
  const dispatch = useDispatch();
  const combustibili = useSelector(Selectors.all);

  React.useEffect(() => {
    dispatch(refreshRagioneSociale);
    return () => {
      dispatch(clearData);
    };
  }, [dispatch]);

  return fold(combustibili.modifyOnline, {
    inProgress: () => null,
    fail: () => null,
    success: () => <EditCombustibili></EditCombustibili>,
    notStarted: () => (
      <>
        <PageOnLoad />
        <div>
          <div className="pageContainer d-flex flex-column">
            <Loader
              load={anyInProgess([
                combustibili.ragioneSociale,
                combustibili.pvis,
                combustibili.reports,
                combustibili.modifyOnline,
                combustibili.pviData,
                combustibili.downloadXML,
                combustibili.checkReports,
              ])}
            />
            <div className="form-group">
              <span className="form-label">Ragione Sociale</span>

              {pipe(
                combustibili.selectedRagioneSociale,
                O.fold(
                  () => <div className="text-secondary"></div>,
                  (r) => (
                    <SingleSelect
                      containsFilter
                      value={[{ value: r.partitaIva, title: r.ragioneSociale }]}
                      update={(x: any[]) =>
                        dispatch(
                          selectRagioneSociale(R.pathOr(
                            null,
                            [0, "info"],
                            x
                          ) as any)
                        )
                      }
                      options={pipe(
                        toOption(combustibili.ragioneSociale),
                        O.fold(
                          () => [],
                          (ragione) =>
                            R.map(
                              (x: any) => ({
                                value: x.partitaIva,
                                title: x.ragioneSociale,
                                info: x,
                              }),
                              ragione
                            )
                        )
                      )}
                    />
                  )
                )
              )}
            </div>
            <div className="form-group">
              {pipe(
                toOption(combustibili.pvis),
                O.fold(
                  () => (
                    <>
                      <div className="selectionContainer">
                        SELEZIONA A RAGIONE
                      </div>
                      <hr />
                    </>
                  ),
                  (pvis) => (
                    <MultiSelect
                      options={pvis.map((x) => ({
                        key: x.nome,
                        pvi: x.pvi,
                        checked: !!combustibili.selectedPVIs.find(
                          (p) => x.nome === p.nome
                        ),
                      }))}
                      onChange={(checkedPvis) =>
                        pipe(
                          checkedPvis,
                          A.filterMap((x) =>
                            x.checked ? O.some(x.key) : O.none
                          ),
                          (selected) =>
                            pvis.filter((x) => selected.includes(x.nome)),
                          selectPVIs,
                          dispatch
                        )
                      }
                    ></MultiSelect>
                  )
                )
              )}
            </div>
            {pipe(
              combustibili.dateSelect,
              O.fold(
                () => null,
                (date) => (
                  <>
                    <span className="form-label">Seleziona Periodo</span>
                    <Datepicker
                      className="dateInput-NotRounded date margin-bottom-18"
                      onChange={(date) =>
                        date &&
                        dispatch(selectMGPDate(format(date, "yyyy-MM-01")))
                      }
                      selected={new Date(date)}
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                    />
                  </>
                )
              )
            )}
            <div className="spacer" />
            <div className="d-flex justify-content-center align-items-center">
              <Protected
                permission={permissions.datiCombustibiliWritePermissions}
                component={() => (
                  <div
                    title={
                      combustibili.selectedPVIs.length === 0
                        ? "Seleziona almeno un impianto"
                        : combustibili.selectedPVIs.length > 1
                        ? "Seleziona un solo impianto per effettuare la modifica"
                        : ""
                    }
                  >
                    <button
                      className="btn btn-danger rounded-pill mx-2"
                      onClick={() => dispatch(modifyOnline)}
                      disabled={
                        R.isEmpty(combustibili.selectedPVIs) ||
                        combustibili.selectedPVIs.length > 1
                      }
                    >
                      Modifica Online
                    </button>
                  </div>
                )}
              />
              <div
                title={
                  combustibili.selectedPVIs.length === 0
                    ? "Seleziona almeno un impianto"
                    : ""
                }
              >
                <button
                  className="btn btn-danger rounded-pill mx-2"
                  onClick={() => dispatch(downloadXML)}
                  disabled={
                    R.pipe<any, any, any, any>(
                      R.pathOr([], ["checkReports", "value"]),
                      R.filter(
                        R.propEq(
                          "competenza",
                          R.pathOr(null, ["dateSelect", "value"], combustibili)
                        )
                      ),
                      R.isEmpty
                    )(combustibili) || combustibili.selectedPVIs.length > 1
                  }
                >
                  Esporta i file XML
                </button>
              </div>
            </div>
            <div className="spacer" />
          </div>
          <div className="d-flex flex-column">
            <table className="combustibiliTable">
              <thead>
                <tr>
                  {pipe(
                    combustibili.dateSelect,
                    O.fold(
                      () => null,
                      (date) => (
                        <>
                          <th>Impianto</th>
                          <th>
                            {productNameFunc({
                              func: subMonths,
                              val: 6,
                              selectedDate: date,
                              fmt: "MMMM  yyyy",
                            })}
                          </th>
                          <th>
                            {productNameFunc({
                              func: subMonths,
                              val: 5,
                              selectedDate: date,
                              fmt: "MMMM  yyyy",
                            })}
                          </th>
                          <th>
                            {productNameFunc({
                              func: subMonths,
                              val: 4,
                              selectedDate: date,
                              fmt: "MMMM  yyyy",
                            })}
                          </th>
                          <th>
                            {productNameFunc({
                              func: subMonths,
                              val: 3,
                              selectedDate: date,
                              fmt: "MMMM  yyyy",
                            })}
                          </th>
                          <th>
                            {productNameFunc({
                              func: subMonths,
                              val: 2,
                              selectedDate: date,
                              fmt: "MMMM  yyyy",
                            })}
                          </th>
                          <th>
                            {productNameFunc({
                              func: subMonths,
                              val: 1,
                              selectedDate: date,
                              fmt: "MMMM  yyyy",
                            })}
                          </th>
                        </>
                      )
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {pipe(
                  toOption(combustibili.reports),
                  O.fold(
                    () => <NoData cols={7} />,
                    (reports) => {
                      return R.isEmpty(combustibili.selectedPVIs) ||
                        R.isNil(combustibili.selectedPVIs) ? (
                        <NoData cols={7} />
                      ) : (
                        combustibili.selectedPVIs.map((row: PVI, i: number) => {
                          return (
                            <tr key={i}>
                              <td>{R.pathOr(null, ["nome"], row)}</td>
                              <ProductValue
                                plant={row.pvi}
                                data={reports}
                                func={subMonths}
                                val={6}
                              />
                              <ProductValue
                                plant={row.pvi}
                                data={reports}
                                func={subMonths}
                                val={5}
                              />
                              <ProductValue
                                plant={row.pvi}
                                data={reports}
                                func={subMonths}
                                val={4}
                              />
                              <ProductValue
                                plant={row.pvi}
                                data={reports}
                                func={subMonths}
                                val={3}
                              />
                              <ProductValue
                                plant={row.pvi}
                                data={reports}
                                func={subMonths}
                                val={2}
                              />
                              <ProductValue
                                plant={row.pvi}
                                data={reports}
                                func={subMonths}
                                val={1}
                              />
                            </tr>
                          );
                        })
                      );
                    }
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="spacer" />
        </div>
      </>
    ),
  });
}

type ProductValueType = {
  func: any;
  val: number;
  plant: string;
  data: any;
};
const ProductValue = ({ plant, data, func, val }: ProductValueType) => {
  const date = R.pipe<Date, Date, string>(
    (x) => func(x, val),
    (x) => format(x, "yyyy-MM-01")
  )(new Date());

  const res = R.pipe(
    R.filter(R.propEq("pvi", plant)),
    R.filter(R.propEq("competenza", date)),
    R.isEmpty
  )(data);

  return (
    <td>
      <span
        className={`fa fa-${
          res ? "times-circle text-danger" : "check-circle text-success"
        }`}
      ></span>
    </td>
  );
};

type ProductNameFuncType = {
  func: any;
  val: number;
  fmt: string;
  selectedDate: any;
  append?: string;
};
const productNameFunc = ({
  func,
  val,
  fmt,
  selectedDate,
  append = "",
}: ProductNameFuncType) => {
  const headerVal = R.pipe<Date, Date, string, string>(
    (x) => func(x, val),
    (x) => format(x, fmt, { locale: it }),
    (s) => s.charAt(0).toUpperCase() + s.slice(1)
  )(new Date());
  const date = R.pipe<Date, Date, string>(
    (x) => func(x, val),
    (x) => format(x, "yyyy-MM-01")
  )(new Date());

  return (
    <a className={`bidMonths ${R.equals(selectedDate, date) ? "active" : ""}`}>
      {`${append}${headerVal}`}
    </a>
  );
};
