import * as R from "ramda";

export const locationChangeCheck = R.pipe<any, any, any, any>(
  R.replace(/\d+/g, "XX"),
  (x: any) => R.filter(R.propEq("path", x), InsightsPaths),
  R.ifElse(R.isEmpty, () => false, R.path([0, "name"]))
);

const InsightsPaths = [
  {
    path: "/DatiProduzione/Produzione",
    name: "DatiProduzione Produzione",
  },
  {
    path: "/DatiProduzione/Settlement",
    name: "DatiProduzione Settlement",
  },
  {
    path: "/AccessoAlMercato/MercatiSpot",
    name: "AccessoAl Mercato Mercati Spot",
  },
  {
    path: "/AccessoAlMercato/MercatiForward",
    name: "AccessoAl Mercato Mercati Forward",
  },
  {
    path: "/MarketBid/MGP&MI",
    name: "Market Bid MGP & MI",
  },
  {
    path: "/MarketBid/Disponibilita",
    name: "Market Bid Disponibilita",
  },
  {
    path: "/MarketBid/Combustibili",
    name: "Market Bid Combustibili",
  },
  {
    path: "/Esiti",
    name: "Esiti",
  },
  {
    path: "/Gestione/ProcessiBidding/Riepilogo",
    name: "Gestione Processi Bidding Riepilogo",
  },
  {
    path: "/Gestione/ProcessiBidding/Offerta/XX",
    name: "Gestione Processi Bidding Offerta",
  },
  {
    path: "/Gestione/CoefficientiUP",
    name: "Gestione Coefficienti UP",
  },
  {
    path: "/Gestione/ProcessoInserimentoContratti/ForecastDeal",
    name: "Gestione Processo Inserimento Contratti Forecast Deal",
  },
  {
    path: "/Gestione/ProcessoInserimentoContratti/ActualDeal",
    name: "Gestione Processo Inserimento Contratti Actual Deal",
  },
  {
    path: "/Configurazioni/UPProvider",
    name: "Configurazioni UP Provider",
  },
  {
    path: "/Configurazioni/PVIProvider",
    name: "Configurazioni PVI Provider",
  },
  {
    path: "/Configurazioni/Tensione",
    name: "Configurazioni Tensione",
  },
  {
    path: "/Configurazioni/UpBackup",
    name: "Configurazioni Up Backup",
  },
  {
    path: "/Configurazioni/SpreadMGP",
    name: "Configurazioni Spread MGP",
  },
  {
    path: "/Configurazioni/InternalLegs",
    name: "Configurazioni Internal Legs",
  },
  {
    path: "/Configurazioni/Fuel",
    name: "Configurazioni Fuel",
  },
  {
    path: "/AttivitaEseguite/AuditTrail",
    name: "Attivita Eseguite Audit Trail",
  },
  {
    path: "/AttivitaEseguite/OperazioniEseguite/MGP&MI",
    name: "Attivita Eseguite Operazioni Eseguite MGP&MI",
  },
  {
    path: "/AttivitaEseguite/OperazioniEseguite/Disponibilita",
    name: "Attivita Eseguite Operazioni Eseguite Disponibilita",
  },
  {
    path: "/AttivitaEseguite/OperazioniEseguite/Combustibili",
    name: "Attivita Eseguite Operazioni Eseguite Combustibili",
  },
];
