import React from "react";
import * as R from "ramda";

type PaginationComponentTypes = {
  count: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => any;
};

const PaginationComponent = ({
  count,
  pageSize,
  page,
  onPageChange
}: PaginationComponentTypes) => {
  const totalPages = Math.ceil(R.divide(count, pageSize));
  return totalPages > 1 ? (
    <div className="paginationNew">
      <div className="page-item">
        <PageItem
          display={page !== 1}
          onChange={() => onPageChange(1)}
          title="First Page"
          value={1}
        />
      </div>
      <div className="elipsis">
        <i className="paging-arrow fas fa-circle" hidden={page !== 3}></i>
        <i className="paging-arrow fas fa-ellipsis-h" hidden={page < 4}></i>
      </div>
      <div className="arrow">
        <PageItem
          display={page > 1}
          onChange={() => onPageChange(R.subtract(page, 1))}
          title="Previous"
          className="paging-arrow"
          value={<i className="fas fa-arrow-left" />}
        />
      </div>
      <div className="page-item">
        <PageItem
          display
          key={page}
          className={"active"}
          onChange={() => onPageChange(page)}
          title={R.toString(page)}
          value={page}
        />
      </div>
      <div className="arrow">
        <PageItem
          display={page < totalPages}
          onChange={() => onPageChange(R.add(1, page))}
          title="Next"
          className="paging-arrow"
          value={<i className="fas fa-arrow-right" />}
        />
      </div>
      <div className="elipsis">
        <i
          className="paging-arrow fas fa-circle"
          hidden={page !== totalPages - 2}
        ></i>
        <i
          className="paging-arrow fas fa-ellipsis-h"
          hidden={page > totalPages - 3}
        ></i>
      </div>
      <div className="page-item">
        <PageItem
          display={page !== totalPages}
          onChange={() => onPageChange(totalPages)}
          title="Last Page"
          value={totalPages}
        />
      </div>
    </div>
  ) : (
    <div />
  );
};

export default PaginationComponent;

type PageItemsTypes = {
  display: boolean;
  onChange: any;
  title: string;
  className?: string;
  value: string | number | JSX.Element;
};

const PageItem = ({
  display,
  onChange,
  title,
  className = "",
  value
}: PageItemsTypes) =>
  R.when(R.equals(true), () => (
    <div className={`pageNo ${className}`} onClick={onChange} title={title}>
      {value}
    </div>
  ))(display);
