import React from "react";
import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, defer, EMPTY, of, timer, throwError } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import { getAllRequests, searchBuilder } from "../../helper";
import { subDays, addDays, format, isValid } from "date-fns";
import { formJson, infoType } from "./forecast/uploadContract";
import { formHeaders } from "./forecast/uploadBulkContract";
import ErrorList from "./forecast/errorList";

export const key = "forecastDeal";

export enum actionTypes {
  EMPTY_DATA = "rtm/market/forecastDeal/EMPTY_DATA",
  FIRST_LOAD = "rtm/market/forecastDeal/FIRST_LOAD",
  SET_RAGIONE_SOCIALE_DATA = "rtm/market/forecastDeal/SET_RAGIONE_SOCIALE_DATA",
  RECIEVED_RAGIONE_SOCIALE_DATA = "rtm/market/forecastDeal/RECIEVED_RAGIONE_SOCIALE_DATA",
  CANCEL = "rtm/market/forecastDeal/CANCEL",
  FORECAST_DEAL_REQUEST = "rtm/market/forecastDeal/FORECAST_DEAL_REQUEST",
  FORECAST_DEAL_FETCHINNG = "rtm/market/forecastDeal/FORECAST_DEAL_FETCHINNG",
  FORECAST_DEAL_DATA = "rtm/market/forecastDeal/FORECAST_DEAL_DATA",
  FORECAST_DEAL_PAGE = "rtm/market/forecastDeal/FORECAST_DEAL_PAGE",
  FORECAST_DEAL_FILTERS = "rtm/market/forecastDeal/FORECAST_DEAL_FILTERS",
  FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE = "rtm/market/forecastDeal/FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE",
  DEAL_CAPTURE_RUN_START = "rtm/market/forecastDeal/DEAL_CAPTURE_RUN_START",
  DEAL_CAPTURE_RUN_COMPLETED = "rtm/market/forecastDeal/DEAL_CAPTURE_RUN_COMPLETED",
  DEAL_CAPTURE_RUN_CLOSE = "rtm/market/forecastDeal/DEAL_CAPTURE_RUN_CLOSE",
  CONTRACT_RUN_START = "rtm/market/forecastDeal/CONTRACT_RUN_START",
  CONTRACT_RUN_COMPLETED = "rtm/market/forecastDeal/CONTRACT_RUN_COMPLETED",
  CONTRACT_RUN_CLOSE = "rtm/market/forecastDeal/CONTRACT_RUN_CLOSE",
  UPLOAD_CONTRACT = "rtm/market/forecastDeal/UPLOAD_CONTRACT",
  UPLOAD_CONTRACT_COMPLETE = "rtm/market/forecastDeal/UPLOAD_CONTRACT_COMPLETE",
  UPLOAD_BULK_CONTRACT = "rtm/market/forecastDeal/UPLOAD_BULK_CONTRACT",
  UPLOAD_BULK_CONTRACT_COMPLETE = "rtm/market/forecastDeal/UPLOAD_BULK_CONTRACT_COMPLETE",
}

type Actions =
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.FIRST_LOAD }
  | { type: actionTypes.SET_RAGIONE_SOCIALE_DATA }
  | { type: actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA; data: any }
  | { type: actionTypes.FORECAST_DEAL_REQUEST }
  | { type: actionTypes.FORECAST_DEAL_FETCHINNG }
  | { type: actionTypes.FORECAST_DEAL_DATA; data: any; count: number }
  | { type: actionTypes.FORECAST_DEAL_PAGE; page: number }
  | {
      type: actionTypes.FORECAST_DEAL_FILTERS;
      filters: ForecastDealFilterStateType;
    }
  | {
      type: actionTypes.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE;
      sociale: any;
    }
  | { type: actionTypes.DEAL_CAPTURE_RUN_START; info: any }
  | { type: actionTypes.DEAL_CAPTURE_RUN_COMPLETED; result: any }
  | { type: actionTypes.DEAL_CAPTURE_RUN_CLOSE }
  | { type: actionTypes.CONTRACT_RUN_START; info: any }
  | { type: actionTypes.CONTRACT_RUN_COMPLETED }
  | { type: actionTypes.CONTRACT_RUN_CLOSE }
  | { type: actionTypes.UPLOAD_CONTRACT; file: any; contractId: any; forceUpdate: boolean }
  | { type: actionTypes.UPLOAD_CONTRACT_COMPLETE }
  | { type: actionTypes.UPLOAD_BULK_CONTRACT; file: any; forceUpdate: boolean }
  | { type: actionTypes.UPLOAD_BULK_CONTRACT_COMPLETE; }
  | { type: actionTypes.EMPTY_DATA };

export type RagioneSocialeType = {
  isFetching: boolean;
  data: any;
};
export const ragioneSocialeState = {
  isFetching: false,
  data: [],
};

function ragioneSocialeReducer(
  state: RagioneSocialeType = ragioneSocialeState,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.SET_RAGIONE_SOCIALE_DATA:
      return { ...state, isFetching: true };
    case actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA:
      return { ...state, isFetching: false, data: action.data };
    case actionTypes.EMPTY_DATA:
      return ragioneSocialeState;
    default:
      return state;
  }
}

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.FORECAST_DEAL_DATA:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FORECAST_DEAL_FILTERS:
    case actionTypes.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE:
      return true;
    default:
      return state;
  }
}
function uploadFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.CANCEL:
    case actionTypes.UPLOAD_CONTRACT_COMPLETE:
    case actionTypes.UPLOAD_BULK_CONTRACT_COMPLETE:
    case actionTypes.CONTRACT_RUN_COMPLETED:
      return false;
    case actionTypes.UPLOAD_CONTRACT:
    case actionTypes.UPLOAD_BULK_CONTRACT:
      return true;
    default:
      return state;
  }
}

export type Periodype = {
  from: string | null;
  to: string | null;
};

export type ForecastDealFilterStateType = {
  ragioneSociale: string | null;
  pvis: string[] | null;
  period: Periodype;
};
export type ForecastDealStateType = {
  fetching: boolean;
  data: any;
  count: number;
  page: number;
  pageSize: number;
  sort: string | null;
  filters: ForecastDealFilterStateType;
};

const dMinus15 = subDays(new Date(), 15);
const dPlus4 = addDays(new Date(), 4);
export const forecastDealState = {
  fetching: false,
  data: [],
  count: 0,
  page: 1,
  pageSize: 20,
  sort: null,
  filters: {
    ragioneSociale: null,
    pvis: null,
    period: {
      from: format(dMinus15, "yyyy-MM-dd"),
      to: format(dPlus4, "yyyy-MM-dd"),
    },
  },
};

export function forecastDealReducer(
  state = forecastDealState as ForecastDealStateType,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.FORECAST_DEAL_FETCHINNG:
      return { ...state, fetching: true };
    case actionTypes.FORECAST_DEAL_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        count: action.count,
      };
    case actionTypes.FORECAST_DEAL_PAGE:
      return {
        ...state,
        fetching: true,
        page: action.page,
      };
    case actionTypes.FORECAST_DEAL_FILTERS:
      return {
        ...state,
        fetching: true,
        filters: action.filters,
      };
    case actionTypes.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE:
      return {
        ...state,
        fetching: true,
        page: 1,
        filters: {
          ...state.filters,
          ragioneSociale: action.sociale,
        },
      };
    case actionTypes.CANCEL:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.EMPTY_DATA:
      return forecastDealState;
    default:
      return state;
  }
}

export type DealCaptureType = {
  isFetching: boolean;
  open: boolean;
  data: any;
};

export const dealCaptureState : DealCaptureType = {
  isFetching: false,
  open: false,
  data: null,
};
function dealCaptureReducer(
  state: DealCaptureType = dealCaptureState,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.DEAL_CAPTURE_RUN_START:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.DEAL_CAPTURE_RUN_COMPLETED:
      return {
        ...state,
        isFetching: false,
        open: true,
        data: action.result,
      };
    case actionTypes.DEAL_CAPTURE_RUN_CLOSE:
    case actionTypes.EMPTY_DATA:
      return dealCaptureState;
    default:
      return state;
  }
}
export type ContractType = {
  isFetching: boolean;
  open: boolean;
};
export const contractState = {
  isFetching: false,
  open: false,
};
function contractReducer(state: ContractType = contractState, action: Actions) {
  switch (action.type) {
    case actionTypes.CONTRACT_RUN_START:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.CONTRACT_RUN_COMPLETED:
      return {
        ...state,
        isFetching: false,
        open: true,
      };
    case actionTypes.CONTRACT_RUN_CLOSE:
    case actionTypes.EMPTY_DATA:
    case actionTypes.CANCEL:
      return contractState;
    default:
      return state;
  }
}

export type reducerType = {
  isFetching: boolean;
  uploadFetching: boolean;
  ragioneSociale: RagioneSocialeType;
  forecastDeal: ForecastDealStateType;
  dealCapture: DealCaptureType;
  contract: ContractType;
};
export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  uploadFetching: uploadFetchingReducer,
  ragioneSociale: ragioneSocialeReducer,
  forecastDeal: forecastDealReducer,
  dealCapture: dealCaptureReducer,
  contract: contractReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA,
});

export const firstLoadAction = (): Actions => ({
  type: actionTypes.FIRST_LOAD,
});

export const getRagioneSocialeAction = (): Actions => ({
  type: actionTypes.SET_RAGIONE_SOCIALE_DATA,
});
const setRagioneSocialeDataAction = (data: any): Actions => ({
  type: actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA,
  data,
});

const ragioneSocialeEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FIRST_LOAD ||
        action.type === actionTypes.SET_RAGIONE_SOCIALE_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state) => {
          return getAllRequests({
            deps,
            api: "core/ragionesociale",
            dispatchError: dispatchNetworkError,
            filters: {
              sort: "RagioneSociale asc",
            },
          });
        }),
        RxOp.map(setRagioneSocialeDataAction)
      )
    )
  );

export const requestForecastDealAction = (): Actions => ({
  type: actionTypes.FORECAST_DEAL_REQUEST,
});
export const setForecastDealPageAction = (page: number): Actions => ({
  type: actionTypes.FORECAST_DEAL_PAGE,
  page,
});
export const setForecastDealFiltersAction = (
  filters: ForecastDealFilterStateType
): Actions => ({
  type: actionTypes.FORECAST_DEAL_FILTERS,
  filters,
});
export const setForecastDealFilterSocialeAction = (sociale: any): Actions => ({
  type: actionTypes.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE,
  sociale,
});
const setForecastDealDataAction = ({
  data,
  count,
}: {
  data: any;
  count: number;
}): Actions => ({
  type: actionTypes.FORECAST_DEAL_DATA,
  data,
  count,
});

type RequestDealsType = { state: any; deps: Dependancies };
const requestDeals = ({ state, deps }: RequestDealsType) => {
  const info = R.path(
    ["management", key, "forecastDeal"],
    state
  ) as ForecastDealStateType;

  const toDateInclusive = R.pipe(
    (x: any) => addDays(x, 1),
    (x: any) => format(x, "yyyy-MM-dd")
  )(new Date(info.filters.period.to as string));

  const queryString = searchBuilder({
    filters: {
      partitaIva: info.filters.ragioneSociale,
      from: info.filters.period.from,
      to: toDateInclusive,
    },
  });

  const skipVal = R.multiply(R.subtract(info.page, 1), info.pageSize);

  return of(state).pipe(
    RxOp.switchMap(() =>
      defer(
        deps.request.get(
          `settlement/forecastDeal?limit=${info.pageSize}&skip=${skipVal}&${queryString}`,
          t.any
        )
      )
    ),
    RxOp.map(
      E.fold<any, any, any>(dispatchNetworkError, setForecastDealDataAction)
    )
  );
};

const forecastDealDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FORECAST_DEAL_REQUEST ||
        action.type === actionTypes.FORECAST_DEAL_FILTERS ||
        action.type === actionTypes.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE ||
        action.type === actionTypes.DEAL_CAPTURE_RUN_COMPLETED ||
        action.type === actionTypes.CONTRACT_RUN_COMPLETED ||
        action.type === actionTypes.FORECAST_DEAL_PAGE
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const info = R.path(
            ["management", key, "forecastDeal", "filters"],
            state
          ) as ForecastDealFilterStateType;

          const periodNull = R.pipe<any, any, any, any, any>(
            R.path(["period"]),
            R.values,
            R.map(R.isNil),
            R.any(R.equals(true))
          )(info);

          const anyNull = R.pipe<any, any, any, any, any, any>(
            R.pick(["ragioneSociale"]),
            R.map((x) => R.isEmpty(x) || R.isNil(x)),
            R.values,
            R.concat(R.__, [periodNull]),
            R.any(R.equals(true))
          )(info);
          return anyNull ? EMPTY : of(state);
        }),
        RxOp.switchMap(() =>
          action$.pipe(
            RxOp.filter(
              (action) =>
                action.type === actionTypes.FORECAST_DEAL_REQUEST ||
                action.type === actionTypes.FORECAST_DEAL_FILTERS ||
                action.type ===
                  actionTypes.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE ||
                action.type === actionTypes.DEAL_CAPTURE_RUN_COMPLETED ||
                action.type === actionTypes.FORECAST_DEAL_PAGE
            ),
            RxOp.startWith(1),
            RxOp.switchMapTo(
              timer(0, 10000).pipe(
                RxOp.exhaustMap(() =>
                  state$.pipe(
                    RxOp.first(),
                    RxOp.switchMap((state) => requestDeals({ state, deps }))
                  )
                )
              )
            ),
            RxOp.takeUntil(action$.pipe(ofType(actionTypes.EMPTY_DATA)))
          )
        )
      )
    )
  );

export const sendDealCaptureAction = (info: any): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_START,
  info,
});
export const closeDealCaptureAction = (): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_CLOSE,
});
const dealCaptureCompletedAction = (result: any): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_COMPLETED,
  result,
});

const dealCaptureCondition = (data: any) => {
  const isRunning = R.pathOr(false, ["process", "isRunning"], data);

  return R.any(R.equals(true))([isRunning]);
};

const dealCaptureForceCondition = (data: any, forceUpdate: boolean) => {
  const isForceUpdate = forceUpdate && !R.pipe<any, any, any>(
    R.pathOr(null, ["check", "status"]),
    R.contains(R.__, ["NA"])
  )(data);

  return isForceUpdate;
}

type DealCaptureEpicRequestType = {
  deps: Dependancies;
  contractId: string;
  dealType: string;
  dealTypeData: any;
  forceUpdate: boolean;
};
const dealCaptureEpicRequest = ({
  deps,
  contractId,
  dealType,
  dealTypeData,
  forceUpdate,
}: DealCaptureEpicRequestType) =>
  of(dealTypeData).pipe(
    RxOp.switchMap((data) =>
       dealCaptureForceCondition(data, forceUpdate)
         ? defer(
           deps.request.post(
             `settlement/dealCapture/process/${contractId}/dealType/${dealTypes(
               dealType
             )}/run`,
             {},
             t.unknown
           )
         )
         :
      dealCaptureCondition(data)
        ? of(E.left("Conditions not met"))
        : defer(
            deps.request.post(
              `settlement/dealCapture/process/${contractId}/dealType/${dealTypes(
                dealType
              )}/run`,
              {},
              t.unknown
            )
          )
        ),
    RxOp.map(
      E.fold<any, any, any>(
        (data) => dealCaptureEpicRequestFailure(data, contractId, dealType),
        (x) => ({
          contractId,
          dealType,
          status: true,
          result: x,
        })
      )
     )
  );
  
type FailureLogType = { 
  contractId: string; 
  dealType: string;
  status: boolean;
  result: any;
};

const dealTypes = (type: string) => {
  switch (type) {
    case "volume":
      return "Volume";
    case "volumeInternal":
      return "VolumeInternal";
    case "volumePerdite":
      return "VolumePerdite";
    case "spread":
      return "Spread";
    case "feeSbilancio":
      return "FeeSbilancio";
    case "cct":
      return "CCT";
    default:
      return "";
  }
};

const dealCaptureEpicRequestFailure = (
  data: any,
  contractId: any,
  dealType: any
) => {
  if (R.hasPath(["response", "title"], data)) {
    return {
      contractId,
      dealType,
      status: false,
      result: R.path(["response", "title"], data),
    };
  }
  return {
    contractId,
    dealType,
    status: false,
    result: data,
  };
};

const DealTypes = [
  "volume",
  "volumeInternal",
  "volumePerdite",
  "spread",
  "feeSbilancio",
  "cct",
];
const dealCaptureEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.DEAL_CAPTURE_RUN_START),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state: any) => {
          const contractId = R.pathOr("", ["contractId"], action.info);
          const forceUpdate = false;
          return of(DealTypes).pipe(
            RxOp.flatMap((x) => x, 6),
            RxOp.mergeMap((dealType) =>
              dealCaptureEpicRequest({
                deps,
                contractId,
                dealType,
                dealTypeData: R.pathOr(
                  null,
                  ["dealType", dealType],
                  action.info
                ),
                forceUpdate
              })
            ),
          );
        })
      )
    )
  );

export const sendContractAction = (info: any): Actions => ({
  type: actionTypes.CONTRACT_RUN_START,
  info,
});
export const closeContractAction = (): Actions => ({
  type: actionTypes.CONTRACT_RUN_CLOSE,
});
const contractCompletedAction = (): Actions => ({
  type: actionTypes.CONTRACT_RUN_COMPLETED,
});

const contractEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.CONTRACT_RUN_START),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state: any) => {
          const contractId = R.pathOr("", ["contractId"], action.info);
          return defer(
            deps.request.post(
              `core/contratto/${contractId}/forecastVolume`,
              {},
              t.unknown
            )
          );
        }),
        RxOp.map(
          E.fold<any, any, any>(dispatchNetworkError, contractCompletedAction)
        )
      )
    )
  );

export const uploadContractAction = ({
  file,
  contractId,
  forceUpdate
}: {
  file: any;
  contractId: any;
  forceUpdate: boolean;
}): Actions => ({
  type: actionTypes.UPLOAD_CONTRACT,
  file,
  contractId,
  forceUpdate,
});

const contractUploadContractAction = (): Actions => ({
  type: actionTypes.CONTRACT_RUN_COMPLETED,
});

const propCols: infoType[] = [
  { propName: "dateTimeOffset", colName: "datetimeoffset", cell: "string" },
  { propName: "value", colName: "value", cell: "string" },
];

const nullChecker = (x: any) => !R.isNil(x) || !R.isEmpty(x);
const contractDatCheck = ({ dateTimeOffset, value }: any) => {
  const dto = nullChecker(dateTimeOffset) && isValid(new Date(dateTimeOffset));
  // eslint-disable-next-line use-isnan
  const val = nullChecker(value) && parseFloat(value) !== NaN;

  return R.all(R.equals(true))([dto, val]);
};

const uploadContractEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.UPLOAD_CONTRACT),
    RxOp.exhaustMap(({ file, contractId, forceUpdate }: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap(() =>
          formJson({
            file,
            info: propCols,
          })
        ),
        RxOp.switchMap((data: any) => {
          const headVals = R.pipe<any, any, any>(
            R.map(R.prop<any>("colName")),
            R.map(R.toLower)
          )(propCols);

          const headers = R.pipe<any, any, any, any, any>(
            R.values,
            R.map(
              R.pipe<any, any, any, any>(
                R.toLower,
                R.replace(" ", ""),
                R.trim
              )
            ),
            R.map((r: string) => R.contains(R.toLower(r), headVals)),
            R.all(R.equals(true))
          )(data[0]);

          if (R.equals(headers, false)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "Headers must be DateTimeOffset && Value",
            });
          }
          return of(R.remove(0, 1, data));
        }),
        RxOp.switchMap((data: any) => {
          const dataCheckerRes = data.map(contractDatCheck);

          if (R.any(R.equals(false))(dataCheckerRes)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "Invalid Data",
            });
          }
          return of(data);
        }),
        RxOp.mergeMap((volume: any) => {
          return defer(
            deps.request.put(
              `core/contratto/${contractId}/forecastVolume `,
              { contractId, volume, forceUpdate } as any,
              t.any
            )
          );
        }),
        RxOp.map(
          E.fold<any, any, any>(
            (err: any) =>
              dispatchNetworkError({
                customError: true,
                status: "File Upload",
                errorMessage: (
                  <ErrorList
                    failures={[
                      {
                        type: "fail",
                        info: err.response,
                        contractId,
                      },
                    ]}
                  />
                ),
              }),
            contractUploadContractAction
          )
        )
      )
    )
  );

export const uploadBulkContractAction = ({ 
  file, 
  forceUpdate 
}: { 
  file: any, 
  forceUpdate: boolean 
}): Actions => ({
  type: actionTypes.UPLOAD_BULK_CONTRACT,
  file,
  forceUpdate
});

const contractBulkUploadContractAction = (): Actions => ({
  type: actionTypes.UPLOAD_BULK_CONTRACT_COMPLETE
});

const bulkContractDatCheck = ({ contractId, dateTimeOffset, value }: any) => {
  const cid = nullChecker(contractId);
  const dto = nullChecker(dateTimeOffset) && isValid(new Date(dateTimeOffset));
  // eslint-disable-next-line use-isnan
  const val = nullChecker(value) && parseFloat(value) !== NaN;

  return R.all(R.equals(true))([cid, dto, val]);
};

type ForecastVolumeEpicRequestType = {
  deps: any,
  res: any,
  contractId: string,
  forceUpdate: boolean
}

const forecastVolumeEpicRequest = ({
  deps,
  res,
  contractId,
  forceUpdate
}: ForecastVolumeEpicRequestType) =>
  of(contractId).pipe(
    RxOp.switchMap((data) =>
      defer(
            deps.request.put(
              `core/contratto/${contractId}/forecastVolume `,
              res,
              t.any
            )
          )
    ),
    RxOp.map(
      E.fold<any, any, any>(
        (data) => data,
        (x) => ({
          contractId,
          result: x,
        })
      )
    )
  );

const errorList: any[] = [];

const uploadBulkContractEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.UPLOAD_BULK_CONTRACT),
    RxOp.exhaustMap(({ file, forceUpdate }: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap(() =>
          formHeaders({
            file,
          })
        ),
        RxOp.switchMap((data: any): any => {
          const headers = R.pipe(
            R.head,
            R.keys
          )(data);
          const tidyVal = R.pipe<any, any, any, any>(
            R.toLower,
            R.replace(" ", ""),
            R.trim
          );
          const headerCheck = R.pipe(
            R.pathOr(null, [0]),
            tidyVal,
            R.equals("datetimeoffset")
          )(headers);

          if (R.equals(headerCheck, false)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage:
                "Headers must contain DateTimeOffset &and it should be in first column",
            });
          }
          return of({
            headers,
            data,
          });
        }),
        RxOp.map(({ headers, data }: any) => {
          const dateTimeOffsetKey = headers[0];
          const contractIds = R.drop(1, headers);

          return R.pipe(
            R.map((row) =>
              contractIds.map((id: any) => ({
                dateTimeOffset: R.pathOr(null, [dateTimeOffsetKey], row),
                contractId: id,
                value: R.pathOr(null, [id], row),
              }))
            ),
            R.flatten,
            R.filter(({ value }: any) => !R.isNil(value))
          )(data);
        }),
        RxOp.switchMap((data: any) => {
          const dataCheckerRes = data.map(bulkContractDatCheck);
          if (R.any(R.equals(false))(dataCheckerRes)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "Invalid Data",
            });
          }
          return of(data);
        }),
        RxOp.map(
          R.pipe<any, any, any, any>(
            R.groupBy((t: any) => t.contractId),
            R.toPairs,
            R.map(([contractId, volume]) => ({
              contractId,
              volume,
              forceUpdate,
            }))
          )
        ),
        RxOp.flatMap((x: any) => x),
        RxOp.mergeMap((res: any) => {
          const info = {
            deps: deps,
            res: res,
            contractId: res.contractId,
            forceUpdate: forceUpdate,
          };

          const contractId = res.contractId;

          if (!(R.isNil(contractId) || R.isEmpty(contractId))) {
            return forecastVolumeEpicRequest({
              deps,
              res,
              contractId,
              forceUpdate,
            });
          }
          return of(info);
        }),
        RxOp.mergeMap((info) => {
          const anyFail = R.pathOr("", ["status"], info)
            ? R.filter((r: any) => r.status >= 400, info)
            : [];
          if (R.isEmpty(anyFail)) {
            const contractId = R.pathOr("", ["contractId"], info);

            if (!(R.isNil(contractId) || R.isEmpty(contractId))) {
              return defer(
                deps.request.get(`settlement/forecastDeal/${contractId}`, t.any)
              );
            } else {
              const temp = {
                contractId: "",
              };
              return of(temp);
            }
          } else {
            errorList.push(info);
            const temp = {
              contractId: "",
            };
            return of(temp);
          }
        }),
        RxOp.map(E.fold<any, any, any>((x) => x, (x) => x)),
        RxOp.mergeMap((x: any) => {
          const anyFail = R.pathOr("", ["status"], x)
            ? R.filter((r: any) => r.status >= 400, x)
            : [];
          if (R.isEmpty(anyFail)) {
            const contractId = R.pathOr("", ["contractId"], x);

            if (!(R.isNil(contractId) || R.isEmpty(contractId))) {
              return of(DealTypes).pipe(
                RxOp.flatMap((x) => x, 6),
                RxOp.mergeMap((dealType) => {
                  return dealCaptureEpicRequest({
                    deps,
                    contractId,
                    dealType,
                    dealTypeData: R.pathOr(null, ["dealType", dealType], x),
                    forceUpdate,
                  });
                })
              );
            } else {
              const temp = {
                contractId: "",
              };
              return of(temp);
            }
          } else {
            errorList.push(x);
            return [];
          }
        }),
        RxOp.toArray(),
        RxOp.map((info: any) => {
          while (errorList.length > 0) {
            let temp = errorList.pop();
            throw temp;
          }
          return contractBulkUploadContractAction();
        }),
        RxOp.catchError((err: any) =>
          of(err).pipe(RxOp.map(dispatchNetworkError))
        )
      )
    )
  );

const dataHandler = (err: any) => {
  if (R.has("detail", err)) {
    return err.detail;
  }

  if (R.has("errors", err)) {
    return R.pipe<any, any, any, any>(
      R.values,
      R.map((row: any) => {
        return (
          R.prop("errorMessage", row) ||
          R.prop("message", row) ||
          row ||
          "An error has occurred. Please contact support."
        );
      }),
      R.join(" ")
    )(err.errors);
  }

  if (R.has("title", err)) {
    return err.title;
  }

  return "An error has occurred. Please contact support.";
};

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  ragioneSocialeEpic,
  forecastDealDataEpic,
  dealCaptureEpic,
  contractEpic,
  uploadContractEpic,
  uploadBulkContractEpic,
);

export const Selectors = {
  all: (s: any): reducerType => s.management[key],
};
