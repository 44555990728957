import * as R from "ramda";

const border = {
  border: {
    right: {
      style: "thick",
      color: "#000000"
    }
  }
};
const font = {
  font: {
    bold: true
  }
};

const style = {
  ...font,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#fbd4b4",
    fgColor: "#fbd4b4"
  }
};
const frozenStyle = {
  ...font,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#00b050",
    fgColor: "#00b050"
  }
};
const frozenStyleEnd = {
  ...font,
  ...border,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#00b050",
    fgColor: "#00b050"
  }
};
const styleEnd = {
  ...font,
  ...border,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#fbd4b4",
    fgColor: "#fbd4b4"
  }
};

type MergeHeaderType = {
  sheet: any;

  mergeHeader: string | null;
  start: number;
  end: number;
};
const mergeExcelHeader = ({
  sheet,

  mergeHeader,
  start,
  end
}: MergeHeaderType) => {
  sheet
    .cell(1, start, 1, end, true)
    .string(R.isNil(mergeHeader) ? "" : mergeHeader)
    .style({
      alignment: {
        horizontal: ["center"]
      },
      ...border
    });
  return sheet;
};

type HeadersType = {
  sheet: any;
  frozen?: boolean;
  headers: string[];
  start: number;
  end: number;
};
const excelHeaders = ({
  sheet,
  frozen = false,
  headers,
  start,
  end
}: HeadersType) => {
  headers.map((val: string, i: number) => {
    const col = R.add(i, start);
    const sty = frozen
      ? R.equals(col, end)
        ? frozenStyleEnd
        : frozenStyle
      : R.equals(col, end)
      ? styleEnd
      : style;
    return sheet
      .cell(2, col)
      .string(val)
      .style(sty);
  });
  return sheet;
};

type ExcelHeaderType = {
  sheet: any;
  frozen?: boolean;
  mergeHeader: string | null;
  headers: string[];
  start: number;
  end: number;
};
const excelHeader = ({
  sheet,
  frozen = false,
  mergeHeader,
  headers,
  start,
  end
}: ExcelHeaderType) => {
  mergeExcelHeader({ sheet, mergeHeader, start, end });
  excelHeaders({ sheet, headers, frozen, start, end });
  return sheet;
};

export const createUPHeaders = (sheet: any) => {
  sheet.row(1).freeze();
  sheet.row(2).freeze();
  sheet.column(1).freeze();
  sheet.column(2).freeze();
  sheet.column(3).freeze();
  sheet.column(1).setWidth(8);
  sheet.column(2).setWidth(15);
  sheet.column(3).setWidth(25);

  excelHeader({
    sheet,
    mergeHeader: "",
    headers: ["UP", "MercatiBidding", "DatetimeOffset"],
    frozen: true,
    start: 1,
    end: 3
  });

  //MisurePrezziBiddingUP
  excelHeader({
    sheet,
    mergeHeader: "MisurePrezziBiddingUP",
    headers: [
      "Provider",
      "MercatiBidding",
      "Q_GR1",
      "Q_GR2",
      "Q_GR3",
      "Q_GR4",
      "P_GR1",
      "P_GR2",
      "P_GR3",
      "P_GR4"
    ],
    start: 4,
    end: 13
  });
  //Computed CapacitaInstallataMW
  excelHeader({
    sheet,
    mergeHeader: "Computed",
    headers: ["Somma di capacitaInstallataMW"],
    start: 14,
    end: 14
  });
  //EsitoBiddingUP
  excelHeader({
    sheet,
    mergeHeader: "EsitoBiddingUP",
    headers: ["MGP", "MI1", "MI2", "MI3", "MI4", "MI5", "MI6", "MI7"],
    start: 15,
    end: 22
  });
  //CoefficientiUP
  excelHeader({
    sheet,
    mergeHeader: "CoefficientiUP",
    headers: ["UploadId", "Coefficiente A", "Coefficiente B"],
    start: 23,
    end: 25
  });
  //CfgSpread
  excelHeader({
    sheet,
    mergeHeader: "CfgSpread",
    headers: ["Zona", "Ora", "SpreadBuy", "SpreadSell"],
    start: 26,
    end: 29
  });
  //OffertatoBiddingUP
  excelHeader({
    sheet,
    mergeHeader: "OffertatoBiddingUP",
    headers: [
      "Q_GR1",
      "Q_GR2",
      "Q_GR3",
      "Q_GR4",
      "P_GR1",
      "P_GR2",
      "P_GR3",
      "P_GR4"
    ],
    start: 30,
    end: 37
  });
  //Computed Eccedenza
  excelHeader({
    sheet,
    mergeHeader: "Computed",
    headers: ["Eccedenza"],
    start: 38,
    end: 38
  });

  //Result
  excelHeader({
    sheet,
    mergeHeader: "Result",
    headers: [
      "Q_GR1",
      "Q_GR2",
      "Q_GR3",
      "Q_GR4",
      "P_GR1",
      "P_GR2",
      "P_GR3",
      "P_GR4"
    ],
    start: 39,
    end: 46
  });
  return sheet;
};
