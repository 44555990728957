import React from "react";
import * as R from "ramda";
import "./checkBox.scss";

type checkBoxTypes = {
  label: string | JSX.Element;
  name: string;
  selected: any;
  allSeleted?: boolean;
  partialSelected?: boolean;
  className?: string;
  update: (a: string[]) => void;
};
const CheckBox = ({
  label,
  name,
  selected,
  allSeleted = false,
  partialSelected = false,
  update,
  className = ""
}: checkBoxTypes) => {
  const onUpdate = () =>
    selected && R.contains(name, selected)
      ? update(R.reject(R.equals(name), selected))
      : update(R.append(name, selected));

  return (
    <div
      className={`form-group boxContainer ${className}`}
      onClick={e => {
        e.stopPropagation();
        onUpdate();
      }}
    >
      <span className="fas-lg checkbox">
        <div className={`box`}>
          <div
            className={`fa mr-2 ${
              allSeleted || (selected && R.contains(name, selected))
                ? "fa-check check"
                : partialSelected
                ? "fa-square "
                : ""
            }`}
          />
        </div>
      </span>
      {label}
    </div>
  );
};

const CheckBoxNew = ({
  label,
  name,
  selected,
  update,
  className
}: checkBoxTypes) => {
  const onUpdate = () =>
    selected && R.contains(name, selected)
      ? update(R.reject(R.equals(name), selected))
      : update(R.append(name, selected));
  return (
    <div
      className={`form-group boxContainer ${className}`}
      onClick={() => onUpdate()}
    >
      <span className="fas-lg checkbox">
        <div className={`box`}>
          <div
            className={`fa mr-2 ${selected === true ? "fa-check check" : ""}`}
          />
        </div>
      </span>
      {label}
    </div>
  );
};

export { CheckBox, CheckBoxNew };
