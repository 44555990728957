import * as R from "ramda";

export const formatBytes = (bytes: number, decimals = 2 as number) => {
  const k = 1024;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const bytesVal = bytes / Math.pow(k, i);
  const decimalVal = decimals < 0 || bytesVal > 99 ? 0 : decimals;

  return R.gt(bytes, 0)
    ? parseFloat((bytes / Math.pow(k, i)).toFixed(decimalVal)) + " " + sizes[i]
    : `0 KB`;
};

type RemoveFileType = {
  fileList: any;
  file: any[];
  update: any;
};
export const removeFile = ({ fileList, file, update }: RemoveFileType) =>
  update(R.reject(R.equals(file), fileList));
