import * as R from "ramda";

export const key = "errorHandler";

export enum actionTypes {
  SET_ERROR = "rtm/errorHandler/SET_ERROR",
  CLEAR_ERROR = "rtm/errorHandler/CLEAR_ERROR",
}

export type DetailsType = {
  title?: string | JSX.Element | null;
  message?: any;
  btnTitle?: any;
};
export type Action =
  | {
      type: actionTypes.SET_ERROR;
      error: boolean;
      details: DetailsType | null;
      defaultErr: any;
    }
  | { type: actionTypes.CLEAR_ERROR };

export type errorModalType = {
  error?: boolean;
  details: DetailsType | null;
  defaultErr?: any;
};

const errorDefault = {
  error: false,
  defaultErr: null,
  details: null,
};
export function reducer(
  state: errorModalType = errorDefault,
  action: Action
): any {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return {
        error: action.error,
        details: action.details,
        defaultErr: action.defaultErr,
      };
    case actionTypes.CLEAR_ERROR:
      return errorDefault;
    default:
      return state;
  }
}

export const setErrorAction = ({
  error = true,
  details = null,
  defaultErr = null,
}: errorModalType): Action => {
  return {
    type: actionTypes.SET_ERROR,
    error,
    details,
    defaultErr,
  };
};

export const clearErrorAction = (): Action => ({
  type: actionTypes.CLEAR_ERROR,
});

export const Selectors: { all: (a: any) => errorModalType } = {
  all: R.prop(key),
};

export const dispatchNetworkError = (err: any): any => {
  if (err.status > 0) {
    if (R.equals(err.status, 400)) {
      if (R.hasPath(["response", "errorMessage"], err)) {
        return setErrorAction({
          details: {
            title: "Error",
            message: err.response.errorMessage,
          },
          defaultErr: err,
        });
      }

      if (R.hasPath(["response", "errors"], err)) {
        return setErrorAction({
          details: {
            title: "Error",
            message: R.pipe<any, any, any, any>(
              R.values,
              R.map(R.map(R.prop("errorMessage"))),
              R.join(", ")
            )(err.response.errors),
          },
          defaultErr: err,
        });
      }
      if (R.hasPath(["response", "extensions", "errors"], err)) {
        return setErrorAction({
          details: {
            title: "Error",
            message: R.pipe<any, any, any>(
              R.map(R.prop("message")),
              R.join(", ")
            )(err.response.extensions.errors),
          },
          defaultErr: err,
        });
      }

      if (R.hasPath(["response", "title"], err)) {
        return setErrorAction({
          details: {
            title: "Error",
            message: err.response.detail,
          },
          defaultErr: err,
        });
      }

      return setErrorAction({
        details: {
          title: "Error",
          message: "An error has occurred. Please contact support.",
        },
        defaultErr: err,
      });
    }

    return setErrorAction({
      details: {
        title: "Error",
        message:
          "Please refresh the page. If the issue persists, please contact support.",
      },
      defaultErr: err,
    });
  }

  if (err.customError) {
    return setErrorAction({
      details: {
        title: "Error",
        message: R.isNil(err.errorMessage)
          ? "An error has occurred. Please contact support."
          : err.errorMessage,
      },
      defaultErr: err,
    });
  }
  return setErrorAction({
    details: {
      title: "Request Failed",
      message: "An error has occurred. Please contact support.",
    },
    defaultErr: err,
  });
};
