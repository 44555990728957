import * as t from "io-ts";
import { pipe } from "fp-ts/lib/pipeable";
import * as T from "fp-ts/lib/TaskEither";

export const pagination = <C extends t.Mixed>(a: C) =>
  t.type({
    count: t.number,
    isCountPartial: t.boolean,
    skip: t.number,
    limit: t.number,
    data: t.array(a)
  });

export type Pagination<A> = {
  count: number;
  isCountPartial: boolean;
  skip: number;
  limit: number;
  data: A[];
};

export function getAllPages<E, A>(
  requester: (skip?: number, limit?: number) => T.TaskEither<E, Pagination<A>>
): T.TaskEither<E, A[]> {
  function go<E, A>(
    requester: (
      skip?: number,
      limit?: number
    ) => T.TaskEither<E, Pagination<A>>,
    skip?: number,
    limit?: number
  ): T.TaskEither<E, A[]> {
    return pipe(
      requester(skip, limit),
      T.chain(res =>
        res.isCountPartial || res.skip + res.limit < res.count
          ? pipe(
              go(requester, res.skip + res.limit, res.limit),
              T.map((next: A[]) => [...res.data, ...next])
            )
          : T.right(res.data)
      )
    );
  }
  return go(requester);
}
