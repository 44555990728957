import * as R from "ramda";
import XLSX from "xlsx";
import { Observable, of } from "rxjs";
import * as RxOp from "rxjs/operators";

const readFileHeaders = ({ file }: { file: any }) =>
  Observable.create((obs: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const dataResult = e.target.result;
      const workbook = XLSX.read(dataResult, { type: "array" });
      const sheets = workbook.SheetNames;
      const res = [] as any;
      sheets.map(sheet => {
        return res.push({
          sheet,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
            defval: null
          })
        });
      });
      obs.next(res);
      obs.complete();
    };
    reader.readAsArrayBuffer(file);
  });

type FormHeadersType = { file: any };
export const formHeaders = ({ file }: FormHeadersType) =>
  of(file).pipe(
    RxOp.flatMap(file => file),
    RxOp.flatMap(file => readFileHeaders({ file })),
    RxOp.map(R.pathOr(null, [0, "data"]))
  );

export default formHeaders;
