import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  key as SpotMarketKey,
  reducer as SpotMarketReducer,
  epic as SpotMarketEpic
} from "./spotMarket";
import {
  key as ForwardMarketKey,
  reducer as ForwardMarketReducer,
  epic as ForwardMarketEpic
} from "./forwardMarket";

export const reducer = combineReducers({
  [SpotMarketKey]: SpotMarketReducer,
  [ForwardMarketKey]: ForwardMarketReducer
});

export const epic = combineEpics(SpotMarketEpic, ForwardMarketEpic);
