import React from "react";

export type EditColType = {
  havePermission: boolean;
  row: any;
  openEditRow: (a: any) => void;
  deleteRow: (a: any) => void;
  cancelModification: (a: any) => void;
};

export const EditCol = ({
  havePermission,
  row,
  openEditRow,
  deleteRow,
  cancelModification
}: EditColType) => {
  return havePermission ? (
    <td>
      <div className="crudTableButtonContainer">
        <i
          onClick={() => openEditRow(row)}
          className={`text-danger fas fa-pencil-alt ${
            row.mod === "Delete" || row.mod === "Add Mapping" ? " d-none" : ""
          }`}
        />
        <i
          onClick={() => deleteRow(row)}
          className={`text-danger fa fa-trash ${
            row.mod === undefined ? "" : " d-none"
          }`}
        />
        <i
          onClick={() => cancelModification(row)}
          className={`fa fa-ban ${
            row.mod
              ? row.mod === "Delete" || row.mod === "Add Mapping"
                ? ""
                : " text-danger "
              : " d-none"
          }`}
        />
      </div>
    </td>
  ) : null;
};

export default EditCol;
