import React from "react";
import InputNumeric from "../../components/inputNumericField";

type TextInputType = {
  value: any;
  update: (a: any) => void;
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
  min?: number | undefined;
  max?: number | undefined;
};

const TextInput = ({
  update,
  value,
  labelName = null,
  readOnly = false,
  requiredField = false,
  min = undefined,
  max = undefined,
}: TextInputType) => (
  <div>
    {labelName ? (
      <label htmlFor="select">
        <b>
          {requiredField ? (
            <div className="d-flex">
              <div>{labelName}</div>
              <div className="required">&nbsp;(Required)</div>
            </div>
          ) : (
            labelName
          )}
        </b>
      </label>
    ) : null}
    <InputNumeric
      type="number"
      value={value}
      update={update}
      readOnly={readOnly}
      min={min}
      max={max}
    />
  </div>
);
export default TextInput;
