import * as R from "ramda";

const disponibilitaCapacitaMassimaWriteAdmin = "disponibilitaCapacitaMassima:write:admin";
const disponibilitaCapacitaMassimaRead = "disponibilitaCapacitaMassima:read";
const disponibilitaCapacitaMassimaWrite = "disponibilitaCapacitaMassima:write";
const misurePrezziBiddingPVIWriteAdmin = "misurePrezziBiddingPVI:write:admin";
const misurePrezziBiddingPVIRead = "misurePrezziBiddingPVI:read";
const misurePrezziBiddingPVIWrite = "misurePrezziBiddingPVI:write";
const datiCombustibiliWriteAdmin = "datiCombustibili:write:admin";
const datiCombustibiliRead = "datiCombustibili:read";
const datiCombustibiliWrite = "datiCombustibili:write";
const esitoBiddingPVIRead = "esitoBiddingPVI:read";
const esitoBiddingUPRead = "esitoBiddingUP:read";
const esitoBiddingPVIWrite = "esitoBiddingPVI:write";
const esitoBiddingUPWrite = "esitoBiddingUP:write";
const coefficientiUPRead = "coefficientiUP:read";
const coefficientiUPWrite = "coefficientiUP:write";
const dealCaptureRead = "dealCapture:read";
const biddingRunRead = "biddingRun:read";
const biddingRunWrite = "biddingRun:write";
const configurationRead = "configuration:read";
const configurationWrite = "configuration:write";
const auditRead = "audit:read";
const contrattoForecastVolumeWriteAdmin = "contrattoForecastVolume:write:admin";
const aclSkipEnforcement = "acl:skipEnforcement";
const admin = "admin";

const mgpMiPermissions = [
  misurePrezziBiddingPVIWriteAdmin,
  misurePrezziBiddingPVIRead,
  misurePrezziBiddingPVIWrite
];
const disponibilitaPermissions = [
  disponibilitaCapacitaMassimaWriteAdmin,
  disponibilitaCapacitaMassimaRead,
  disponibilitaCapacitaMassimaWrite
];
const datiCombustibiliPermissions = [
  datiCombustibiliWriteAdmin,
  datiCombustibiliRead,
  datiCombustibiliWrite
];
const esitiPermissions = [esitoBiddingPVIRead, esitoBiddingUPRead, esitoBiddingPVIWrite, esitoBiddingUPWrite];
const processiBiddingPermissions = [biddingRunRead, biddingRunWrite];
const coefficientiPermissions = [coefficientiUPRead, coefficientiUPWrite];
const configurationPermissions = [configurationRead, configurationWrite];
const auditTrailPermissions = [auditRead];
const mgpMiWritePermissions = [
  misurePrezziBiddingPVIWriteAdmin,
  misurePrezziBiddingPVIWrite
];
const disponibilitaWritePermissions = [
  disponibilitaCapacitaMassimaWriteAdmin,
  disponibilitaCapacitaMassimaWrite
];
const datiCombustibiliWritePermissions = [
  datiCombustibiliWriteAdmin,
  datiCombustibiliWrite
];

const marketBidPermissions = R.flatten([
  mgpMiPermissions,
  disponibilitaPermissions,
  datiCombustibiliPermissions
]);
const gestionePermissions = R.flatten([
  processiBiddingPermissions,
  coefficientiPermissions
]);
const operazioniEseguite = R.flatten([
  mgpMiWritePermissions,
  disponibilitaWritePermissions,
  datiCombustibiliWritePermissions
]);
const attivitaEseguite = R.flatten([
  auditTrailPermissions,
  mgpMiWritePermissions,
  disponibilitaWritePermissions,
  datiCombustibiliWritePermissions
]);

export default {
  marketBidPermissions,
  esitiPermissions,
  gestionePermissions,
  configurationPermissions,
  attivitaEseguite,
  mgpMiPermissions,
  disponibilitaPermissions,
  datiCombustibiliPermissions,
  processiBiddingPermissions,
  coefficientiPermissions,
  mgpMiWritePermissions,
  disponibilitaWritePermissions,
  datiCombustibiliWritePermissions,
  auditRead,
  operazioniEseguite,
  datiCombustibiliWriteAdmin,
  misurePrezziBiddingPVIWriteAdmin,
  disponibilitaCapacitaMassimaWriteAdmin,
  dealCaptureRead,
  configurationWrite,
  contrattoForecastVolumeWriteAdmin,
  aclSkipEnforcement,
  admin
};
