import xl from "excel4node";
import JSZip from "jszip";
import disponibilitaCapacitaMassima from "./disponibilitaCapacitaMassima";
import misurePrezziBiddingPVI from "./misurePrezziBiddingPVI";
import { Operations } from "../executedOperations";

type DownloadType = { fileName: string; data: any; operation: Operations };
const download = ({ fileName, data, operation }: DownloadType) => {
  switch (operation) {
    case "MGP&MI":
    case "Disponibilita":
      return createExcel({ fileName, data, operation });
    case "Combustibili":
      return datiCombustibiliFileCreation({ fileName, data, operation });
  }
};

type CreateExcelType = { fileName: string; data: any; operation: Operations };
const createExcel = ({ fileName, data, operation }: CreateExcelType) => {
  var wb = new xl.Workbook();
  const sheet = wb.addWorksheet("Sheet1");
  excelFileType({ sheet, data, operation });
  return wb
    .writeToBuffer()
    .then((buffer: any) =>
      excelFileCreation({ buffer, fileName: `${fileName}.xlsx` })
    );
};

export default download;

type ExcelType = { data: any; operation: Operations; sheet: any };
const excelFileType = ({ operation, data, sheet }: ExcelType) => {
  switch (operation) {
    case "MGP&MI":
      return misurePrezziBiddingPVI({ sheet, data });
    case "Disponibilita":
      return disponibilitaCapacitaMassima({ sheet, data });
    case "Combustibili":
      return null;
  }
};

const datiCombustibiliFileCreation = ({ fileName, data, operation }: CreateExcelType) => {
  var zip = new JSZip();
  zip.file(`emissioni${fileName}.xml`, data[0]);
  zip.file(`costiSmaltimento${fileName}.xml`, data[1]);
  zip.file(`ecotasse${fileName}.xml`, data[2]);
  zip.file(`consumoCombustibile${fileName}.xml`, data[3]);

  zip
    .generateAsync({ type: "blob" })

    .then(content =>
      excelFileCreation({ buffer: content, fileName: `datiCombustibili${fileName}.zip` })
    );
};

type ExcelFileCreationType = {
  buffer: any;
  fileName: any;
};
const excelFileCreation = ({ buffer, fileName }: ExcelFileCreationType) => {
  var a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(
    new Blob([buffer], { type: "application/octet-stream" })
  );
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
