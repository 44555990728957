import { Observable } from "rxjs";
import * as R from "ramda";
import { MappingTools } from "ark-tools";
import { eitherSanctuary } from "./helper";
import { infoType } from "./excel";
import moment from "moment";

const { commitModification } = MappingTools;

export const modHelperFunc = ({
  dataArr,
  pks,
  errMsg
}: {
  dataArr: any;
  pks: any;
  errMsg: any;
}) =>
  Observable.create((obs: any) => {
    const modifyHelper = dataArr.map((row: any, i: number) =>
      R.pipe(
        R.remove(i, 1),
        (x: any) => ({
          pks,
          errMsg,
          modalData: { orig: row, row, type: "Add Mapping" },
          tableData: x
        }),
        commitModification,
        val =>
          eitherSanctuary({
            val,
            fail: (err: any) => ({
              title: "Mapping blocked",
              message: err.join(", ")
            }),
            pass: (x: any) => x
          })
      )(dataArr)
    );

    const result = modifyHelper.filter(
      (x: any) => x.title === "Mapping blocked"
    );

    if (result.length > 0) {
      obs.error({
        customError: true,
        status: "Mapping blocked",
        errorMessage: result[0].message
      });
    }

    obs.next(dataArr);
    obs.complete();
  });

const floatNumber = (x: any) => {
  const value = parseFloat(x);
  return R.equals(value, NaN) ? null : value;
};

const allNumerProps = R.pipe<any, any, any, any>(
  R.filter(R.propEq("cell", "number")),
  R.map(R.prop("propName")),
  R.flatten
);

export const numberCheck = ({ row, info }: { row: any; info: infoType[] }) => {
  const nullCheck = R.pipe<any, any, any>(
    R.values,
    R.all(R.equals(null))
  )(row);

  const value = R.pipe<any, any, any, any>(
    R.pick(allNumerProps(info)),
    R.map(floatNumber),
    R.merge(row)
  )(row);
  return nullCheck ? null : value;
};

const dateFormatHourHandler = R.pipe<any, any, any, any>(
  R.split("-"),
  R.map((x: any) => x),
  (x: any) => {
    return `${x[2]}-${x[1]}-${x[0]}`;
  }
);

const allDateProps = R.pipe<any, any, any, any>(
  R.filter(R.propEq("cell", "date")),
  R.map(R.prop("propName")),
  R.flatten
);
export const dateCheck = ({ row, info }: { row: any; info: infoType[] }) => {
  const nullCheck = R.pipe<any, any, any>(
    R.values,
    R.all(R.equals(null))
  )(row);

  const value = R.pipe<any, any, any, any>(
    R.pick(allDateProps(info)),
    R.map((x: any) => moment(x).format("YYYY-MM-DD")),
    R.merge(row)
  )(row);
  return nullCheck ? null : value;
};

type AnyNullType = {
  row: any;
  nullArr: string[];
};
export const anyNull = ({ row, nullArr = [] }: AnyNullType) =>
  R.pipe<any, any, any, any, any>(
    R.pick(nullArr),
    R.map(x => R.isEmpty(x) || R.isNil(x)),
    R.values,
    R.any(R.equals(true))
  )(row);

type NullNamesType = {
  list: string[];
  info: infoType[];
};
export const nullNames = ({ list, info }: NullNamesType) =>
  R.pipe<any, any, any, any>(
    R.filter((row: infoType) => R.contains(row.propName, list)),
    R.map(R.prop("colName")),
    R.join(", ")
  )(info);

export default modHelperFunc;
