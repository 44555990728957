import { addDays } from "date-fns";
import { format, toDate, utcToZonedTime } from "date-fns-tz";
import xl from "excel4node";
import { sequenceT } from "fp-ts/lib/Apply";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/TaskEither";
import { flow } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import * as t from "io-ts";
import * as Ra from "ramda";
import { hasPath, isNil } from "ramda";
import { combineEpics, ofType } from "redux-observable";
import { EMPTY, Observable, defer, from, of } from "rxjs";
import * as RxOp from "rxjs/operators";
import XLSX from "xlsx";
import { getAllPages, pagination } from "../../utils/pagination";
import {
  NetworkRequest,
  fail,
  inProgress,
  notStarted,
  success,
} from "../../utils/request";
import { Dependancies } from "../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "./errorHandler";
import {
  ContractInfo,
  DisponibilitaCapacitaMassimaTimeserieRecord,
  DisponibilitaCapacitaMassimaUploadOutput,
  DisponibilitaRecord,
  PVI,
  RagioneSociale,
  RagioneSocialePVI,
} from "./ragioneSocialePvi/types";

const optionZip = sequenceT(O.option);
const traverseT = A.array.traverse(T.taskEither);
export const key = "DISPONIBILITIA";

export const refreshRagioneSociale = {
  type: "rtm/DISPONIBILITIA/REFRESH_RAGIONE_SOCIALE",
} as const;

const refreshRagioneSocialeFail = (error: string) =>
  ({
    type: "rtm/DISPONIBILITIA/REFRESH_RAGIONE_SOCIALE_FAILED",
    error,
  } as const);

const setRagioneSocialeOptions = (ragioneSociale: RagioneSociale[]) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_RAGIONE_SOCIALE_OPTIONS",
    ragioneSociale,
  } as const);

export const selectRagioneSociale = (ragioneSociale: RagioneSociale) =>
  ({
    type: "rtm/DISPONIBILITIA/SELECT_RAGIONE_SOCIALE",
    ragioneSociale,
  } as const);

const fectchPVIsFail = (error: string) =>
  ({
    type: "rtm/DISPONIBILITIA/FETCH_PVI_FAIL",
    error,
  } as const);

const setPvis = (pvis: PVI[]) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_PVIS",
    pvis,
  } as const);

export const selectPVIs = (pvis: PVI[]) =>
  ({
    type: "rtm/DISPONIBILITIA/SELECT_PVI",
    pvis,
  } as const);

export const selectPVIString = (pvis: string[]) =>
  ({
    type: "rtm/DISPONIBILITIA/SELECT_PVI_STRING",
    pvis,
  } as const);

const fetchContractInfoSilentFail = (error: string) =>
  ({
    type: "rtm/DISPONIBILITIA/FETCH_CONTRACT_INFO_SILENT_FAIL",
    error,
  } as const);
const fetchContractInfoFail = (error: string) =>
  ({
    type: "rtm/DISPONIBILITIA/FETCH_CONTRACT_INFO_FAIL",
    error,
  } as const);

const setContractInfos = (contractInfos: ContractInfo[]) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_CONTRACT_INFOS",
    contractInfos,
  } as const);

const setDefaultDate = (date: O.Option<DateSelect>) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_DEFAULT_DATE",
    date,
  } as const);
export const selectMGPDate = (start: string, end: string) =>
  ({
    type: "rtm/DISPONIBILITIA/SELECT_MGP_DATE",
    start,
    end,
  } as const);
export const setPviData = (pvi: PVI[]) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_PVI_DATA",
    pvi,
  } as const);
export const exportExcel = (count: number) =>
  ({
    type: "rtm/DISPONIBILITIA/EXPORT_EXCEL",
    count,
  } as const);

export const uploadExcel = (file: File) =>
  ({
    type: "rtm/DISPONIBILITIA/UPLOAD_EXCEL",
    file,
  } as const);

export const modifyOnline = {
  type: "rtm/DISPONIBILITIA/MODIFY_ONLINE",
} as const;

export const cancelModifyOnline = {
  type: "rtm/DISPONIBILITIA/CANCEL_MODIFY_ONLINE",
} as const;

const modifyOnlineDataFail = (error: string) =>
  ({
    type: "rtm/DISPONIBILITIA/MODIFY_ONLINE_DATA_FAIL",
    error,
  } as const);

const setModifyOnlineData = (
  data: DisponibilitaCapacitaMassimaTimeserieRecord[]
) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_MODIFY_ONLINE_DATA",
    data,
  } as const);
const cancel = () =>
  ({
    type: "rtm/DISPONIBILITIA/CANCEL",
  } as const);

export const uploadModifyOnline = (
  data: any[][],
  pvi: string,
  partitaIva: string,
  dates: any
) =>
  ({
    type: "rtm/DISPONIBILITIA/UPLOAD_MODIFY_ONLINE",
    data,
    pvi,
    partitaIva,
    dates,
  } as const);

export const activeContractAction = (pvi: string, partitaIva: string) =>
  ({
    type: "rtm/DISPONIBILITIA/ACTIVE_CONTRACT",
    pvi,
    partitaIva,
  } as const);

export const setActiveContractAction = (contractInfo: ContractInfo) =>
  ({
    type: "rtm/DISPONIBILITIA/SET_ACTIVE_CONTRACT",
    contractInfo,
  } as const);

export const uploadSuccess = (
  res: DisponibilitaCapacitaMassimaUploadOutput[],
  result?: "success" | "error" | "partial"
) =>
  ({
    type: "rtm/DISPONIBILITIA/UPLOAD_SUCCESSFUL",
    res,
    result,
  } as const);

export const uploadClose = () =>
  ({
    type: "rtm/DISPONIBILITIA/UPLOAD_CLOSE",
  } as const);

export const uploadFail = (error: any) =>
  ({
    type: "rtm/DISPONIBILITIA/UPLOAD_FAIL",
    error,
  } as const);

export const modifyOnlineFail = (error: any) =>
  ({
    type: "rtm/DISPONIBILITIA/MODIFY_ONLINE_FAIL",
    error,
  } as const);

export const clearData = {
  type: "rtm/DISPONIBILITIA/CLEAR_DATA",
} as const;

type Action =
  | typeof clearData
  | typeof refreshRagioneSociale
  | typeof modifyOnline
  | typeof cancelModifyOnline
  | ReturnType<
      | typeof selectRagioneSociale
      | typeof exportExcel
      | typeof refreshRagioneSocialeFail
      | typeof setRagioneSocialeOptions
      | typeof setPvis
      | typeof fectchPVIsFail
      | typeof selectPVIs
      | typeof setPviData
      | typeof selectPVIString
      | typeof fetchContractInfoFail
      | typeof fetchContractInfoSilentFail
      | typeof setContractInfos
      | typeof selectMGPDate
      | typeof uploadExcel
      | typeof activeContractAction
      | typeof setActiveContractAction
      | typeof modifyOnlineDataFail
      | typeof setModifyOnlineData
      | typeof uploadModifyOnline
      | typeof uploadSuccess
      | typeof uploadClose
      | typeof uploadFail
      | typeof modifyOnlineFail
      | typeof setDefaultDate
      | typeof cancel
    >;

type DateSelect = { min: string; start: string; end: string };

export type State = {
  isFetching: boolean;
  ragioneSociale: NetworkRequest<string, RagioneSociale[]>;
  selectedRagioneSociale: O.Option<RagioneSociale>;
  pvis: NetworkRequest<string, PVI[]>;
  allPvis: PVI[];
  selectedPVIs: PVI[];
  selectedPVIStrings: string[];
  contractInfos: NetworkRequest<string, ContractInfo[]>;
  dateSelect: O.Option<DateSelect>;
  modifyOnline: NetworkRequest<
    string,
    DisponibilitaCapacitaMassimaTimeserieRecord[]
  >;
  uploadOngoing: NetworkRequest<string, any>;
  uploadModify: any;
  bulkUploadResponse: DisponibilitaCapacitaMassimaUploadOutput[];
  bulkUploadResult?: "success" | "error" | "partial";
  activeContract: ContractInfo | null;
};

export const initialState: State = {
  isFetching: false,
  ragioneSociale: notStarted,
  selectedRagioneSociale: O.none,
  pvis: notStarted,
  allPvis: [],
  selectedPVIs: [],
  selectedPVIStrings: [],
  contractInfos: notStarted,
  dateSelect: O.none,
  modifyOnline: notStarted,
  uploadOngoing: notStarted,
  uploadModify: false,
  bulkUploadResponse: [],
  bulkUploadResult: "success",
  activeContract: null,
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case "rtm/DISPONIBILITIA/CLEAR_DATA":
      return initialState;
    case "rtm/DISPONIBILITIA/REFRESH_RAGIONE_SOCIALE":
      return {
        ...state,
        isFetching: true,
        ragioneSociale: inProgress,
      };
    case "rtm/DISPONIBILITIA/ACTIVE_CONTRACT":
      return {
        ...state,
        isFetching: true,
      };
    case "rtm/DISPONIBILITIA/SET_ACTIVE_CONTRACT":
      return {
        ...state,
        isFetching: false,
        activeContract: action.contractInfo,
      };
    case "rtm/DISPONIBILITIA/REFRESH_RAGIONE_SOCIALE_FAILED":
      return {
        ...state,
        isFetching: false,
        ragioneSociale: fail(action.error),
      };
    case "rtm/DISPONIBILITIA/SET_PVI_DATA":
      return {
        ...state,
        isFetching: false,
        allPvis: action.pvi,
      };
    case "rtm/DISPONIBILITIA/SET_RAGIONE_SOCIALE_OPTIONS":
      return {
        ...state,
        isFetching: false,
        ragioneSociale: success(action.ragioneSociale),
        selectedRagioneSociale: pipe(
          state.selectedRagioneSociale,
          O.alt(() => A.head(action.ragioneSociale))
        ),
      };
    case "rtm/DISPONIBILITIA/SELECT_RAGIONE_SOCIALE":
      return {
        ...state,
        isFetching: true,
        selectedRagioneSociale: O.some(action.ragioneSociale),
        pvis: inProgress,
        selectedPVIs: [],
        dateSelect: O.none,
      };
    case "rtm/DISPONIBILITIA/FETCH_PVI_FAIL":
      return { ...state, isFetching: false, pvis: fail(action.error) };
    case "rtm/DISPONIBILITIA/SET_PVIS":
      return {
        ...state,
        isFetching: false,
        pvis: success(action.pvis),
        //selectedPVIs: action.pvis.slice(0, 1),
      };
    case "rtm/DISPONIBILITIA/SELECT_PVI":
      return {
        ...state,
        isFetching: false,
        selectedPVIs: action.pvis,
        //        contractInfos: inProgress,
      };
    case "rtm/DISPONIBILITIA/SELECT_PVI_STRING":
      return {
        ...state,
        isFetching: false,
        selectedPVIStrings: action.pvis,
      };
    case "rtm/DISPONIBILITIA/FETCH_CONTRACT_INFO_SILENT_FAIL":
    case "rtm/DISPONIBILITIA/FETCH_CONTRACT_INFO_FAIL":
      return {
        ...state,
        isFetching: false,
        contractInfos: fail(action.error),
        activeContract: null,
      };
    case "rtm/DISPONIBILITIA/SET_CONTRACT_INFOS":
      return {
        ...state,
        isFetching: false,
        contractInfos: success(action.contractInfos),
      };
    case "rtm/DISPONIBILITIA/SET_DEFAULT_DATE":
      return {
        ...state,
        isFetching: false,
        dateSelect: action.date,
      };
    case "rtm/DISPONIBILITIA/SELECT_MGP_DATE":
      return {
        ...state,
        isFetching: false,
        dateSelect: O.some({
          start: action.start,
          end: action.end,
          min: action.start,
        }),
      };
    case "rtm/DISPONIBILITIA/MODIFY_ONLINE":
      return { ...state, isFetching: true, modifyOnline: inProgress };
    case "rtm/DISPONIBILITIA/CANCEL_MODIFY_ONLINE":
      return { ...state, isFetching: false, modifyOnline: notStarted };
    case "rtm/DISPONIBILITIA/MODIFY_ONLINE_DATA_FAIL":
      return { ...state, isFetching: false, modifyOnline: fail(action.error) };
    case "rtm/DISPONIBILITIA/SET_MODIFY_ONLINE_DATA":
      return {
        ...state,
        isFetching: false,
        modifyOnline: success(action.data),
      };
    case "rtm/DISPONIBILITIA/CANCEL":
      return {
        ...state,
        isFetching: false,
      };
    case "rtm/DISPONIBILITIA/UPLOAD_SUCCESSFUL":
      return {
        ...state,
        uploadModify: true,
        uploadOngoing: success([]),
        bulkUploadResponse: action.res,
        bulkUploadResult: action.result || "success",
      };
    case "rtm/DISPONIBILITIA/MODIFY_ONLINE_FAIL":
    case "rtm/DISPONIBILITIA/UPLOAD_FAIL":
      return {
        ...state,
        uploadModify: false,
        uploadOngoing: fail(action.error),
      };
    case "rtm/DISPONIBILITIA/UPLOAD_CLOSE":
      return {
        ...state,
        uploadModify: false,
      };

    case "rtm/DISPONIBILITIA/UPLOAD_MODIFY_ONLINE":
      return {
        ...state,
        uploadOngoing: inProgress,
      };
    case "rtm/DISPONIBILITIA/EXPORT_EXCEL":
    case "rtm/DISPONIBILITIA/UPLOAD_EXCEL":
      return state;
  }
}

const refreshRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    ofType("rtm/DISPONIBILITIA/REFRESH_RAGIONE_SOCIALE"),
    RxOp.exhaustMap(
      getAllPages((skip = 0, limit = 999) =>
        deps.request.get(
          `core/ragionesociale?skip=${skip}&limit=${limit}&sort=RagioneSociale%20asc`,
          pagination(RagioneSociale)
        )
      )
    ),
    RxOp.map(
      E.fold<string, RagioneSociale[], Action>(
        refreshRagioneSocialeFail,
        setRagioneSocialeOptions
      )
    )
  );
const setRagioneSocialeOptionsEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/DISPONIBILITIA/SET_RAGIONE_SOCIALE_OPTIONS"
        ? pipe(
            from(a.ragioneSociale),
            RxOp.take(1)
          )
        : EMPTY
    ),
    RxOp.exhaustMap((a) =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${a.partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      )
    ),
    RxOp.map(E.map((x) => x.map((x) => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const selectRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/DISPONIBILITIA/SELECT_RAGIONE_SOCIALE" ? of(a) : EMPTY
    ),
    RxOp.exhaustMap((a) =>
      defer(
        getAllPages((skip = 0, limit = 999) => {
          let partitaIva = "";
          if (a.ragioneSociale) partitaIva = a.ragioneSociale.partitaIva;
          return deps.request.get(
            `core/ragionesocialepvi?partitaIva=${partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          );
        })
      )
    ),
    RxOp.map(E.map((x) => x.map((x) => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );
const activeContractEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/DISPONIBILITIA/ACTIVE_CONTRACT" ? of(a) : EMPTY
    ),
    RxOp.exhaustMap((a) =>
      defer(
        deps.request.get(
          `core/ragionesocialepvi/${a.partitaIva}/${a.pvi}/activeContract`,
          ContractInfo
        )
      )
    ), // Log API response
    RxOp.map(
      E.fold<string, ContractInfo, Action>(
        (error) => fetchContractInfoSilentFail(error),
        setActiveContractAction
      )
    )
  );

const selectPVIsEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((x) =>
      x.type === "rtm/DISPONIBILITIA/SET_PVIS" ||
      x.type === "rtm/DISPONIBILITIA/SELECT_PVI"
        ? of(x)
        : EMPTY
    ),
    RxOp.switchMapTo(
      pipe(
        state$,
        RxOp.first(),
        RxOp.map(Selectors.all),
        RxOp.map((x) => [x.selectedRagioneSociale, x.selectedPVIs] as const)
      )
    ),
    RxOp.mergeMap(([ragione, pvis]) =>
      pipe(
        ragione,
        O.fold(() => EMPTY, (ragione) => of([ragione, pvis] as const))
      )
    ),
    RxOp.exhaustMap(([ragione, pvis]) =>
      defer(
        traverseT(pvis, (pvi) =>
          deps.request.get(
            `core/ragionesocialepvi/${ragione.partitaIva}/${pvi.pvi}/activeContract`,
            ContractInfo
          )
        )
      )
    ),
    RxOp.map(
      E.fold<string, ContractInfo[], Action>(
        () =>
          fetchContractInfoFail(
            "Non risultano contratti attivi per la controparte selezionata"
          ),
        setContractInfos
      )
    )
  );

export const getUserSelectionsExcelCreate = (
  state$: Observable<any>
): Observable<E.Either<string, [PVI[], DateSelect]>> =>
  pipe(
    state$,
    RxOp.first(),
    RxOp.map(Selectors.all),
    RxOp.map(combineUserSelectionsWithoutRagioneSociale),
    RxOp.map(
      O.fold(
        () => E.left("Missing user selections"),
        ([pvis, dateSelect]) => E.right([pvis, dateSelect])
      )
    )
  );

const getMisureBiddingTimeserieA = (
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    getUserSelectionsExcelCreate(state$),
    RxOp.map(E.fold(() => O.none, (userSelections) => O.some(userSelections))),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([pvis, date]) => {
          interface Raggruppamento {
            partitaIva: string;
            pvis: PVI[];
          }
          const raggruppatiPerRagioneSociale = pvis.reduce(
            (acc: Record<string, Raggruppamento>, pvi) => {
              const key = pvi.ragioneSociale
                ? pvi.ragioneSociale.partitaIva
                : pvi.pvi;
              if (!acc[key]) {
                acc[key] = {
                  partitaIva: pvi.ragioneSociale
                    ? pvi.ragioneSociale.partitaIva
                    : "",
                  pvis: [],
                };
              }
              acc[key].pvis.push(pvi);
              return acc;
            },
            {}
          );
          return from(Object.values(raggruppatiPerRagioneSociale)).pipe(
            RxOp.mergeMap((raggruppamento: any) =>
              defer(
                getAllPages((skip = 0, limit = 999) =>
                  deps.request.get(
                    `producer/disponibilitaCapacitaMassima/timeserie?skip=${skip}&limit=${limit}&onlyUploadEnabled=true&partitaIva=${
                      raggruppamento.partitaIva
                    }&${raggruppamento.pvis
                      .map((p: any) => `pvi=${p.pvi}`)
                      .join("&")}&${`from=${pipe(
                      toDate(date.start),
                      (d) =>
                        format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                          timeZone: "Europe/Rome",
                        }),
                      encodeURIComponent
                    )}&to=${pipe(
                      toDate(date.end),
                      (d) => addDays(d, 1),
                      (d) =>
                        format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                          timeZone: "Europe/Rome",
                        }),
                      encodeURIComponent
                    )}`}`,
                    pagination(DisponibilitaCapacitaMassimaTimeserieRecord)
                  )
                )
              ).pipe(
                RxOp.map(
                  E.map(
                    (data) =>
                      [raggruppamento.pvis[0].ragioneSociale, data] as const
                  )
                )
              )
            )
          );
        }
      )
    )
  );

const exportExcelEpic = (
  action$: Observable<Action & { count: number }>,
  state$: Observable<any>,
  deps: Dependancies
) => {
  return pipe(
    action$,
    ofType("rtm/DISPONIBILITIA/EXPORT_EXCEL"),
    RxOp.withLatestFrom(state$),
    RxOp.mergeMap(([action, state]) => {
      const count = action.count;
      return getMisureBiddingTimeserieA(state$, deps).pipe(
        RxOp.bufferCount(count),
        RxOp.map(E.right),
        RxOp.tap(
          E.fold(console.log, (response) => {
            const store = Selectors.all(state);
            const selectedPVIs = store.selectedPVIs;
            const responseData = response
              .map((x: any) => {
                return {
                  ragioneSociale: x.right[0],
                  data: x.right[1],
                };
              })
              .filter((x: any) => x.data && x.data.length > 0);
            // Group data by pvi
            const groupedData = responseData.reduce((acc: any, curr: any) => {
              curr.data.forEach((d: any) => {
                if (!acc[d.pvi]) {
                  acc[d.pvi] = [];
                }
                acc[d.pvi].push(d);
              });
              return acc;
            }, {});

            var wb = new xl.Workbook();
            var ws = wb.addWorksheet("Disponibilità");

            // Write PVI names to the first row
            Object.keys(groupedData).forEach((pvi: string, j: number) => {
              ws.cell(1, j + 2).string(
                isNil(pvi)
                  ? ""
                  : selectedPVIs.filter((x) => x.pvi === pvi)[0].nome
              );
              ws.cell(2, j + 2).string(pvi);
            });

            // Write "DateTimeOffset" to the first column of the second row
            ws.cell(2, 1).string("DateTimeOffset");
            let dateTimeOffsets: any = [];
            if (responseData.length > 0) {
              dateTimeOffsets = [
                ...new Set(
                  responseData[0].data.map((d: any) => d.dateTimeOffset)
                ),
              ];
            }

            // Write DateTimeOffset values
            dateTimeOffsets.forEach((dateTimeOffset: any, i: number) => {
              const row = i + 3; // Start from the third row

              // Write DateTimeOffset to the first column
              ws.cell(row, 1).string(
                isNil(dateTimeOffset) ? "" : dateTimeOffset
              );

              // Write disponibilita values under the corresponding PVI
              Object.keys(groupedData).forEach((pvi: string, j: number) => {
                const data = groupedData[pvi].find(
                  (x: any) => x.dateTimeOffset === dateTimeOffset
                );
                if (data) {
                  ws.cell(row, j + 2).number(
                    data.disponibilita ? data.disponibilita : 0
                  );
                }
              });
            });

            wb.writeToBuffer().then(function(buffer: any) {
              var a = window.document.createElement("a");
              a.href = window.URL.createObjectURL(
                new Blob([buffer], { type: "application/octet-stream" })
              );
              a.download = "DisponibilitaCapacitaMassima_Upload.xlsx";

              // Append anchor to body.
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

              // Remove anchor from body
            });
          })
        ),
        RxOp.mergeMapTo(EMPTY)
      );
    })
  );
};

const modifyOnlineEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    ofType("rtm/DISPONIBILITIA/MODIFY_ONLINE"),
    RxOp.mergeMapTo(getMisureBiddingTimeserieA(state$, deps)),
    RxOp.map(
      E.fold(
        modifyOnlineDataFail,
        ([, data]) => setModifyOnlineData(data) as Action
      )
    )
  );

const modifyOnlineUploadEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/DISPONIBILITIA/UPLOAD_MODIFY_ONLINE" ? of(a) : EMPTY
    ),
    RxOp.mergeMap((a) =>
      pipe(
        O.fromNullable(a), // Create an Option from a
        O.fold(
          () => EMPTY,
          (a): Observable<any> => {
            // Modify a.data
            console.log(a.data);
            const modifiedData = a.data.map((x: any) => ({
              pvi: a.pvi,
              dateTimeOffset: x[1],
              disponibilita: x[2],
              capacitaMassima: null,
            }));

            return defer(
              deps.request.post(
                `producer/disponibilitaCapacitaMassima/upload`,
                {
                  partitaIVA: a.partitaIva,
                  pvi: a.pvi,
                  from: a.dates.start,
                  to: Ra.pipe(
                    (x: any) => addDays(x, 1),
                    (x: any) => format(x, "yyyy-MM-dd")
                  )(new Date(a.dates.end)),
                  records: modifiedData,
                },
                t.unknown
              )
            );
          }
        ),
        RxOp.tap((response) => console.log("API response:", response)), // Log API response
        RxOp.map(E.fold<any, any, Action>(modifyOnlineFail, uploadSuccess))
      )
    )
  );

const contractInfoEpic = (action$: Observable<Action>) =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/DISPONIBILITIA/SET_CONTRACT_INFOS" ? of(a) : EMPTY
    ),
    RxOp.map((x) =>
      setDefaultDate(getDatepickerDate(Date.now(), x.contractInfos))
    )
  );

const failureEpic = (action$: Observable<Action>) =>
  action$.pipe(
    RxOp.filter(
      (action: any) =>
        action.type === "rtm/DISPONIBILITIA/FETCH_PVI_FAIL" ||
        action.type === "rtm/DISPONIBILITIA/REFRESH_RAGIONE_SOCIALE_FAILED" ||
        action.type === "rtm/DISPONIBILITIA/FETCH_CONTRACT_INFO_FAIL" ||
        action.type === "rtm/DISPONIBILITIA/MODIFY_ONLINE_DATA_FAIL" ||
        action.type === "rtm/DISPONIBILITIA/MODIFY_ONLINE_FAIL"
    ),
    RxOp.map((x) => dispatchNetworkError(x.error))
  );

const failureBulkUploadEpic = (action$: Observable<Action>) =>
  action$.pipe(
    RxOp.filter(
      (action: any) => action.type === "rtm/DISPONIBILITIA/UPLOAD_FAIL"
    ),
    RxOp.mergeMap((err) => {
      const response = Ra.pathOr(null, ["response"], err.error);
      console.log(response);
      const errorCode: string = Ra.pathOr("", ["title"], response);
      const disponibilitaCapacita = Ra.pathOr(
        [],
        ["disponibilitaCapacita", "disponibilitaCapacita"],
        response
      );
      //Way to Skip specific ERRORS

      return of({
        type: "rtm/DISPONIBILITIA/UPLOAD_SUCCESSFUL",
        res: disponibilitaCapacita,
        result: errorCode === "CAPACITA_MASSIMA_BU" ? "partial" : "error",
      });
    })
  );
/*
     type: "rtm/DISPONIBILITIA/UPLOAD_SUCCESSFUL",
    res


  */

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const Selectors = {
  all: (s: any): State => s[key],
  modifyOnline: hasPath([key, "modifyOnline", "value"]),
};

const uploadExcelEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/DISPONIBILITIA/UPLOAD_EXCEL" ? of(a) : EMPTY
    ),
    RxOp.mergeMap((a) =>
      pipe(
        state$,
        RxOp.first(),
        RxOp.map(Selectors.all),
        RxOp.mergeMap((s) =>
          pipe(
            optionZip(O.some(s.selectedPVIs), s.dateSelect, O.some(a.file)),
            O.fold(() => EMPTY, (x) => of(x))
          )
        )
      )
    ),
    RxOp.mergeMap(([pvis, date, file]) =>
      ((file as any).arrayBuffer() as Promise<any>).then(
        (fileB: ArrayBuffer) => [pvis, date, fileB] as const
      )
    ),
    RxOp.mergeMap(([pvis, date, file]) =>
      pipe(
        new Uint8Array(file),
        (x) => XLSX.read(x, { type: "array" }),
        (x) => {
          const json = XLSX.utils.sheet_to_json(x.Sheets[x.SheetNames[0]], {
            range: 1,
          });

          return json;
        },
        (json) => {
          const disponibilitaCapacita = rowToDisponibilitaCapacita(
            json,
            pvis,
            date
          );
          return disponibilitaCapacita;
        },
        E.fromOption(() => "File headers don't match Disponibilitia"),
        E.chain(
          flow(
            t.array(
              t.type({
                partitaIva: t.string,
                pvi: t.string,
                from: t.string,
                to: t.string,
                records: t.array(DisponibilitaRecord),
              })
            ).decode,
            E.mapLeft(() => "Could not parse file")
          )
        ),
        E.map((as) =>
          defer(
            deps.request.post(
              `producer/disponibilitaCapacitaMassima/bulkupload`,
              as,
              t.unknown
            )
          )
        ),
        E.fold((e) => of(E.left(e)), (x) => x)
      )
    ),
    RxOp.map(E.fold<any, any, Action>(uploadFail, uploadSuccess))
  );

function rowToDisponibilitaCapacita(
  rows: any[],
  pvis: any[],
  date: any
): O.Option<
  { partitaIva: string; pvi: string; records: DisponibilitaRecord[] }[]
> {
  rows = rows.filter((x) => x["DateTimeOffset"] !== undefined);
  const transformedData = rows.flatMap((item) => {
    const dateTimeOffset = item.DateTimeOffset;
    return Object.keys(item)
      .filter((key) => key !== "DateTimeOffset")
      .map((key) => {
        const selectedPVI = pvis.find((pvi) => pvi.pvi === key); // Find the corresponding PVI
        return {
          partitaIva: selectedPVI ? selectedPVI.ragioneSociale.partitaIva : "",
          pvi: key,
          disponibilita: item[key],
          dateTimeOffset,
          capacitaMassima: null,
        };
      });
  });

  const groupedData = transformedData.reduce(
    (acc, item) => {
      const key = `${item.partitaIva}-${item.pvi}`; // Create a unique key for each partitaIva and pvi pair
      if (!acc[key]) {
        acc[key] = {
          partitaIva: item.partitaIva,
          pvi: item.pvi,
          from: date.start,
          to: Ra.pipe(
            (x: any) => addDays(x, 1),
            (x: any) => format(x, "yyyy-MM-dd")
          )(new Date(date.end)),
          records: [],
        };
      }
      acc[key].records.push({
        disponibilita: Number(item.disponibilita) || null, // Ensure disponibilita is a number, null, or undefined
        dateTimeOffset: String(item.dateTimeOffset), // Ensure dateTimeOffset is a string
        capacitaMassima: Number(item.capacitaMassima) || null, //
      });
      return acc;
    },
    {} as {
      [key: string]: {
        partitaIva: string;
        pvi: string;
        from: string;
        to: string;
        records: {
          disponibilita: number | null | undefined;
          dateTimeOffset: string;
          capacitaMassima: number | null | undefined;
        }[];
      };
    }
  );

  const groupedDataArray = Object.values(groupedData);

  return O.some(groupedDataArray);
}

/*function checkHeaders(rows: any[]) {
  return pipe(
    A.head(rows),
    O.chain(
      flow(
        Object.keys,
        A.map((x) =>
          A.findFirst((mi) => mi === x)(
            concat(["CapacitaMassima"], disponibilitaHeaders)
          )
        ),
        A.array.sequence(O.option)
      )
    ),
    O.fold(constFalse, constTrue)
  );
}
*/

function getDatepickerDate(
  now: number,
  contractInfos: ContractInfo[]
): O.Option<DateSelect> {
  return pipe(
    contractInfos,
    A.chain((x) => x.fornitura || null),
    A.findFirst((x) => x.caricamentoDisponibilita),
    O.map(() =>
      format(utcToZonedTime(now, "Europe/Rome"), "HH:mm") <= "11:00"
        ? {
            min: pipe(
              utcToZonedTime(now, "Europe/Rome"),
              (d) => addDays(d, 1),
              (d) => format(d, "yyyy-MM-dd")
            ),
            start: pipe(
              utcToZonedTime(now, "Europe/Rome"),
              (d) => addDays(d, 1),
              (d) => format(d, "yyyy-MM-dd")
            ),
            end: pipe(
              utcToZonedTime(now, "Europe/Rome"),
              (d) => addDays(d, 1),
              (d) => format(d, "yyyy-MM-dd")
            ),
          }
        : {
            min: pipe(
              utcToZonedTime(now, "Europe/Rome"),
              (d) => addDays(d, 2),
              (d) => format(d, "yyyy-MM-dd")
            ),
            start: pipe(
              utcToZonedTime(now, "Europe/Rome"),
              (d) => addDays(d, 2),
              (d) => format(d, "yyyy-MM-dd")
            ),
            end: pipe(
              utcToZonedTime(now, "Europe/Rome"),
              (d) => addDays(d, 2),
              (d) => format(d, "yyyy-MM-dd")
            ),
          }
    )
  );
}

export const combineUserSelections = (s: State) =>
  optionZip(s.selectedRagioneSociale, O.some(s.selectedPVIs), s.dateSelect);

export const combineUserSelectionsWithoutRagioneSociale = (s: State) =>
  optionZip(O.some(s.selectedPVIs), s.dateSelect);
export const epic = combineEpics(
  refreshRagioneSocialeEpic,
  setRagioneSocialeOptionsEpic,
  selectRagioneSocialeEpic,
  selectPVIsEpic,
  exportExcelEpic,
  uploadExcelEpic,
  modifyOnlineEpic,
  modifyOnlineUploadEpic,
  contractInfoEpic,
  errorHandlerEpic,
  failureEpic,
  activeContractEpic,
  failureBulkUploadEpic
);
