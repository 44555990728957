import React from "react";
import * as R from "ramda";
import { NavLink } from "react-router-dom";
import main from "../../styles/header.module.scss";

type SubMenuItemTypes = {
  title: string;
  route: string;
};

const SubMenuItem = ({ title, route }: SubMenuItemTypes) => {
  return (
    <NavLink
      key={title}
      activeClassName={main.subMenuItemActive}
      className={main.subMenuItem}
      isActive={(match: any, location: any) => {
        return match && (R.contains(match.url, location.pathname) as any);
      }}
      to={`/${route}/${R.replace(/\s/g, "", title)}`}
    >
      <span className="label">{title}</span>
    </NavLink>
  );
};
export default SubMenuItem;
