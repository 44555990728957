import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  loginAction,
  handleRedirectAction,
} from "./redux/modules/authentication";
import style from "./styles/login.module.scss";

export function Login(props: { error?: string }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.search) dispatch(handleRedirectAction);
    else dispatch(loginAction);
  }, []);

  return <div className={style.loginContainer} />;
}
