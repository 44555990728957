import React from "react";
import * as R from "ramda";
import { format } from "date-fns";
import DateRange from "../../components/dateRange";

type TextInputType = {
  value: { uploadedFrom: any; uploadedTo: any };
  update: (a: any) => void;
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
};

const TextInput = ({
  update,
  value,
  labelName = null,
  requiredField = false
}: TextInputType) => {
  const fromDateTime = R.equals(null, value.uploadedFrom)
    ? null
    : new Date(value.uploadedFrom);
  const toDateTime = R.equals(null, value.uploadedTo)
    ? null
    : new Date(value.uploadedTo);
  return (
    <>
      {labelName ? (
        <label className="form-label" htmlFor="select">
        {requiredField ? (
          <span className="d-flex">
            <div>{labelName}</div>
            <div className="required">&nbsp;(Required)</div>
          </span>
        ) : (
          labelName
        )}
      </label>
      ) : null}
      <DateRange
        startDate={fromDateTime}
        onStartDateChange={(e: Date) => {
          return update({
            ...value,
            uploadedFrom: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
          });
        }}
        endDate={toDateTime}
        onEndDateChange={(e: Date) =>
          update({
            ...value,
            uploadedTo: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
          })
        }
      />
    </>
  );
};
export default TextInput;
