import React from "react";
import * as R from "ramda";
import main from "../styles/header.module.scss";
import SubMenuItem from "./componets/subMenuItem";
import Protected from "../protectedComponent";
import permissions from "../permissions";

import { withRouter } from "react-router-dom";

export const DatiProduzioneSubMenu = () => {
  return (
    <div className="subMenuNavHolder">
      <SubMenuItem title="Produzione" route="DatiProduzione" />
      <SubMenuItem title="Settlement" route="DatiProduzione" />
    </div>
  );
};

export const AccessoAlMercatoSubMenu = () => {
  return (
    <div className="subMenuNavHolder">
      <SubMenuItem title="Mercati Spot" route="AccessoAlMercato" />
      <SubMenuItem title="Mercati Forward" route="AccessoAlMercato" />
    </div>
  );
};

export const GestioneSubMenu = () => {
  return (
    <div className="subMenuNavHolder">
      <Protected
        permission={permissions.processiBiddingPermissions}
        component={() => (
          <SubMenuItem title="Processi Bidding" route="Gestione" />
        )}
      />
      <Protected
        permission={permissions.coefficientiPermissions}
        component={() => (
          <SubMenuItem title="Coefficienti UP" route="Gestione" />
        )}
      />
      <Protected
        permission={[permissions.dealCaptureRead]}
        component={() => (
          <SubMenuItem
            title="Processo Inserimento Contratti"
            route="Gestione"
          />
        )}
      />
    </div>
  );
};
export const ConfigurazioniSubMenu = () => {
  return (
    <div className="subMenuNavHolder">
      <SubMenuItem title="Up Provider" route="Configurazioni" />
      <SubMenuItem title="PVI Provider" route="Configurazioni" />
      <SubMenuItem title="Tensione" route="Configurazioni" />
      <SubMenuItem title="Up Backup" route="Configurazioni" />
      <SubMenuItem title="Spread MGP" route="Configurazioni" />
      <SubMenuItem title="Internal Legs" route="Configurazioni" />
      <SubMenuItem title="Fuel" route="Configurazioni" />
    </div>
  );
};

export const AttivitaEseguiteSubMenu = () => {
  return (
    <div className="subMenuNavHolder">
      <Protected
        permission={[permissions.auditRead]}
        component={() => (
          <SubMenuItem title="Audit Trail" route="AttivitaEseguite" />
        )}
      />
      <Protected
        permission={permissions.operazioniEseguite}
        component={() => (
          <SubMenuItem title="Operazioni Eseguite" route="AttivitaEseguite" />
        )}
      />
    </div>
  );
};

const SelectSubMenu = ({ mainLocation }: { mainLocation: string }) => {
  switch (mainLocation) {
    case "DatiProduzione":
      return <DatiProduzioneSubMenu />;
    case "AccessoAlMercato":
      return <AccessoAlMercatoSubMenu />;
    case "Esiti":
      return null;
    case "MarketBid":
      return null;
    case "Gestione":
      return <GestioneSubMenu />;
    case "Configurazioni":
      return <ConfigurazioniSubMenu />;
    case "AttivitaEseguite":
      return <AttivitaEseguiteSubMenu />;
    default:
      return null;
  }
};

export const SubMenu = ({ mainLocation }: { mainLocation: string }) => {
  return (
    <div className={main.subMenu}>
      <SelectSubMenu mainLocation={mainLocation} />
    </div>
  );
};

export default R.compose<any, any>(withRouter)(SubMenu);
