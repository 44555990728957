import React from "react";
import * as R from "ramda";

type NotifcationModalType = {
  open: boolean;
  title: string | JSX.Element;
  message: string | JSX.Element;
  footer?: JSX.Element;
  close: () => void;
  width?: "small" | "medium" | "large" | "xlarge" | "xxlarge" | "xxxlarge";
};

const NotifcationModal = ({
  open,
  title,
  message,
  footer,
  close,
  width = "medium",
}: NotifcationModalType) => {
  let pixelWidth = { style: {} };
  switch (width) {
    case "small":
      pixelWidth = { style: { maxWidth: "200px" } };
      break;
    case "medium":
      pixelWidth = { style: { maxWidth: "400px" } };
      break;
    case "large":
      pixelWidth = { style: { maxWidth: "600px" } };
      break;
    case "xlarge":
      pixelWidth = { style: { maxWidth: "800px" } };
      break;
    case "xxlarge":
      pixelWidth = { style: { maxWidth: "1000px" } };
      break;
    case "xxxlarge":
      pixelWidth = { style: { maxWidth: "1200px" } };
      break;
  }
  return (
    <>
      {R.when(R.equals(true), () => (
        <div
          className="modal mapping-modal z-index-modal fade show d-block"
          id="configModal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="overlay fadeIn" />
          <div
            className="modal-dialog"
            style={pixelWidth.style}
            role="document"
          >
            <div className="modal-content">
              <div className="modal-close-btn">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => close()}
                >
                  <i className="fas fa-times-circle"></i>
                </button>
              </div>
              <div className="modal-header text-center">
                <h5 className="modal-title">{title}</h5>
              </div>
              <div className="modal-body text-center">{message}</div>
              <div className="modal-footer">
                {footer || (
                  <button
                    className="btn btn-danger rounded-pill mx-2"
                    onClick={() => close()}
                  >
                    OK
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))(open)}
    </>
  );
};

export default NotifcationModal;
