import React from "react";
import { onEnter } from "../../utils/event";

type TextInputTypes = {
  value: string;
  update: (str: string) => any;
  type?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  label?: string;
  autoFocus?: boolean;
  min?: number | undefined;
  max?: number | undefined;
};

const InputNumeric = ({
  value,
  update,
  name = "textInput",
  className = "",
  placeholder = "Inserire valore",
  readOnly = false,
  autoFocus = false,
  min = undefined,
  max = undefined,
}: TextInputTypes) => {
  const onEnterUpdate = (e: any) => update(e.target.value);
  return (
    <div className="textInput">
      <input
        type="number"
        name={name}
        autoComplete={name}
        readOnly={readOnly}
        className={`${readOnly ? "disabled" : ""}${className}`}
        value={value}
        placeholder={readOnly ? "" : placeholder}
        onChange={e => update(e.target.value)}
        onKeyDown={onEnter(onEnterUpdate)}
        autoFocus={autoFocus}
        min={min}
        max={max}
      />
    </div>
  );
};

export default InputNumeric;
