import * as R from "ramda";
import XLSX from "xlsx";
import { Observable, of } from "rxjs";
import * as RxOp from "rxjs/operators";

type cellType = "string" | "number" | "enum" | "date";
export type infoType = {
  propName: string;
  colName: string;
  cell: cellType;
  enumName?: any;
};

const readFile = ({ file, headers }: { file: any; headers: string[] }) =>
  Observable.create((obs: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const dataResult = e.target.result;
      const workbook = XLSX.read(dataResult, { type: "array" });
      const sheets = workbook.SheetNames;
      const res = [] as any;
      sheets.map(sheet => {
        return res.push({
          sheet,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
            header: headers,
            defval: null
          })
        });
      });
      obs.next(res);
      obs.complete();
    };
    reader.readAsArrayBuffer(file);
  });

type FormJsonType = { file: any; info: infoType[] };
export const formJson = ({ file, info }: FormJsonType) =>
  of(file).pipe(
    RxOp.flatMap(file => file),
    RxOp.flatMap(file =>
      readFile({ file, headers: R.map(R.prop("propName"), info) })
    ),
    RxOp.map(R.pathOr(null, [0, "data"]))
  );

export default formJson;
