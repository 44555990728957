import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { format } from "date-fns";
import {
  Selectors,
  TablesStateType,
  fetchingAuditList,
  fetchingAuditusers,
  setSelectedAuditId,
  SelectedAuditIdType,
  setPageAuditList,
  getAuditList,
  AuditKindsType,
  exportAuditDiff,
  FilterTablesStateType,
  setFiltersAuditList,
  kindOptions,
} from "../redux/modules/audits/audit";
import TextTableHeader from "../components/tableComponents/textTableHeader";
import Pagination from "../components/tableComponents/paginationNew";
import NoData from "../components/tableComponents/noData";
import SelectInput from "./components/selectInput";
import DateRangeInput from "./components/dateRange";
import Loader from "../components/loader";
import PageOnLoad from "../components/pageOnLoad";

type AuditType = {
  auditListTable: TablesStateType;
  selectedAuditID: string;
  selectedAuditKind: AuditKindsType;
  selectedAuditDiff: any;
  userListTable: any;
  isFetching: any;
  fetchingAuditList: () => void;
  fetchingAuditusers: () => void;
  setSelectedAuditId: (a: SelectedAuditIdType) => void;
  setPageAuditList: (a: number) => void;
  exportAuditDiff: () => void;
  setFiltersAuditList: (a: FilterTablesStateType) => void;
};

const Audit = ({
  auditListTable,
  selectedAuditID,
  selectedAuditKind,
  selectedAuditDiff,
  userListTable,
  isFetching,
  fetchingAuditList,
  fetchingAuditusers,
  setSelectedAuditId,
  setPageAuditList,
  exportAuditDiff,
  setFiltersAuditList,
}: AuditType) => {
  useEffect(() => {
    fetchingAuditList();
    fetchingAuditusers();
  }, []);
  return (
    <>
      <PageOnLoad />
      <div className="pageContainer">
        <h1>Audit Trail</h1>
        <AuditFilters
          filters={auditListTable.filters}
          update={setFiltersAuditList}
          users={userListTable}
        />
        <div className="auditTables">
          <AuditListTable
            tableData={auditListTable}
            selectedAuditID={selectedAuditID}
            setSelectedAuditId={setSelectedAuditId}
            setPageAuditList={setPageAuditList}
          />
          <SelectedAuditListTable
            kind={selectedAuditKind}
            data={selectedAuditDiff}
            exportConfig={exportAuditDiff}
          />
        </div>
      </div>
      <Loader load={isFetching} />
    </>
  );
};

export default connect(
  Selectors.all,
  {
    fetchingAuditList,
    fetchingAuditusers,
    setSelectedAuditId,
    setPageAuditList,
    exportAuditDiff,
    setFiltersAuditList,
  }
)(Audit);

const getAuditKind = (kind: string) => {
  switch (kind) {
    case "PVIProvider":
      return "PVI Provider";
    case "UPProvider":
      return "UP Provider";
    case "Tensione":
      return "Tensione";
    case "UPBackup":
      return "UP Backup";
    case "InternalLegs":
      return "Internal Legs";
    case "Fuel":
      return "Fuel";
    case "Acl":
      return "Acl";
    case "MisurePrezziBiddingPVIUpload":
      return "MisurePrezziBiddingPVIUpload";
    case "DisponibilitaCapacitaMassimaUpload":
      return "DisponibilitaCapacitaMassimaUpload";
    case "SpreadMGP":
      return "Spread MGP";
    default:
      return undefined;
  }
};

const rowClassMapper = (rowType: string | null) => {
  switch (rowType) {
    case "+++":
      return "green-background  white-text";
    case "---":
      return "red-background  white-text";
    default:
      return "";
  }
};

const isModCell = (cell: any) => (cell ? R.contains("->", cell) : false);

const formatOverriddenCells = (cell: string | null) => {
  if (cell) {
    const mod = isModCell(cell);
    const splitVals = mod ? R.split("->", cell) : cell;
    return mod ? (
      <>
        {splitVals[0]}
        <i className="fa fa-arrow-right" aria-hidden="true" />
        {splitVals[1]}
      </>
    ) : (
      <>{R.isNil(cell) ? "" : cell}</>
    );
  }
  return "";
};

type AuditFiltersType = {
  filters: FilterTablesStateType;
  users: any;
  update: (a: FilterTablesStateType) => void;
};

export const AuditFilters = ({ filters, users, update }: AuditFiltersType) => {
  const updateFilters = ({ key, val }: { key: string; val: any }) =>
    update({ ...filters, [key]: val });
  return (
    <div className="auditFilters">
      <div className="filter">
        <DateRangeInput
          update={update}
          labelName="Send Time Dal/Al"
          value={filters}
        />
      </div>
      <div className="filter width100">
        <SelectInput
          containsFilter
          update={(val) => updateFilters({ key: "users", val })}
          labelName="Users"
          value={
            R.isNil(filters.users)
              ? []
              : [
                  {
                    value: filters.users,
                    title: filters.users,
                  },
                ]
          }
          options={R.map((x) => ({ value: x, title: x }), users)}
        />
      </div>
      <div className="filter width100">
        <SelectInput
          containsFilter
          update={(val) => updateFilters({ key: "auditKinds", val })}
          labelName="Audit Kinds"
          value={
            R.isNil(filters.auditKinds)
              ? []
              : [
                  {
                    value: filters.auditKinds,
                    title: getAuditKind(filters.auditKinds),
                  },
                ]
          }
          options={R.map(
            (x) => ({ value: x, title: getAuditKind(x) as any }),
            kindOptions
          )}
        />
      </div>
      <div className="spacer" />
    </div>
  );
};

const getCellval = (x: any, row: any) => {
  switch (x.propName) {
    case "fromDate":
    case "toDate":
    case "tradeDate":
    case "al":
    case "dal": {
      const val = R.pathOr(null, [x.propName], row);
      return R.isNil(val) ? val : format(new Date(val), "dd-MM-yyyy");
    }
    default:
      return R.pathOr(null, [x.propName], row);
  }
};

type SelectedAuditListTableType = {
  data: any;
  kind: AuditKindsType;
  exportConfig: () => any;
};

export const SelectedAuditListTable = ({
  data,
  kind,
  exportConfig,
}: SelectedAuditListTableType) => {
  const tableInfo = getAuditList(kind);
  return data ? (
    <div className="selectedAudit">
      <div className="tableContainer">
        <div className="scroll">
          <table>
            <thead>
              <tr>
                <th className="auditDownloadButton">
                  <div
                    title="Download audit"
                    className="crudTableButtonContainer"
                  >
                    <i
                      className="fa fa-download"
                      onClick={() => exportConfig()}
                    ></i>
                  </div>
                </th>
                <th>Edit Type</th>
                {tableInfo.map((row: any, i: number) => (
                  <TextTableHeader
                    key={`SelectedAuditListTableHeader-${i}`}
                    title={row.colName}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {R.isEmpty(data) ? (
                <NoData cols={R.add(2, R.length(tableInfo))} />
              ) : (
                data.map((row: any, i: number) => {
                  const rowMod = R.pathOr(null, ["@@"], row);
                  return (
                    <tr
                      key={`SelectedAuditListTable-${i}`}
                      className={rowClassMapper(rowMod)}
                    >
                      <td></td>
                      <td>{editTypeCol(rowMod)}</td>
                      {tableInfo.map((x: any, k: number) => {
                        const cell = getCellval(x, row);
                        return (
                          <td
                            key={`${x.propName}-${k}`}
                            className={`${
                              isModCell(cell)
                                ? "bold yellow-background  white-text text-nowrap"
                                : ""
                            } `}
                          >
                            {formatOverriddenCells(cell)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : null;
};

const editTypeCol = (type: any) => {
  switch (type) {
    case "---":
      return "Cancellata";
    case "+++":
      return "Inserita";
    default:
    case "-> -> undefined":
      return "Aggiornata";
  }
};

type AuditListTableType = {
  tableData: any;
  selectedAuditID: string;
  setSelectedAuditId: (a: SelectedAuditIdType) => void;
  setPageAuditList: (a: number) => void;
};

export const AuditListTable = ({
  tableData,
  selectedAuditID,
  setSelectedAuditId,
  setPageAuditList,
}: AuditListTableType) => {
  useEffect(() => {
    R.when(R.equals(true), () =>
      setSelectedAuditId({
        auditId: R.path(["data", 0, "auditId"], tableData) as string,
        auditKind: R.path(["data", 0, "kind"], tableData) as any,
      })
    )(!R.isEmpty(tableData.data) && R.isNil(selectedAuditID));
  }, [tableData.data]);
  return (
    <div className="tableContainer auditSideTable auditList">
      <div className="audit-btn-download" />
      <div className="scroll ">
        <table className="">
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "45%" }} />
            <col style={{ width: "25%" }} />
          </colgroup>
          <thead>
            <tr>
              <TextTableHeader title="Send Time" />
              <TextTableHeader title="User" />
              <TextTableHeader title="Kind" />
            </tr>
          </thead>
          <tbody>
            {R.isEmpty(tableData.data) ? (
              <NoData cols={3} />
            ) : (
              tableData.data.map((row: any, i: number) => {
                return (
                  <tr
                    key={`AuditListTable-${i}`}
                    className={`${
                      R.equals(selectedAuditID, row.auditId)
                        ? "blue-background"
                        : " hover"
                    }`}
                    onClick={() =>
                      setSelectedAuditId({
                        auditId: row.auditId,
                        auditKind: row.kind,
                      })
                    }
                  >
                    <td
                      title={R.unless(R.isNil, (x) =>
                        format(new Date(x), "dd-MM-yyyy HH:mm:ss")
                      )(row.sysStartTime)}
                    >
                      {R.unless(R.isNil, (x) =>
                        format(new Date(x), "dd-MM-yyyy HH:mm:ss")
                      )(row.sysStartTime)}
                    </td>
                    <td title={row.userid}>{row.userid}</td>
                    <td title={getAuditKind(row.kind)}>
                      {getAuditKind(row.kind)}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <div className="pagingContainer">
        <Pagination
          count={tableData.count}
          pageSize={tableData.pageSize}
          page={tableData.page}
          onPageChange={setPageAuditList}
        />
      </div>
    </div>
  );
};
