import React from "react";
import { useDispatch, useSelector } from "react-redux";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";
import "../styles/jexcel-overrides.scss";
import * as R from "ramda";
import {
  MisurePrezziBiddingPVITimeserieRecord,
  ContractInfo,
  MercatiBidding,
  MarketsBiddableFrom,
} from "../redux/modules/ragioneSocialePvi/types";
import {
  cancelModifyOnline,
  uploadModifyOnline,
  ifMI,
  uploadClose,
  hasCodiceRilevante,
  State,
} from "../redux/modules/MGP";
import { pipe } from "fp-ts/lib/pipeable";
import { constFalse, constTrue } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import { Selectors } from "../redux/modules/MGP";
import { Selectors as ErrorHandlerSelector } from "../redux/modules/errorHandler";
import { format } from "date-fns-tz";
import styles from "./styles.module.scss";
import NotifcationModal from "../components/notifcationModal";
import Loader from "../components/loader";
import { anyInProgess, toOption } from "../utils/request";
import {
  eachDayOfInterval,
  isWithinInterval,
  addHours,
  areIntervalsOverlapping,
  startOfDay,
  endOfDay,
} from "date-fns";
import PageOnLoad from "../components/pageOnLoad";
import { setErrorAction } from "../redux/modules/errorHandler";
import { subDays } from "date-fns/esm";
import { nullType } from "io-ts";

const getError = (
  instance: any,
  cell: any,
  x: number,
  y: number,
  value: any,
  selectedPVIs: any
) => {
  const colNumber = x;
  const compareWith = R.contains("Q_", instance.jexcel.headers[x].title)
    ? 1
    : R.contains("P_", instance.jexcel.headers[x].title)
    ? -1
    : 0;

  const price = R.contains("P_", instance.jexcel.headers[x].title)
    ? parseInt(value)
    : instance.jexcel.options.data[y][
        compareWith + parseInt(colNumber.toString())
      ];

  const quantity = R.contains("Q_", instance.jexcel.headers[x].title)
    ? parseInt(value)
    : instance.jexcel.options.data[y][
        compareWith + parseInt(colNumber.toString())
      ];
  const isNonRilevante = R.hasPath(["codiceUpNonRilevante"], selectedPVIs[0]);
  if (quantity < 0 && price === "" && !isNonRilevante)
    return "Il prezzo deve essere valorizzato";
  if (quantity > 0 && price === "" && !isNonRilevante)
    return "Il prezzo deve essere valorizzato";
};

export default function Edit(props: {
  data: MisurePrezziBiddingPVITimeserieRecord[];
  contractInfos: ContractInfo[];
}) {
  const dispatch = useDispatch();
  const mgp = useSelector(Selectors.all);
  const error = useSelector(ErrorHandlerSelector.all);

  const excelContainer = React.useRef(null);
  const excelInstance = React.useRef({
    destroy: () => {},
    setData: (a: any) => {},
    getData: (): string[][] => [],
    getCell: (s: string): HTMLDivElement => document.createElement("div"),
  });
  const gradini = GradiniCheck(props.contractInfos);
  var isRilevante = hasCodiceRilevante(props.contractInfos);
  const codiceRelevante = pipe(
    toOption(mgp.contractInfos),
    O.chain(O.fromPredicate(hasCodiceRilevante)),
    O.fold(constTrue, constFalse)
  );
  React.useEffect(() => {
    const container = excelContainer.current;

    const selectedMercati = pipe(
      mgp.selectedMercati,
      O.fold(() => null, (x) => x)
    );

    excelInstance.current = jexcel(container, {
      allowInsertRow: false,
      allowInsertColumn: false,
      columns: [
        { type: "hidden", title: "Date", width: 100, readOnly: true },
        { title: "Data", width: 100, readOnly: true },
        { title: "Ora", width: 60, readOnly: true },
        {
          type: "numeric",
          title: "Q_CAPACITY[MWh]",
          width: 160,
          readOnly: true,
        },
        ...ifMI(
          mgp.selectedMercati,
          () => [
            {
              type: "numeric",
              title: "Posizione",
              width: 120,
              readOnly: true,
            },
            {
              type: "numeric",
              title: "Prezzo MGP",
              width: 120,
              readOnly: true,
            },
          ],
          () => []
        ),
        {
          type: "numeric",
          title: "Q_GR1[MWh]",
          width: 120,
        },
        {
          type: "numeric",
          title: "P_GR1[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "Q_GR2[MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "P_GR2[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "Q_GR3[MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "P_GR3[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "Q_GR4[MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "P_GR4[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
      ],
      onbeforechange: (
        instance: any,
        cell: any,
        x: number,
        y: number,
        value: any
      ) => {
        const checkMessage = getError(
          instance,
          cell,
          x,
          y,
          value,
          mgp.selectedPVIs
        );
        return ifMI(
          mgp.selectedMercati,
          () =>
            value === 0 || value === ""
              ? ""
              : pipe(
                  value,
                  Number,
                  E.fromPredicate(Number.isFinite, () => {
                    const res = instance.jexcel.getData(cell);
                    return R.isEmpty(res) ? "" : res[0][0];
                  }),
                  E.getOrElse((prev) => {
                    dispatch(
                      setErrorAction({
                        details: {
                          title: "ATTENZIONE",
                          message:
                            "Utilizzare il punto come separatore decimale",
                        },
                      })
                    );
                    return prev;
                  }),
                  E.fromPredicate(
                    () => {
                      if (checkMessage) return false;
                      return true;
                    },
                    () => {
                      const res = instance.jexcel.getData(cell);
                      return R.isEmpty(res) ? "" : res[0][0];
                    }
                  ),
                  E.getOrElse((prev) => {
                    dispatch(
                      setErrorAction({
                        details: {
                          title: "ATTENZIONE",
                          message: checkMessage,
                        },
                      })
                    );
                    return prev;
                  })
                ),
          () =>
            pipe(
              value,
              Number,
              E.fromPredicate(Number.isFinite, () => {
                const res = instance.jexcel.getData(cell);
                return R.isEmpty(res) ? "" : res[0][0];
              }),
              E.getOrElse((prev) => {
                dispatch(
                  setErrorAction({
                    details: {
                      title: "ATTENZIONE",
                      message: "Utilizzare il punto come separatore decimale",
                    },
                  })
                );
                return prev;
              })
            )
        );
      },
      onbeforepaste: (el: any, val: any) => {
        return R.replace(/,/g, ".", val);
      },
      updateTable: function(
        instance: any,
        cell: any,
        col: any,
        row: any,
        val: any,
        label: any,
        cellName: any
      ) {
        const entryCol = col > ifMI(mgp.selectedMercati, () => 3, () => 1);
        const negativeVal = parseFloat(label) < 0;

        const tableData = getDataObj(excelInstance, mgp.selectedMercati);
        const marketNotSatisfied = !checkCapacityMarketSatisfiedRow(
          tableData[row],
          mgp
        );

        const isGrCol = R.includes("Q_GR", this.columns[col].title);
        cell.className = R.pipe(
          R.split(" "),
          (list: any) => {
            return marketNotSatisfied && isGrCol && val !== ""
              ? R.append("marketNotSatisfiedCell", list)
              : R.filter((c) => !R.equals("marketNotSatisfiedCell", c), list);
          },
          (list: any) =>
            entryCol && negativeVal
              ? R.append("text-danger", list)
              : R.filter((c) => !R.equals("text-danger", c), list),
          (list: any) => {
            const mercatiBiddableFrom = mgp.mercatiBiddableFrom.filter(
              (x) => x.mercatiBidding === selectedMercati
            );
            return canEditHour(
              R.pathOr([], [0], mercatiBiddableFrom) as any,
              excelInstance.current.getCell("C" + (row + 1)).textContent || "1"
            )
              ? R.filter((c) => !R.equals("readonlyCol", c), list)
              : R.append("readonlyCol", list);
          },
          R.join(" ")
        )(cell.className);
      },
    });
    return () => jexcel.destroy(container);
  }, []);

  const selectedMercati = pipe(
    mgp.selectedMercati,
    O.fold(() => null, (x) => x)
  );

  const getPosizione = (data: any, date: any) => {
    return R.pipe<any, any, any>(
      R.filter(R.propEq("dateTimeOffset", date)),
      (d) => setPosizione({ market: selectedMercati, data: d[0] || {} })
    )(data);
  };

  const posizioneCalc = ({ list, data }: { list: string[]; data: any }) => {
    const x = R.pipe(
      R.pick(list),
      R.values
    )(data);
    return R.isEmpty(data) ? null : x.reduce((a: any, b: any) => a + b, 0);
  };

  const setPosizione = ({
    market,
    data,
  }: {
    market: string | null;
    data: any;
  }) => {
    switch (market) {
      case "MI1":
        return posizioneCalc({
          list: ["mgP_Q"],
          data,
        });
      case "MI2":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q"],
          data,
        });
      case "MI3":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q"],
          data,
        });
      case "MI4":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q"],
          data,
        });
      case "MI5":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q"],
          data,
        });
      case "MI6":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q", "mI5_Q"],
          data,
        });
      case "MI7":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q", "mI5_Q", "mI6_Q"],
          data,
        });
      default:
        return null;
    }
  };

  const getPrezzomgp = (data: any, date: any) =>
    R.prop("mgP_P")(
      R.head<any>(
        R.filter<any, any>((d: any) =>
          R.equals(date, R.prop("dateTimeOffset", d))
        )(data || [])
      )
    );
  const esitoBiddingUPData = R.pathOr([], ["esitoBiddingUPData", "value"])(mgp);
  const esitoBiddingComputedData = R.pathOr(
    [],
    ["esitoBiddingComputed", "value"]
  )(mgp);

  const market = R.pathOr("", ["selectedMercati", "value"], mgp);
  const start = R.equals(market, "MGP")
    ? new Date(
        `${R.pathOr("", ["dateSelect", "value", "start"], mgp)}T00:00:00`
      )
    : new Date(
        `${R.pathOr("", ["dateSelect", "value", "date"], mgp)}T00:00:00`
      );
  const end = R.equals(market, "MGP")
    ? new Date(`${R.pathOr("", ["dateSelect", "value", "end"], mgp)}T23:00:00`)
    : new Date(
        `${R.pathOr("", ["dateSelect", "value", "date"], mgp)}T23:00:00`
      );

  const allHourlyDates = R.pipe<any, any, any, any, any, any>(
    R.map((d: any) => R.range(0, 25).map((val) => addHours(d, val))),
    R.flatten,
    R.filter((d: any) =>
      isWithinInterval(d, {
        start,
        end,
      })
    ),
    R.map((d: Date) =>
      format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
        timeZone: "Europe/Rome",
      })
    ),
    R.uniq
  )(
    eachDayOfInterval({
      start,
      end,
    })
  );

  React.useEffect(() => {
    var excelData = allHourlyDates.map((d: any) => {
      const x = R.filter(R.propEq("dateTimeOffset", d), props.data);

      const date = format(new Date(d.split("T")[0]), "yyyy-MM-dd", {
        timeZone: "Europe/Rome",
      });
      return [
        d,
        format(new Date(d.split("T")[0]), "dd-MM-yyyy", {
          timeZone: "Europe/Rome",
        }),
        d.split("T")[1].split(":")[0],
        getQCapacity(date, mgp),
        ...ifMI(
          mgp.selectedMercati,
          () => [
            getPosizione(esitoBiddingComputedData, d),
            getPrezzomgp(esitoBiddingUPData, d),
          ],
          () => []
        ),
        R.pathOr(null, [0, "q_GR1"], x),
        R.pathOr(null, [0, "p_GR1"], x),
        R.pathOr(null, [0, "q_GR2"], x),
        R.pathOr(null, [0, "p_GR2"], x),
        R.pathOr(null, [0, "q_GR3"], x),
        R.pathOr(null, [0, "p_GR3"], x),
        R.pathOr(null, [0, "q_GR4"], x),
        R.pathOr(null, [0, "p_GR4"], x),
      ];
    });
    excelInstance.current.setData(excelData);
  }, [props.data, esitoBiddingUPData, esitoBiddingComputedData]);

  return (
    <>
      <PageOnLoad customPath="ModifyOnline" />
      {R.when(
        (x) => R.equals(x, false) || R.isNil(x),
        () => (
          <NotifcationModal
            open={mgp.uploadModify}
            close={() => dispatch(uploadClose())}
            title="Upload Successful"
            message={
              <>
                {mgp.isCapacityMarketSatisfied ? null : (
                  <div className="isCapacityMarketSatisfied">
                    Rischio Capacity Market Non Rispettato
                  </div>
                )}
                <div>MGP & MI Upload Successful</div>
              </>
            }
          />
        )
      )(error.error)}

      <div>
        <div className="d-flex flex-column">
          <div
            className="backButton"
            onClick={() => dispatch(cancelModifyOnline)}
          >
            <i className="fas fa-arrow-circle-left" />
            MGP & MI
          </div>
          <div className="containerHeader"> Modifica MGP&MI </div>
          <div className="d-flex flex-row">
            <div className="detailsHeaderTitle">
              <div className="form-group text-capitalized">Mercato</div>
              <div className="form-group text-uppercase">
                <b>{R.pathOr(null, ["selectedMercati", "value"], mgp)}</b>
              </div>
            </div>
            <div className="detailsHeaderTitle">
              <div className="form-group text-capitalized">Impianto</div>
              <div className="form-group text-capitalized">
                <b>{R.pathOr(null, ["selectedPVIs", 0, "nome"], mgp)}</b>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`margin-bottom-18 jexcel_hidden_index ${styles.modifyTable}`}
        >
          <div className="spacer"></div>
          <div className="centerInFlexContainer">
            <div ref={excelContainer}></div>
          </div>
          <div>
            Per le unità abilitate al Capacity Market: Cella Colorata significa
            “Rischio Capacity Market Non Rispettato”
          </div>
          <br></br>
          <div>
            <button
              className="btn btn-danger rounded-pill"
              onClick={() => {
                return pipe(
                  mgp.selectedMercati,
                  O.chain((m) => (m !== "MGP" ? O.some(m) : O.none)),
                  O.fold(
                    () =>
                      dispatch(
                        uploadModifyOnline(
                          getData(excelInstance, mgp.selectedMercati),
                          market,
                          isRilevante,
                          props.contractInfos[0].upsa || false
                        )
                      ),
                    () => {
                      const tableData = getDataObj(
                        excelInstance,
                        mgp.selectedMercati
                      );
                      const checkQGR = qgrCheck({
                        data: tableData,
                      });
                      if (checkQGR) {
                        dispatch(
                          setErrorAction({
                            details: {
                              title: "ATTENZIONE",
                              message:
                                "Le ore senza quantità o con quantità = 0 saranno ignorate",
                              btnTitle: "Ok",
                            },
                          })
                        );
                      }
                      return dispatch(
                        uploadModifyOnline(
                          getData(excelInstance, mgp.selectedMercati),
                          market,
                          isRilevante,
                          props.contractInfos[0].upsa || false
                        )
                      );
                    }
                  )
                );
              }}
            >
              Invia dati inseriti
            </button>
          </div>
        </div>
      </div>
      <Loader
        load={anyInProgess([
          mgp.ragioneSociale,
          mgp.pvis,
          mgp.market,
          mgp.contractInfos,
          mgp.modifyOnline,
          mgp.esitoBiddingComputed,
          mgp.uploadOngoing,
          mgp.capacityMarket,
          mgp.marketConfigPVIInfo,
        ])}
      />
    </>
  );
}

/*const valuePGRChecker = (row: any, index: number) => {
  const q_gr = R.prop(`q_GR${index}`, row);
  const p_gr = R.prop(`p_GR${index}`, row);

  // Removed in https://dev.azure.com/arklabita/ProjectONE/_workitems/edit/8432
  // In comments
  // if (!R.isEmpty(q_gr) && p_gr <= 0) return false;
  if (q_gr < 0 && p_gr <= 0) return false;
  if (q_gr > 0 && p_gr < 0) return false;

  if (R.isEmpty(q_gr) && R.isEmpty(p_gr)) return true;
  if (R.isNil(q_gr) && R.isNil(p_gr)) return true;

  return true;
};

const pgrCheck = ({ data }: { data: any }) =>
  R.pipe<any, any, any>(
    R.map((row: any) =>
      R.any(R.equals(false))([
        valuePGRChecker(row, 1),
        valuePGRChecker(row, 2),
        valuePGRChecker(row, 3),
        valuePGRChecker(row, 4),
      ])
    ),
    R.any(R.equals(true))
  )(data);
*/

const valueQGRChecker = (row: any, index: number) => {
  const q_gr = R.prop(`q_GR${index}`, row);
  const p_gr = R.prop(`p_GR${index}`, row);

  if (!R.isEmpty(p_gr) && (q_gr === 0 || q_gr === "")) return false;

  if (R.isEmpty(q_gr) && R.isEmpty(p_gr)) return true;
  if (R.isNil(q_gr) && R.isNil(p_gr)) return true;

  return true;
};

const qgrCheck = ({ data }: { data: any }) =>
  R.pipe<any, any, any>(
    R.map((row: any) =>
      R.any(R.equals(false))([
        valueQGRChecker(row, 1),
        valueQGRChecker(row, 2),
        valueQGRChecker(row, 3),
        valueQGRChecker(row, 4),
      ])
    ),
    R.any(R.equals(true))
  )(data);

const GradiniCheck = (contracts: ContractInfo[]) =>
  pipe(
    contracts,
    A.chain((c) => c.fornitura || null),
    A.findFirst((x) => x.caricamentoMIGradini || x.caricamentoMGPGradini),
    O.fold(constFalse, constTrue)
  );

function getData(
  excelInstance: any,
  selectedMercati: O.Option<MercatiBidding>
) {
  return excelInstance.current.getData().map((row: any) =>
    ifMI(
      selectedMercati,
      () => {
        const [
          dateTimeOffest,
          ,
          ,
          q_Capacity,
          posizione,
          ,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
        ] = row;
        return [
          dateTimeOffest,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
          q_Capacity,
          posizione,
        ];
      },
      () => {
        const [
          dateTimeOffest,
          ,
          ,
          q_Capacity,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
        ] = row;
        return [
          dateTimeOffest,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
          q_Capacity,
        ];
      }
    )
  );
}

function getDataObj(
  excelInstance: any,
  selectedMercati: O.Option<MercatiBidding>
) {
  return excelInstance.current.getData().map((row: any) =>
    ifMI(
      selectedMercati,
      () => {
        const [
          dateTimeOffest,
          ,
          ,
          q_Capacity,
          posizione,
          ,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
        ] = row;
        return {
          dateTimeOffest,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
          q_Capacity,
          posizione,
        };
      },
      () => {
        const [
          dateTimeOffest,
          ,
          ,
          q_Capacity,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
        ] = row;
        return {
          dateTimeOffest,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
          q_Capacity,
        };
      }
    )
  );
}

function canEditHour(market: MarketsBiddableFrom, hour: string) {
  return market.biddableFrom === "00" ? true : hour >= market.biddableFrom;
}

function checkCapacityMarketSatisfiedRowList(tableData: any, state: State) {
  return tableData.map((row: any) =>
    checkCapacityMarketSatisfiedRow(row, state)
  );
}
const stringChecker = (a: any) => (R.isEmpty(a) ? 0 : a);

//this logic is duplicated
function checkCapacityMarketSatisfiedRow(row: any, state: State) {
  const q_GR1 = stringChecker(R.pathOr(0, ["q_GR1"], row));
  const q_GR2 = stringChecker(R.pathOr(0, ["q_GR2"], row));
  const q_GR3 = stringChecker(R.pathOr(0, ["q_GR3"], row));
  const q_GR4 = stringChecker(R.pathOr(0, ["q_GR4"], row));
  const p_GR1 = stringChecker(R.pathOr(0, ["p_GR1"], row));
  const p_GR2 = stringChecker(R.pathOr(0, ["p_GR2"], row));
  const p_GR3 = stringChecker(R.pathOr(0, ["p_GR3"], row));
  const p_GR4 = stringChecker(R.pathOr(0, ["p_GR4"], row));
  const posizione = stringChecker(R.pathOr(0, ["posizione"], row));
  const q_Capacity_Val = R.pathOr(null, ["q_Capacity"], row);
  const q_Capacity = q_Capacity_Val === "" ? null : q_Capacity_Val;

  if (R.isNil(q_Capacity)) return true;

  const capacityMarketArr = R.pathOr(
    null,
    ["capacityMarket", "value"],
    state
  ) as any;

  //use capacitymarket config, a SINGLE record {"type":"CapacityMarket","table":[{"id":1,"p_Ven":0.0000000000,"p_Acq":3000.0000000000}]}
  //not by pvi
  //const pvi = R.pathOr(null, ["selectedPVIs", 0, "pvi"], state);

  const capacityMarketcfg = capacityMarketArr.filter((x: any) => x.id === 1)[0];

  const selectedMercati = pipe(
    state.selectedMercati,
    O.fold(() => null, (x) => x)
  );

  if (selectedMercati === "MGP") {
    //#8695
    //const q_GR_Sum = q_GR1 + q_GR2 + q_GR3 + q_GR4;
    const q_GR_Sum = q_GR1;
    //#8432
    return !(q_GR_Sum < q_Capacity);
  }

  const q_GR1_OFF_VEN = q_GR1 > 0 ? q_GR1 : 0;
  const q_GR2_OFF_VEN = q_GR2 > 0 ? q_GR2 : 0;
  const q_GR3_OFF_VEN = q_GR3 > 0 ? q_GR3 : 0;
  const q_GR4_OFF_VEN = q_GR4 > 0 ? q_GR4 : 0;
  const q_GR1_OFF_ACQ = q_GR1 < 0 ? q_GR1 : 0;
  const q_GR2_OFF_ACQ = q_GR2 < 0 ? q_GR2 : 0;
  const q_GR3_OFF_ACQ = q_GR3 < 0 ? q_GR3 : 0;
  const q_GR4_OFF_ACQ = q_GR4 < 0 ? q_GR4 : 0;
  const p_Ven = R.pathOr(0, ["p_Ven"], capacityMarketcfg);
  const p_Acq = R.pathOr(0, ["p_Acq"], capacityMarketcfg);
  const tot_Quantity =
    posizione +
    (p_GR1 <= p_Ven ? q_GR1_OFF_VEN : 0) +
    (p_GR2 <= p_Ven ? q_GR2_OFF_VEN : 0) +
    (p_GR3 <= p_Ven ? q_GR3_OFF_VEN : 0) +
    (p_GR4 <= p_Ven ? q_GR4_OFF_VEN : 0) +
    (p_GR1 >= p_Acq ? q_GR1_OFF_ACQ : 0) +
    (p_GR2 >= p_Acq ? q_GR2_OFF_ACQ : 0) +
    (p_GR3 >= p_Acq ? q_GR3_OFF_ACQ : 0) +
    (p_GR4 >= p_Acq ? q_GR4_OFF_ACQ : 0);

  //#8432
  return !(tot_Quantity < q_Capacity);
}

const getQCapacity = (date: string, state: State) => {
  const mcpi = R.pathOr([], ["marketConfigPVIInfo", "value"], state);
  const pvi = R.pathOr(null, ["selectedPVIs", 0, "pvi"], state);
  const config = mcpi.filter((x: any) => x.pvi === pvi);
  const marketConfig = R.pathOr(null, [0], config) as any;
  if (R.isNil(marketConfig) || R.isNil(date) || R.isEmpty(date)) return null;

  // Get Market Config PVI Info for the date
  // Can only return 1 or none
  var dMinus1 = subDays(new Date(date), 1);
  const overlap = marketConfig.quantityPeriods.filter((x: any) =>
    areIntervalsOverlapping(
      { start: startOfDay(new Date(x.from)), end: startOfDay(new Date(x.to)) },
      { start: dMinus1, end: dMinus1 }
    )
  );
  return R.pathOr(null, [0, "value"], overlap) as any;
};
