import React from "react";
import "./radiobutton.scss";

type RadioButtonTypes = {
  checked: boolean;
  label: string;
  name: string;
  update: (a: string) => void;
};
const RadioButton = ({
  checked = false,
  label,
  name,
  update
}: RadioButtonTypes) => {
  return (
    <div className="form-group" onClick={() => update(name)}>
      <span className="fa-stack fas-lg radioButton">
        <div>
          <div hidden={!checked} />
        </div>
      </span>
      {label}
    </div>
  );
};

export default RadioButton;
