export const Underlying_Parse = (val: any) => {
  switch (val) {
    case "Italy":
      return "Italy";
      case "ItalyNord":
        return "Italy Nord";
      case "ItalyCNOR":
        return "Italy CNOR";
      case "ItalySud":
        return "Italy Sud";
      case "ItalySici":
        return "Italy Sici";
      case "ItalyCALA":
        return "Italy CALA";
      case "ItalyPCSUD":
        return "Italy PCSUD";
      case "ItalySard":
        return "Italy Sard";
    default:
      return val;
  }
};