import React from "react";
import * as R from "ramda";
import InputTextField from "../inputTextField";

type TextInputFilterTypes = {
  propKey: string;
  filters: any;
  onChange: any;
};

const TextInputFilter = ({
  propKey,
  filters,
  onChange
}: TextInputFilterTypes) => {
  const val = R.prop(propKey, filters);
  return (
    <InputTextField
      name="value"
      value={val}
      update={(x: any) => {
        return onChange({
          ...filters,
          [propKey]: R.isEmpty(x) ? null : x
        });
      }}
    />
  );
};

export default TextInputFilter;
