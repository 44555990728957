import * as R from "ramda";
import xl from "excel4node";
import { format as tzFormat } from "date-fns-tz";
import { getPlantName, getUpName } from "../../../esiti/Esiti";

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {},
}: NumberCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .number(val)
        .style(style);
};

type DateCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
  format?: string;
};

// const dateCell = ({
//   sheet,
//   rowData,
//   rowIndex,
//   colIndex,
//   path,
//   style = {},
//   format = "dd/MM/yyyy"
// }: DateCellType) => {
//   const val = R.pathOr(null, path, rowData);
//   return R.isNil(val)
//     ? sheet
//     : sheet
//         .cell(rowIndex, colIndex)
//         .date(val)
//         .style({ ...style, numberFormat: format });
// };

const createHeaders = ({ sheet }: { sheet: any }) => {
  [
    "Impianto",
    "UP",
    "DataCue",
    "Ora",
    "MGP Q (MWh)   ",
    "MGP P  (€/MWh)",
    "MI1 Q (MWh)   ",
    "MI1 P  (€/MWh)",
    "MI2 Q (MWh)   ",
    "MI2 P  (€/MWh)",
    "MI3 Q (MWh)   ",
    "MI3 P  (€/MWh)",
    "MI4 Q (MWh)   ",
    "MI4 P  (€/MWh)",
    "MI5 Q (MWh)   ",
    "MI5 P  (€/MWh)",
    "MI6 Q (MWh)   ",
    "MI6 P  (€/MWh)",
    "MI7 Q (MWh)   ",
    "MI7 P  (€/MWh)",
    "PV",
    "RispettoCapacity ",
  ].map((header: string, col: number) => {
    return sheet
      .cell(1, R.add(1, col))
      .string(header)
      .style({
        alignment: {
          horizontal: ["center"],
        },
        font: {
          bold: true,
        },
        fill: {
          type: "pattern",
          patternType: "solid",
          bgColor: "#99CC00",
          fgColor: "#99CC00",
        },
      });
  });

  return sheet;
};

const setDatetimeCols = ({
  date,
  sheet,
  rowIndex,
}: {
  date: string;
  sheet: any;
  rowIndex: number;
}) => {
  const d = tzFormat(new Date(date as any), "yyyy-MM-dd", {
    timeZone: "Europe/Rome",
  });
  const h = tzFormat(new Date(date as any), "HH", {
    timeZone: "Europe/Rome",
  });
  sheet.cell(rowIndex, 3).string(d);
  sheet.cell(rowIndex, 4).string(h);
};

type SetRowType = { sheet: any; rowData: any; rowIndex: number; pvis: any };
const setRowData = ({ sheet, rowData, rowIndex, pvis }: SetRowType) => {
  const info = { sheet, rowData, rowIndex };

  const implanto = R.pathOr("", ["pvi"], rowData);
  const upName = R.pathOr("", ["pvi"], rowData);
  const isCapacityMarketSatisfied = R.pathOr(
    null,
    ["isCapacityMarketSatisfied"],
    rowData
  );

  sheet.cell(rowIndex, 1).string(
    getPlantName({
      plant: implanto,
      list: pvis,
    })
  );
  sheet.cell(rowIndex, 2).string(
    getUpName({
      up: upName,
      list: pvis,
    })
  );

  const dateTime = R.pathOr(null, ["dateTimeOffset"], rowData);
  if (!R.isNil(dateTime)) {
    setDatetimeCols({ date: dateTime, sheet, rowIndex });
  }

  numberCell({
    ...info,
    colIndex: 5,
    path: ["mgP_Q"],
  });
  numberCell({
    ...info,
    colIndex: 6,
    path: ["mgP_P"],
  });
  numberCell({
    ...info,
    colIndex: 7,
    path: ["mI1_Q"],
  });
  numberCell({
    ...info,
    colIndex: 8,
    path: ["mI1_P"],
  });
  numberCell({
    ...info,
    colIndex: 9,
    path: ["mI2_Q"],
  });
  numberCell({
    ...info,
    colIndex: 10,
    path: ["mI2_P"],
  });
  numberCell({
    ...info,
    colIndex: 11,
    path: ["mI3_Q"],
  });
  numberCell({
    ...info,
    colIndex: 12,
    path: ["mI3_P"],
  });
  numberCell({
    ...info,
    colIndex: 13,
    path: ["mI4_Q"],
  });
  numberCell({
    ...info,
    colIndex: 14,
    path: ["mI4_P"],
  });
  numberCell({
    ...info,
    colIndex: 15,
    path: ["mI5_Q"],
  });
  numberCell({
    ...info,
    colIndex: 16,
    path: ["mI5_P"],
  });
  numberCell({
    ...info,
    colIndex: 17,
    path: ["mI6_Q"],
  });
  numberCell({
    ...info,
    colIndex: 18,
    path: ["mI6_P"],
  });
  numberCell({
    ...info,
    colIndex: 19,
    path: ["mI7_Q"],
  });
  numberCell({
    ...info,
    colIndex: 20,
    path: ["mI7_P"],
  });
  numberCell({
    ...info,
    colIndex: 21,
    path: ["pv"],
  });

  R.isNil(isCapacityMarketSatisfied)
    ? sheet.cell(rowIndex, 22).string("")
    : sheet.cell(rowIndex, 22).bool(isCapacityMarketSatisfied);

  return sheet;
};

const createBody = ({
  sheet,
  data,
  pvis,
}: {
  sheet: any;
  data: any;
  pvis: any;
}) => {
  data.map((rowData: any, i: any) =>
    setRowData({
      sheet,
      rowData,
      rowIndex: R.add(i, 2),
      pvis,
    })
  );
  return sheet;
};

type CreateExcelType = { fileName: string; info: any };

export const createExcel = ({ fileName, info }: CreateExcelType) => {
  var wb = new xl.Workbook();
  var wsEsiti = wb.addWorksheet("Esiti");
  createHeaders({ sheet: wsEsiti });

  createBody({
    sheet: wsEsiti,
    pvis: R.pathOr([], ["selectedPVIs"], info),
    data: R.pathOr([], ["data", "value"], info),
  });

  return wb.writeToBuffer().then(function(buffer: any) {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([buffer], { type: "application/octet-stream" })
    );
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  });
};

export default createExcel;
