import * as R from "ramda";
import { defer, EMPTY, of, Observable } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";

export enum RxJsLoggingLevel {
  TRACE,
  DEBUG,
  INFO,
  ERROR,
}

let rxjsLoggingLevel = RxJsLoggingLevel.INFO;

export function setRxJsLoggingLevel(level: RxJsLoggingLevel) {
  rxjsLoggingLevel = level;
}

export const debug = (level: number, message: string) => (
  source: Observable<any>
) =>
  source.pipe(
    RxOp.tap((val) => {
      if (level >= rxjsLoggingLevel) {
        console.log(message + ": ", val);
      }
    })
  );
const keyType = ({ key, data }: { key: string; data: any }) =>
  R.pipe(
    R.prop<any, any>(key),
    R.type
  )(data);

const objDataBuilder = ({
  data,
  equator,
  join,
}: {
  data: any;
  equator: string;
  join: string;
}) =>
  R.pipe(
    R.keys,
    R.map((key: any) => `${key} ${equator} '${R.prop(key, data)}'`),
    R.join(` ${join} `)
  )(data);

const arrDataBuilder = ({
  key,
  data,
  equator,
  join,
}: {
  key: string;
  data: any;
  equator: string;
  join: string;
}) => {
  switch (R.type(data[0])) {
    case "Object":
      return R.pipe(
        R.map(({ value }: { value: string }) => {
          const val = R.equals(equator, "eq") ? `'${value}'` : value;
          return `${key} ${equator} ${val}`;
        }),
        R.values,
        R.join(` ${join} `)
      )(data);
    case "Number":
    case "String":
      return R.pipe(
        R.map((value: any) => {
          const val = R.equals(equator, "eq") ? `'${value}'` : value;
          return `${key} ${equator} ${val}`;
        }),
        R.join(` ${join} `)
      )(data);
    default:
      return "";
  }
};

const builder = ({
  key,
  data,
  equator,
  join,
}: {
  key: string;
  data: any;
  equator: string;
  join: string;
}) => {
  switch (keyType({ key, data })) {
    case "Array":
      return arrDataBuilder({ key, data: R.prop(key, data), equator, join });
    case "Object":
      return objDataBuilder({ data: R.prop(key, data), equator, join });
    case "Number":
    case "String": {
      const val = R.prop(key, data);
      const res = R.equals(equator, "eq")
        ? `'${val}'`
        : encodeURIComponent(val);
      return R.isEmpty(val) ? "" : `${key} ${equator} ${res}`;
    }
    default:
      return "";
  }
};

export const queryStringBuilder = ({ filters }: { filters: any }) =>
  R.pipe(
    R.keys,
    R.map((key: any) =>
      builder({ key, data: filters, equator: "eq", join: "or" })
    ),
    R.filter((x: any) => x),
    R.join(" and "),
    encodeURIComponent,
    R.unless(R.isEmpty, (x) => `&$filter=${x}`)
  )(filters);

export const searchBuilder = ({ filters }: { filters: any }) =>
  R.pipe(
    R.keys,
    R.map((key: any) =>
      builder({ key, data: filters, equator: "=", join: "&" })
    ),
    R.filter((x: any) => x),
    R.join("&"),
    R.unless(R.isEmpty, (x) => `&${x}`),
    R.unless(R.isEmpty, (x) => R.replace(/ /g, "", x))
  )(filters);

type GetRequestType = {
  deps: any;
  api: string;
  dispatchError: any;
  page?: number;
  pageSize?: number;
  filters?: any;
};

const getRequest = ({
  deps,
  api,
  dispatchError,
  page = 1,
  pageSize = 200,
  filters,
}: GetRequestType) => {
  const skipVal = R.multiply(R.subtract(page, 1), pageSize);
  const queryString = searchBuilder({ filters: { ...filters } });

  return of(`${api}?skip=${skipVal}&limit=${pageSize}${queryString}`).pipe(
    RxOp.switchMap((url) => defer(deps.request.get(url, t.any))),
    RxOp.map(E.fold<any, any, any>(dispatchError, (x) => x))
  );
};

export const getAllRequests = ({
  deps,
  api,
  dispatchError,
  filters,
}: GetRequestType): any => {
  return getRequest({
    deps,
    api,
    dispatchError,
    filters,
  }).pipe(
    RxOp.expand((x: any) =>
      x.isCountPartial || x.limit + x.skip < x.count
        ? getRequest({
            deps,
            filters,
            api,
            dispatchError,
            page: 1 + (x.skip + x.limit) / x.limit,
          })
        : EMPTY
    ),
    RxOp.map((x: any) => x.data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};

export const downloadXml = ({
  data,
  fileName,
  fileType,
}: {
  data: any;
  fileName: string;
  fileType: string;
}) => {
  const blob = new Blob([data], {
    type: "",
  });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, `${fileName}.${fileType}`);
  } else {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = `${fileName}.${fileType}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  return true;
};
