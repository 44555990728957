import React from "react";
import * as R from "ramda";
import { format } from "date-fns";
import DatePicker from "../../components/datePicker";

type TextInputType = {
  value: any;
  update: (a: any) => void;
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
};

const TextInput = ({
  update,
  value,
  labelName = null,
  readOnly = false,
  requiredField = false
}: TextInputType) => {
  const res = R.equals(null, value) ? null : new Date(value);
  return (
    <>
      {labelName ? (
        <label htmlFor="select">
          <b>
            {requiredField ? (
              <div className="d-flex">
                <div>{labelName}</div>
                <div className="required">&nbsp;(Required)</div>
              </div>
            ) : (
              labelName
            )}
          </b>
        </label>
      ) : null}
      <DatePicker
        date={res}
        disabled={readOnly}
        onDateChange={(d: any) =>
          update(R.equals(null, d) ? null : format(d, "yyyy-MM-dd"))
        }
      />
    </>
  );
};
export default TextInput;
