import React from "react";
import loader from "../../styles/loader.module.scss";

type LoaderTypes = {
  load: boolean;
  id?: string;
};

const Loader = ({ load, id = "loaderOverlay" }: LoaderTypes) => (
  <div className={`${loader.loadOverlay} ${load ? loader.spin : ""}`} id={id}>
    <div className={loader.loadContainer}>
      <i className={`${loader.loaderSpinner} fa fa-spinner fa-spin`} />
    </div>
  </div>
);

export default Loader;
