import { EMPTY, Observable, of, defer, merge, forkJoin, from } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as Ra from "ramda";
import {
  NetworkRequest,
  notStarted,
  success,
  inProgress,
  fail,
  toOption,
} from "../../utils/request";
import { pagination, getAllPages } from "../../utils/pagination";
import { Dependancies } from "../storeTypes";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/pipeable";
import { sequenceT } from "fp-ts/lib/Apply";
import * as A from "fp-ts/lib/Array";
import * as R from "fp-ts/lib/Record";
import * as Tuple from "fp-ts/lib/Tuple";
import {
  RagioneSociale,
  PVI,
  RagioneSocialePVI,
  ContractInfo,
  MisurePrezziBiddingPVITimeserieRecord,
  MercatiBidding,
  MarketsBiddableFrom,
  Market,
  MarketState,
  EsitoBiddingComputedPVI,
  CapacityMarket,
  MarketConfigPVIInfo,
} from "./ragioneSocialePvi/types";
import { combineEpics, ofType } from "redux-observable";
import xl from "excel4node";
import * as t from "io-ts";
import XLSX from "xlsx";
import { format, toDate, utcToZonedTime } from "date-fns-tz";
import {
  addDays,
  areIntervalsOverlapping,
  max,
  min,
  startOfDay,
  subDays,
} from "date-fns";
import { dispatchNetworkError } from "./errorHandler";
import { constTrue, constFalse, flow } from "fp-ts/lib/function";
import { permissionCheck } from "../../helper/permissions";
import permissions from "../../permissions";
import { tap } from "rxjs/operators";

const optionZip = sequenceT(O.option);
const traverseT = A.array.traverse(T.taskEither);
export const key = "MGP";

export const refreshRagioneSociale = {
  type: "rtm/MGP/REFRESH_RAGIONE_SOCIALE",
} as const;

const refreshRagioneSocialeFail = (error: string) =>
  ({
    type: "rtm/MGP/REFRESH_RAGIONE_SOCIALE_FAILED",
    error,
  } as const);

const setRagioneSocialeOptions = (ragioneSociale: RagioneSociale[]) =>
  ({
    type: "rtm/MGP/SET_RAGIONE_SOCIALE_OPTIONS",
    ragioneSociale,
  } as const);

export const selectRagioneSociale = (ragioneSociale: RagioneSociale) =>
  ({
    type: "rtm/MGP/SELECT_RAGIONE_SOCIALE",
    ragioneSociale,
  } as const);

const fectchPVIsFail = (error: string) =>
  ({
    type: "rtm/MGP/FETCH_PVI_FAIL",
    error,
  } as const);

const setPvis = (pvis: PVI[]) =>
  ({
    type: "rtm/MGP/SET_PVIS",
    pvis,
  } as const);

export const selectPVIs = (pvis: PVI[]) =>
  ({
    type: "rtm/MGP/SELECT_PVI",
    pvis,
  } as const);

const fetchContractInfoFail = (error: any) => {
  return {
    type: "rtm/MGP/FETCH_CONTRACT_INFO_FAIL",
    error,
  } as const;
};

const setContractInfos = (contractInfos: ContractInfo[], admin: any) =>
  ({
    type: "rtm/MGP/SET_CONTRACT_INFOS",
    contractInfos,
    admin,
  } as const);

export const selectMGPDate = (start: string, end: string) =>
  ({
    type: "rtm/MGP/SELECT_MGP_DATE",
    start,
    end,
  } as const);
export const selectMIDate = (date: string) =>
  ({
    type: "rtm/MGP/SELECT_MI_DATE",
    date,
  } as const);

export const exportExcel = {
  type: "rtm/MGP/EXPORT_EXCEL",
} as const;

export const uploadExcel = (file: File) =>
  ({
    type: "rtm/MGP/UPLOAD_EXCEL",
    file,
  } as const);

export const modifyOnline = {
  type: "rtm/MGP/MODIFY_ONLINE",
} as const;

export const cancelModifyOnline = {
  type: "rtm/MGP/CANCEL_MODIFY_ONLINE",
} as const;

const modifyOnlineDataFail = (error: string) =>
  ({
    type: "rtm/MGP/MODIFY_ONLINE_DATA_FAIL",
    error,
  } as const);

const setModifyOnlineData = (data: MisurePrezziBiddingPVITimeserieRecord[]) =>
  ({
    type: "rtm/MGP/SET_MODIFY_ONLINE_DATA",
    data,
  } as const);

export const uploadModifyOnline = (
  data: any[][],
  market: string,
  isRilevante: boolean,
  upsa: boolean
) =>
  ({
    type: "rtm/MGP/UPLOAD_MODIFY_ONLINE",
    data,
    market,
    isRilevante,
    upsa,
  } as const);

const fectchMercatiFail = (error: string) =>
  ({
    type: "rtm/MGP/FETCH_MERCATI_FAIL",
    error,
  } as const);

const setMercati = (market: Market) =>
  ({
    type: "rtm/MGP/SET_MERCATI",
    market,
  } as const);

export const selectMercati = (mercati: MercatiBidding) =>
  ({
    type: "rtm/MGP/SELECT_MERCATI",
    mercati,
  } as const);

const esitoComputedFail = (error: string) =>
  ({
    type: "rtm/MGP/ESITO_COMPUTED_FAIL",
    error,
  } as const);
const capacityMarketFail = (error: string) =>
  ({
    type: "rtm/MGP/CAPACITY_MARKET_FAIL",
    error,
  } as const);
const marketConfigPVIInfoFail = (error: string) =>
  ({
    type: "rtm/MGP/MARKET_CONFIG_PVI_INFO_FAIL",
    error,
  } as const);

const setEsitoComputedData = (data: EsitoBiddingComputedPVI[]) =>
  ({
    type: "rtm/MGP/SET_ESITO_COMPUTED_DATA",
    data,
  } as const);
const setCapacityMarketData = (data: CapacityMarket[]) => {
  return {
    type: "rtm/MGP/SET_CAPACITY_MARKET_DATA",
    data,
  } as const;
};
const setMarketConfigPVIInfoData = (data: MarketConfigPVIInfo[]) =>
  ({
    type: "rtm/MGP/SET_MARKET_CONFIG_PVI_INFO_DATA",
    data,
  } as const);

export const uploadSuccess = (isCapacityMarketSatisfied: boolean) =>
  ({
    type: "rtm/MGP/UPLOAD_SUCCESSFUL",
    isCapacityMarketSatisfied,
  } as const);

export const uploadClose = () =>
  ({
    type: "rtm/MGP/UPLOAD_CLOSE",
  } as const);

export const uploadFail = (error: any) =>
  ({
    type: "rtm/MGP/UPLOAD_FAIL",
    error,
  } as const);

const productionFail = (error: string) =>
  ({
    type: "rtm/MGP/PRODUCTION_FAIL",
    error,
  } as const);

const setProductionData = (data: any[]) =>
  ({
    type: "rtm/MGP/SET_PRODUCTION_DATA",
    data,
  } as const);

const esitoBiddingUPFail = (error: string) =>
  ({
    type: "rtm/MGP/ESITO_BIDDING_UP_FAIL",
    error,
  } as const);

const setEsitoBiddingUPData = (data: any[]) =>
  ({
    type: "rtm/MGP/SET_ESITO_BIDDING_UP_DATA",
    data,
  } as const);
export const clearData = {
  type: "rtm/MGP/CLEAR_DATA",
} as const;

type Action =
  | typeof clearData
  | typeof refreshRagioneSociale
  | typeof exportExcel
  | typeof modifyOnline
  | typeof cancelModifyOnline
  | ReturnType<
      | typeof selectRagioneSociale
      | typeof refreshRagioneSocialeFail
      | typeof setRagioneSocialeOptions
      | typeof setPvis
      | typeof fectchPVIsFail
      | typeof selectPVIs
      | typeof fetchContractInfoFail
      | typeof setContractInfos
      | typeof selectMGPDate
      | typeof selectMIDate
      | typeof uploadExcel
      | typeof fectchMercatiFail
      | typeof setMercati
      | typeof selectMercati
      | typeof modifyOnlineDataFail
      | typeof setModifyOnlineData
      | typeof uploadModifyOnline
      | typeof esitoComputedFail
      | typeof setEsitoComputedData
      | typeof capacityMarketFail
      | typeof marketConfigPVIInfoFail
      | typeof setCapacityMarketData
      | typeof setMarketConfigPVIInfoData
      | typeof uploadSuccess
      | typeof uploadClose
      | typeof uploadFail
      | typeof setEsitoBiddingUPData
      | typeof esitoBiddingUPFail
      | typeof setProductionData
      | typeof productionFail
    >;

type DateSelect =
  | { tag: "MI"; date: string }
  | { tag: "MGP"; min: string; start: string; end: string };

export type State = {
  ragioneSociale: NetworkRequest<string, RagioneSociale[]>;
  selectedRagioneSociale: O.Option<RagioneSociale>;
  pvis: NetworkRequest<string, PVI[]>;
  selectedPVIs: PVI[];
  market: NetworkRequest<string, Market>;
  availableMercati: O.Option<MercatiBidding[]>;
  mercatiBiddableFrom: MarketsBiddableFrom[];
  selectedMercati: O.Option<MercatiBidding>;
  contractInfos: NetworkRequest<string, ContractInfo[]>;
  dateSelect: O.Option<DateSelect>;
  modifyOnline: NetworkRequest<string, MisurePrezziBiddingPVITimeserieRecord[]>;
  esitoBiddingComputed: NetworkRequest<string, EsitoBiddingComputedPVI[]>;
  marketConfigPVIInfo: NetworkRequest<string, MarketConfigPVIInfo[]>;
  capacityMarket: NetworkRequest<string, CapacityMarket[]>;
  forecastProductionData: NetworkRequest<string, any>;
  esitoBiddingUPData: NetworkRequest<string, any>;
  uploadOngoing: NetworkRequest<string, any>;
  uploadModify: any;
  isCapacityMarketSatisfied: boolean;
};

export const initialState: State = {
  ragioneSociale: notStarted,
  selectedRagioneSociale: O.none,
  pvis: notStarted,
  selectedPVIs: [],
  market: notStarted,
  availableMercati: O.none,
  mercatiBiddableFrom: [],
  selectedMercati: O.none,
  contractInfos: notStarted,
  dateSelect: O.none,
  modifyOnline: notStarted,
  esitoBiddingComputed: notStarted,
  marketConfigPVIInfo: notStarted,
  capacityMarket: notStarted,
  forecastProductionData: notStarted,
  esitoBiddingUPData: notStarted,
  uploadOngoing: notStarted,
  uploadModify: false,
  isCapacityMarketSatisfied: false,
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case "rtm/MGP/CLEAR_DATA":
      return initialState;
    case "rtm/MGP/REFRESH_RAGIONE_SOCIALE":
      return { ...state, ragioneSociale: inProgress };
    case "rtm/MGP/REFRESH_RAGIONE_SOCIALE_FAILED":
      return {
        ...state,
        ragioneSociale: fail(action.error),
      };
    case "rtm/MGP/SET_RAGIONE_SOCIALE_OPTIONS":
      return {
        ...state,
        ragioneSociale: success(action.ragioneSociale),
        selectedRagioneSociale: pipe(
          state.selectedRagioneSociale,
          O.alt(() => A.head(action.ragioneSociale))
        ),
      };
    case "rtm/MGP/SELECT_RAGIONE_SOCIALE":
      return {
        ...state,
        selectedRagioneSociale: O.some(action.ragioneSociale),
        pvis: inProgress,
        selectedPVIs: [],
        availableMercati: O.none,
        selectedMercati: O.none,
        dateSelect: O.none,
      };
    case "rtm/MGP/FETCH_PVI_FAIL":
      return { ...state, pvis: fail(action.error) };
    case "rtm/MGP/SET_PVIS":
      return {
        ...state,
        pvis: success(action.pvis),
        selectedPVIs: action.pvis.slice(0, 1),
      };
    case "rtm/MGP/SELECT_PVI":
      return {
        ...state,
        selectedPVIs: action.pvis,
        contractInfos: inProgress,
        availableMercati: O.none,
        selectedMercati: O.none,
        dateSelect: O.none,
      };
    case "rtm/MGP/FETCH_CONTRACT_INFO_FAIL":
      return {
        ...state,
        contractInfos: fail(action.error),
      };
    case "rtm/MGP/SET_CONTRACT_INFOS":
      return {
        ...state,
        contractInfos: success(action.contractInfos),
        availableMercati: pipe(
          toOption(state.market),
          O.map((market) =>
            getAvailableMarkets(market, action.contractInfos, action.admin)
          )
        ),
      };

    case "rtm/MGP/SELECT_MGP_DATE":
      return {
        ...state,
        dateSelect: pipe(
          state.dateSelect,
          O.map((d) => ({ ...d, start: action.start, end: action.end }))
        ),
      };
    case "rtm/MGP/SELECT_MI_DATE":
      return {
        ...state,
        dateSelect: pipe(
          state.dateSelect,
          O.map((d) => ({ ...d, date: action.date }))
        ),
      };
    case "rtm/MGP/FETCH_MERCATI_FAIL":
      return { ...state, market: fail(action.error) };
    case "rtm/MGP/SET_MERCATI": {
      return {
        ...state,
        market: success(action.market),
        mercatiBiddableFrom: action.market.marketStates.map((x) => ({
          mercatiBidding: x.mercatiBidding,
          biddableFrom: x.config.biddableFrom.split(":")[0],
        })),
      };
    }
    case "rtm/MGP/SELECT_MERCATI":
      return {
        ...state,
        selectedMercati: O.some(action.mercati),
        dateSelect: pipe(
          toOption(state.market),
          O.chain((market) =>
            getMarketDate(action.mercati, market.marketStates)
          )
        ),
      };
    case "rtm/MGP/MODIFY_ONLINE":
      return {
        ...state,
        modifyOnline: inProgress,
        esitoBiddingComputed: inProgress,
      };
    case "rtm/MGP/CANCEL_MODIFY_ONLINE":
      return {
        ...state,
        modifyOnline: notStarted,
        esitoBiddingComputed: notStarted,
      };
    case "rtm/MGP/MODIFY_ONLINE_DATA_FAIL":
      return { ...state, modifyOnline: fail(action.error) };
    case "rtm/MGP/SET_MODIFY_ONLINE_DATA":
      return {
        ...state,
        modifyOnline: success(action.data),
      };
    case "rtm/MGP/ESITO_COMPUTED_FAIL":
      return {
        ...state,
        esitoBiddingComputed: fail(action.error),
      };
    case "rtm/MGP/SET_ESITO_COMPUTED_DATA":
      return {
        ...state,
        esitoBiddingComputed: success(action.data),
      };
    case "rtm/MGP/CAPACITY_MARKET_FAIL":
      return {
        ...state,
        capacityMarket: fail(action.error),
      };
    case "rtm/MGP/MARKET_CONFIG_PVI_INFO_FAIL":
      return {
        ...state,
        marketConfigPVIInfo: fail(action.error),
      };
    case "rtm/MGP/SET_CAPACITY_MARKET_DATA":
      return {
        ...state,
        capacityMarket: success(action.data),
      };
    case "rtm/MGP/SET_MARKET_CONFIG_PVI_INFO_DATA":
      return {
        ...state,
        marketConfigPVIInfo: success(action.data),
      };
    case "rtm/MGP/SET_ESITO_BIDDING_UP_DATA":
      return {
        ...state,
        esitoBiddingUPData: success(action.data),
      };

    case "rtm/MGP/ESITO_BIDDING_UP_FAIL":
      return { ...state, esitoBiddingUPData: fail(action.error) };
    case "rtm/MGP/SET_PRODUCTION_DATA":
      return {
        ...state,
        forecastProductionData: success(action.data),
      };
    case "rtm/MGP/PRODUCTION_FAIL":
      return { ...state, forecastProductionData: fail(action.error) };
    case "rtm/MGP/UPLOAD_SUCCESSFUL":
      return {
        ...state,
        uploadModify: true,
        uploadOngoing: success([]),
        isCapacityMarketSatisfied: action.isCapacityMarketSatisfied,
      };
    case "rtm/MGP/UPLOAD_FAIL":
      return {
        ...state,
        uploadModify: false,
        uploadOngoing: fail(action.error),
      };
    case "rtm/MGP/UPLOAD_CLOSE":
      return {
        ...state,
        uploadModify: false,
        isCapacityMarketSatisfied: false,
      };
    case "rtm/MGP/UPLOAD_MODIFY_ONLINE":
      return {
        ...state,
        uploadOngoing: inProgress,
      };
    case "rtm/MGP/EXPORT_EXCEL":
    case "rtm/MGP/UPLOAD_EXCEL":
      return state;
  }
}

const refreshRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    ofType("rtm/MGP/REFRESH_RAGIONE_SOCIALE"),
    RxOp.exhaustMap(() =>
      merge(
        pipe(
          getAllPages((skip = 0, limit = 999) =>
            deps.request.get(
              `core/ragionesociale?skip=${skip}&limit=${limit}&sort=RagioneSociale%20asc`,
              pagination(RagioneSociale)
            )
          ),
          T.bimap<string, Action, RagioneSociale[], Action>(
            refreshRagioneSocialeFail,
            setRagioneSocialeOptions
          ),
          defer
        ),
        pipe(
          deps.request.get(`producer/market`, Market),
          T.bimap<string, Action, Market, Action>(
            fectchMercatiFail,
            setMercati
          ),
          defer
        ),
        pipe(
          deps.request.get("core/capacitymarket", t.any),
          T.bimap<string, Action, Market, Action>(
            capacityMarketFail,
            (x: any) => {
              return setCapacityMarketData(x);
            }
          ),
          defer
        ),
        pipe(
          getAllPages((skip = 0, limit = 999) =>
            deps.request.get(
              `core/marketconfigpviinfo?skip=${skip}&limit=${limit}`,
              t.any
            )
          ),
          T.bimap<string, Action, MarketConfigPVIInfo[], Action>(
            marketConfigPVIInfoFail,
            setMarketConfigPVIInfoData
          ),
          defer
        )
      )
    ),
    RxOp.map(E.fold((x) => x, (x) => x))
  );

const setRagioneSocialeOptionsEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) => {
      return a.type === "rtm/MGP/SET_RAGIONE_SOCIALE_OPTIONS"
        ? pipe(
            from(a.ragioneSociale),
            RxOp.take(1)
          )
        : EMPTY;
    }),
    RxOp.exhaustMap((a) => {
      console.log("Ragione Sociale:", a); // Log the ragione sociale
      return defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${a.partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      );
    }),
    RxOp.map(
      E.map((x) => {
        console.log("Response:", x); // Log the response
        return x.map((x) => x.pvi);
      })
    ),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const selectRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/MGP/SELECT_RAGIONE_SOCIALE" ? of(a) : EMPTY
    ),
    RxOp.exhaustMap((a) =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${a.ragioneSociale.partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      )
    ),
    RxOp.map(E.map((x) => x.map((x) => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const selectPVIsEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((x) =>
      x.type === "rtm/MGP/SET_PVIS" || x.type === "rtm/MGP/SELECT_PVI"
        ? of(x)
        : EMPTY
    ),
    RxOp.switchMapTo(
      pipe(
        state$,
        RxOp.first(),
        RxOp.map(Selectors.all),
        RxOp.map((x) => [x.selectedRagioneSociale, x.selectedPVIs] as const)
      )
    ),
    RxOp.mergeMap(([ragione, pvis]) =>
      pipe(
        ragione,
        O.fold(() => EMPTY, (ragione) => of([ragione, pvis] as const))
      )
    ),
    RxOp.exhaustMap(([ragione, pvis]) =>
      defer(
        traverseT(pvis, (pvi) =>
          deps.request.get(
            `core/ragionesocialepvi/${ragione.partitaIva}/${pvi.pvi}/activeContract`,
            ContractInfo
          )
        )
      )
    ),
    RxOp.map(E.fold<any, any, any>((x: any) => x, (x: any) => x)),
    RxOp.mergeMap((res: any) => {
      return Ra.hasPath(["status"], res)
        ? of(
            fetchContractInfoFail(
              "Non risultano contratti attivi per la controparte selezionata"
            )
          )
        : state$.pipe(
            RxOp.first(),
            RxOp.map((state) => ({
              admin: permissionCheck({
                state,
                permission: permissions.misurePrezziBiddingPVIWriteAdmin,
              }),
              res,
            })),
            RxOp.map(({ admin, res }: any) => setContractInfos(res, admin))
          );
    })
  );

const getUserSelections = (
  state$: Observable<any>
): Observable<
  O.Option<
    [
      RagioneSociale,
      PVI[],
      DateSelect,
      MercatiBidding,
      ContractInfo[],
      MarketsBiddableFrom[],
      CapacityMarket[],
      MarketConfigPVIInfo[]
    ]
  >
> =>
  pipe(
    state$,
    RxOp.first(),
    RxOp.map(Selectors.all),
    RxOp.map((s) =>
      optionZip(
        s.selectedRagioneSociale,
        O.some(s.selectedPVIs),
        s.dateSelect,
        s.selectedMercati,
        toOption(s.contractInfos),
        O.some(s.mercatiBiddableFrom),
        toOption(s.capacityMarket),
        toOption(s.marketConfigPVIInfo)
      )
    )
  );

const getMisureBiddingTimeserie = (
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    getUserSelections(state$),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([ragione, pvis, date, mercati, contractInfos, mercatiBiddableFrom]) =>
          defer(
            getAllPages((skip = 0, limit = 999) =>
              deps.request.get(
                `producer/misurePrezziBiddingPVI/timeserie?skip=${skip}&limit=${limit}&partitaIva=${
                  ragione.partitaIva
                }&${pvis.map((p) => `pvi=${p.pvi}`).join("&")}&${
                  date.tag === "MI"
                    ? `from=${pipe(
                        toDate(date.date),
                        (d) =>
                          format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                            timeZone: "Europe/Rome",
                          }),
                        encodeURIComponent
                      )}&to=${pipe(
                        toDate(date.date),
                        (d) => addDays(d, 1),
                        (d) =>
                          format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                            timeZone: "Europe/Rome",
                          }),
                        encodeURIComponent
                      )}`
                    : `from=${pipe(
                        toDate(date.start),
                        (d) =>
                          format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                            timeZone: "Europe/Rome",
                          }),
                        encodeURIComponent
                      )}&to=${pipe(
                        toDate(date.end),
                        (d) => addDays(d, 1),
                        (d) =>
                          format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                            timeZone: "Europe/Rome",
                          }),
                        encodeURIComponent
                      )}`
                }&mercatiBidding=${mercati}`,
                pagination(MisurePrezziBiddingPVITimeserieRecord)
              )
            )
          ).pipe(
            RxOp.map(
              E.map(
                (data) =>
                  [ragione, data, contractInfos, mercatiBiddableFrom] as const
              )
            )
          )
      )
    )
  );

const getEsitoComputedBidding = (state$: Observable<any>, deps: Dependancies) =>
  pipe(
    getUserSelections(state$),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([_ragione, pvis, date, _mercati, contractInfos]) => {
          const pviList = pvis.map((p) => p.pvi);
          const start = date.tag === "MI" ? date.date : date.start;
          const end = date.tag === "MI" ? date.date : date.end;

          return requestEsitoComputedBidding(deps, pviList, start, end);
        }
      )
    )
  );

const esitoDateFmt = (date: Date) =>
  format(date, "yyyy-MM-dd'T'HH:mm:SSXXX", {
    timeZone: "Europe/Rome",
  });

const requestEsitoComputedBidding = (
  deps: Dependancies,
  pvis: string[],
  startDate: string,
  endDate: string
) => {
  const start = encodeURIComponent(esitoDateFmt(toDate(startDate)));
  const end = encodeURIComponent(esitoDateFmt(addDays(toDate(endDate), 1)));
  const pvsStr = pvis.map((p) => `pvi=${p}`).join("&");
  return of([]).pipe(
    RxOp.exhaustMap((a) =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `bidding/esitoBiddingComputedPVI?skip=${skip}&limit=${limit}&${pvsStr}&start=${start}&end=${end}`,
            pagination(t.any)
          )
        )
      )
    )
  );
};

const getProductionTimeserie = (state$: Observable<any>, deps: Dependancies) =>
  pipe(
    getUserSelections(state$),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([_ragione, pvis, date, _mercati, contractInfos]) =>
          pipe(
            getUserSelections(state$),
            RxOp.mergeMap(
              O.fold(
                () => defer(T.left("Missing user selections")),
                ([_ragione, pvis, date, _mercati, contractInfos]) =>
                  defer(
                    deps.request.get(
                      `producer/production/timeserie/${_ragione.partitaIva}/${
                        date.tag === "MI"
                          ? `${pipe(
                              toDate(date.date),
                              (d) =>
                                format(d, "yyyy-MM-dd", {
                                  timeZone: "Europe/Rome",
                                }),
                              encodeURIComponent
                            )}/${pipe(
                              toDate(date.date),
                              (d) => addDays(d, 1),
                              (d) =>
                                format(d, "yyyy-MM-dd", {
                                  timeZone: "Europe/Rome",
                                }),
                              encodeURIComponent
                            )}`
                          : `${pipe(
                              toDate(date.start),
                              (d) =>
                                format(d, "yyyy-MM-dd", {
                                  timeZone: "Europe/Rome",
                                }),
                              encodeURIComponent
                            )}/${pipe(
                              toDate(date.end),
                              (d) => addDays(d, 1),
                              (d) =>
                                format(d, "yyyy-MM-dd", {
                                  timeZone: "Europe/Rome",
                                }),
                              encodeURIComponent
                            )}`
                      }?${pvis.map((p) => `pvi=${p.pvi}`)}`,
                      t.any
                    )
                  )
              )
            )
          )
      )
    )
  );

const getEsitoBiddingUP = (state$: Observable<any>, deps: Dependancies) =>
  pipe(
    getUserSelections(state$),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([_ragione, pvis, date, _mercati, contractInfos]) =>
          defer(
            deps.request.get(
              `bidding/esitoBiddingUP?skip=0&limit=500&${pvis.map(
                (p) => `codiceUp=${p.codiceUp}`
              )}${
                date.tag === "MI"
                  ? `&start=${pipe(
                      toDate(date.date),
                      (d) =>
                        format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                          timeZone: "Europe/Rome",
                        }),
                      encodeURIComponent
                    )}&end=${pipe(
                      toDate(date.date),
                      (d) => addDays(d, 1),
                      (d) =>
                        format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                          timeZone: "Europe/Rome",
                        }),
                      encodeURIComponent
                    )}`
                  : `&start=${pipe(
                      toDate(date.start),
                      (d) =>
                        format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                          timeZone: "Europe/Rome",
                        }),
                      encodeURIComponent
                    )}&end=${pipe(
                      toDate(date.end),
                      (d) => addDays(d, 1),
                      (d) =>
                        format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
                          timeZone: "Europe/Rome",
                        }),
                      encodeURIComponent
                    )}`
              }`,
              t.any
            )
          )
      )
    )
  );

const exportExcelEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    action$,
    ofType("rtm/MGP/EXPORT_EXCEL"),
    RxOp.mergeMapTo(getMisureBiddingTimeserie(state$, deps)),
    RxOp.tap(
      E.fold(
        console.log,
        ([ragione, data, contractInfos, mercatiBiddableFrom]) => {
          var wb = new xl.Workbook();
          const gradini =
            contractInfos[0].fornitura[0].caricamentoMIGradini ||
            contractInfos[0].fornitura[0].caricamentoMGPGradini ||
            null;

          // Add Worksheets to the workbook
          var ws = wb.addWorksheet(ragione.ragioneSociale);
          const market = Ra.path<MercatiBidding>([0, "mercatiBidding"])(data);

          const list = pipe(
            ifMI(
              pipe(
                A.head(data),
                O.map((d) => d.mercatiBidding)
              ),
              () => miHeaders,
              () => mgpHeaders
            ),
            gradini ? (x) => x : A.takeLeft(5)
          );
          list.forEach((cell, j) => ws.cell(1, j + 1).string(cell));

          filterMarketData({ data, market, mercatiBiddableFrom }).forEach(
            (d: MisurePrezziBiddingPVITimeserieRecord, i: number) => {
              const row = i + 2;
              ws.cell(row, 1).string(d.pvi);
              ws.cell(row, 2).string(d.mercatiBidding);
              ws.cell(row, 3).string(d.dateTimeOffset);
              numberCell({ ws, row, col: 4, val: d.q_GR1 });
              const mgp = market === "MGP";
              const isRilevante = hasCodiceRilevante(contractInfos);
              if (isRilevante) {
                numberCell({ ws, row, col: 5, val: d.p_GR1 });
              }
              if (mgp || !isRilevante) {
                ws.cell(row, 5).style({
                  fill: {
                    type: "pattern",
                    patternType: "solid",
                    bgColor: "#a9a9a9",
                    fgColor: "#a9a9a9",
                  },
                });
              }
              if (!isRilevante) {
                ws.addDataValidation({
                  type: "textLength",
                  error: "La colonna P_GR1 non puo' essere modificata",
                  operator: "equal",
                  sqref: "E2:E1000",
                  formulas: [""],
                });
              }

              const offset = isRilevante ? 1 : 0;
              if (gradini) {
                numberCell({ ws, row, col: offset + 5, val: d.q_GR2 });
                numberCell({ ws, row, col: offset + 6, val: d.p_GR2 });
                numberCell({ ws, row, col: offset + 7, val: d.q_GR3 });
                numberCell({ ws, row, col: offset + 8, val: d.p_GR3 });
                numberCell({ ws, row, col: offset + 9, val: d.q_GR4 });
                numberCell({ ws, row, col: offset + 10, val: d.p_GR4 });
              }
            }
          );

          wb.writeToBuffer().then(function(buffer: any) {
            // Do something with buffer
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
              new Blob([buffer], { type: "application/octet-stream" })
            );
            a.download = "MisurePrezziBiddingPVI_Upload.xlsx";

            // Append anchor to body.
            document.body.appendChild(a);
            a.click();

            // Remove anchor from body
            document.body.removeChild(a);
          });
        }
      )
    ),
    RxOp.mergeMapTo(EMPTY)
  );

type NumberCellType = {
  ws: any;
  row: number;
  col: number;
  val: any;
  style?: any;
};
const numberCell = ({ ws, row, col, val, style = {} }: NumberCellType) => {
  if (Ra.equals("Number", Ra.type(val))) {
    return ws
      .cell(row, col)
      .number(val)
      .style(style);
  }
  return null;
};

const filterMarketData = ({
  data,
  market,
  mercatiBiddableFrom,
}: {
  data: any;
  market: MercatiBidding | undefined;
  mercatiBiddableFrom: MarketsBiddableFrom[];
}) => {
  const mercatiBiddableFromList = mercatiBiddableFrom.filter(
    (x) => x.mercatiBidding === market
  );

  const marketBidFromHour = Ra.pathOr(
    "00",
    [0, "biddableFrom"],
    mercatiBiddableFromList
  );

  return Ra.pipe<any, any, any, any, any>(
    Ra.map((row: any) =>
      Ra.assoc("hour", row.dateTimeOffset.split("T")[1].split(":")[0], row)
    ),
    Ra.filter((row: any) => row.hour >= marketBidFromHour),
    R.map(Ra.dissoc("hour")),
    Ra.values
  )(data || []);
};

const uploadExcelEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap((a) => (a.type === "rtm/MGP/UPLOAD_EXCEL" ? of(a) : EMPTY)),
    RxOp.mergeMap((a) =>
      pipe(
        state$,
        RxOp.first(),
        RxOp.map(Selectors.all),
        RxOp.mergeMap((s) =>
          pipe(
            optionZip(
              s.selectedRagioneSociale,
              O.some(s.selectedPVIs),
              s.selectedMercati,
              s.dateSelect,
              O.some(a.file),
              O.some(s.mercatiBiddableFrom),
              toOption(s.capacityMarket),
              toOption(s.marketConfigPVIInfo)
            ),
            O.fold(() => EMPTY, (x) => of(x))
          )
        )
      )
    ),
    RxOp.mergeMap(
      ([
        ragione,
        pvis,
        mercati,
        date,
        file,
        mercatiBiddableFrom,
        capacityMarket,
        marketConfigPVIInfo,
      ]) => {
        return ((file as any).arrayBuffer() as Promise<any>).then(
          (fileB: ArrayBuffer) =>
            [
              ragione,
              pvis,
              mercati,
              date,
              fileB,
              mercatiBiddableFrom,
              capacityMarket,
              marketConfigPVIInfo,
            ] as const
        );
      }
    ),
    RxOp.mergeMap(
      ([
        ragione,
        pvis,
        mercati,
        date,
        file,
        mercatiBiddableFrom,
        capacityMarket,
        marketConfigPVIInfo,
      ]) =>
        pipe(
          new Uint8Array(file),
          (x) => XLSX.read(x, { type: "array" }),
          (x) => XLSX.utils.sheet_to_json(x.Sheets[x.SheetNames[0]]),
          <A>(x: A): [A, A] => [x, x],
          Tuple.map(
            flow(
              (x) => A.head(x) as O.Option<{}>,
              O.map(Object.keys),
              O.chain((headers) => getRowParser(ragione.partitaIva, headers)),
              E.fromOption(() => "File headers don't match MGP or MI")
            )
          ),
          ([parser, rows]) =>
            E.either.map(parser, (parser) => rows.map(parser)),
          E.chain(
            flow(
              t.array(MisurePrezziBiddingPVITimeserieRecord).decode,
              E.mapLeft(() => "Parser Failed")
            )
          ),
          E.map((as) =>
            R.fromFoldableMap(
              A.getMonoid<MisurePrezziBiddingPVITimeserieRecord>(),
              A.array
            )(as, (a) => [a.pvi + "~" + a.mercatiBidding, [a]])
          ),
          E.map(
            R.collect((k, v) => {
              const pvi = k.split("~")[0] as string;
              const mercatiBidding = k.split("~")[1] as MercatiBidding;
              const records = filterMarketData({
                data: v,
                market: k.split("~")[1] as any,
                mercatiBiddableFrom,
              });

              const dateList = records.map((r: any) =>
                toDate(r.dateTimeOffset)
              );
              const from = format(min(dateList), "yyyy-MM-dd");
              const to = format(addDays(max(dateList), 1), "yyyy-MM-dd");
              return {
                partitaIVA: ragione.partitaIva,
                pvi,
                mercatiBidding,
                from,
                to,
                records,
                marketConfigPVIInfo,
                capacityMarket,
              };
            })
          ),
          E.fold((e) => of(E.left(e)), (x) => of(E.right(x)))
        )
    ),
    RxOp.map(E.fold<any, any, Action>((x: any) => x, (x: any) => x)),
    RxOp.mergeMap((arr: any) => {
      return Ra.type(arr) === "String"
        ? of({
            result: [arr],
            isCapacityMarketSatisfied: false,
          })
        : of(arr).pipe(
            RxOp.flatMap((x: any) => x),
            /////////////////////
            RxOp.flatMap((body: any) => {
              return defer(
                deps.request.post(
                  `producer/misurePrezziBiddingPVI/upload`,
                  Ra.omit(["capacityMarket", "marketConfigPVIInfo"], body),
                  t.any
                )
              ).pipe(
                RxOp.map(E.fold<any, any, any>((x) => x, (x) => x)),
                RxOp.map((result: any) => ({ result, data: body }))
              );
            }),
            /////////////////////
            RxOp.mergeMap(({ result, data }: any) => {
              return requestEsitoComputedBidding(
                deps,
                [data.pvi],
                data.from,
                data.to
              ).pipe(
                RxOp.map(E.fold<any, any, any>((x) => x, (x) => x)),
                RxOp.map((esitoComBid: any) => {
                  const records = data.records.map((row: any) => {
                    return {
                      ...row,
                      posizione: getPosizione(
                        esitoComBid,
                        row.dateTimeOffset,
                        data.mercatiBidding
                      ),
                    };
                  });
                  const isCapacityMarketSatisfiedArr = checkCapacityMarketSatisfiedRowList(
                    records,
                    data.mercatiBidding,
                    data.pvi,
                    data.marketConfigPVIInfo,
                    data.capacityMarket
                  );

                  const isCapacityMarketSatisfied = Ra.any(
                    (x) => x === false,
                    isCapacityMarketSatisfiedArr
                  );
                  return {
                    result,
                    data: {
                      ...data,
                      records,
                      isCapacityMarketSatisfied: isCapacityMarketSatisfied,
                    },
                  };
                })
              );
            }),
            RxOp.toArray()
          );
    }),
    RxOp.mergeMap((x: any) => {
      const anyFail = x.filter(
        (x: any) => Ra.isEmpty(x.result.id) || Ra.isNil(x.result.id)
      );
      if (R.isEmpty(anyFail)) {
        const cap = x.map((row: any) => row.data.isCapacityMarketSatisfied);
        return of(E.right(Ra.any(Ra.equals(false), cap)));
      }
      return of(E.left(anyFail[0].result));
    }),
    RxOp.map(E.fold<any, any, Action>(uploadFail, uploadSuccess))
  );

const getPosizione = (data: any, date: any, market: any) => {
  return Ra.pipe<any, any, any>(
    Ra.filter(Ra.propEq("dateTimeOffset", date)),
    (d) => setPosizione({ market, data: d[0] || {} })
  )(data);
};

const posizioneCalc = ({ list, data }: { list: string[]; data: any }) => {
  const x = Ra.pipe(
    Ra.pick(list),
    Ra.values
  )(data);
  return Ra.isEmpty(data) ? null : x.reduce((a: any, b: any) => a + b, 0);
};

const setPosizione = ({
  market,
  data,
}: {
  market: string | null;
  data: any;
}) => {
  switch (market) {
    case "MI1":
      return posizioneCalc({
        list: ["mgP_Q"],
        data,
      });
    case "MI2":
      return posizioneCalc({
        list: ["mgP_Q", "mI1_Q"],
        data,
      });
    case "MI3":
      return posizioneCalc({
        list: ["mgP_Q", "mI1_Q", "mI2_Q"],
        data,
      });
    case "MI4":
      return posizioneCalc({
        list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q"],
        data,
      });
    case "MI5":
      return posizioneCalc({
        list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q"],
        data,
      });
    case "MI6":
      return posizioneCalc({
        list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q", "mI5_Q"],
        data,
      });
    case "MI7":
      return posizioneCalc({
        list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q", "mI5_Q", "mI6_Q"],
        data,
      });
    default:
      return null;
  }
};

const modifyOnlineEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    ofType("rtm/MGP/MODIFY_ONLINE"),
    RxOp.mergeMapTo(
      forkJoin(
        pipe(
          getProductionTimeserie(state$, deps),
          RxOp.map(
            E.fold(
              productionFail,
              (data) => setProductionData(data.forecast.timeseries) as Action
            )
          )
        ),
        pipe(
          getEsitoBiddingUP(state$, deps),
          RxOp.map(
            E.fold(
              esitoBiddingUPFail,
              (data) => setEsitoBiddingUPData(data.data) as Action
            )
          )
        ),
        pipe(
          getMisureBiddingTimeserie(state$, deps),
          RxOp.map(
            E.fold(modifyOnlineDataFail, ([, data, contractInfos]) => {
              if (data[0].mercatiBidding === "MGP") {
                for (let i = 0; i < data.length; i++) {
                  if (data[i].p_GR1 === null) {
                    if (contractInfos[0].upsa) {
                      data[i].p_GR1 = null;
                    } else {
                      data[i].p_GR1 = 0;
                    }
                  }
                }
              }
              data.filter((x) => x.p_GR1);
              return setModifyOnlineData(data) as Action;
            })
          )
        ),
        pipe(
          getEsitoComputedBidding(state$, deps),
          RxOp.map(
            E.fold(
              esitoComputedFail,
              (data) => setEsitoComputedData(data) as Action
            )
          )
        )
      )
    ),
    RxOp.mergeMap(([prodTimeserie, esitoBiddingUP, misure, esito]) =>
      of(prodTimeserie, esitoBiddingUP, misure, esito)
    )
  );

const getVal = (val: any) => (Ra.isEmpty(val) ? null : Number(val));

const getPQVal = ({
  quan,
  price,
  grKey,
  market,
}: {
  quan: any;
  price: any;
  grKey: number;
  market: string;
}) => {
  const quantity = getVal(quan);
  const p_val = getVal(price);
  const q_val = quantity === 0 && market !== "MGP" ? null : quantity;
  if (Ra.isNil(q_val) && !Ra.isNil(p_val))
    return {
      [`q_GR${grKey}`]: null,
      [`p_GR${grKey}`]: null,
    };
  return {
    [`q_GR${grKey}`]: q_val,
    [`p_GR${grKey}`]: !Ra.isNil(q_val) && Ra.isNil(p_val) ? 0 : p_val,
  };
};

const modifyOnlineUploadEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    RxOp.mergeMap((a) =>
      a.type === "rtm/MGP/UPLOAD_MODIFY_ONLINE" ? of(a) : EMPTY
    ),
    RxOp.map((x) => {
      return x.data.map(
        ([
          date,
          q_GR1,
          p_GR1,
          q_GR2,
          p_GR2,
          q_GR3,
          p_GR3,
          q_GR4,
          p_GR4,
          q_Capacity,
          posizione,
        ]) => {
          const q_GR1_val = getVal(q_GR1);
          const price =
            !Ra.isNil(q_GR1_val) && !x.isRilevante
              ? x.upsa
                ? 4000
                : 0
              : p_GR1;
          return {
            dateTimeOffset: date,
            ...getPQVal({
              quan: q_GR1,
              price: !Ra.isNil(q_GR1_val) && !x.isRilevante ? 0 : p_GR1,
              grKey: 1,
              market: x.market,
            }),
            ...getPQVal({
              quan: q_GR2,
              price: p_GR2,
              grKey: 2,
              market: x.market,
            }),
            ...getPQVal({
              quan: q_GR3,
              price: p_GR3,
              grKey: 3,
              market: x.market,
            }),
            ...getPQVal({
              quan: q_GR4,
              price: p_GR4,
              grKey: 4,
              market: x.market,
            }),
            q_Capacity,
            posizione,
          };
        }
      );
    }),
    RxOp.mergeMap((a) =>
      pipe(
        getUserSelections(state$),
        RxOp.mergeMap(
          O.fold(
            () => EMPTY,
            ([
              ragione,
              pvis,
              date,
              market,
              contractInfos,
              mercatiBiddableFrom,
              capacityMarket,
              marketConfigPVIInfo,
            ]): any => {
              const records = filterMarketData({
                data: a,
                market,
                mercatiBiddableFrom,
              });

              const isCapacityMarketSatisfiedArr = checkCapacityMarketSatisfiedRowList(
                records,
                market,
                pvis[0].pvi,
                marketConfigPVIInfo,
                capacityMarket
              );
              const isCapacityMarketSatisfied = !Ra.any(
                (x) => x === false,
                isCapacityMarketSatisfiedArr
              );

              return of({
                partitaIVA: ragione.partitaIva,
                pvi: pvis[0].pvi,
                mercatiBidding: market,
                ...(date.tag === "MI"
                  ? {
                      from: date.date,
                      to: pipe(
                        new Date(date.date),
                        (d) => addDays(d, 1),
                        (d) => format(d, "yyyy-MM-dd")
                      ),
                    }
                  : {
                      from: date.start,
                      to: pipe(
                        new Date(date.end),
                        (d) => addDays(d, 1),
                        (d) => format(d, "yyyy-MM-dd")
                      ),
                    }),
                records,
                isCapacityMarketSatisfied,
              });
            }
          )
        )
      )
    ),
    RxOp.switchMap((body: any) => {
      return of([]).pipe(
        RxOp.flatMap(() => {
          return defer(
            deps.request.post(
              `producer/misurePrezziBiddingPVI/upload`,
              body,
              t.any
            )
          );
        }),
        RxOp.map(
          E.fold<any, any, any>(uploadFail, (x) =>
            uploadSuccess(body.isCapacityMarketSatisfied)
          )
        )
      );
    })
  );

const failureEpic = (action$: Observable<Action>) =>
  action$.pipe(
    RxOp.filter(
      (action: any) =>
        action.type === "rtm/MGP/FETCH_PVI_FAIL" ||
        action.type === "rtm/MGP/ESITO_COMPUTED_FAIL" ||
        action.type === "rtm/MGP/CAPACITY_MARKET_FAIL" ||
        action.type === "rtm/MGP/MARKET_CONFIG_PVI_INFO_FAIL" ||
        action.type === "rtm/MGP/REFRESH_RAGIONE_SOCIALE_FAILED" ||
        action.type === "rtm/MGP/FETCH_MERCATI_FAIL" ||
        action.type === "rtm/MGP/UPLOAD_FAIL" ||
        action.type === "rtm/MGP/FETCH_CONTRACT_INFO_FAIL" ||
        action.type === "rtm/MGP/MODIFY_ONLINE_DATA_FAIL"
    ),
    RxOp.map((x) => dispatchNetworkError(x.error))
  );

export const epic = combineEpics(
  refreshRagioneSocialeEpic,
  setRagioneSocialeOptionsEpic,
  selectRagioneSocialeEpic,
  selectPVIsEpic,
  exportExcelEpic,
  uploadExcelEpic,
  modifyOnlineEpic,
  modifyOnlineUploadEpic,
  failureEpic
);

export const Selectors = {
  all: (s: any): State => s[key],
  modifyOnline: Ra.hasPath([key, "modifyOnline", "value"]),
};

const mgpHeaders = [
  "PVI",
  "MercatiBidding",
  "DatetimeOffset",
  "Q_GR1 [MWh]",
  "P_GR1 [€/MWh]",
  "Q_GR2 [MWh]",
  "P_GR2 [€/MWh]",
  "Q_GR3 [MWh]",
  "P_GR3 [€/MWh]",
  "Q_GR4 [MWh]",
  "P_GR4 [€/MWh]",
];
const miHeaders = [
  "PVI",
  "MercatiBidding",
  "DatetimeOffset",
  "Q_GR1 [MWh]",
  "P_GR1 [€/MWh]",
  "Q_GR2 [MWh]",
  "P_GR2 [€/MWh]",
  "Q_GR3 [MWh]",
  "P_GR3 [€/MWh]",
  "Q_GR4 [MWh]",
  "P_GR4 [€/MWh]",
];

function parseNumber(a: string) {
  return a ? Number(a) : 0;
}

function getMarketDate(
  mercati: MercatiBidding,
  available: MarketState[]
): O.Option<DateSelect> {
  return pipe(
    available,
    A.findFirst((x) => x.mercatiBidding === mercati),
    O.chain((x) =>
      mercati === "MGP"
        ? O.some<DateSelect>({
            tag: "MGP",
            min: pipe(
              utcToZonedTime(x.validDataStart, "Europe/Rome"),
              (d) => format(d, "yyyy-MM-dd")
            ),
            start: pipe(
              utcToZonedTime(x.validDataStart, "Europe/Rome"),
              (d) => format(d, "yyyy-MM-dd")
            ),
            end: pipe(
              utcToZonedTime(x.validDataStart, "Europe/Rome"),
              (d) => format(d, "yyyy-MM-dd")
            ),
          })
        : pipe(
            O.fromNullable(x.validDataStart),
            O.map((end) => ({ tag: "MI", date: end.split("T")[0] }))
          )
    )
  );
}

function getAvailableMarkets(
  marketState: Market,
  contracts: ContractInfo[],
  admin: any
): MercatiBidding[] {
  return admin
    ? pipe(
        marketState.marketStates,
        A.map((x) => x.mercatiBidding)
      )
    : pipe(
        marketState.marketStates,
        A.filter((x) => x.isOpen),
        A.filter((x) => {
          const f = contracts[0]
            ? contracts[0].fornitura[0]
            : {
                caricamentoMGP: false,
                caricamentoMI: false,
                caricamentoMGPGradini: false,
                caricamentoMIGradini: false,
              };

          if (x.mercatiBidding === "MGP") {
            return f.caricamentoMGP || f.caricamentoMGPGradini;
          }

          return f.caricamentoMI || f.caricamentoMIGradini;
        }),
        A.filter((x) =>
          x.config.isIntraDay ? miFilter(contracts) : mgpFilter(contracts)
        ),
        A.map((x) => x.mercatiBidding)
      );
}

const mgpFilter = (contracts: ContractInfo[]) =>
  pipe(
    contracts,
    A.chain((c) => c.fornitura || null),
    A.findFirst((x) => x.caricamentoMGP || x.caricamentoMGPGradini),
    O.fold(constFalse, constTrue)
  );
const miFilter = (contracts: ContractInfo[]) =>
  pipe(
    contracts,
    A.chain((c) => c.fornitura || null),
    A.findFirst((x) => x.caricamentoMI || x.caricamentoMIGradini),
    O.fold(constFalse, constTrue)
  );

export function ifMI<A>(
  selectedMercati: O.Option<MercatiBidding>,
  mi: () => A,
  mgp: () => A
) {
  return pipe(
    selectedMercati,
    O.chain((m) => (m !== "MGP" ? O.some(m) : O.none)),
    O.fold(mgp, mi)
  );
}

function getRowParser(partitaIva: string, headers: string[]) {
  return O.option.alt(getMGPParser(partitaIva, headers), () =>
    getMIParser(partitaIva, headers)
  );
}

function getMGPParser(partitaIva: string, headers: string[]) {
  return pipe(
    headers,
    A.map((x) => A.findFirst((mgp) => mgp === x)(mgpHeaders)),
    A.array.sequence(O.option),
    O.map(() => rowToMgp(partitaIva))
  );
}
function getMIParser(partitaIva: string, headers: string[]) {
  return pipe(
    headers,
    A.map((x) => A.findFirst((mi) => mi === x)(miHeaders)),
    A.array.sequence(O.option),
    O.map(() => rowToMi(partitaIva))
  );
}

const getRowVal = (val: any) =>
  Ra.equals(undefined, val) ? null : parseNumber(val);

const getRowPQVal = ({
  row,
  headers,
  headerKey1,
  headerKey2,
  grKey,
}: {
  row: any;
  headers: any;
  headerKey1: number;
  headerKey2: number;
  grKey: number;
}) => {
  const q_val = getRowVal(row[headers[headerKey1]]);
  const p_val = getRowVal(row[headers[headerKey2]]);
  return {
    [`q_GR${grKey}`]: q_val,
    [`p_GR${grKey}`]: !Ra.isNil(q_val) && Ra.isNil(p_val) ? 0 : p_val,
  };
};

function rowToMgp(partitaIva: string) {
  return (row: any) => {
    const q_GR1_val = getRowVal(row[mgpHeaders[3]]);
    const mercatiBidding = row[mgpHeaders[1]];
    const val = getRowPQVal({
      row,
      headers: miHeaders,
      headerKey1: 3,
      headerKey2: 4,
      grKey: 1,
    });
    const mgpGR1 =
      mercatiBidding === "MGP"
        ? Ra.isNil(q_GR1_val)
          ? { q_GR1: q_GR1_val, p_GR1: null }
          : val
        : val;
    return {
      partitaIVA: partitaIva,
      pvi: row[mgpHeaders[0]],
      mercatiBidding: row[mgpHeaders[1]],
      dateTimeOffset: row[mgpHeaders[2]],
      ...mgpGR1,
      ...getRowPQVal({
        row,
        headers: mgpHeaders,
        headerKey1: 5,
        headerKey2: 6,
        grKey: 2,
      }),
      ...getRowPQVal({
        row,
        headers: mgpHeaders,
        headerKey1: 7,
        headerKey2: 8,
        grKey: 3,
      }),
      ...getRowPQVal({
        row,
        headers: mgpHeaders,
        headerKey1: 9,
        headerKey2: 10,
        grKey: 4,
      }),
    };
  };
}
function rowToMi(partitaIva: string) {
  return (row: any) => {
    return {
      partitaIVA: partitaIva,
      pvi: row[miHeaders[0]],
      mercatiBidding: row[miHeaders[1]],
      dateTimeOffset: row[miHeaders[2]],
      ...getRowPQVal({
        row,
        headers: miHeaders,
        headerKey1: 3,
        headerKey2: 4,
        grKey: 1,
      }),
      ...getRowPQVal({
        row,
        headers: miHeaders,
        headerKey1: 5,
        headerKey2: 6,
        grKey: 2,
      }),
      ...getRowPQVal({
        row,
        headers: miHeaders,
        headerKey1: 7,
        headerKey2: 8,
        grKey: 3,
      }),
      ...getRowPQVal({
        row,
        headers: miHeaders,
        headerKey1: 9,
        headerKey2: 10,
        grKey: 4,
      }),
    };
  };
}

export function hasCodiceRilevante(contractInfos: ContractInfo[]): boolean {
  return pipe(
    A.head(contractInfos),
    O.chain((x) => O.fromNullable(x._Refs.pvi.codiceUpRilevante)),
    O.fold(constFalse, constTrue)
  );
}

function checkCapacityMarketSatisfiedRowList(
  row: any[],
  selectedMercati: MercatiBidding,
  pvi: string,
  config: MarketConfigPVIInfo[],
  capacityMarket: CapacityMarket[]
) {
  return row.map((row: any) =>
    checkCapacityMarketSatisfiedRow(
      row,
      selectedMercati,
      pvi,
      config,
      capacityMarket
    )
  );
}

const stringChecker = (a: any) => (R.isEmpty(a) ? 0 : a);
//this logic is duplicated
function checkCapacityMarketSatisfiedRow(
  row: any,
  selectedMercati: MercatiBidding,
  pvi: string,
  config: MarketConfigPVIInfo[],
  capacityMarket: CapacityMarket[]
) {
  const q_GR1 = Ra.pathOr(0, ["q_GR1"], row);
  const q_GR2 = Ra.pathOr(0, ["q_GR2"], row);
  const q_GR3 = Ra.pathOr(0, ["q_GR3"], row);
  const q_GR4 = Ra.pathOr(0, ["q_GR4"], row);
  const p_GR1 = Ra.pathOr(0, ["p_GR1"], row);
  const p_GR2 = Ra.pathOr(0, ["p_GR2"], row);
  const p_GR3 = Ra.pathOr(0, ["p_GR3"], row);
  const p_GR4 = Ra.pathOr(0, ["p_GR4"], row);
  const posizione = Ra.pathOr(0, ["posizione"], row);
  const dateTimeOffest = stringChecker(Ra.pathOr("", ["dateTimeOffset"], row));
  const q_Capacity = getQCapacity(dateTimeOffest, pvi, config);

  if (Ra.isNil(q_Capacity)) return true;

  //use capacitymarket config, a SINGLE record {"type":"CapacityMarket","table":[{"id":1,"p_Ven":0.0000000000,"p_Acq":3000.0000000000}]}
  //not by pvi
  //const pvi = R.pathOr(null, ["selectedPVIs", 0, "pvi"], state);
  //#8432
  const capacityMarketcfg = capacityMarket.filter((x: any) => x.id === 1)[0];

  if (selectedMercati === "MGP") {
    //#8695
    //const q_GR_Sum = q_GR1 + q_GR2 + q_GR3 + q_GR4;
    const q_GR_Sum = q_GR1;
    //following specification negating the result
    //#8432
    return !(q_GR_Sum < q_Capacity);
  }

  const q_GR1_OFF_VEN = q_GR1 > 0 ? q_GR1 : 0;
  const q_GR2_OFF_VEN = q_GR2 > 0 ? q_GR2 : 0;
  const q_GR3_OFF_VEN = q_GR3 > 0 ? q_GR3 : 0;
  const q_GR4_OFF_VEN = q_GR4 > 0 ? q_GR4 : 0;
  const q_GR1_OFF_ACQ = q_GR1 < 0 ? q_GR1 : 0;
  const q_GR2_OFF_ACQ = q_GR2 < 0 ? q_GR2 : 0;
  const q_GR3_OFF_ACQ = q_GR3 < 0 ? q_GR3 : 0;
  const q_GR4_OFF_ACQ = q_GR4 < 0 ? q_GR4 : 0;
  const p_Ven = Ra.pathOr(0, ["p_Ven"], capacityMarketcfg);
  const p_Acq = Ra.pathOr(0, ["p_Acq"], capacityMarketcfg);
  const tot_Quantity =
    posizione +
    (p_GR1 <= p_Ven ? q_GR1_OFF_VEN : 0) +
    (p_GR2 <= p_Ven ? q_GR2_OFF_VEN : 0) +
    (p_GR3 <= p_Ven ? q_GR3_OFF_VEN : 0) +
    (p_GR4 <= p_Ven ? q_GR4_OFF_VEN : 0) +
    (p_GR1 >= p_Acq ? q_GR1_OFF_ACQ : 0) +
    (p_GR2 >= p_Acq ? q_GR2_OFF_ACQ : 0) +
    (p_GR3 >= p_Acq ? q_GR3_OFF_ACQ : 0) +
    (p_GR4 >= p_Acq ? q_GR4_OFF_ACQ : 0);

  //following specification negating the result
  //#8432
  return !(tot_Quantity < q_Capacity);
}

const getQCapacity = (
  date: string,
  pvi: string,
  config: MarketConfigPVIInfo[]
) => {
  const configArr = config.filter((x: any) => x.pvi === pvi);
  const marketConfig = Ra.pathOr(null, [0], configArr) as any;
  if (Ra.isNil(marketConfig) || Ra.isNil(date) || Ra.isEmpty(date)) return null;

  // Get Market Config PVI Info for the date
  // Can only return 1 or none
  var dMinus1 = subDays(new Date(date), 1);
  const overlap = marketConfig.quantityPeriods.filter((x: any) =>
    areIntervalsOverlapping(
      { start: startOfDay(new Date(x.from)), end: startOfDay(new Date(x.to)) },
      { start: dMinus1, end: dMinus1 }
    )
  );
  return Ra.pathOr(null, [0, "value"], overlap) as any;
};
