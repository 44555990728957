import { format } from "date-fns";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import * as R from "ramda";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxList } from "../components/CheckBoxList/checkBoxList";
import Accordion from "../components/accordion/accordion";
import { CheckBox } from "../components/checkBox";
import DateRange from "../components/dateRange";
import DrapDropFileUpload from "../components/dragFile";
import InputTextField from "../components/inputTextField";
import Loader from "../components/loader";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";
import permissions from "../permissions";
import styles from "../productionData/production.module.scss";
import Protected from "../protectedComponent";
import {
  Selectors,
  activeContractAction,
  cancelModifyOnline,
  exportExcel,
  modifyOnline,
  selectMGPDate,
  selectPVIString,
  selectPVIs,
  selectRagioneSociale,
  setPviData,
  uploadClose,
  uploadExcel,
} from "../redux/modules/Disponibilitia";
import {
  firstLoadAction,
  getPVIAction,
  Selectors as productionSelectors,
} from "../redux/modules/productionData/production";
import { PVI, RagioneSociale } from "../redux/modules/ragioneSocialePvi/types";
import { anyInProgess, fold } from "../utils/request";
import EditDisponibilitia from "./EditDisponibilitia";
import UploadStatusComponent from "./UploadStatusComponent";

export type PVIDataType = {
  id: string;
  pvi: PVI;
  raguioneSociale: RagioneSociale;
};

export default function DisponibilitiaNew() {
  const [files, setFiles] = React.useState<any[]>([]);
  const [filter, setFilter] = useState("");
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);

  const dispatch = useDispatch();
  const mgp = useSelector(Selectors.all);
  const productionDataStore = useSelector(productionSelectors.all);
  const pviData = R.prop("data", productionDataStore.pvi);
  const ragioneSociale = productionDataStore.ragioneSociale;
  const pvi = productionDataStore.pvi;

  React.useEffect(() => {
    //dispatch(clearData);

    dispatch(firstLoadAction());
    dispatch(getPVIAction());
    dispatch(
      selectMGPDate(
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyyy-MM-dd")
      )
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (mgp.selectedPVIStrings.length === 1) {
      let selected = mgp.selectedPVIStrings[0].split("|")[1];
      let pvi: any = [];
      if (pviData.length > 0) {
        pvi = pviData.filter((pvi: any) => pvi.pvi.pvi === selected);
        console.log(pvi);
      } else {
        pvi = mgp.allPvis.filter((pvi: any) => pvi.pvi.pvi === selected);
      }
      const selectedPvi = mgp.selectedPVIs.filter(
        (pvi) => pvi.pvi === selected
      )[0];
      if (pvi.length > 0) {
        let partitaIva =
          pvi.length === 1
            ? pvi[0].ragioneSociale.partitaIva
            : selectedPvi.ragioneSociale
            ? selectedPvi.ragioneSociale.partitaIva
            : "";
        dispatch(activeContractAction(selected, partitaIva));
      }
    }
  }, [mgp.selectedPVIStrings, dispatch]);

  React.useEffect(() => {
    if (pvi.data.length > 0) {
      dispatch(setPviData(pvi.data));
    }
  }, [pvi.data]);

  const handleCheckboxClick = (a: string[]) => {
    let selection: any;
    if (pviData.length > 0) {
      selection = pviData.filter((pvi: any) => a.includes(pvi.id));
    } else {
      selection = mgp.allPvis.filter((pvi: any) => a.includes(pvi.id));
    }
    const pvis = selection.map((pvi: any) => {
      pvi.pvi.ragioneSociale = pvi.ragioneSociale;
      return pvi.pvi;
    });

    dispatch(selectPVIString(a));
    dispatch(selectPVIs(pvis));
  };

  const [ragioneSocialeList, setRagioneSocialeList] = useState([]);

  React.useEffect(() => {
    R.when(R.equals(true), () =>
      dispatch(selectRagioneSociale(ragioneSociale.data[0].partitaIva))
    )(R.isNil(mgp.selectedRagioneSociale) && !R.isEmpty(ragioneSociale.data));

    R.when(R.equals(true), () => dispatch(selectPVIs([pvi.data[0].id])))(
      R.isNil(mgp.pvis) && !R.isEmpty(pvi.data)
    );

    R.when(R.equals(true), () =>
      setRagioneSocialeList(
        R.pipe<any, any, any, any, any>(
          R.groupBy(R.path(["ragioneSociale", "partitaIva"]) as any) as any,
          R.toPairs,
          R.sortBy((x: any) =>
            R.pathOr("", ["ragioneSociale", "ragioneSociale"])(R.head(x[1]))
          ),
          R.map((x: any) => x[1])
        )(pviData)
      )
    )(!R.isEmpty(pviData));
  }, [ragioneSociale.data, pviData]);

  const checkIfDisabled = () => {
    if (mgp.activeContract && mgp.activeContract.fornitura[0]) {
      return !mgp.activeContract.fornitura[0].caricamentoDisponibilita;
    }
    return true;
  };

  return fold(mgp.modifyOnline, {
    fail: () => null,
    inProgress: () => (
      <>
        <Loader
          load={anyInProgess([
            mgp.ragioneSociale,
            mgp.pvis,
            mgp.modifyOnline,
            mgp.contractInfos,
          ])}
        />
        <div className="container">
          <div
            className="backButton"
            onClick={() => dispatch(cancelModifyOnline)}
          >
            <i className="fas fa-arrow-circle-left" />
          </div>
        </div>
      </>
    ),
    success: (data) => <EditDisponibilitia data={data}></EditDisponibilitia>,
    notStarted: () => (
      <>
        <PageOnLoad />
        <div className="container d-flex flex-column margin-bottom-18">
          <Loader
            load={anyInProgess([
              mgp.ragioneSociale,
              mgp.pvis,
              mgp.modifyOnline,
              mgp.contractInfos,
            ])}
          />
          <NotifcationModal
            open={mgp.uploadModify}
            close={() => dispatch(uploadClose())}
            title={
              mgp.bulkUploadResult === "error"
                ? "Upload Failed"
                : "Upload Successful"
            }
            message={
              <UploadStatusComponent
                data={mgp.bulkUploadResponse}
                result={mgp.bulkUploadResult}
              />
            }
            width="xxlarge"
          />
          <div className={styles.regioneSelector}>
            <div>
              <div>
                <span className="form-label">Ragione Sociale</span>
              </div>
              {ragioneSociale.data.length < 15 &&
              ragioneSociale.data.length > 0 ? (
                <div></div>
              ) : (
                <InputTextField
                  name="value"
                  value={filter}
                  placeholder="Inserire Ragione Sociale..."
                  update={setFilter}
                ></InputTextField>
              )}
              <div className={styles.selectionCheckboxes}>
                {R.length(pvi.data) < 35 && R.length(pvi.data) > 0 ? (
                  <CheckBox
                    label={"Seleziona tutti"}
                    name={"Seleziona tutti"}
                    selected={selectedAll}
                    allSeleted={selectedAll}
                    update={() => {
                      setSelectedAll(!selectedAll);
                      dispatch(
                        selectPVIs(R.map(R.path(["id"]))(
                          R.flatten(ragioneSocialeList)
                        ) as PVI[])
                      );
                    }}
                  />
                ) : (
                  <div></div>
                )}
                <CheckBox
                  label={"Mostra selezionati"}
                  name={"Mostra selezionati"}
                  selected={selectedOnly}
                  allSeleted={selectedOnly}
                  update={() => setSelectedOnly(!selectedOnly)}
                />
              </div>
            </div>

            <div className={styles.regioneSelectorContent}>
              {ragioneSocialeList.map((ragioneSocialeListPvi: any, i: any) =>
                (filter === "" ||
                  R.contains(
                    R.toLower(filter),
                    R.toLower(
                      ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale
                    )
                  )) &&
                ((!selectedOnly ||
                  R.filter(
                    (x: any) =>
                      ragioneSocialeListPvi[0].ragioneSociale.partitaIva ===
                      R.head(R.split("|", x || ""))
                  )(mgp.selectedPVIStrings || []).length > 0) &&
                  R.contains(
                    R.toLower(filter),
                    R.toLower(
                      ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale
                    )
                  )) ? (
                  <Accordion
                    key={`${ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale}-${ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale}- ${i}`}
                    title={
                      <label className="form-group text-uppercase">
                        <CheckBox
                          label={
                            ragioneSocialeListPvi[0].ragioneSociale
                              .ragioneSociale
                          }
                          name={
                            ragioneSocialeListPvi[0].ragioneSociale.partitaIva
                          }
                          selected={mgp.selectedPVIStrings}
                          partialSelected={
                            R.filter(
                              (x: any) =>
                                ragioneSocialeListPvi[0].ragioneSociale
                                  .partitaIva === R.head(R.split("|", x || ""))
                            )(mgp.selectedPVIStrings || []).length > 0 &&
                            R.filter(
                              (x: any) =>
                                ragioneSocialeListPvi[0].ragioneSociale
                                  .partitaIva === R.head(R.split("|", x || ""))
                            )(mgp.selectedPVIStrings || []).length <
                              R.map(R.path(["id"]))(ragioneSocialeListPvi)
                                .length
                          }
                          allSeleted={
                            R.filter(
                              (x: any) =>
                                ragioneSocialeListPvi[0].ragioneSociale
                                  .partitaIva === R.head(R.split("|", x || ""))
                            )(mgp.selectedPVIStrings || []).length >=
                            R.length(
                              R.map(R.path(["id"]))(ragioneSocialeListPvi)
                            )
                          }
                          update={() => {
                            checkIfUpdate(
                              ragioneSocialeListPvi,
                              mgp.selectedPVIStrings
                            )
                              ? handleCheckboxClick(R.uniq(
                                  R.difference(
                                    mgp.selectedPVIStrings || [],
                                    R.map(R.path(["id"]))(ragioneSocialeListPvi)
                                  )
                                ) as string[])
                              : handleCheckboxClick(R.uniq(
                                  R.concat(
                                    R.map(R.path(["id"]))(
                                      ragioneSocialeListPvi
                                    ),
                                    mgp.selectedPVIStrings || []
                                  )
                                ) as string[]);
                          }}
                        />
                      </label>
                    }
                    content={
                      <CheckboxList
                        filters={mgp.selectedPVIStrings}
                        ragioneSocialeListPvi={ragioneSocialeListPvi}
                        setSelectedPVI={handleCheckboxClick}
                        disponibilita={true}
                      ></CheckboxList>
                    }
                  />
                ) : (
                  <></>
                )
              )}
              {R.pipe<any, any>(
                R.filter((x: any) =>
                  R.contains(
                    R.toLower(filter),
                    R.toLower(R.path(["ragioneSociale", "ragioneSociale"])(
                      x
                    ) as string)
                  )
                )
              )(pviData).length === 0 && filter !== "" ? (
                <div className={styles.pviFilter}>
                  Nessuna Ragione Sociale disponibile con questo filtro
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="form-group text-uppercase">
            {pipe(
              mgp.dateSelect,
              O.fold(
                () => (
                  <div className="center-vertical">
                    <div>
                      <span className="form-label">Seleziona Periodo</span>

                      <DateRange
                        startDate={new Date()}
                        onStartDateChange={(value) =>
                          dispatch(
                            selectMGPDate(
                              format(value, "yyyy-MM-dd"),
                              format(new Date(), "yyyy-MM-dd")
                            )
                          )
                        }
                        endDate={new Date()}
                        onEndDateChange={(value) =>
                          dispatch(
                            selectMGPDate(
                              format(new Date(), "yyyy-MM-dd"),
                              format(value, "yyyy-MM-dd")
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                ),
                ({ start, end, min }) => (
                  <div className="center-vertical">
                    <div>
                      <span className="form-label">Seleziona Periodo</span>
                      <DateRange
                        startDate={new Date(start)}
                        onStartDateChange={(value) =>
                          dispatch(
                            selectMGPDate(format(value, "yyyy-MM-dd"), end)
                          )
                        }
                        endDate={new Date(end)}
                        onEndDateChange={(value) =>
                          dispatch(
                            selectMGPDate(start, format(value, "yyyy-MM-dd"))
                          )
                        }
                      />
                    </div>
                  </div>
                )
              )
            )}
          </div>
          <div className="spacer" />
          <div className="d-flex justify-content-center align-items-center">
            <Protected
              permission={permissions.disponibilitaWritePermissions}
              component={() => (
                <div
                  title={
                    mgp.selectedPVIStrings.length === 0
                      ? "Seleziona almeno un impianto"
                      : mgp.selectedPVIStrings.length > 1
                      ? "Seleziona un solo impianto per effettuare la modifica"
                      : ""
                  }
                >
                  <button
                    className="btn btn-danger rounded-pill mx-2"
                    onClick={() => dispatch(modifyOnline)}
                    disabled={
                      mgp.selectedPVIStrings.length !== 1 || checkIfDisabled()
                    }
                  >
                    Modifica Online
                  </button>
                  oppure
                </div>
              )}
            />
            <div
              title={
                mgp.selectedPVIStrings.length === 0
                  ? "Seleziona almeno un impianto"
                  : ""
              }
            >
              <button
                className="btn btn-danger rounded-pill mx-2"
                onClick={() =>
                  dispatch(
                    exportExcel(
                      mgp.selectedPVIs.reduce((acc: any, pvi) => {
                        const key = pvi.ragioneSociale
                          ? pvi.ragioneSociale.partitaIva
                          : pvi.pvi;
                        if (!acc[key]) {
                          acc[key] = {
                            partitaIva: pvi.ragioneSociale
                              ? pvi.ragioneSociale.partitaIva
                              : "",
                            pvis: [],
                          };
                        }
                        acc[key].pvis.push(pvi);
                        return acc;
                      }, {}).length
                    )
                  )
                }
                disabled={mgp.selectedPVIStrings.length < 1}
              >
                Esporta il file XLSX
              </button>
            </div>
          </div>
          <Protected
            permission={permissions.disponibilitaWritePermissions}
            component={() => (
              <>
                <div className="spacer" />
                <DrapDropFileUpload
                  fileList={files}
                  update={setFiles}
                  disabled={false}
                  upload={(x: any) => dispatch(uploadExcel(x[0]))}
                  validFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </>
            )}
          />
          <div className="spacer" />
          <Loader
            load={anyInProgess([
              mgp.ragioneSociale,
              mgp.pvis,
              mgp.modifyOnline,
              mgp.contractInfos,
            ])}
          />
        </div>
      </>
    ),
  });
}

function checkIfUpdate(ragioneSocialeListPvi: any, pvis: any) {
  let count = 0;
  const target = ragioneSocialeListPvi[0].ragioneSociale.partitaIva + "|";

  for (let i = 0; i < (pvis || []).length; i++) {
    if (pvis[i].indexOf(target) !== -1) {
      count++;
    }
  }
  return count >= ragioneSocialeListPvi.map((item: any) => item.id).length;
}
