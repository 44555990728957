import xl from "excel4node";
import { createPVIHeaders } from "./biddingPVI/header";
import { createPVIData } from "./biddingPVI/excelData";
import { createUPHeaders } from "./biddingUP/header";
import { createUPData } from "./biddingUP/excelData";

type CreateExcelType = { fileName: string; data: any; market: any };

export const createComputedExcel = ({
  fileName,
  data,
  market
}: CreateExcelType) => {
  var wb = new xl.Workbook();
  var wsPVI = wb.addWorksheet("Computed Bidding PVI");
  createPVIHeaders(wsPVI);
  createPVIData({ sheet: wsPVI, data: data.computedBiddingPVI, market });

  var wsUP = wb.addWorksheet("Computed Bidding UP");
  createUPHeaders(wsUP);
  createUPData({ sheet: wsUP, data: data.computedBiddingUP, market });

  return wb.writeToBuffer().then(function(buffer: any) {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([buffer], { type: "application/octet-stream" })
    );
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  });
};
