import * as R from "ramda";

const border = {
  border: {
    right: {
      style: "thick",
      color: "#000000"
    }
  }
};

type GetCodiceDataType = {
  upData: any;
  market: any;
};
const getCodiceupData = ({ upData, market }: GetCodiceDataType) =>
  R.pipe<any, any, any, any>(
    R.prop("timeSerie"),
    R.map((timeSerie: any) => {
      return {
        ...timeSerie,
        codiceUp: upData.codiceUp,
        pvi: upData.pvi,
        market
      };
    }),
    R.values
  )(upData);

type CreatePVIType = {
  sheet: any;
  data: any;
  market: any;
};
export const createPVIData = ({ sheet, data, market }: CreatePVIType) => {
  const orderedData = R.pipe(
    R.map((upData: any) => getCodiceupData({ upData, market })),
    R.values,
    R.flatten,
    R.sortBy(
      R.compose<any, any, any, any>(
        R.prop<any>("codiceUp"),
        R.prop<any>("pvi"),
        R.prop<any>("dateTimeOffset")
      )
    )
  )(data);

  orderedData.map((rowData: any, i: any) =>
    setPVIRowData({
      sheet,
      rowData,
      rowIndex: R.add(i, 3)
    })
  );
  return sheet;
};

type StringCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const stringCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: StringCellType) =>
  sheet
    .cell(rowIndex, colIndex)
    .string(R.pathOr("", path, rowData))
    .style(style);

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: NumberCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
        .cell(rowIndex, colIndex)
        .string("")
        .style(style)
    : sheet
        .cell(rowIndex, colIndex)
        .number(val)
        .style(style);
};

type SetPVIRowType = { sheet: any; rowData: any; rowIndex: number };
const setPVIRowData = ({ sheet, rowData, rowIndex }: SetPVIRowType) => {
  const info = { sheet, rowData, rowIndex };

  stringCell({ ...info, colIndex: 1, path: ["pvi"] });
  stringCell({ ...info, colIndex: 2, path: ["market"] });
  stringCell({ ...info, colIndex: 3, path: ["codiceUp"] });
  stringCell({ ...info, colIndex: 4, path: ["dateTimeOffset"], style: border });
  stringCell({
    ...info,
    colIndex: 5,
    path: ["misurePrevisionaliBiddingPVI", "provider"]
  });
  stringCell({
    ...info,
    colIndex: 6,
    path: ["misurePrevisionaliBiddingPVI", "versione"]
  });
  numberCell({
    ...info,
    colIndex: 7,
    path: ["misurePrevisionaliBiddingPVI", "q_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 8,
    path: ["misurePrevisionaliBiddingPVI", "q_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 9,
    path: ["misurePrevisionaliBiddingPVI", "q_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 10,
    path: ["misurePrevisionaliBiddingPVI", "q_GR4"]
  });
  numberCell({
    ...info,
    colIndex: 11,
    path: ["misurePrevisionaliBiddingPVI", "p_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 12,
    path: ["misurePrevisionaliBiddingPVI", "p_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 13,
    path: ["misurePrevisionaliBiddingPVI", "p_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 14,
    path: ["misurePrevisionaliBiddingPVI", "p_GR4"],
    style: border
  });
  stringCell({
    ...info,
    colIndex: 15,
    path: ["misurePrezziBiddingPVI", "partitaIVA"]
  });
  stringCell({
    ...info,
    colIndex: 16,
    path: ["misurePrezziBiddingPVI", "mercatiBidding"]
  });
  numberCell({
    ...info,
    colIndex: 17,
    path: ["misurePrezziBiddingPVI", "uploadId"]
  });
  numberCell({
    ...info,
    colIndex: 18,
    path: ["misurePrezziBiddingPVI", "q_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 19,
    path: ["misurePrezziBiddingPVI", "q_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 20,
    path: ["misurePrezziBiddingPVI", "q_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 21,
    path: ["misurePrezziBiddingPVI", "q_GR4"]
  });
  numberCell({
    ...info,
    colIndex: 22,
    path: ["misurePrezziBiddingPVI", "p_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 23,
    path: ["misurePrezziBiddingPVI", "p_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 24,
    path: ["misurePrezziBiddingPVI", "p_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 25,
    path: ["misurePrezziBiddingPVI", "p_GR4"],
    style: border
  });
  stringCell({
    ...info,
    colIndex: 26,
    path: ["disponibilitaCapacitaMassimaPVI", "partitaIVA"]
  });
  numberCell({
    ...info,
    colIndex: 27,
    path: ["disponibilitaCapacitaMassimaPVI", "uploadId"]
  });
  numberCell({
    ...info,
    colIndex: 28,
    path: ["disponibilitaCapacitaMassimaPVI", "disponibilita"]
  });
  numberCell({
    ...info,
    colIndex: 29,
    path: ["disponibilitaCapacitaMassimaPVI", "capacitaMassima"]
  });
  numberCell({
    ...info,
    colIndex: 30,
    path: ["disponibilitaCapacitaMassimaPVI", "capacitaInstallataMW"],
    style: border
  });
  numberCell({
    ...info,
    colIndex: 31,
    path: ["intermediate", "tensioneValue"],
    style: border
  });
  numberCell({
    ...info,
    colIndex: 32,
    path: ["result", "q_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 33,
    path: ["result", "q_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 34,
    path: ["result", "q_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 35,
    path: ["result", "q_GR4"]
  });
  numberCell({
    ...info,
    colIndex: 36,
    path: ["result", "p_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 37,
    path: ["result", "p_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 38,
    path: ["result", "p_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 39,
    path: ["result", "p_GR4"],
    style: border
  });
  return sheet;
};
