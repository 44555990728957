import React, { useEffect } from "react";
import * as R from "ramda";
import { withRouter, match } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import {
  Selectors,
  OperationsTablesStateType,
  Operations,
  setSelectedOperationAction,
  setPageOperationsList,
  setFiltersOperationsList,
  FilterOperationsTablesStateType,
  downloadOperationsAction,
  fetchingOperationsList,
  clearAllDataAction,
  PVIType,
} from "../redux/modules/audits/executedOperations";
import { Selectors as EnumSelectors } from "../redux/modules/enums";
import Pagination from "../components/tableComponents/paginationNew";
import DateRangeInput from "./components/executedDateRange";
import { NoData } from "../components/tableComponents/noData";
import Loader from "../components/loader";
import Protected from "../protectedComponent";
import permissions from "../permissions";
import PageOnLoad from "../components/pageOnLoad";
import DateTableHeader from "./components/dateTableHeader";
import SelectTableHeader from "./components/selectTableHeader";
import DateRangeTableHeader from "./components/dateRangeTableHeader";

type ExecutedOperationType = {
  operationsTable: OperationsTablesStateType;
  isFetching: boolean;
  pvi: PVIType;
  userList: string[];
  enums: any;
  match: match;
  setSelectedOperationAction: (a: Operations) => void;
  setPageOperationsList: (a: number) => void;
  setFiltersOperationsList: (a: FilterOperationsTablesStateType) => void;
  downloadOperationsAction: (a: any) => void;
  fetchingOperationsList: () => void;
  clearAllDataAction: () => void;
};

const ExecutedOperation = ({
  operationsTable,
  isFetching,
  pvi,
  userList,
  enums,
  match,
  setSelectedOperationAction,
  setPageOperationsList,
  setFiltersOperationsList,
  downloadOperationsAction,
  fetchingOperationsList,
  clearAllDataAction,
}: ExecutedOperationType) => {
  const operation = R.pipe<any, any, any, any>(
    R.prop("path"),
    R.split("/AttivitaEseguite/OperazioniEseguite/"),
    R.path([1])
  )(match);

  useEffect(() => {
    setSelectedOperationAction(operation);
    fetchingOperationsList();
    return () => clearAllDataAction();
  }, [operation]);

  return (
    <div className="pageContainer">
      <h1>Operazioni Eseguite</h1>
      <ExecutedOperationHeader />
      <ExecutedOperationFilters
        filters={operationsTable.filters}
        update={setFiltersOperationsList}
      />
      <div className="spacer"></div>
      <ExecutedOperationTable
        tableInfo={operationsTable}
        operation={operation}
        setPage={setPageOperationsList}
        downloadFile={downloadOperationsAction}
        filters={operationsTable.filters}
        update={setFiltersOperationsList}
        enums={{ pvi, userList, ...enums }}
      />
      <div className="spacer"></div>
      <Loader load={operationsTable.isFetching || isFetching} />
    </div>
  );
};

export default R.compose<any, any, any>(
  withRouter,
  connect(
    (s: any) => ({
      ...Selectors.all(s),
      enums: EnumSelectors.all(s),
    }),
    {
      setSelectedOperationAction,
      setPageOperationsList,
      setFiltersOperationsList,
      downloadOperationsAction,
      fetchingOperationsList,
      clearAllDataAction,
    }
  )
)(ExecutedOperation);

const ExecutedOperationHeader = () => {
  return (
    <div className="componentNav d-flex justify-content-center flex-row">
      <div className="componentNavHolder">
        <Protected
          permission={permissions.mgpMiWritePermissions}
          component={() => (
            <NavLink
              activeClassName="active"
              className="selection-label"
              isActive={(match: any, location: any) => {
                return (
                  match && (R.contains(match.url, location.pathname) as any)
                );
              }}
              to={`/AttivitaEseguite/OperazioniEseguite/MGP&MI`}
            >
              MGP & MI
            </NavLink>
          )}
        />
        <Protected
          permission={permissions.disponibilitaWritePermissions}
          component={() => (
            <NavLink
              activeClassName="active"
              className="selection-label"
              isActive={(match: any, location: any) => {
                return (
                  match && (R.contains(match.url, location.pathname) as any)
                );
              }}
              to={`/AttivitaEseguite/OperazioniEseguite/Disponibilita`}
            >
              Disponibilità
            </NavLink>
          )}
        />
        <Protected
          permission={permissions.datiCombustibiliWritePermissions}
          component={() => (
            <NavLink
              activeClassName="active"
              className="selection-label"
              isActive={(match: any, location: any) => {
                return (
                  match && (R.contains(match.url, location.pathname) as any)
                );
              }}
              to={`/AttivitaEseguite/OperazioniEseguite/Combustibili`}
            >
              Combustibili
            </NavLink>
          )}
        />
      </div>
    </div>
  );
};

type ExecutedOperationFiltersType = {
  filters: FilterOperationsTablesStateType;
  update: (a: FilterOperationsTablesStateType) => void;
};
const ExecutedOperationFilters = ({
  filters,
  update,
}: ExecutedOperationFiltersType) => {
  return (
    <div className="d-flex">
      <div className="width100 d-flex justify-content-center align-items-center">
        <div className="auditFilters">
          <div className="filter">
            <DateRangeInput
              update={update}
              labelName="SELEZIONA PERIODO"
              value={filters}
            />
          </div>
          <div className="spacer" />
        </div>
      </div>
      <div className="spacer" />
    </div>
  );
};

type ExecutedOperationTableType = {
  tableInfo: OperationsTablesStateType;
  operation: Operations;
  filters: FilterOperationsTablesStateType;
  enums: any;
  setPage: (a: number) => void;
  downloadFile: (a: any) => void;
  update: (a: FilterOperationsTablesStateType) => void;
};
const ExecutedOperationTable = ({
  tableInfo,
  operation,
  downloadFile,
  setPage,
  filters,
  enums,
  update,
}: ExecutedOperationTableType) => {
  return (
    <>
      <div className="tableContainer auditList">
        <div className="scroll">
          <ExecutedOperationReports
            operation={operation}
            tableInfo={tableInfo}
            downloadFile={downloadFile}
            filters={filters}
            enums={enums}
            update={update}
          />
        </div>
        <div className="pagingContainer">
          <Pagination
            count={tableInfo.count}
            pageSize={tableInfo.pageSize}
            page={tableInfo.page}
            onPageChange={setPage}
          />
        </div>
      </div>
    </>
  );
};

type ExecutedOperationReportsType = {
  operation: Operations;
  tableInfo: OperationsTablesStateType;
  downloadFile: (a: any) => void;
  filters: FilterOperationsTablesStateType;
  enums: any;
  update: (a: FilterOperationsTablesStateType) => void;
};
const ExecutedOperationReports = ({
  operation,
  tableInfo,
  downloadFile,
  filters,
  enums,
  update,
}: ExecutedOperationReportsType): any => {
  switch (operation) {
    case "MGP&MI":
      return (
        <DisponibilitaCapacitaMassimaTable
          tableInfo={tableInfo}
          downloadFile={downloadFile}
          filters={filters}
          enums={enums}
          update={update}
        />
      );
    case "Disponibilita":
      return (
        <MisurePrezziBiddingPVITable
          tableInfo={tableInfo}
          downloadFile={downloadFile}
          filters={filters}
          enums={enums}
          update={update}
        />
      );
    case "Combustibili":
      return (
        <DatiCombustibiliTable
          tableInfo={tableInfo}
          downloadFile={downloadFile}
          filters={filters}
          enums={enums}
          update={update}
        />
      );
  }
};

const dateformatter = ({ path, data }: { path: any; data: any }) => {
  const val = R.pathOr(null, path, data);
  return R.isNil(val) ? val : format(new Date(val), "dd-MM-yyyy HH:mm:ss");
};

type DisponibilitaCapacitaMassimaTableType = {
  tableInfo: OperationsTablesStateType;
  downloadFile: (a: any) => void;
  filters: FilterOperationsTablesStateType;
  enums: any;
  update: (a: FilterOperationsTablesStateType) => void;
};
const DisponibilitaCapacitaMassimaTable = ({
  tableInfo,
  downloadFile,
  filters,
  enums,
  update,
}: DisponibilitaCapacitaMassimaTableType) => {
  return (
    <>
      <PageOnLoad />
      <table className="table">
        <thead>
          <tr>
            <th className="date">Date</th>
            <Protected
              permission={[permissions.auditRead]}
              component={() => (
                <SelectTableHeader
                  title="User"
                  propName="user"
                  options={R.map(
                    (x) => ({ value: x, title: x }),
                    enums.userList
                  )}
                  filters={filters}
                  setFilter={update}
                />
              )}
              fallback={() => <th className="date">User</th>}
            />

            <SelectTableHeader
              title="PVI"
              propName="pvi"
              options={R.map(
                (x) => ({ value: x.pvi, title: x.pvi }),
                enums.pvi.data
              )}
              filters={filters}
              setFilter={update}
            />
            <SelectTableHeader
              title="Market"
              propName="mercatiBidding"
              options={R.map(
                (x) => ({ value: x, title: x }),
                enums.enumData.Mercato
              )}
              filters={filters}
              setFilter={update}
            />
            <DateTableHeader
              title="Da"
              propName="from"
              filters={filters}
              setFilter={update}
            />
            <DateTableHeader
              title="A"
              propName="to"
              filters={filters}
              setFilter={update}
              colSpan={2}
            />
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(tableInfo.data) || R.isNil(tableInfo.data) ? (
            <NoData cols={7} />
          ) : (
            tableInfo.data.map((row: any) => {
              const name = R.pathOr("", ["audit", "userid"], row);
              const userName = R.contains("@clients", name)
                ? "Axpo Service"
                : name;
              return (
                <tr key={row.audit.auditId}>
                  <td>{dateformatter({ path: ["uploadedAt"], data: row })}</td>
                  <td>{userName}</td>
                  <td>{R.pathOr(null, ["pvi"], row)}</td>
                  <td>{R.pathOr(null, ["mercatiBidding"], row)}</td>
                  <td>{R.pathOr(null, ["from"], row)}</td>
                  <td>{R.pathOr(null, ["to"], row)}</td>
                  <td>
                    <div
                      className="bidding-arrow"
                      onClick={() => downloadFile(R.path(["id"], row))}
                    >
                      <i className="fas fa-file-download"></i>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};

type MisurePrezziBiddingPVITableType = {
  tableInfo: OperationsTablesStateType;
  downloadFile: (a: any) => void;
  filters: FilterOperationsTablesStateType;
  enums: any;
  update: (a: FilterOperationsTablesStateType) => void;
};
const MisurePrezziBiddingPVITable = ({
  tableInfo,
  downloadFile,
  filters,
  enums,
  update,
}: MisurePrezziBiddingPVITableType) => {
  return (
    <>
      <PageOnLoad />
      <table className="table">
        <thead>
          <tr>
            <th className="date">Date</th>
            <Protected
              permission={[permissions.auditRead]}
              component={() => (
                <SelectTableHeader
                  title="User"
                  propName="user"
                  options={R.map(
                    (x) => ({ value: x, title: x }),
                    enums.userList
                  )}
                  filters={filters}
                  setFilter={update}
                />
              )}
              fallback={() => <th className="date">User</th>}
            />
            <SelectTableHeader
              title="PVI"
              propName="pvi"
              options={R.map(
                (x) => ({ value: x.pvi, title: x.pvi }),
                enums.pvi.data
              )}
              filters={filters}
              setFilter={update}
            />
            <DateTableHeader
              title="Da"
              propName="from"
              filters={filters}
              setFilter={update}
            />
            <DateTableHeader
              title="A"
              propName="to"
              filters={filters}
              setFilter={update}
              colSpan={2}
            />
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(tableInfo.data) || R.isNil(tableInfo.data) ? (
            <NoData cols={5} />
          ) : (
            tableInfo.data.map((row: any) => {
              const name = R.pathOr("", ["audit", "userid"], row);
              const userName = R.contains("@clients", name)
                ? "Axpo Service"
                : name;
              return (
                <tr key={row.audit.auditId}>
                  <td>{dateformatter({ path: ["uploadedAt"], data: row })}</td>
                  <td>{userName}</td>
                  <td>{R.pathOr(null, ["pvi"], row)}</td>
                  <td>{R.pathOr(null, ["from"], row)}</td>
                  <td>{R.pathOr(null, ["to"], row)}</td>
                  <td>
                    <div
                      className="bidding-arrow"
                      onClick={() => downloadFile(R.path(["id"], row))}
                    >
                      <i className="fas fa-file-download"></i>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};
type DatiCombustibiliTableType = {
  tableInfo: OperationsTablesStateType;
  downloadFile: (a: any) => void;
  filters: FilterOperationsTablesStateType;
  enums: any;
  update: (a: FilterOperationsTablesStateType) => void;
};
const DatiCombustibiliTable = ({
  tableInfo,
  downloadFile,
  filters,
  enums,
  update,
}: DatiCombustibiliTableType) => {
  return (
    <>
      <PageOnLoad />
      <table className="table">
        <thead>
          <tr>
            <th className="date">Last Updated</th>
            <Protected
              permission={[permissions.auditRead]}
              component={() => (
                <SelectTableHeader
                  title="User"
                  propName="user"
                  options={R.map(
                    (x) => ({ value: x, title: x }),
                    enums.userList
                  )}
                  filters={filters}
                  setFilter={update}
                />
              )}
              fallback={() => <th className="date">User</th>}
            />
            <SelectTableHeader
              title="PVI"
              propName="pvi"
              options={R.map(
                (x) => ({ value: x.pvi, title: x.pvi }),
                enums.pvi.data
              )}
              filters={filters}
              setFilter={update}
            />
            <DateRangeTableHeader
              title="Competenza From/To"
              propNameTo="competenzaTo"
              propNameFrom="competenzaFrom"
              filters={filters}
              setFilter={update}
              colSpan={2}
            />
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(tableInfo.data) || R.isNil(tableInfo.data) ? (
            <NoData cols={5} />
          ) : (
            tableInfo.data.map((row: any) => {
              const name = R.pathOr("", ["audit", "userid"], row);
              const userName = R.contains("@clients", name)
                ? "Axpo Service"
                : name;
              return (
                <tr key={row.audit.auditId}>
                  <td>{dateformatter({ path: ["lastUpdated"], data: row })}</td>
                  <td>{userName}</td>
                  <td>{R.pathOr(null, ["pvi"], row)}</td>
                  <td>{R.pathOr(null, ["competenza"], row)}</td>
                  <td>
                    <div
                      className="bidding-arrow"
                      onClick={() =>
                        downloadFile(
                          `${row.partitaIva}/${row.pvi}/${row.competenza}`
                        )
                      }
                    >
                      <i className="fas fa-file-download"></i>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};
