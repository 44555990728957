import React from "react";
import * as R from "ramda";
import { withRouter, match } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import MGP from "./MGP";
import DisponibilitiaNew from "./DisponibilitiaNew";
import Combustibili from "./Combustibili";
import EditCombustibili from "./EditCombustibili";
import { Selectors as DisponibilitiaSelectors } from "../redux/modules/Disponibilitia";
import { Selectors as MGPSelectors } from "../redux/modules/MGP";
import { Selectors as CombustibiliSelectors } from "../redux/modules/Combustibili";
import Protected from "../protectedComponent";
import permissions from "../permissions";

type MarketBidType = {
  match: match;
  disponibilitiaMod: boolean;
  mgpMod: boolean;
  combustibiliMod: boolean;
};

const MarketBid = ({
  match,
  disponibilitiaMod,
  mgpMod,
  combustibiliMod,
}: MarketBidType) => {
  const market = R.pipe<any, any, any, any>(
    R.prop("path"),
    R.split("/MarketBid/"),
    R.path([1])
  )(match);
  return (
    <div className="pageContainer">
      <MarketMenuHeader
        modify={disponibilitiaMod || mgpMod || combustibiliMod}
      />
      <Markets market={market} />
    </div>
  );
};

export default R.compose<any, any, any>(
  withRouter,
  connect(
    (s: any) => ({
      disponibilitiaMod: DisponibilitiaSelectors.modifyOnline(s),
      mgpMod: MGPSelectors.modifyOnline(s),
      combustibiliMod: CombustibiliSelectors.modifyOnline(s),
    }),
    {}
  )
)(MarketBid);

type MarketMenuHeaderType = { modify: boolean };

const MarketMenuHeader = ({ modify }: MarketMenuHeaderType) => {
  return R.unless(R.equals(true), () => (
    <div>
      <h1>Market Bid</h1>
      <div className="componentNav d-flex justify-content-center flex-row">
        <div className="componentNavHolder">
          <Protected
            permission={permissions.mgpMiPermissions}
            component={() => (
              <NavLink
                activeClassName="active"
                className="selection-label"
                isActive={(match: any, location: any) => {
                  return (
                    match && (R.contains(match.url, location.pathname) as any)
                  );
                }}
                to={`/MarketBid/MGP&MI`}
              >
                MGP & MI
              </NavLink>
            )}
          />
          <Protected
            permission={permissions.disponibilitaPermissions}
            component={() => (
              <NavLink
                activeClassName="active"
                className="selection-label"
                isActive={(match: any, location: any) => {
                  return (
                    match && (R.contains(match.url, location.pathname) as any)
                  );
                }}
                to={`/MarketBid/Disponibilita`}
              >
                Disponibilità
              </NavLink>
            )}
          />
          <Protected
            permission={permissions.datiCombustibiliPermissions}
            component={() => (
              <NavLink
                activeClassName="active"
                className="selection-label"
                isActive={(match: any, location: any) => {
                  return (
                    match && (R.contains(match.url, location.pathname) as any)
                  );
                }}
                to={`/MarketBid/Combustibili`}
              >
                Combustibili
              </NavLink>
            )}
          />
        </div>
      </div>
    </div>
  ))(modify);
};

type Markets =
  | "MGP&MI"
  | "Disponibilita"
  | "Combustibili"
  | "Combustibili/Edit";

type MarketsType = {
  market: Markets;
};

const Markets = ({ market }: MarketsType): any => {
  switch (market) {
    case "Disponibilita":
      return <DisponibilitiaNew />;
    case "Combustibili":
      return <Combustibili />;
    case "Combustibili/Edit":
      return <EditCombustibili />;
    case "MGP&MI":
      return <MGP />;
  }
};
