import React from "react";
import Logo from "../styles/assets/axpo-logo-transparent.png";
import main from "../styles/footer.module.scss";
export const Footer = () => {
  return (
    <>
      <div className={main.footer}>
        <div className={main.details}>
          <div className={main.value}>Sei anche un consumatore?</div>
          <div className={main.label}>
            Per visualizzare le fatture, monitorare i tuoi consumi, caricare le
            tue previsioni di consumo e molto altro puoi accedere all'area
            personale MyAxpo
          </div>
        </div>

        <a
          className={`btn btn-footer rounded-pill mx-2 margin-bottom-18 ${main.axpoenergiaLink}`}
          rel="noopener noreferrer"
          href="https://axpoenergia.com/my/login"
          target="_blank"
        >
          Accedi a MyAxpo
        </a>
      </div>
      <div className={main.axpoFooter}>
        <div className={main.logoContainer}>
          <img className={main.logo} src={Logo} alt="Axpo Logo"></img>
        </div>
        <div className={main.axpoFooterItem}>Note Legali</div>
        <div className={main.axpoFooterItem}>Privacy Policy</div>
        <div className={main.axpoFooterItem}>Cookies Policy</div>
        <div className={main.footerDetails}>
          <div>Axpo Italia SpA</div>
          <div>P. IVA 01141160992</div>
          <div>R.E.A. di Roma 987225</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
