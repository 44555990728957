import * as R from "ramda";

const getTimeseriesValues = (data: TimeseriesDataType[]) =>
  R.reduce((a, b) => R.add(a, R.pathOr(0, ["value"], b)), 0, data);

const getTimeseriesNill = (data: TimeseriesDataType[]) =>
  R.pipe(
    R.map(R.pathOr(false, ["value"])),
    x => {
      return R.all(R.equals(false))(x);
    }
  )(data);

const getTimeseriesCertains = (data: TimeseriesDataType[]) =>
  R.pipe(
    R.map(R.pathOr(false, ["certain"])),
    x => R.all(R.equals(true))(x)
  )(data);

type TimeseriesDataType = {
  dateTimeOffset: string;
  value: number;
  certain: boolean;
};
const getTimeseriesData = R.pipe<any, any, any, any, any, any>(
  R.map(R.prop("timeseries")),
  x => R.flatten(x),
  R.groupBy(R.prop<any, any>("dateTimeOffset")),
  R.map((row: TimeseriesDataType[]) => {
    return getTimeseriesNill(row)
      ? {
          dateTimeOffset: R.pathOr(null, [0, "dateTimeOffset"], row)
        }
      : {
          dateTimeOffset: R.pathOr(null, [0, "dateTimeOffset"], row),
          value: getTimeseriesValues(row),
          certain: getTimeseriesCertains(row)
        };
  }),
  R.values
);

const getStatisticsData = R.pipe<any, any, any>(
  R.map(R.prop("statistics")),
  x => R.flatten(x)
);

export const setProdData = (prop: string, data: any) =>
  R.pipe<any, any, any>(
    R.map(R.prop(prop)),
    x => ({
      timeseries: getTimeseriesData(x),
      statistics: getStatisticsData(x)
    })
  )(data);

export default setProdData;
