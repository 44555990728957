import {
  ActionsObservable,
  StateObservable,
  ofType,
  combineEpics,
} from "redux-observable";
import { Dependancies } from "../storeTypes";
import { switchMap, switchMapTo, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import IdTokenVerifier from "idtoken-verifier";

export const key = "auth";

const verifier = new IdTokenVerifier({});
export type User = { user: string };

type State =
  | { tag: "initial" }
  | { tag: "login-required" }
  | { tag: "login-failed"; error?: string }
  | { tag: "authenticated"; profile: User };

export const initAction = { type: "INIT" } as const;
export const handleRedirectAction = {
  type: "authentication/HANDLE_REDIRECT",
} as const;
const checkSessionFailedAction = () =>
  ({
    type: "authentication/CHECK_SESSION_FAILED",
  } as const);

const checkSessionSuccessAction = (profile: User) =>
  ({
    type: "authentication/CHECK_SESSION_SUCCESS",
    profile,
  } as const);

export const loginAction = {
  type: "authentication/LOGIN",
} as const;
const loginSuccessAction = (profile: User) =>
  ({
    type: "authentication/LOGIN_SUCCESS",
    profile,
  } as const);

const loginFailedAction = (error?: string) =>
  ({
    type: "authentication/LOGIN_FAILED",
    error,
  } as const);

export const logoutAction = {
  type: "authentication/LOGOUT",
} as const;

type Action =
  | typeof initAction
  | typeof handleRedirectAction
  | ReturnType<
      | typeof loginSuccessAction
      | typeof checkSessionFailedAction
      | typeof checkSessionSuccessAction
      | typeof loginFailedAction
    >;

export function reducer(
  state: State = { tag: "initial" },
  action: Action
): State {
  switch (action.type) {
    case "INIT":
      return { tag: "initial" };
    case "authentication/CHECK_SESSION_FAILED":
      return { tag: "login-required" };
    case "authentication/CHECK_SESSION_SUCCESS":
      return { tag: "authenticated", profile: action.profile };
    case "authentication/LOGIN_FAILED":
      return { tag: "login-failed", error: action.error };
    case "authentication/LOGIN_SUCCESS":
      return { tag: "authenticated", profile: action.profile };
    case handleRedirectAction.type:
    default:
      return state;
  }
}

export function initEpic(
  action$: ActionsObservable<any>,
  store$: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(initAction.type),
    switchMap(() =>
      window.location.search
        ? of(handleRedirectAction)
        : deps.auth
            .getTokenSilently()
            .then((token: any) => verifier.decode(token).payload)
            .then((accessToken: any) =>
              deps.auth
                .getUser()
                .then((user: any) => ({ ...user, accessToken }))
            )

            .then(checkSessionSuccessAction, checkSessionFailedAction)
    )
  );
}
export function redirectEpic(
  action$: ActionsObservable<any>,
  store$: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(handleRedirectAction.type),
    switchMap(() =>
      deps.auth
        .handleRedirectCallback()
        .finally(() => window.history.pushState({}, "", "/"))
        .then(() =>
          deps.auth
            .getTokenSilently()
            .then((token: any) => verifier.decode(token).payload)
            .then((accessToken: any) =>
              deps.auth
                .getUser()
                .then((user: any) => ({ ...user, accessToken }))
            )
        )
        .then(loginSuccessAction, loginFailedAction)
    )
  );
}

export function loginEpic(
  action$: ActionsObservable<any>,
  store$: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(loginAction.type),
    switchMap(() => deps.auth.loginWithRedirect()),
    switchMapTo(EMPTY)
  );
}

export function logoutEpic(
  action$: ActionsObservable<any>,
  store$: StateObservable<any>,
  deps: Dependancies
) {
  return action$.pipe(
    ofType(logoutAction.type),
    tap(() => deps.auth.logout()),
    switchMapTo(EMPTY)
  );
}

export const epic = combineEpics(initEpic, redirectEpic, loginEpic, logoutEpic);

export const Selectors = {
  auth: (state: any): State => state[key],
};
