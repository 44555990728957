import * as R from "ramda";
import { combineReducers } from "redux";
import { combineEpics, ofType } from "redux-observable";
import { Observable, defer, of, EMPTY } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import { format, subDays, addDays } from "date-fns";
import { pagination } from "../../../utils/pagination";

export const key = "forwardMarket";

export enum actionTypes {
  EMPTY_DATA = "rtm/market/forwardMarket/EMPTY_DATA",
  SET_COMMODITY = "rtm/market/forwardMarket/SET_COMMODITY",
  RECIEVED_DATA = "rtm/market/forwardMarket/RECIEVED_DATA",
  SELECT_PRODUCTS = "rtm/market/forwardMarket/SELECT_PRODUCTS",
  CANCEL = "rtm/market/forwardMarket/CANCEL",
}

export type ProductType = "M+1" | "M+2" | "M+3" | "Q+1" | "Q+2" | "Y+1";

type Actions =
  | { type: actionTypes.SET_COMMODITY; commodity: CommodityType }
  | { type: actionTypes.RECIEVED_DATA; data: any }
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.SELECT_PRODUCTS; list: ProductType[] }
  | { type: actionTypes.EMPTY_DATA };

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.SET_COMMODITY:
      return true;
    case actionTypes.CANCEL:
    case actionTypes.RECIEVED_DATA:
      return false;
    default:
      return state;
  }
}

const commodityReducer = (state: CommodityType = null, action: Actions) => {
  switch (action.type) {
    case actionTypes.SET_COMMODITY:
      return action.commodity;
    case actionTypes.EMPTY_DATA:
      return null;
    default:
      return state;
  }
};
const dataReducer = (state: any = [], action: Actions) => {
  switch (action.type) {
    case actionTypes.RECIEVED_DATA:
      return action.data;
    case actionTypes.SET_COMMODITY:
    case actionTypes.EMPTY_DATA:
      return [];
    default:
      return state;
  }
};

const productsDeafult = ["M+1", "M+2", "M+3", "Q+1", "Q+2", "Y+1"];
const productsReducer = (state: any = productsDeafult, action: Actions) => {
  switch (action.type) {
    case actionTypes.SELECT_PRODUCTS:
      return action.list;
    case actionTypes.EMPTY_DATA:
      return productsDeafult;
    default:
      return state;
  }
};

export type reducerType = {
  isFetching: boolean;
  commodity: CommodityType;
  data: any;
  products: any;
};

export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  commodity: commodityReducer,
  data: dataReducer,
  products: productsReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

const ForwardPrezziType = t.type({
  commodity: t.string,
  date: t.string,
  relativeProduct: t.string,
  absoluteProduct: t.string,
  price: t.number,
});
type GetForwardPrezziType = {
  deps: any;
  page?: number;
  pageSize?: number;
  commodity: any;
  start: any;
  end: any;
};
const getForwardPrezzi = ({
  deps,
  page = 1,
  pageSize = 200,
  commodity,
  start,
  end,
}: GetForwardPrezziType) => {
  const skipVal = R.multiply(R.subtract(page, 1), pageSize);

  return of(
    `prices/prezziforward?skip=${skipVal}&limit=${pageSize}&commodity=${commodity}&start=${start}&end=${end}&relativeProduct=M%2B1&relativeProduct=M%2B2&relativeProduct=M%2B3&relativeProduct=Q%2B1&relativeProduct=Q%2B2&relativeProduct=Y%2B1`
  ).pipe(
    RxOp.switchMap((url) =>
      defer(deps.request.get(url, pagination(ForwardPrezziType)))
    ),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, (x) => x))
  );
};

const getAllForwardPrezzi = ({
  deps,
  commodity,
  start,
  end,
}: GetForwardPrezziType): any => {
  return getForwardPrezzi({
    deps,
    commodity,
    start,
    end,
  }).pipe(
    RxOp.expand((x: any) => {
      return x.isCountPartial || x.limit + x.skip < x.count
        ? getForwardPrezzi({
            deps,
            commodity,
            start,
            end,
            page: 1 + (x.skip + x.limit) / x.limit,
          })
        : EMPTY;
    }),
    RxOp.map((x: any) => x.data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};

export type CommodityType = "power" | "gas" | null;
export const setCommodityAction = (commodity: CommodityType): Actions => ({
  type: actionTypes.SET_COMMODITY,
  commodity,
});

const receivedTableData = (data: any) => ({
  type: actionTypes.RECIEVED_DATA,
  data,
});

const getForwardMarketDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.SET_COMMODITY),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap(() => {
          const start = R.pipe(
            (x: Date) => subDays(x, 7),
            (x: Date) => format(x, "yyyy-MM-dd")
          )(new Date());

          const end = R.pipe(
            (x: Date) => addDays(x, 1),
            (x: Date) => format(x, "yyyy-MM-dd")
          )(new Date());

          return getAllForwardPrezzi({
            deps,
            commodity: action.commodity,
            start,
            end,
          });
        }),
        RxOp.map((data: any) => receivedTableData(data)),
        RxOp.catchError(dispatchNetworkError)
      )
    )
  );

export const setProductsAction = (list: ProductType[]): Actions => ({
  type: actionTypes.SELECT_PRODUCTS,
  list,
});

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(errorHandlerEpic, getForwardMarketDataEpic);

export const Selectors = {
  all: (s: any): reducerType => s.markets[key],
};
