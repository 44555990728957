import * as R from "ramda";

export const getStateOptions = (kind: string) => {
  switch (kind) {
    case "Starting":
      return "Starting";
    case "Computing":
      return "Computing";
    case "SendingXDM":
      return "Sending XDM";
    case "SentToXDM":
      return "Sent To XDM";
    case "SentToXDMWithWarning":
      return "Sent To XDM With Warning";
    case "SendingBidEvo":
      return "Sending BidEvo";
    case "SentToBidEvo":
      return "Sent To BidEvo";
    case "SentToBidEvoWithWarning":
      return "Sent To BidEvo With Warning";
    case "Failed":
      return "Failed";
    case "Execution":
      return "In Esecuzione";
    case "ReadyToBid":
      return "Ready To Bid";
    default:
      return null;
  }
};

export const disableApplyBtn = (modal: any) => {
  return (
    modal.date === null ||
    modal.market === null ||
    modal.biddingBidEvoTarget === null ||
    modal.applyCoefficientiUp === null
  );
};

export const getStateColStyle = (kind: string | null) => {
  switch (kind) {
    case "Info":
    case "SentToXDM":
    case "SentToBidEvo":
    case "ReadyToBid":
      return "green-background  white-text";
    case "Error":
    case "Fatal":
    case "Failed":
      return "red-background  white-text";
    case "Debug":
    case "SentToXDMWithWarning":
    case "SentToBidEvoWithWarning":
    case "Warning":
    case "Execution":
      return "yellow-background  white-text";
    case "Starting":
    case "Computing":
    case "Trace":
    case "SendingXDM":
    case "SendingBidEvo":
    default:
      return "";
  }
};

export const allPvis = R.pipe<any, any, any>(
  R.map(R.path(["pvi", "pvi"])),
  R.values
);

export const ragioneSocialeVal = ({
  filters,
  data,
}: {
  filters: any;
  data: any;
}) =>
  R.pipe<any, any, any>(
    R.filter(R.propEq("partitaIva", filters)),
    (x) => {
      return R.isEmpty(x)
        ? []
        : [{ value: x[0].partitaIva, title: x[0].ragioneSociale }];
    }
  )(data);
