import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  key as ProductionKey,
  reducer as ProductionReducer,
  epic as ProductionEpic
} from "./production";
import {
  key as SettlementKey,
  reducer as SettlementReducer,
  epic as SettlementEpic
} from "./settlement";

export const reducer = combineReducers({
  [ProductionKey]: ProductionReducer,
  [SettlementKey]: SettlementReducer
});

export const epic = combineEpics(ProductionEpic, SettlementEpic);
