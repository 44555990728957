import React from "react";
import { array, mapWithIndex } from "fp-ts/lib/Array";
import { Lens, fromTraversable } from "monocle-ts";
import { fold } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import { chunksOf } from "fp-ts/lib/Array";
import styles from "./multiselect/style.module.scss";
import { CheckBoxNew } from "./checkBox";

const checkedL = Lens.fromProp<SelectOption>()("checked");
const traverseL = fromTraversable(array)<SelectOption>();

const findUnchecked = traverseL.asFold().find(o => !o.checked);

type SelectOption = { key: string; pvi?: string; checked: boolean };

function toggle(key: string, options: SelectOption[]): SelectOption[] {
  const modify = traverseL
    .filter(o => o.key === key)
    .composeLens(checkedL)
    .modify(b => !b);

  return modify(options);
}

function selectAll(options: SelectOption[]): SelectOption[] {
  const setter = traverseL.composeLens(checkedL);

  return pipe(
    findUnchecked(options),
    fold(() => setter.set(false), () => setter.set(true)),
    fn => fn(options)
  );
}

export default function MultiSelect(props: {
  options: SelectOption[];
  onChange: (o: SelectOption[]) => void;
}) {
  const allChecked = pipe(
    findUnchecked(props.options),
    fold(() => true, () => false)
  );

  return (
    <>
      <CheckBoxNew
        key={22}
        label="Seleziona tutti gli impianti"
        className="selectAllCheckBox"
        name={""}
        selected={allChecked}
        update={() => props.onChange(selectAll(props.options))}
      />
      <div className={styles.container}>
        {pipe(
          props.options,
          chunksOf(3),
          mapWithIndex((i, chunk) => (
            <div className={styles.subGrid} key={i}>
              {chunk.map(o => (
                <div className={styles.centerItems} key={o.key}>
                  <CheckBoxNew
                    key={o.key}
                    label={<span title={o.pvi}>{o.key}</span>}
                    className="groupCheckBox"
                    selected={o.checked}
                    name=""
                    update={() => props.onChange(toggle(o.key, props.options))}
                  />
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </>
  );
}
