import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { format } from "date-fns";
import { Selectors as EnumsSelectors } from "../redux/modules/enums";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import RadioBtn from "../components/radioButton";
import { CheckBox } from "../components/checkBox";
import styles from "./settlement.module.scss";
import "../styles/highcharts.module.scss";
import DateRange from "../components/dateRange";
import { NoData } from "../components/tableComponents/noData";
import Loader from "../components/loader";
import InputTextField from "../components/inputTextField";
import { getSettlementSeries, getAllPlantsNew, getCurveData } from "./helper";
import {
  Selectors,
  RagioneSocialeType,
  PVIType,
  FiltersType,
  firstLoadAction,
  clearAllDataAction,
  setSelectedSocialeAction,
  setSelectedPVIAction,
  CurveTypes,
  setSelectedCurvesAction,
  Periodype,
  setPeriodAction,
  GranularityType,
  setGranularityAction,
  getCurveName,
  exportDataAction,
} from "../redux/modules/productionData/settlement";
import Pagination from "../components/tableComponents/paginationNew";
import PageOnLoad from "../components/pageOnLoad";
import Accordion from "../components/accordion/accordion";

NoDataToDisplay(Highcharts);

type SettlementType = {
  isFetching: boolean;
  ragioneSociale: RagioneSocialeType;
  pvi: PVIType;
  filters: FiltersType;
  productionData: any;
  firstLoadAction: () => void;
  clearAllDataAction: () => void;
  setSelectedSocialeAction: (a: any) => void;
  setSelectedPVIAction: (a: any) => void;
  setSelectedCurvesAction: (a: CurveTypes) => void;
  setPeriodAction: (a: Periodype) => void;
  setGranularityAction: (a: GranularityType) => void;
  exportDataAction: () => void;
};

const Settlement = ({
  isFetching,
  ragioneSociale,
  pvi,
  filters,
  productionData,
  firstLoadAction,
  clearAllDataAction,
  setSelectedSocialeAction,
  setSelectedPVIAction,
  setSelectedCurvesAction,
  setPeriodAction,
  setGranularityAction,
  exportDataAction,
}: SettlementType) => {
  useEffect(() => {
    firstLoadAction();
    return () => clearAllDataAction();
  }, []);
  return (
    <>
      <div className="pageContainer">
        <h1>Settlement</h1>
        <div className="pageContent">
          <SettlementFilters
            ragioneSociale={ragioneSociale}
            pvi={pvi}
            filters={filters}
            setSelectedSociale={setSelectedSocialeAction}
            setSelectedPVI={setSelectedPVIAction}
            setSelectedCurves={setSelectedCurvesAction}
            setPeriod={setPeriodAction}
            setGranularity={setGranularityAction}
          />
        </div>
        <div className="spacer"></div>
        <SettlementChart data={productionData} filters={filters} />
        <div className="spacer"></div>
        <SettlementTable
          data={productionData}
          pvi={pvi}
          exportData={exportDataAction}
          ragioneSociale={ragioneSociale}
        />
        <Loader
          load={isFetching || pvi.isFetching || ragioneSociale.isFetching}
        />
      </div>
      <PageOnLoad />
    </>
  );
};

export default connect(
  (s: any) => ({ ...Selectors.all(s), enums: EnumsSelectors.enums(s) }),
  {
    firstLoadAction,
    clearAllDataAction,
    setSelectedSocialeAction,
    setSelectedPVIAction,
    setSelectedCurvesAction,
    setPeriodAction,
    setGranularityAction,
    exportDataAction,
  }
)(Settlement);

type SettlementFiltersType = {
  ragioneSociale: RagioneSocialeType;
  pvi: PVIType;
  filters: FiltersType;
  setSelectedSociale: (a: any) => void;
  setSelectedPVI: (a: any) => void;
  setSelectedCurves: (a: any) => void;
  setPeriod: (a: Periodype) => void;
  setGranularity: (a: any) => void;
};

const CheckboxList = ({
  ragioneSocialeListPvi,
  setSelectedPVI,
  filters,
}: any) => {
  const [filter, setFilter] = useState("");
  const [
    ragioneSocialeListPviFiltered,
    setragioneSocialeListPviFiltered,
  ] = useState(ragioneSocialeListPvi);
  useEffect(() => {
    setragioneSocialeListPviFiltered(
      R.filter((x: any) =>
        R.contains(R.toLower(filter), R.toLower(x.pvi.nome))
      )(R.sortBy(R.pathOr("", ["pvi", "nome"]))(ragioneSocialeListPvi) || [])
    );
  }, [ragioneSocialeListPvi]);
  return (
    <div className={`${styles.spotPlantSelect}`}>
      {ragioneSocialeListPvi.length > 10 ? (
        <div className={styles.pviFilter}>
          <InputTextField
            name="value"
            value={filter}
            placeholder="Inserire PVI..."
            update={(x: string) => {
              setFilter(x);
            }}
          ></InputTextField>
        </div>
      ) : (
        <></>
      )}
      <div className={`${styles.spotPlantGrid}`}>
        {R.map(
          (y: any) => (
            <CheckBox
              key={`${y.pvi.nome}-${y.id}`}
              label={y.pvi.nome}
              name={y.id}
              selected={filters.pvis}
              update={setSelectedPVI}
            />
          ),
          ragioneSocialeListPviFiltered
        )}
      </div>
      {R.length(ragioneSocialeListPviFiltered) === 0 ? (
        <div className={styles.pviFilter}>
          Nessun PVI disponibile con questo filtro
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const SettlementFilters = ({
  ragioneSociale,
  pvi,
  filters,
  setSelectedSociale,
  setSelectedPVI,
  setSelectedCurves,
  setPeriod,
  setGranularity,
}: SettlementFiltersType) => {
  const [ragioneSocialeList, setRagioneSocialeList] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const pviData = R.prop("data", pvi);
  useEffect(() => {
    R.when(R.equals(true), () =>
      setSelectedSociale(ragioneSociale.data[0].partitaIva)
    )(R.isNil(filters.ragioneSociale) && !R.isEmpty(ragioneSociale.data));

    R.when(R.equals(true), () => setSelectedPVI([pvi.data[0].id]))(
      R.isNil(filters.pvis) && !R.isEmpty(pvi.data)
    );

    R.when(R.equals(true), () =>
      setRagioneSocialeList(
        R.pipe<any, any, any, any, any>(
          R.groupBy(R.path(["ragioneSociale", "partitaIva"]) as any) as any,
          R.toPairs,
          R.sortBy((x: any) =>
            R.pathOr("", ["ragioneSociale", "ragioneSociale"])(R.head(x[1]))
          ),
          R.map((x: any) => x[1])
        )(pvi.data)
      )
    )(!R.isEmpty(pvi.data));
  }, [ragioneSociale.data, pvi.data]);
  return (
    <>
      <div className={styles.spotSelector}>
        <div className={styles.regioneSelector}>
          <div>
            <div>
              <span className="form-label">Ragione Sociale</span>
            </div>
            {ragioneSociale.data.length < 15 &&
            ragioneSociale.data.length > 0 ? (
              <div></div>
            ) : (
              <InputTextField
                name="value"
                value={filter}
                placeholder="Inserire Ragione Sociale..."
                update={setFilter}
              ></InputTextField>
            )}
            <div className={styles.selectionCheckboxes}>
              {R.length(pvi.data) < 35 && R.length(pvi.data) > 0 ? (
                <CheckBox
                  label={"Seleziona tutti"}
                  name={"Seleziona tutti"}
                  selected={selectedAll}
                  allSeleted={selectedAll}
                  update={() => {
                    setSelectedAll(!selectedAll);
                    setSelectedPVI(
                      R.map(R.path(["id"]))(R.flatten(ragioneSocialeList))
                    );
                  }}
                />
              ) : (
                <div></div>
              )}
              <CheckBox
                label={"Mostra selezionati"}
                name={"Mostra selezionati"}
                selected={selectedOnly}
                allSeleted={selectedOnly}
                update={() => setSelectedOnly(!selectedOnly)}
              />
            </div>
          </div>
          <div className={styles.regioneSelectorContent}>
            {ragioneSocialeList.map((ragioneSocialeListPvi: any, i: any) =>
              (filter === "" ||
                R.contains(
                  R.toLower(filter),
                  R.toLower(
                    ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale
                  )
                )) &&
              ((!selectedOnly ||
                R.filter(
                  (x: any) =>
                    ragioneSocialeListPvi[0].ragioneSociale.partitaIva ===
                    R.head(R.split("|", x || ""))
                )(filters.pvis || []).length > 0) &&
                R.contains(
                  R.toLower(filter),
                  R.toLower(
                    ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale
                  )
                )) ? (
                <Accordion
                  key={`${ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale}-${ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale}- ${i}`}
                  title={
                    <label className="form-group text-uppercase">
                      <CheckBox
                        label={
                          ragioneSocialeListPvi[0].ragioneSociale.ragioneSociale
                        }
                        name={
                          ragioneSocialeListPvi[0].ragioneSociale.partitaIva
                        }
                        selected={filters.pvis}
                        partialSelected={
                          R.filter(
                            (x: any) =>
                              ragioneSocialeListPvi[0].ragioneSociale
                                .partitaIva === R.head(R.split("|", x || ""))
                          )(filters.pvis || []).length > 0 &&
                          R.filter(
                            (x: any) =>
                              ragioneSocialeListPvi[0].ragioneSociale
                                .partitaIva === R.head(R.split("|", x || ""))
                          )(filters.pvis || []).length <
                            R.map(R.path(["id"]))(ragioneSocialeListPvi).length
                        }
                        allSeleted={
                          R.filter(
                            (x: any) =>
                              ragioneSocialeListPvi[0].ragioneSociale
                                .partitaIva === R.head(R.split("|", x || ""))
                          )(filters.pvis || []).length >=
                          R.length(R.map(R.path(["id"]))(ragioneSocialeListPvi))
                        }
                        update={() => {
                          R.filter(
                            R.contains(
                              ragioneSocialeListPvi[0].ragioneSociale
                                .partitaIva + "|"
                            )
                          )(filters.pvis || []).length >=
                          R.length(R.map(R.path(["id"]))(ragioneSocialeListPvi))
                            ? setSelectedPVI(
                                R.uniq(
                                  R.difference(
                                    filters.pvis || [],
                                    R.map(R.path(["id"]))(ragioneSocialeListPvi)
                                  )
                                )
                              )
                            : setSelectedPVI(
                                R.uniq(
                                  R.concat(
                                    R.map(R.path(["id"]))(
                                      ragioneSocialeListPvi
                                    ),
                                    filters.pvis || []
                                  )
                                )
                              );
                        }}
                      />
                    </label>
                  }
                  content={
                    <CheckboxList
                      filters={filters}
                      ragioneSocialeListPvi={ragioneSocialeListPvi}
                      setSelectedPVI={setSelectedPVI}
                    ></CheckboxList>
                  }
                />
              ) : (
                <></>
              )
            )}
            {R.pipe<any, any>(
              R.filter((x: any) =>
                R.contains(
                  R.toLower(filter),
                  R.toLower(R.path(["ragioneSociale", "ragioneSociale"])(
                    x
                  ) as string)
                )
              )
            )(pviData).length === 0 && filter !== "" ? (
              <div className={styles.pviFilter}>
                Nessuna Ragione Sociale disponibile con questo filtro
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className="form-label">Curve</div>
          <div className="d-flex flex-column">
            <div className="form-group">
              <CheckBox
                label={getCurveName("settlement")}
                name="settlement"
                className="curveCheckBox"
                selected={filters.curves}
                update={setSelectedCurves}
              />
              <CheckBox
                label={getCurveName("unbalance")}
                name="unbalance"
                className="curveCheckBox"
                selected={filters.curves}
                update={setSelectedCurves}
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="horizontalRule" />
      <div className="spacer"></div>
      <label className="form-label">Seleziona Periodo</label>
      <div className="d-flex align-items-center">
        <DateRange
          startDate={
            R.equals(null, filters.period.from)
              ? null
              : new Date(filters.period.from as any)
          }
          onStartDateChange={(e: Date) => {
            return setPeriod({
              ...filters.period,
              from: R.equals(null, e) ? null : format(e, "yyyy-MM-dd"),
            });
          }}
          endDate={
            R.equals(null, filters.period.to)
              ? null
              : new Date(filters.period.to as any)
          }
          onEndDateChange={(e: Date) =>
            setPeriod({
              ...filters.period,
              to: R.equals(null, e) ? null : format(e, "yyyy-MM-dd"),
            })
          }
        />
        <div className="form-check mx-3 checkbox-inline-margin">
          <RadioBtn
            checked={R.equals(filters.granularity, "daily")}
            label="Daily"
            name="daily"
            update={setGranularity}
          />
        </div>
        <div className="form-check mx-3 checkbox-inline-margin">
          <RadioBtn
            checked={R.equals(filters.granularity, "hourly")}
            label="Hourly"
            name="hourly"
            update={setGranularity}
          />
        </div>
      </div>
    </>
  );
};

const getPviName = ({ id, list }: { id: string; list: any }) =>
  R.pipe<any, any, any, any>(
    R.map(R.prop("pvi")),
    R.filter(R.propEq("pvi", id)),
    R.pathOr(id, [0, "nome"])
  )(list);

type SettlementTableType = {
  data: any;
  pvi: PVIType;
  ragioneSociale: any;
  exportData: () => void;
};
const SettlementTable = ({
  data,
  pvi,
  exportData,
  ragioneSociale,
}: SettlementTableType) => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 20;

  const tableData = R.ifElse(
    R.isEmpty,
    () => [],
    R.map((plant: any) => {
      const partitaIva = plant.partitaIva;
      plant = plant.pvi;
      return {
        plant,
        ragioneSociale: R.find(R.propEq("partitaIva", partitaIva))(
          ragioneSociale.data
        ).ragioneSociale,
        unbalance: getCurveData({ data, plant, curve: "unbalance" }),
        settlement: getCurveData({ data, plant, curve: "settlement" }),
        spread: getCurveData({ data, plant, curve: "spread" }),
        net: getCurveData({ data, plant, curve: "net" }),
      };
    })
  )(getAllPlantsNew(data));

  const tData = (d: any) =>
    R.pipe(
      R.drop((page - 1) * pageSize),
      R.take(pageSize)
    )(d);

  const cellVal = (val: any) => (R.isNil(val) ? null : `${val.toFixed(2)}€`);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <button
          className={`btn btn-danger rounded-pill mx-2 ${
            R.isEmpty(data) || R.isNil(data) ? "disabled" : ""
          }`}
          onClick={() =>
            R.unless(R.equals(true), () => exportData())(
              R.isEmpty(data) || R.isNil(data)
            )
          }
        >
          Esporta il file XLSX
        </button>
      </div>
      <div className="spacer"></div>
      <div className="tableContainer auditList">
        <div className="scroll">
          <table className="table">
            <thead>
              <tr>
                <th>Ragione Sociale</th>
                <th>Impianto</th>
                <th>Sbilancio</th>
                <th>Power</th>
                <th>Fee</th>
                <th>Netto</th>
              </tr>
            </thead>
            <tbody>
              {R.isEmpty(tableData) || R.isNil(tableData) ? (
                <NoData cols={4} />
              ) : (
                tData(tableData).map((row: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{R.pathOr(null, ["ragioneSociale"], row)}</td>
                      <td>{getPviName({ id: row.plant, list: pvi.data })}</td>
                      <td>{cellVal(R.pathOr(null, ["unbalance"], row))}</td>
                      <td>{cellVal(R.pathOr(null, ["settlement"], row))}</td>
                      <td>{cellVal(R.pathOr(null, ["spread"], row))}</td>
                      <td>{cellVal(R.pathOr(null, ["net"], row))}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="pagingContainer">
          <Pagination
            count={tableData.length}
            pageSize={pageSize}
            page={page}
            onPageChange={setPage}
          />
        </div>
      </div>
    </>
  );
};

type SettlementChartType = {
  data: any;
  filters: FiltersType;
};
const SettlementChart = ({ data, filters }: SettlementChartType) => {
  const opts = {
    ...options,
    series: getSettlementSeries({
      data,
      granularity: filters.granularity,
      curves: filters.curves,
    }),
    tooltip: {
      ...options.tooltip,
      xDateFormat: R.equals(filters.granularity, "daily")
        ? "%b %d, %Y"
        : "%b %d, %Y %H:%M",
    },
  } as any;

  return (
    <div className="chart">
      <div className="alignRight">
        <div
          title="Seleziona la parte del grafico che ti
          interessa &#10;con il mouse per ingrandirla 
          e visualizzarne &#10;i dettagli."
          className="infoHover"
        >
          Zoom
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={opts} />
    </div>
  );
};
const options: Highcharts.Options = {
  colors: ["#e10019", "#761613", "#cfa2a7", "#ed6675", "#9e9294", "#000000"],
  chart: {
    zoomType: "x",
    type: "spline",
    style: {
      fontFamily: "Avenir-Roman",
    },
  },
  title: {
    text: "",
  },
  tooltip: {
    shared: true,
    valueDecimals: 2,
    valueSuffix: "€",
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    title: {
      text: "",
    },
    type: "datetime",
    dateTimeLabelFormats: {
      day: { main: "%b %d, %Y" },
      hour: { main: "%H:%M" },
    },
  },
  yAxis: {
    opposite: true,
    title: {
      text: " €",
    },
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
  },
  legend: {
    align: "left",
    verticalAlign: "top",
  },
  lang: {
    noData: "Nessun Dato Disponibile",
  },
  time: {
    useUTC: false,
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#303030",
    },
  },
};
