import React, { useRef } from "react";
import * as R from "ramda";
import { formatBytes, removeFile } from "./helper";
import Logo from "../../styles/assets/fileUpload.png";

type DrapDropFileUpload = {
  fileList: any[];
  update: (files: any) => any;
  validFileTypes?: string;
  multipleFiles?: boolean;
  upload: (x: any[]) => void;
  disabled?: boolean;
};

const DrapDropFileUpload = ({
  fileList,
  update,
  validFileTypes,
  multipleFiles = false,
  upload,
  disabled = false
}: DrapDropFileUpload) => {
  const fileInput = useRef<any>(null);
  const updateFileList = (uploadFiles: any) => {
    let allExistingFiles = R.map(R.prop("name"), fileList) as any;
    return R.pipe<any, any, any, any, any>(
      R.unless(
        () => R.isNil(validFileTypes),
        R.filter((file: any) => R.equals(file.type, validFileTypes))
      ),
      R.filter((file: any) =>
        multipleFiles ? !R.contains(file.name, allExistingFiles) : file
      ),
      x => (multipleFiles ? R.concat(R.__, fileList)(x) : [R.head(x)]),
      update
    )(uploadFiles);
  };

  return (
    <>
      <div
        className="container d-flex p-5 border mb-5"
        onDrop={e => {
          e.preventDefault();
          updateFileList(e.dataTransfer.files);
        }}
        onDragOver={(e: any) => e.preventDefault()}
      >
        {R.isEmpty(fileList) ? (
          <>
            <img className="uploadLogo" src={Logo} alt="Axpo Logo"></img>
            <div>
              <div className="text-uppercase">
                Trascina Qui Il File Excel Compilato
              </div>
              <div className="text-muted">oppure</div>
              <a
                className="clickableLink"
                onClick={() => fileInput.current.click()}
              >
                Carica il file
              </a>
              <input
                type="file"
                accept={validFileTypes}
                ref={fileInput}
                className="d-none"
                multiple
                onChange={(e: any) => updateFileList(e.target.files)}
              />
            </div>
          </>
        ) : (
          <>
            <img className="uploadLogo" src={Logo} alt="Axpo Logo"></img>{" "}
            <div className="dragFileContainer">
              <table className="dragFileTable">
                <tbody>
                  {fileList.map((file: any, i: number) => (
                    <tr className="file" key={i}>
                      <td>
                        {`${file.name || ""} (${formatBytes(file.size)})`}{" "}
                      </td>
                      <td
                        onClick={() => removeFile({ file, fileList, update })}
                      >
                        {" "}
                        <i className="fas fa-times clickableIcon" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                <a
                  className="clickableLink"
                  onClick={() => fileInput.current.click()}
                >
                  Carica il file
                </a>
                <input
                  type="file"
                  accept={validFileTypes}
                  ref={fileInput}
                  className="d-none"
                  multiple
                  onChange={(e: any) => updateFileList(e.target.files)}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <button
        className="btn btn-danger rounded-pill"
        onClick={() => upload(fileList)}
        disabled={fileList.length <= 0 || disabled}
      >
        Invia il file caricato
      </button>
    </>
  );
};

export default DrapDropFileUpload;
