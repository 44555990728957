import * as R from "ramda";
import { stringCell, numberCell, styleFormat } from "./helper";

const excelHeader = ({ sheet }: { sheet: any }) => {
  sheet.row(1).freeze();
  sheet
    .cell(1, 1)
    .string("PVI")
    .style(styleFormat);
  sheet
    .cell(1, 2)
    .string("MercatiBidding")
    .style(styleFormat);
  sheet
    .cell(1, 3)
    .string("DatetimeOffset")
    .style(styleFormat);
  sheet
    .cell(1, 4)
    .string("Q_GR1 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 5)
    .string("P_GR1 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 6)
    .string("Q_GR2 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 7)
    .string("P_GR2 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 8)
    .string("Q_GR3 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 9)
    .string("P_GR3 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 10)
    .string("Q_GR4 [MWh]")
    .style(styleFormat);
  sheet
    .cell(1, 11)
    .string("P_GR4 [MWh]")
    .style(styleFormat);

  return sheet;
};

const excelBody = ({ sheet, data }: { sheet: any; data: any }) => {
  data.records.map((rowData: any, ri: number) => {
    const info = { sheet, rowData, rowIndex: R.add(ri, 2) };
    stringCell({
      ...info,
      colIndex: 1,
      rowData: data,
      path: ["pvi"]
    });
    stringCell({
      ...info,
      colIndex: 2,
      rowData: data,
      path: ["mercatiBidding"]
    });
    stringCell({ ...info, colIndex: 3, path: ["dateTimeOffset"] });
    numberCell({
      ...info,
      colIndex: 4,
      path: ["q_GR1"]
    });
    numberCell({
      ...info,
      colIndex: 5,
      path: ["p_GR1"]
    });
    numberCell({
      ...info,
      colIndex: 6,
      path: ["q_GR2"]
    });
    numberCell({
      ...info,
      colIndex: 7,
      path: ["p_GR2"]
    });
    numberCell({
      ...info,
      colIndex: 8,
      path: ["q_GR3"]
    });
    numberCell({
      ...info,
      colIndex: 9,
      path: ["p_GR3"]
    });
    numberCell({
      ...info,
      colIndex: 10,
      path: ["q_GR4"]
    });
    numberCell({
      ...info,
      colIndex: 11,
      path: ["p_GR4"]
    });
    return true;
  });
  return sheet;
};

type DisponibilitaCapacitaMassimaType = { sheet: any; data: any };

export const disponibilitaCapacitaMassima = ({
  sheet,
  data
}: DisponibilitaCapacitaMassimaType) => {
  excelHeader({ sheet });
  excelBody({ sheet, data });
  return sheet;
};

export default disponibilitaCapacitaMassima;
