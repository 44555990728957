import React, { useEffect } from "react";
import * as R from "ramda";
import { useLocation } from "react-router-dom";

export const PageOnLoad = ({ customPath }: { customPath?: string }) => {
  let location = useLocation();
  useEffect(() => {
    const path = R.isNil(customPath)
      ? location.pathname
      : `${location.pathname}/${customPath}`;
    pageChangeTGM(path);
  }, []);

  return <></>;
};

export default PageOnLoad;

declare global {
  interface Window {
    dataLayer: any;
  }
}

const pageChangeTGM = (page: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: "XchangePageview", XchangePage: page });
};
