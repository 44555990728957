import { IO, io } from "fp-ts/lib/IO";
import * as A from "fp-ts/lib/Array";

const arrayTraverse = A.array.traverse(io);
const arraySequence = A.array.sequence(io);

export const cleanUpLegacyStorage = arraySequence([
  arrayTraverse(["ai_session", "ai_user"], cleanCookie),
  arrayTraverse(["user"], cleanLocalStorageItem)
]);

function cleanCookie(key: string): IO<void> {
  return () => {
    document.cookie = `${key}=0;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  };
}
function cleanLocalStorageItem(key: string): IO<void> {
  return () => localStorage.removeItem(key);
}
