import React from "react";
import Accordion from "../components/accordion/accordion";
import {
  DisponibilitaCapacitaMassimaUploadOutput,
  UploadStatus,
} from "../redux/modules/ragioneSocialePvi/types";
import "./UploadStatusComponent.css";
import { da } from "date-fns/locale";

type Props = {
  data: DisponibilitaCapacitaMassimaUploadOutput[];
  result: string | undefined;
};

const UploadStatusComponent: React.FC<Props> = ({ data, result }) => {
  const successCount = data.filter(
    (item) => item.status === UploadStatus.Success
  ).length;
  const errorCount = data.filter((item) => item.status === UploadStatus.Error)
    .length;

  console.log(`Number of successes: ${successCount}`);
  console.log(`Number of errors: ${errorCount}`);
  const allSuccess = data.every((item) => item.status === UploadStatus.Success);
  console.log(data);
  if (allSuccess && result === "success") {
    return <div>Upload Success!</div>;
  } else if (data.length === 0) {
    return <div>Upload Disponibilita failed</div>;
  }
  const processStatusDetail = (statusDetail: string) => {
    if (statusDetail === undefined || statusDetail === null) {
      return "";
    }
    if (statusDetail.toLowerCase().includes("partitaiva")) {
      return "PVI not found. Ensure that the PVI is correct and try again.";
    }
    return statusDetail;
  };
  const title =
    result === "error" ? "Upload Failed" : "Upload Completed with Errors";
  return (
    <div>
      <div>{title}</div>
      <Accordion
        title="Error Details"
        content={
          <table className="table">
            <thead>
              <tr>
                <th className="table-cell">PVI</th>
                <th className="table-cell">Status</th>
                <th className="table-cell">ErrorMessage</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={
                    item.status === UploadStatus.Error ? "error-row" : ""
                  }
                >
                  <td className="table-cell">{item.pvi}</td>
                  <td className="table-cell">{item.status}</td>
                  <td className="table-cell">
                    {processStatusDetail(item.statusDetail)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      />
    </div>
  );
};

export default UploadStatusComponent;
