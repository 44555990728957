import React from "react";

type ToggleSwitchTypes = {
  value: any;
  update: (a: boolean) => any;
  disabled?: boolean;
};

const ToggleSwitch = ({
  value = false,
  update,
  disabled = false,
}: ToggleSwitchTypes) => (
  <label className={`switch`}>
    <input
      type="checkbox"
      className={`switchInput ${value ? "" : ""}`}
      checked={value}
      disabled={disabled}
      onChange={() => update(!value)}
    />
    <div className={`slider ${disabled ? "disabled" : ""}`}></div>
  </label>
);
export default ToggleSwitch;
