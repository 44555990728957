import React, { useState, useEffect } from "react";
import * as R from "ramda";
import Logo from "../styles/assets/axpo-logo-transparent.png";
import main from "../styles/header.module.scss";
import { User } from "ark-auth/dist/types";
import UserProfile from "./userProfile";
import Menu from "./menu";
import SubMenu from "./subMenu";
import { withRouter } from "react-router";

export const Header = (props: { profile: User; location: any }) => {
  const locationPath = R.pipe<any, string, string[], any>(
    R.prop("pathname"),
    R.split("/"),
    R.nth(1)
  )(props.location);

  const [mainLocation, setMainLocation] = useState<string | null>(null);
  useEffect(() => {
    setMainLocation(locationPath);
  }, [locationPath]);

  return (
    <>
      <div className={main.axpoHeader}>
        <div className={main.spacer}></div>
        <div>
          <a
            className={main.axpoenergiaLink}
            rel="noopener noreferrer"
            href="https://axpoenergia.com/"
            target="_blank"
          >
            axpoenergia.com
          </a>
        </div>
      </div>
      <header className={main.header}>
        <div className={main.logoContainer}>
          <img className={main.logo} src={Logo} alt="Axpo Logo"></img>
        </div>
        <Menu locationPath={locationPath} />
        <div className="spacer" />
        <div className={main.logoContainer}>
          <UserProfile profile={props.profile} />
        </div>
      </header>
      <SubMenu mainLocation={mainLocation} />
    </>
  );
};

export default R.compose<any, any>(withRouter)(Header);
