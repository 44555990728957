import React, { useEffect, useRef, useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { format } from "date-fns";
import { Selectors as EnumsSelectors } from "../redux/modules/enums";
import "../styles/highcharts.module.scss";
import DateRange from "../components/dateRange";
import { NoData } from "../components/tableComponents/noData";
import Loader from "../components/loader";
import SingleSelect from "../components/singleSelectMain";
import { ragioneSocialeVal } from "./helper";
import { option } from "../components/singleSelect/helper";
import Pagination from "../components/tableComponents/paginationNew";
import {
  Selectors,
  RagioneSocialeType,
  firstLoadAction,
  clearAllDataAction,
  ragioneSocialeState,
  forecastDealState,
  ForecastDealStateType,
  ForecastDealFilterStateType,
  setForecastDealFiltersAction,
  setForecastDealPageAction,
  setForecastDealFilterSocialeAction,
  sendDealCaptureAction,
  dealCaptureState,
  DealCaptureType,
  closeDealCaptureAction,
  ContractType,
  contractState,
  closeContractAction,
  sendContractAction,
  uploadContractAction,
  uploadBulkContractAction
} from "../redux/modules/management/forecastDeal";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";
import { permissionCheck } from "../helper/permissions";
import permissions from "../permissions";

type ProduzioneType = {
  state: any;
  isFetching?: boolean;
  uploadFetching?: boolean;
  ragioneSociale?: RagioneSocialeType;
  forecastDeal?: ForecastDealStateType;
  dealCapture?: DealCaptureType;
  contract?: ContractType;
  firstLoadAction: () => void;
  clearAllDataAction: () => void;
  setForecastDealFiltersAction: (a: ForecastDealFilterStateType) => void;
  setForecastDealPageAction: (a: number) => void;
  setForecastDealFilterSocialeAction: (a: any) => void;
  sendDealCaptureAction: (a: any) => void;
  sendContractAction: (a: any) => void;
  closeDealCaptureAction: () => void;
  closeContractAction: () => void;
  uploadContractAction: (a: any) => void;
  uploadBulkContractAction: (a: any) => void;
};

const Produzione = ({
  state,
  isFetching = false,
  uploadFetching = false,
  ragioneSociale = ragioneSocialeState,
  forecastDeal = forecastDealState,
  dealCapture = dealCaptureState,
  contract = contractState,
  clearAllDataAction,
  firstLoadAction,
  setForecastDealFiltersAction,
  setForecastDealPageAction,
  setForecastDealFilterSocialeAction,
  sendDealCaptureAction,
  sendContractAction,
  closeDealCaptureAction,
  closeContractAction,
  uploadContractAction,
  uploadBulkContractAction
}: ProduzioneType) => {
  const fileBulkInput = useRef<any>(null);
  const havePermission = permissionCheck({
    state,
    permission: permissions.contrattoForecastVolumeWriteAdmin
  });

  useEffect(() => {
    firstLoadAction();
    return () => clearAllDataAction();
  }, []);

  return (
    <>
      <PageOnLoad />
      <div>
        <div className="pageContainer">
          <ProduzioneFilters
            ragioneSociale={ragioneSociale}
            filters={forecastDeal.filters}
            fileInput={fileBulkInput}
            update={setForecastDealFiltersAction}
            updateSociale={setForecastDealFilterSocialeAction}
          />
          <div className="spacer"></div>
          <Loader
            load={
              isFetching ||
              uploadFetching ||
              ragioneSociale.isFetching ||
              dealCapture.isFetching ||
              contract.isFetching ||
              forecastDeal.fetching
            }
          />
        </div>
        <ProduzioneTable
          havePermission={havePermission}
          info={forecastDeal}
          setPage={setForecastDealPageAction}
          sendDealCapture={sendDealCaptureAction}
          sendContract={sendContractAction}
          uploadContract={uploadContractAction}
        />
        <NotifcationModal
          open={dealCapture.open && !forecastDeal.fetching}
          close={closeDealCaptureAction}
          title="Processo Inserimento Contratti Risultato"
          message={<DealCaptureResult data={dealCapture.data} />}
        />
        <NotifcationModal
          open={contract.open && !forecastDeal.fetching}
          close={closeContractAction}
          title="Upload Successful"
          message="Genera VOL Upload Successful"
        />
      </div>
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={fileBulkInput}
        className="d-none"
        onChange={(e: any) => {
          e.preventDefault();
          uploadBulkContractAction({ file: e.target.files, forceUpdate: havePermission });
          e.target.value = "";
        }}
      />
    </>
  );
};

export default connect(
  (s: any) => ({ state: s, ...Selectors.all(s), enums: EnumsSelectors.enums(s) }),
  {
    firstLoadAction,
    clearAllDataAction,
    setForecastDealFiltersAction,
    setForecastDealPageAction,
    setForecastDealFilterSocialeAction,
    sendDealCaptureAction,
    sendContractAction,
    closeDealCaptureAction,
    closeContractAction,
    uploadContractAction,
    uploadBulkContractAction
  }
)(Produzione);

type ProduzioneFiltersType = {
  ragioneSociale: RagioneSocialeType;
  filters: ForecastDealFilterStateType;
  fileInput: any;
  update: (a: ForecastDealFilterStateType) => void;
  updateSociale: (a: any) => void;
};
const ProduzioneFilters = ({
  ragioneSociale,
  filters,
  fileInput,
  update,
  updateSociale,
}: ProduzioneFiltersType) => {
  useEffect(() => {
    R.when(R.equals(true), () =>
      updateSociale(ragioneSociale.data[0].partitaIva)
    )(R.isNil(filters.ragioneSociale) && !R.isEmpty(ragioneSociale.data));
  }, [ragioneSociale.data]);

  const updateFilters = ({ key, val }: { key: string; val: any }) =>
    update({ ...filters, [key]: val });

  return (
    <>
      <div className="container noPadding">
          <div className="d-flex">
            <div className="width100 d-flex justify-content-center align-items-center">
              <div className="auditFilters">
                <div className="filter regSocialeFilter">
                  <label className="form-label">Ragione Sociale</label>
                  <SingleSelect
                    containsFilter
                    value={ragioneSocialeVal({
                      filters: filters.ragioneSociale,
                      data: ragioneSociale.data,
                    })}
                    update={(x: option[]) =>
                      updateSociale(R.pathOr(null, [0, "value"], x))
                    }
                    options={R.map(
                      (x: any) => ({
                        value: x.partitaIva,
                        title: x.ragioneSociale,
                      }),
                      ragioneSociale.data
                    )}
                  />
                </div>
              </div>
            </div>
            <button
              className="btn btn-danger rounded-pill mx-2 forecastBulkBtn"
              onClick={() => fileInput.current.click()}
            >
              <span>
                <span>Bulk Upload</span>
                <span>
                  <i className="fas fa-upload" />
                </span>
              </span>
            </button>
          </div>
        {/* </div> */}
      </div>
      <div className="d-flex">
        <div className="width100 d-flex justify-content-center align-items-center">
          <div className="auditFilters">
            <div className="filter">
              <span className="form-label">Seleziona Periodo</span>
              <DateRange
                startDate={
                  R.equals(null, filters.period.from)
                    ? null
                    : new Date(filters.period.from as any)
                }
                onStartDateChange={(e: Date) => {
                  return updateFilters({
                    key: "period",
                    val: {
                      ...filters.period,
                      from: R.equals(null, e) ? null : format(e, "yyyy-MM-dd"),
                    },
                  });
                }}
                endDate={
                  R.equals(null, filters.period.to)
                    ? null
                    : new Date(filters.period.to as any)
                }
                onEndDateChange={(e: Date) =>
                  updateFilters({
                    key: "period",
                    val: {
                      ...filters.period,
                      to: R.equals(null, e) ? null : format(e, "yyyy-MM-dd"),
                    },
                  })
                }
              />
            </div>
            <div className="spacer" />
          </div>
        </div>
      </div>
    </>
  );
};

type ProduzioneTableType = {
  havePermission: boolean;
  info: ForecastDealStateType;
  setPage: (a: number) => void;
  sendDealCapture: (a: any) => void;
  sendContract: (a: any) => void;
  uploadContract: (a: any) => any;
};
const ProduzioneTable = ({
  havePermission,
  info,
  setPage,
  sendDealCapture,
  sendContract,
  uploadContract
}: ProduzioneTableType) => {
  const fileInput = useRef<any>(null);
  const [contractId, setContractId] = useState<string | null>(null);
  return (
    <>
      <div className="tableContainer">
        <div className="scroll">
          <table className="table">
            <thead>
              <tr>
                <th>Ragione Sociale</th>
                <th>Impianto</th>
                <th>Id SFS</th>
                <th>Data Inizio Contratto</th>
                <th>Data Fine Contratto</th>
                <th colSpan={2}>Volume</th>
                <th colSpan={2}>Volume Internal</th>
                <th colSpan={2}>Volume Perdite</th>
                <th colSpan={2}>Spread</th>
                <th colSpan={2}>Sbilancio</th>
                <th colSpan={2}>CCT</th>
              </tr>
            </thead>
            <tbody>
              {R.isEmpty(info.data) || R.isNil(info.data) ? (
                <NoData cols={17} />
              ) : (
                info.data.map((row: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>
                        {R.pathOr(
                          null,
                          ["ragioneSociale", "ragioneSociale"],
                          row
                        )}
                      </td>
                      <td>{R.pathOr(null, ["pvi", "nome"], row)}</td>
                      <td>{R.pathOr(null, ["contratto", "codiceCRM"], row)}</td>
                      <td>
                        {R.isNil(row.contratto.dataInizioContratto)
                          ? null
                          : format(
                              new Date(row.contratto.dataInizioContratto),
                              "dd-MM-yyyy"
                            )}
                      </td>
                      <td>
                        {R.isNil(row.contratto.dataFineContratto)
                          ? null
                          : format(
                              new Date(row.contratto.dataFineContratto),
                              "dd-MM-yyyy"
                            )}
                      </td>
                      <TdStatus row={row} type="volume" />
                      <TdStatus row={row} type="volumeInternal" />
                      <TdStatus row={row} type="volumePerdite" />
                      <TdStatus row={row} type="spread" />
                      <TdStatus row={row} type="feeSbilancio" />
                      <TdStatus row={row} type="cct" />
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="pagingContainer">
          <Pagination
            count={info.count}
            pageSize={info.pageSize}
            page={info.page}
            onPageChange={setPage}
          />
        </div>
      </div>
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={fileInput}
        className="d-none"
        onChange={(e: any) => {
          e.preventDefault();
          uploadContract({
            file: e.target.files,
            contractId,
            forceUpdate: havePermission,
          });
          e.target.value = "";
        }}
      />
    </>
  );
};

type TdStatusType = { row: any; type: string };
const TdStatus = ({ row, type }: TdStatusType) => {
  const status = R.pathOr(null, ["dealType", type, "check", "status"], row);
  const state = R.pathOr(
    null,
    ["dealType", type, "process", "dealCaptureProcessRun", "state"],
    row
  );
  const isRunning = R.pathOr(
    false,
    ["dealType", type, "process", "isRunning"],
    row
  );
  const processRunState = R.pathOr(
    null,
    ["dealType", type, "process", "dealCaptureProcessRun", "state "],
    row
  );

  const cell = cellClass({ status, isRunning, processRunState });

  return (
    <>
      <td className={`contrattiCell ${cell}`}>{statusName(status)}</td>
      <td className={`contrattiCell ${cell}`}>{processRunName(state)}</td>
    </>
  );
};

type StatusType =
  | "NA"
  | "NoMeasures"
  | "ToBeSent"
  | "ContractNotAligned"
  | "Aligned"
  | null;

type ProcessRunStateType =
  | "LoadingInEnrichment"
  | "LoadedInEnrichment"
  | "StagingInEnrichment"
  | "Error"
  | "SentToElviz"
  | "RemovedInEnrichment"
  | null;

type CellClassType = {
  status: StatusType;
  isRunning: boolean;
  processRunState: ProcessRunStateType;
};

const cellClass = ({ status, isRunning, processRunState }: CellClassType) => {
  if (R.contains(status, ["NA"])) {
    return "blackCell";
  }

  if (R.contains(status, ["Aligned"])) {
    return "greenCell";
  }

  if (
    R.contains(status, ["NoMeasures", "ToBeSent", "ContractNotAligned"]) &&
    isRunning
  ) {
    return "yellowCell";
  }

  if (
    R.contains(status, ["NoMeasures", "ToBeSent", "ContractNotAligned"]) &&
    R.contains(processRunState, ["Error"])
  ) {
    return "redCell";
  }

  if (
    R.contains(status, ["NoMeasures", "ContractNotAligned"]) &&
    !isRunning &&
    R.isNil(processRunState)
  ) {
    return "orangeCell";
  }

  return "";
};

const DealCaptureResult = (data: any) => {
  return (
    <div className="modal-body-margin">
      <div className="table">
        <div className="scroll">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Deal Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <DealCaptureResultRow type="volume" data={data} />
              <DealCaptureResultRow type="volumeInternal" data={data} />
              <DealCaptureResultRow type="volumePerdite" data={data} />
              <DealCaptureResultRow type="spread" data={data} />
              <DealCaptureResultRow type="feeSbilancio" data={data} />
              <DealCaptureResultRow type="cct" data={data} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const DealCaptureResultRow = ({ type, data }: { type: string; data: any }) => {
  const status = (type: string) =>
    R.pipe<any, any, any>(
      R.filter(R.propEq("dealType", type)),
      R.path([0, "status"])
    )(data.data);
  return (
    <tr>
      <th>{dealTypes(type)}</th>
      <th>
        <i
          className={`fa fa-${status(type) ? "check" : "times"}-circle ${
            status(type) ? "add" : "remove"
          }`}
        />
      </th>
    </tr>
  );
};

const dealTypes = (type: string) => {
  switch (type) {
    case "volume":
      return "Volume";
    case "volumeInternal":
      return "Volume Internal";
    case "volumePerdite":
      return "Volume Perdite";
    case "spread":
      return "Spread";
    case "feeSbilancio":
      return "Fee Sbilancio";
    case "cct":
      return "CCT";
    default:
      return "";
  }
};

const statusName = (type: StatusType) => {
  switch (type) {
    case "NA":
      return "NA";
    case "NoMeasures":
      return "No Measures";
    case "ToBeSent":
      return "To Be Sent";
    case "ContractNotAligned":
      return "Contract Not Aligned";
    case "Aligned":
      return "Aligned";
    default:
      return null;
  }
};
const processRunName = (type: ProcessRunStateType) => {
  switch (type) {
    case "LoadingInEnrichment":
      return "Loading In Enrichment";
    case "LoadedInEnrichment":
      return "Loaded In Enrichment";
    case "StagingInEnrichment":
      return "Staging In Enrichment";
    case "Error":
      return "Error";
    case "SentToElviz":
      return "Sent To Elviz";
    case "RemovedInEnrichment":
      return "Removed In Enrichment";
    default:
      return null;
  }
};
