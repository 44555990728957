import xl from "excel4node";
import { createHeaders } from "./header";
import { createData } from "./bodyData";

type CreateExcelType = { fileName: string; data: any };

export const createComputedExcel = ({ fileName, data }: CreateExcelType) => {
  var wb = new xl.Workbook();
  var sheet = wb.addWorksheet("CoefficientiUP");
  createHeaders({ sheet, data });
  createData({ sheet, data });

  return wb.writeToBuffer().then(function(buffer: any) {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([buffer], { type: "application/octet-stream" })
    );
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  });
};
