import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, defer, throwError, of, EMPTY } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { MappingTools } from "ark-tools";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import { eitherSanctuary } from "./helper";
import {
  modHelperFunc,
  numberCheck,
  dateCheck,
  nullNames,
  anyNull
} from "./helperts";
import {
  actionTypes as ErrorActions,
  setErrorAction,
  dispatchNetworkError
} from "../errorHandler";
import { createExcel, infoType } from "./excel";
import { formJson } from "./upload";

const {
  buildRequest,
  removeModification,
  deleteItem,
  commitModification
} = MappingTools;

export const key = "fuel";
export const pks = ["fuelType"];
export const nullArr = ["fuelType", "unit", "category"];
export const propCols: infoType[] = [
  {
    propName: "fuelType",
    colName: "Fuel Type",
    cell: "enum",
    enumName: "FuelType"
  },
  { propName: "unit", colName: "Unit", cell: "enum", enumName: "Unit" },
  { propName: "name", colName: "Name", cell: "string" },
  {
    propName: "category",
    colName: "Category",
    cell: "enum",
    enumName: "Category"
  },
  {
    propName: "description",
    colName: "Description",
    cell: "string"
  }
];
const errMsg = "The mappings you have inserted is already present";

export enum actionTypes {
  EMPTY_DATA = "rtm/config/fuel/EMPTY_DATA",
  FETCHING = "rtm/config/fuel/FETCHING",
  RECIEVED = "rtm/config/fuel/RECIEVED",
  CANCEL = "rtm/config/fuel/CANCEL",
  CLEAR_ALL_STORED_DATA = "rtm/config/fuel/CLEAR_ALL_STORED_DATA",
  RECIEVED_DATA = "rtm/config/fuel/RECIEVED_DATA",
  OPEN_EDIT_MODAL = "rtm/config/fuel/OPEN_EDIT_MODAL",
  CANCEL_EDIT_MODAL = "rtm/config/fuel/CANCEL_EDIT_MODAL",
  COMMIT_CHANGES = "rtm/config/fuel/COMMIT_CHANGES",
  UPDATE_MODAL_ROW = "rtm/config/fuel/UPDATE_MODAL_ROW",
  REMOVE_MODIFIFCATION = "rtm/config/fuel/REMOVE_MODIFIFCATION",
  DELETE_ROW = "rtm/config/fuel/DELETE_ROW",
  APPLY_MAPPINGS_MODAL_CLOSE = "rtm/config/fuel/APPLY_MAPPINGS_MODAL_CLOSE",
  APPLY_MAPPINGS_MODAL_OPEN = "rtm/config/fuel/APPLY_MAPPINGS_MODAL_OPEN",
  SET_COMMIT_CHANGES = "rtm/config/fuel/SET_COMMIT_CHANGES",
  EXPORT_DATA = "rtm/config/fuel/EXPORT_DATA",
  EXPORT_DATA_COMPLETE = "rtm/config/fuel/EXPORT_DATA_COMPLETE",
  UPLOAD_DATA = "rtm/config/fuel/UPLOAD_DATA",
  UPLOAD_DATA_SUCCEEDED = "rtm/config/fuel/UPLOAD_DATA_SUCCEEDED",
  UPLOAD_DATA_SUCCEEDED_CLOSE = "rtm/config/fuel/UPLOAD_DATA_SUCCEEDED_CLOSE",
  APPLY_CHANGES = "rtm/config/fuel/APPLY_CHANGES"
}

type Actions =
  | { type: actionTypes.EMPTY_DATA }
  | { type: actionTypes.FETCHING }
  | { type: actionTypes.RECIEVED }
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.CLEAR_ALL_STORED_DATA }
  | {
      type: actionTypes.RECIEVED_DATA;
      table: any;
      eTag: string | null;
    }
  | {
      type: actionTypes.OPEN_EDIT_MODAL;
      edit: any;
    }
  | { type: actionTypes.CANCEL_EDIT_MODAL }
  | { type: actionTypes.COMMIT_CHANGES; data: any }
  | { type: actionTypes.UPDATE_MODAL_ROW; row: any }
  | { type: actionTypes.REMOVE_MODIFIFCATION; data: any }
  | { type: actionTypes.DELETE_ROW; data: any }
  | { type: actionTypes.APPLY_MAPPINGS_MODAL_CLOSE }
  | { type: actionTypes.APPLY_MAPPINGS_MODAL_OPEN }
  | { type: actionTypes.SET_COMMIT_CHANGES; modalData: any; tableData: any }
  | { type: actionTypes.UPLOAD_DATA; file: any }
  | { type: actionTypes.UPLOAD_DATA_SUCCEEDED }
  | { type: actionTypes.UPLOAD_DATA_SUCCEEDED_CLOSE }
  | { type: actionTypes.EXPORT_DATA }
  | { type: actionTypes.EXPORT_DATA_COMPLETE }
  | { type: actionTypes.APPLY_CHANGES };

const eTagReducer = (state: any = null, action: Actions) => {
  switch (action.type) {
    case actionTypes.RECIEVED_DATA:
      return action.eTag;
    case actionTypes.CLEAR_ALL_STORED_DATA:
    case actionTypes.EMPTY_DATA:
      return null;
    default:
      return state;
  }
};

const dataReducer = (state: any = [], action: Actions) => {
  switch (action.type) {
    case actionTypes.RECIEVED_DATA:
      return action.table;
    case actionTypes.COMMIT_CHANGES:
    case actionTypes.REMOVE_MODIFIFCATION:
    case actionTypes.DELETE_ROW:
      return action.data;
    case actionTypes.CLEAR_ALL_STORED_DATA:
    case actionTypes.EMPTY_DATA:
      return [];
    default:
      return state;
  }
};

const modalInitialState = {
  type: null,
  open: false,
  row: {}
};

type ModalInitialType = {
  type: string | null;
  open: boolean;
  row: any;
};

function modalReducer(
  state: ModalInitialType = modalInitialState,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.OPEN_EDIT_MODAL:
      return { ...action.edit, orig: action.edit.row, open: true };
    case actionTypes.UPDATE_MODAL_ROW:
      return { ...state, row: action.row };
    case actionTypes.CANCEL_EDIT_MODAL:
    case actionTypes.COMMIT_CHANGES:
      return modalInitialState;
    default:
      return state;
  }
}

function applyMappingsModalReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.APPLY_MAPPINGS_MODAL_CLOSE:
    case actionTypes.APPLY_CHANGES:
    case actionTypes.RECIEVED_DATA:
    case actionTypes.RECIEVED:
      return false;
    case actionTypes.APPLY_MAPPINGS_MODAL_OPEN:
      return true;
    default:
      return state;
  }
}

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.RECIEVED_DATA:
    case actionTypes.RECIEVED:
    case actionTypes.CANCEL:
    case actionTypes.EXPORT_DATA_COMPLETE:
    case actionTypes.UPLOAD_DATA_SUCCEEDED:
      return false;
    case actionTypes.FETCHING:
    case actionTypes.APPLY_CHANGES:
    case actionTypes.EXPORT_DATA:
    case actionTypes.UPLOAD_DATA:
      return true;
    default:
      return state;
  }
}

function uploadSucceedReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.UPLOAD_DATA_SUCCEEDED_CLOSE:
      return false;
    case actionTypes.UPLOAD_DATA_SUCCEEDED:
      return true;
    default:
      return state;
  }
}

export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  data: dataReducer,
  eTag: eTagReducer,
  modal: modalReducer,
  applyMappingsModal: applyMappingsModalReducer,
  uploadSucceed: uploadSucceedReducer
});

export const requestDataAction = (): Actions => ({
  type: actionTypes.FETCHING
});
const cancel = (): Actions => ({ type: actionTypes.CANCEL });
export const clearAllStoredDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA
});

type ReceivedCrudTableType = {
  table: any;
  eTag: string;
};
const receivedCrudTable = ({ table, eTag }: ReceivedCrudTableType) => ({
  type: actionTypes.RECIEVED_DATA,
  table,
  eTag
});

const requestDataEpic = (
  action$: Observable<Actions>,
  _state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      action =>
        action.type === actionTypes.FETCHING ||
        action.type === actionTypes.UPLOAD_DATA_SUCCEEDED
    ),
    RxOp.switchMap(() =>
      defer(
        deps.request.get(
          `configuration/fuel`,
          t.type({
            eTag: t.string,
            table: t.any
          })
        )
      )
    ),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, receivedCrudTable))
  );

export const openModalAction = (edit: any): Actions => ({
  type: actionTypes.OPEN_EDIT_MODAL,
  edit
});
export const closeModalAction = (): Actions => ({
  type: actionTypes.CANCEL_EDIT_MODAL
});

export const updateModalRowAction = (row: any): Actions => ({
  type: actionTypes.UPDATE_MODAL_ROW,
  row
});

export const closeMappingsModalAction = (): Actions => ({
  type: actionTypes.APPLY_MAPPINGS_MODAL_CLOSE
});
export const openMappingsModalAction = (): Actions => ({
  type: actionTypes.APPLY_MAPPINGS_MODAL_OPEN
});

export type DeleteRowSendType = {
  row: any;
  data: any;
};
export const deleteRowAction = ({ row, data }: DeleteRowSendType): Actions => ({
  type: actionTypes.REMOVE_MODIFIFCATION,
  data: deleteItem({
    data,
    row,
    pks
  })
});

export type CancelChangesType = {
  row: any;
  data: any;
};
export const cancelModifictionAction = ({
  row,
  data
}: CancelChangesType): Actions => ({
  type: actionTypes.REMOVE_MODIFIFCATION,
  data: removeModification({
    data,
    row,
    pks
  })
});

const commitChanges = (data: any): Actions => ({
  type: actionTypes.COMMIT_CHANGES,
  data
});

export type CommitChangesType = {
  modalData: any;
  tableData: any;
};
export const commitChangesAction = ({
  modalData,
  tableData
}: CommitChangesType) => ({
  type: actionTypes.SET_COMMIT_CHANGES,
  modalData,
  tableData
});

const commitChangesEpic = (
  action$: Observable<Actions>,
  _state$: Observable<any>,
  _deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(action => action.type === actionTypes.SET_COMMIT_CHANGES),
    RxOp.map((action: any) => {
      return R.pipe(
        commitModification,
        val =>
          eitherSanctuary({
            val,
            fail: (err: any) =>
              setErrorAction({
                details: {
                  title: "Mapping blocked",
                  message: err.join(", ")
                }
              }),
            pass: commitChanges
          })
      )({
        pks,
        errMsg,
        modalData: action.modalData,
        tableData: action.tableData
      });
    })
  );

export const applyChangesAction = (): Actions => ({
  type: actionTypes.APPLY_CHANGES
});

const applyChangesEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.APPLY_CHANGES),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state: any) =>
          defer(
            deps.request.put(
              `configuration/fuel`,
              buildRequest({
                data: state.config.fuel.data,
                eTag: state.config.fuel.eTag,
                type: "fuel"
              }) as any,
              t.any
            )
          )
        ),
        RxOp.map(E.fold<any, any, any>(dispatchNetworkError, requestDataAction))
      )
    )
  );

export const exportDataAction = (): Actions => ({
  type: actionTypes.EXPORT_DATA
});

const exportDataDownloaded = (): Actions => ({
  type: actionTypes.EXPORT_DATA_COMPLETE
});

const exportDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_DATA),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state: any) => {
          return createExcel({
            fileName: "Fuel",
            sheetName: "Fuel",
            data: R.pathOr(null, ["config", key, "data"], state),
            enums: R.pathOr(null, ["enums", "enumData"], state),
            info: propCols
          });
        }),
        RxOp.map(exportDataDownloaded)
      )
    )
  );

export const uploadDataAction = (file: any): Actions => ({
  type: actionTypes.UPLOAD_DATA,
  file
});
const uploadSucceededAction = (): Actions => ({
  type: actionTypes.UPLOAD_DATA_SUCCEEDED
});
export const uploadSucceededCloseAction = (): Actions => ({
  type: actionTypes.UPLOAD_DATA_SUCCEEDED_CLOSE
});

const uploadDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.UPLOAD_DATA),
    RxOp.switchMap((action: any) => {
      return R.isNil(action.file) ? EMPTY : of(action);
    }),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap(() =>
          formJson({
            file: action.file,
            info: propCols,
            sheetName: "Fuel"
          })
        ),
        RxOp.flatMap((x: any) => x),
        RxOp.switchMap((data: any) => {
          const headVals = R.pipe<any, any, any>(
            R.map(R.prop<any>("colName")),
            R.map(R.toLower)
          )(propCols);

          const headers = R.pipe<any, any, any, any>(
            R.values,
            R.map((r: string) => R.contains(R.toLower(r), headVals)),
            R.all(R.equals(true))
          )(data[0]);

          if (R.equals(headers, false)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "Missing header or incorrect"
            });
          }
          return of(R.remove(0, 1, data));
        }),
        RxOp.map(R.map(row => numberCheck({ row, info: propCols }))),
        RxOp.map(R.map(row => dateCheck({ row, info: propCols }))),
        RxOp.switchMap((data: any) => {
          const res = R.map((row: any) => anyNull({ row, nullArr }))(data);
          if (R.any(R.equals(true))(res)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: `${nullNames({
                list: nullArr,
                info: propCols
              })} can't be null`
            });
          }
          return of(data);
        }),
        RxOp.flatMap(dataArr => modHelperFunc({ dataArr, pks, errMsg })),
        RxOp.mergeMap(data =>
          state$.pipe(
            RxOp.first(),
            RxOp.map(R.path(["config", key])),
            RxOp.map((state: any) => ({ state, data }))
          )
        ),
        RxOp.switchMap(({ state, data }: { state: any; data: any }) => {
          return defer(
            deps.request.put(
              `configuration/fuel`,
              buildRequest({
                data,
                eTag: state.eTag,
                type: "fuel"
              }) as any,
              t.any
            )
          );
        }),
        RxOp.map(E.fold<any, any, any>(x => x, x => x)),
        RxOp.toArray(),
        RxOp.map((x: any) => {
          const anyFail = R.filter((r: any) => r.status >= 400, x);
          if (R.isEmpty(anyFail)) {
            return uploadSucceededAction();
          }
          return dispatchNetworkError(anyFail[0]);
        }),
        RxOp.catchError((err: any) =>
          of(err).pipe(RxOp.map(dispatchNetworkError))
        )
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  requestDataEpic,
  commitChangesEpic,
  applyChangesEpic,
  errorHandlerEpic,
  exportDataEpic,
  uploadDataEpic
);

export const Selectors = {
  all: R.path(["config", key])
};
