import * as t from "io-ts";

const fuelType = t.union([
  t.literal("ABL"),
  t.literal("ACF"),
  t.literal("ACG"),
  t.literal("ACS"),
  t.literal("BDA"),
  t.literal("BDF"),
  t.literal("BDG"),
  t.literal("BDR"),
  t.literal("BDS"),
  t.literal("BFR"),
  t.literal("BNZ"),
  t.literal("BRC"),
  t.literal("BRS"),
  t.literal("BSO"),
  t.literal("CDC"),
  t.literal("CDG"),
  t.literal("CDP"),
  t.literal("CDR"),
  t.literal("CER"),
  t.literal("CHE"),
  t.literal("CRB"),
  t.literal("CSB"),
  t.literal("CTC"),
  t.literal("GAO"),
  t.literal("GDA"),
  t.literal("GDC"),
  t.literal("GDE"),
  t.literal("GDO"),
  t.literal("GDR"),
  t.literal("GNA"),
  t.literal("GNI"),
  t.literal("GPG"),
  t.literal("GPL"),
  t.literal("GRP"),
  t.literal("GSL"),
  t.literal("GSS"),
  t.literal("HDR"),
  t.literal("INB"),
  t.literal("LGN"),
  t.literal("LIG"),
  t.literal("MDL"),
  t.literal("NFT"),
  t.literal("OBZ"),
  t.literal("OMZ"),
  t.literal("ORI"),
  t.literal("OSZ"),
  t.literal("OVG"),
  t.literal("PTG"),
  t.literal("RLB"),
  t.literal("RSU"),
  t.literal("TRB"),
]);
export const RagioneSociale = t.type({
  partitaIva: t.string,
  ragioneSociale: t.string,
  descrizione: t.string,
});
export type RagioneSociale = t.TypeOf<typeof RagioneSociale>;

export const PVI = t.type({
  pvi: t.string,
  // fuelType: fuelType,
  nome: t.string,
  rinnovabile: t.boolean,
  programmabile: t.boolean,
  codiceUp: t.string,
  fuelInfo: t.array(t.type({ pvi: t.string, fuelType: fuelType })),
  ragioneSociale: t.union([RagioneSociale, t.null, t.undefined]),
});
export type PVI = t.TypeOf<typeof PVI>;

export const RagioneSocialePVI = t.type({
  id: t.string,
  pvi: PVI,
  ragioneSociale: RagioneSociale,
});

export type RagioneSocialePVI = t.TypeOf<typeof RagioneSocialePVI>;

export const ContractInfo = t.type({
  partitaIva: t.string,
  pvi: t.string,
  upsa: t.union([t.undefined, t.null, t.boolean]),
  fornitura: t.array(
    t.type({
      caricamentoMGP: t.boolean,
      caricamentoMGPGradini: t.boolean,
      caricamentoMI: t.boolean,
      caricamentoMIGradini: t.boolean,
      caricamentoDisponibilita: t.boolean,
    })
  ),
  _Refs: t.type({
    pvi: t.type({
      codiceUpRilevante: t.union([t.undefined, t.null, t.string]),
      codiceUpNonRilevante: t.union([t.undefined, t.null, t.string]),
    }),
  }),
});
export type ContractInfo = t.TypeOf<typeof ContractInfo>;

export const MercatiBidding = t.union([
  t.literal("MGP"),
  t.literal("MI1"),
  t.literal("MI2"),
  t.literal("MI3"),
  t.literal("MI4"),
  t.literal("MI5"),
  t.literal("MI6"),
  t.literal("MI7"),
]);
export type MercatiBidding = t.TypeOf<typeof MercatiBidding>;

export const MisurePrezziBiddingPVIUploadData = t.type({
  dateTimeOffset: t.string,
  q_GR1: t.any,
  p_GR1: t.any,
  q_GR2: t.any,
  p_GR2: t.any,
  q_GR3: t.any,
  p_GR3: t.any,
  q_GR4: t.any,
  p_GR4: t.any,
});

export type MisurePrezziBiddingPVIUploadData = t.TypeOf<
  typeof MisurePrezziBiddingPVIUploadData
>;

export const MisurePrezziBiddingPVIUpload = t.type({
  partitaIVA: t.string,
  pvi: t.string,
  mercatiBidding: MercatiBidding,
  from: t.string,
  to: t.string,
  // uploadId: t.number,
  records: t.array(MisurePrezziBiddingPVIUploadData),
});

export type MisurePrezziBiddingPVIUpload = t.TypeOf<
  typeof MisurePrezziBiddingPVIUpload
>;

export const MisurePrezziBiddingPVITimeserieRecord = t.type({
  partitaIVA: t.string,
  pvi: t.string,
  mercatiBidding: MercatiBidding,
  // uploadId: t.number,
  dateTimeOffset: t.string,
  q_GR1: t.union([t.undefined, t.null, t.number]),
  p_GR1: t.union([t.undefined, t.null, t.number]),
  q_GR2: t.union([t.undefined, t.null, t.number]),
  p_GR2: t.union([t.undefined, t.null, t.number]),
  q_GR3: t.union([t.undefined, t.null, t.number]),
  p_GR3: t.union([t.undefined, t.null, t.number]),
  q_GR4: t.union([t.undefined, t.null, t.number]),
  p_GR4: t.union([t.undefined, t.null, t.number]),
});

export type MisurePrezziBiddingPVITimeserieRecord = t.TypeOf<
  typeof MisurePrezziBiddingPVITimeserieRecord
>;
export const MarketState = t.type({
  config: t.type({
    close: t.string,
    open: t.string,
    enabled: t.boolean,
    biddableFrom: t.string,
    isIntraDay: t.boolean,
    offsetD: t.number,
  }),
  mercatiBidding: MercatiBidding,
  isOpen: t.boolean,
  validDataEnd: t.union([t.undefined, t.null, t.string]),
  validDataStart: t.string,
});
export type MarketState = t.TypeOf<typeof MarketState>;

export const Market = t.type({
  time: t.string,
  marketStates: t.array(MarketState),
});

export type Market = t.TypeOf<typeof Market>;

export const DisponibilitaCapacitaMassimaTimeserieRecord = t.type({
  pvi: t.string,
  dateTimeOffset: t.string,
  disponibilita: t.union([t.undefined, t.null, t.number]),
  capacitaMassima: t.union([t.undefined, t.null, t.number]),
});

export const DisponibilitaRecord = t.type({
  dateTimeOffset: t.string,
  disponibilita: t.union([t.undefined, t.null, t.number]),
  capacitaMassima: t.union([t.undefined, t.null, t.number]),
});

export type DisponibilitaRecord = t.TypeOf<typeof DisponibilitaRecord>;

export type DisponibilitaCapacitaMassimaTimeserieRecord = t.TypeOf<
  typeof DisponibilitaCapacitaMassimaTimeserieRecord
>;

export const EsitoBiddingComputedPVI = t.type({
  pvi: t.string,
  dateTimeOffset: t.string,
  mgP_Q: t.number,
  mgP_P: t.number,
  mI1_Q: t.number,
  mI1_P: t.number,
  mI2_Q: t.number,
  mI2_P: t.number,
  mI3_Q: t.number,
  mI3_P: t.number,
  mI4_Q: t.number,
  mI4_P: t.number,
  mI5_Q: t.number,
  mI5_P: t.number,
  mI6_Q: t.number,
  mI6_P: t.number,
  mI7_Q: t.number,
  mI7_P: t.number,
  pv: t.number,
});
export type EsitoBiddingComputedPVI = t.TypeOf<typeof EsitoBiddingComputedPVI>;

export const CapacityMarket = t.type({
  id: t.number,
  p_Ven: t.number,
  p_Acq: t.number,
});
export type CapacityMarket = t.TypeOf<typeof CapacityMarket>;

export const DataCombustibiliRecord = t.type({
  fuelType: t.string,
  value: t.number,
  pci: t.number,
});
export type DataCombustibiliRecord = t.TypeOf<typeof DataCombustibiliRecord>;

export const DataCombustibiliReport = t.type({
  pvi: t.string,
  partitaIva: t.string,
  competenza: t.string,
  emissioni: t.number,
  smaltimento: t.number,
  ecotasse: t.number,
  combustibili: t.record(t.string, t.array(DataCombustibiliRecord)),
});
export type DataCombustibiliReport = t.TypeOf<typeof DataCombustibiliReport>;

export const QuantityPeriods = t.type({
  from: t.string,
  to: t.string,
  value: t.number,
});
export const MarketConfigPVIInfo = t.type({
  pvi: t.string,
  quantityPeriods: t.array(QuantityPeriods),
});
export type MarketConfigPVIInfo = t.TypeOf<typeof MarketConfigPVIInfo>;

export const MarketsBiddableFrom = t.type({
  mercatiBidding: MercatiBidding,
  biddableFrom: t.string,
});

export type MarketsBiddableFrom = t.TypeOf<typeof MarketsBiddableFrom>;

export enum UploadStatus {
  NotSet = "NotSet",
  Success = "Success",
  Error = "Error",
}
export type DisponibilitaCapacitaMassimaUploadData = {
  dateTimeOffset: Date;
  disponibilita?: number;
  capacitaMassima?: number;
};

export type DisponibilitaCapacitaMassimaUploadOutput = {
  id: number;
  partitaIVA: string;
  pvi: string;
  from: Date;
  to: Date;
  uploadedAt: Date;
  records: DisponibilitaCapacitaMassimaUploadData[];
  audit: any;
  status: UploadStatus;
  statusDetail: string;
};
