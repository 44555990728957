import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {
  Selectors,
  setCommodityAction,
  CommodityType,
  ProductType,
  setProductsAction
} from "../redux/modules/markets/forwardMarket";
import Loader from "../components/loader";
import NoData from "../components/tableComponents/noData";
import RelativeCheckbox from "./componets/relativeCheckbox";
import DateValue from "./componets/relativeDate";
import ProductValue from "./componets/relativeProductValue";
import { chartData, lastNoOfDaysList, getProductName } from "./helper";
import PageOnLoad from "../components/pageOnLoad";

NoDataToDisplay(Highcharts);

type ForwardMarketType = {
  isFetching: any;
  commodity: CommodityType;
  data: any;
  products: ProductType[];
  setCommodityAction: (a: CommodityType) => void;
  setProductsAction: (a: ProductType[]) => void;
};

const ForwardMarket = ({
  isFetching,
  commodity,
  data,
  products,
  setCommodityAction,
  setProductsAction
}: ForwardMarketType) => {
  return (
    <>
      <PageOnLoad />
      <div className="pageContainer">
        <h1>Mercati Forward</h1>
        <div className="pageContent">
          <CommodityFilter
            commodity={commodity}
            setCommodityAction={setCommodityAction}
          />
        </div>
        <CommodityTable data={data} />
        <div className="spacer"></div>
        <RelativeFilter
          products={products}
          setProductsAction={setProductsAction}
        />
        <div className="spacer"></div>
        <RelativeChart data={data} products={products} />
        <div className="spacer"></div>
        <Loader load={isFetching} />
      </div>
    </>
  );
};

export default connect(
  (s: any) => ({ ...Selectors.all(s) }),
  { setCommodityAction, setProductsAction }
)(ForwardMarket);

type CommodityFilterType = {
  commodity: CommodityType;
  setCommodityAction: (a: CommodityType) => void;
};

const CommodityFilter = ({
  commodity,
  setCommodityAction
}: CommodityFilterType) => {
  useEffect(() => {
    R.when(R.isNil, () => setCommodityAction("power"))(commodity);
  }, [setCommodityAction]);
  return (
    <div className="componentNav d-flex justify-content-center flex-row">
      <div className="componentNavHolder">
        <a
          className={`selection-label ${
            R.equals(commodity, "power") ? "active" : ""
          }`}
          onClick={() => setCommodityAction("power")}
        >
          Power
        </a>
        <a
          className={`selection-label ${
            R.equals(commodity, "gas") ? "active" : ""
          }`}
          onClick={() => setCommodityAction("gas")}
        >
          Gas
        </a>
      </div>
    </div>
  );
};

type CommodityTableType = {
  data: any;
};
const CommodityTable = ({ data }: CommodityTableType) => {
  useEffect(() => {}, []);
  const list = lastNoOfDaysList(7);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>{getProductName("M+1")}</th>
            <th>{getProductName("M+2")}</th>
            <th>{getProductName("M+3")}</th>
            <th>{getProductName("Q+1")}</th>
            <th>{getProductName("Q+2")}</th>
            <th>{getProductName("Y+1")}</th>
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(data) || R.isNil(data) ? (
            <NoData cols={7} />
          ) : (
            list.map((date: string, i: number) => {
              return (
                <tr key={i} hidden={R.equals(i, 0)}>
                  <DateValue date={date} />
                  <ProductValue date={date} data={data} product="M+1" />
                  <ProductValue date={date} data={data} product="M+2" />
                  <ProductValue date={date} data={data} product="M+3" />
                  <ProductValue date={date} data={data} product="Q+1" />
                  <ProductValue date={date} data={data} product="Q+2" />
                  <ProductValue date={date} data={data} product="Y+1" />
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};

type RelativeFilterType = {
  products: ProductType[];
  setProductsAction: (a: ProductType[]) => void;
};

const RelativeFilter = ({
  products,
  setProductsAction
}: RelativeFilterType) => {
  return (
    <>
      <label>
        Seleziona uno o piu prodotti per visualizzare il grafico dei mercati
        forward
      </label>
      <br />
      <b>
        <label className="text-uppercase">CURVE DI PREZZO</label>
      </b>
      <div className="relativePeriod">
        <RelativeCheckbox
          name="M+1"
          products={products}
          setProductsAction={setProductsAction}
        />
        <RelativeCheckbox
          name="M+2"
          products={products}
          setProductsAction={setProductsAction}
        />
        <RelativeCheckbox
          name="M+3"
          products={products}
          setProductsAction={setProductsAction}
        />
        <RelativeCheckbox
          name="Q+1"
          products={products}
          setProductsAction={setProductsAction}
        />
        <RelativeCheckbox
          name="Q+2"
          products={products}
          setProductsAction={setProductsAction}
        />
        <RelativeCheckbox
          name="Y+1"
          products={products}
          setProductsAction={setProductsAction}
        />
      </div>
    </>
  );
};

type RelativeChartType = {
  data: any;
  products: ProductType[];
};
const RelativeChart = ({ data, products }: RelativeChartType) => {
  const series = R.ifElse(
    x => R.isEmpty(x) || R.isNil(x),
    () => [],
    () => chartData({ data, products })
  )(data);

  const opts = {
    ...options,
    series
  } as any;

  return (
    <div className="chart">
      <div className="alignRight">
        <div
          title="Seleziona la parte del grafico che ti
          interessa &#10;con il mouse per ingrandirla 
          e visualizzarne &#10;i dettagli."
          className="infoHover"
        >
          Zoom
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={opts} />
    </div>
  );
};
const options: any = {
  chart: {
    type: "column",
    zoomType: "x",
    style: {
      fontFamily: "Avenir-Roman"
    }
  },
  title: {
    text: ""
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%b %e, %Y"
    }
  },
  plotOptions: {
    series: {
      events: {
        legendItemClick: function() {
          return false;
        }
      }
    }
  },
  tooltip: {
    shared: true,
    useHTML: true,
    xDateFormat: "%b %e, %Y",
    pointFormat:
      '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} €/MWh</b><br/>'
  },
  credits: {
    enabled: false
  },
  yAxis: {
    opposite: true,
    title: {
      text: "€/MWh"
    }
  },
  legend: {
    align: "left",
    verticalAlign: "top"
  },
  lang: {
    noData: "Nessun Dato Disponibile"
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#303030"
    }
  }
};
