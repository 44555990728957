import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Selectors as ErrorHandlerSelector,
  clearErrorAction,
  DetailsType
} from "./redux/modules/errorHandler";
import errorModal from "./styles/errorModal.module.scss";

type errModalType = {
  error?: boolean;
  details?: DetailsType | null;
  clearErrorAction?: any;
};

const ErrorModal = ({
  error = false,
  details = null,
  clearErrorAction
}: errModalType) => {
  return (
    <>
      {R.when(R.equals(true), () => (
        <div className={`ff ${errorModal.errorModal}`}>
          <div
            className={errorModal.overlay}
            onClick={() => clearErrorAction()}
          />
          <div className={errorModal.modalContainer}>
            <div className={errorModal.modalContent}>
              <h6 className={errorModal.header}>
                {R.pathOr(null, ["title"], details)}
              </h6>
              <div className={errorModal.body}>
                {R.pathOr(null, ["message"], details)}
              </div>
              <div className={errorModal.footer}>
                <button
                  className={`btn btn-danger rounded-pill mx-2 margin-bottom-18 ${errorModal.cancelBtn}`}
                  onClick={() => clearErrorAction()}
                >
                  {R.pathOr("Close", ["btnTitle"], details)}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))(error && !R.isNil(details))}
    </>
  );
};

export default connect(
  ErrorHandlerSelector.all,
  { clearErrorAction }
)(ErrorModal);
