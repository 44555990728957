import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Dependancies } from "../storeTypes";

export const key = "cridaDate";
export enum actionTypes {
  SET_CRIDADATE = "met/cridaDate/SET_CRIDADATE",
}

type Actions = {
  type: actionTypes.SET_CRIDADATE;
  cridaDate: any;
};

export function Reducer(state: any = null, action: Actions): any {
  switch (action.type) {
    case actionTypes.SET_CRIDADATE:
      return action.cridaDate;
    default:
      return state;
  }
}

const setCridaDateAction = (cridaDate: any): Actions => ({
  type: actionTypes.SET_CRIDADATE,
  cridaDate,
});

const initcridaDateEpic = (
  action$: Observable<any>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    filter((x) => x.type === "INIT"),
    map(() =>
      setCridaDateAction({
        cridaDate: deps.cridaDate,
      })
    )
  );

export const epic = combineEpics(initcridaDateEpic);

export type CridaDateState = {
  cridaDate: string;
};
export const Selectors = {
  all: (state: any): CridaDateState => state[key],
  cridaDate: (state: any): string => state[key].cridaDate,
};
