import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { permissionCheck } from "../helper/permissions";
import permissions from "../permissions";
import {
  Selectors,
  nullArr,
  requestDataAction,
  closeModalAction,
  openMappingsModalAction,
  closeMappingsModalAction,
  openModalAction,
  updateModalRowAction,
  deleteRowAction,
  DeleteRowSendType,
  cancelModifictionAction,
  CancelChangesType,
  commitChangesAction,
  CommitChangesType,
  applyChangesAction,
  clearAllStoredDataAction,
  exportDataAction,
  uploadDataAction,
  uploadSucceededCloseAction,
  setCorePVI,
  requestCorePVI
} from "../redux/modules/configs/internalLegs";
import {
  Selectors as EnumsSelectors,
  requestConfigEnumsAction
} from "../redux/modules/enums";
import {
  modCheck,
  rowClass,
  ConfigModalInfoType,
  getConfigModalInfo,
  TableType,
  filterOptionsFunc,
  filterTextFunc,
  sortbyFunc,
  DownloadUpload,
  filterMatchTextFunc
} from "./helper";
import Loader from "../components/loader";
import { SortValType } from "../components/tableComponents/sort";
import ConfigModal from "./components/configModal";
import ConfirmationModal from "./components/confirmationModal";
import TextInput from "./components/textInput";
import SelectInput from "./components/selectInput";
import EditCol from "./components/editCol";
import AddBtn from "./components/addBtnHeader";
import NoData from "./components/noData";
import ApplyChangesBtn from "./components/applyChangesBtn";
import TextTableHeader from "./components/textTableHeader";
import SelectTableHeader from "./components/selectTableHeader";
import DateTableHeader from "./components/dateTableHeader";
import DateInput from "./components/dateInput";
import NumericInput from "./components/numericInput";
import { format } from "date-fns";
import NotifcationModal from "../components/notifcationModal";
import { anyNull } from "../redux/modules/configs/helperts";
import PageOnLoad from "../components/pageOnLoad";
import { PVIType } from "../redux/modules/configs/coreTypes";
import { isDebuggerStatement } from "typescript";
import { Underlying_Parse } from "../helper/enumNames";

type CrudTableType = {
  state: any;
  data: any;
  modal: any;
  isFetching: boolean;
  uploadSucceed: boolean;
  applyMappingsModal: boolean;
  enums: any;
  configEnums: any;
  corePVI: PVIType[];
  requestDataAction: () => void;
  closeModalAction: () => void;
  openMappingsModalAction: () => void;
  closeMappingsModalAction: () => void;
  openModalAction: (a: any) => void;
  updateModalRowAction: (a: any) => void;
  deleteRowAction: (a: DeleteRowSendType) => void;
  cancelModifictionAction: (a: CancelChangesType) => void;
  commitChangesAction: (a: CommitChangesType) => void;
  applyChangesAction: () => void;
  clearAllStoredDataAction: () => void;
  requestConfigEnumsAction: () => void;
  exportDataAction: () => void;
  uploadDataAction: (a: any) => void;
  uploadSucceededCloseAction: () => void;
  setCorePVI: (a: PVIType[]) => void;
  requestCorePVI: () => void;
};

const CrudTable = ({
  state,
  data,
  modal,
  isFetching,
  uploadSucceed,
  corePVI,
  requestDataAction,
  applyMappingsModal,
  enums,
  configEnums,
  closeModalAction,
  updateModalRowAction,
  openMappingsModalAction,
  closeMappingsModalAction,
  openModalAction,
  deleteRowAction,
  cancelModifictionAction,
  commitChangesAction,
  applyChangesAction,
  clearAllStoredDataAction,
  requestConfigEnumsAction,
  exportDataAction,
  uploadDataAction,
  uploadSucceededCloseAction,
  requestCorePVI
}: CrudTableType) => {
  useEffect(() => {
    R.when(R.isEmpty, () => requestDataAction())(data);
    R.when(R.isEmpty, () => requestConfigEnumsAction())(
      configEnums.pviProvider
    );
    requestCorePVI();
    return () => clearAllStoredDataAction();
  }, [requestDataAction, clearAllStoredDataAction]);
  return (
    <>
    <PageOnLoad />
    <div className="pageContainer">
      <h1>Internal Legs</h1>
      <Table
        state={state}
        data={data}
        enums={enums}
        openModal={openModalAction}
        deleteRow={deleteRowAction}
        cancelModifiction={cancelModifictionAction}
      />
      <DownloadUpload
        exportData={exportDataAction}
        uploadData={uploadDataAction}
      />
      <ApplyChangesBtn
        hasChanges={modCheck(data)}
        open={() => openMappingsModalAction()}
      />
      <Prompt
        when={modCheck(data)}
        message="Are you sure you want to leave without saving changes?"
      />
      <Loader load={isFetching} />
      <ConfirmationModal
        openModal={applyMappingsModal}
        modalTitle="Apply Changes"
        modalBody={<span>Are you sure you want to apply changes?</span>}
        closeModal={() => closeMappingsModalAction()}
        cofirmModal={() => applyChangesAction()}
      />
      <ConfigModal
        openModal={modal.open}
        modalTitle={modal.type}
        modalBody={getConfigModalInfo({
          modalRow: modal.row,
          modalType: modal.type,
          update: updateModalRowAction,
          enums,
          configEnums,
          corePVI,
          func: configModalInfo
        })}
        disableApplyBtn={disableApplyBtn(modal)}
        closeModal={() => closeModalAction()}
        commitChanges={() =>
          commitChangesAction({
            modalData: modal,
            tableData: data
          })
        }
      />
      <NotifcationModal
        open={uploadSucceed && !isFetching}
        close={uploadSucceededCloseAction}
        title="Upload Successful"
        message="Internal Legs Upload Successful"
      />
    </div>
    </>
  );
};

export default R.compose(
  connect(
    (s: any) => ({
      state: s,
      ...Selectors.all(s),
      enums: EnumsSelectors.enums(s),
      configEnums: EnumsSelectors.configEnums(s)
    }),
    {
      requestDataAction,
      closeModalAction,
      updateModalRowAction,
      openMappingsModalAction,
      closeMappingsModalAction,
      openModalAction,
      deleteRowAction,
      cancelModifictionAction,
      commitChangesAction,
      applyChangesAction,
      clearAllStoredDataAction,
      requestConfigEnumsAction,
      exportDataAction,
      uploadDataAction,
      uploadSucceededCloseAction,
      requestCorePVI
    }
  )
)(CrudTable);

const disableApplyBtn = (modal: any) => {
  return (
    (modal.type === "Update Mapping" &&
      (modal.orig ? R.equals(modal.row, modal.orig) : true)) ||
    anyNull({ row: modal.row, nullArr }) ||
    modal.row.guaranteeVolume <= 0 ||
    modal.row.percentualeSharingAirbag <= 0 ||
    modal.row.strike <= 0 ||
    modal.row.spread < 0
  );
};

export const Table = ({
  state,
  data,
  enums = null,
  openModal,
  deleteRow,
  cancelModifiction
}: TableType) => {
  const havePermission = permissionCheck({
    state,
    permission: permissions.configurationWrite
  });

  const addNewRow = () =>
    openModal({
      type: "Add Mapping",
      row: {
        pvi: null,
        type: null,
        fromDate: null,
        toDate: null,
        tradeDate: null,
        counterpartyInternal: null,
        portfolio: null,
        counterpartyExternal: null,
        underlying: null,
        buySell: null,
        operationType: null,
        guaranteeVolume: null,
        percentualeSharingAirbag: null,
        strike: null,
        spread: null
      }
    });

  const editRow = (row: any) =>
    openModal({
      type: "Update Mapping",
      row: {
        pvi: row.pvi,
        type: row.type,
        fromDate: row.fromDate,
        toDate: row.toDate,
        tradeDate: row.tradeDate,
        counterpartyInternal: row.counterpartyInternal,
        portfolio: row.portfolio,
        counterpartyExternal: row.counterpartyExternal,
        underlying: row.underlying,
        buySell: row.buySell,
        operationType: row.operationType,
        guaranteeVolume: row.guaranteeVolume,
        percentualeSharingAirbag: row.percentualeSharingAirbag,
        strike: row.strike,
        spread: row.spread
      }
    });

  const [sortFilter, setSortFilter] = useState<SortValType>({
    column: null,
    direction: null
  });

  const [filters, setFilter] = useState<any>({
    pvi: null,
    type: null,
    fromDate: null,
    toDate: null,
    tradeDate: null,
    counterpartyInternal: null,
    portfolio: null,
    counterpartyExternal: null,
    underlying: null,
    buySell: null,
    operationType: null,
    guaranteeVolume: null,
    percentualeSharingAirbag: null,
    strike: null,
    spread: null
  });

  const textFilter1 = R.compose<any, any, any, any, any, any, any, any>(
    d => filterTextFunc({ key: "type", data: d, filters }),
    d => filterTextFunc({ key: "fromDate", data: d, filters }),
    d => filterTextFunc({ key: "toDate", data: d, filters }),
    d => filterTextFunc({ key: "tradeDate", data: d, filters }),
    d => filterTextFunc({ key: "counterpartyInternal", data: d, filters }),
    d => filterTextFunc({ key: "portfolio", data: d, filters })
  ) as any;
  const textFilter2 = R.compose<any, any, any, any, any, any, any, any>(
    d => filterTextFunc({ key: "counterpartyExternal", data: d, filters }),
    d => filterMatchTextFunc({ key: "underlying", data: d, filters }),
    d => filterTextFunc({ key: "buySell", data: d, filters }),
    d => filterTextFunc({ key: "operationType", data: d, filters }),
    d => filterTextFunc({ key: "guaranteeVolume", data: d, filters }),
    d => filterTextFunc({ key: "percentualeSharingAirbag", data: d, filters })
  ) as any;
  const textFilter3 = R.compose<any, any, any>(
    d => filterTextFunc({ key: "strike", data: d, filters }),
    d => filterTextFunc({ key: "spread", data: d, filters })
  ) as any;

  const tableData = R.pipe<any, any, any, any, any, any>(
    filterOptionsFunc,
    textFilter1,
    textFilter2,
    textFilter3,
    d => sortbyFunc({ sort: sortFilter, data: d })
  )({
    data,
    filters: R.omit(
      [
        "type",
        "fromDate",
        "toDate",
        "tradeDate",
        "counterpartyInternal",
        "portfolio",
        "counterpartyExternal",
        "underlying",
        "buySell",
        "operationType",
        "guaranteeVolume",
        "percentualeSharingAirbag",
        "strike",
        "spread"
      ],
      filters
    )
  });

  return (
    <div className="tableContainer">
      <div className="scroll">
        <table className="internalLegsConfigTable">
          <thead>
            <tr>
              <AddBtn havePermission={havePermission} addNewRow={addNewRow} />
              <TextTableHeader
                title="PVI"
                propName="pvi"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
              <SelectTableHeader
                title="Type"
                propName="type"
                options={R.map(
                  x => ({ value: x, title: x }),
                  enums.TipoPrezzoProduttori
                )}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "14em" }}
              />
              <DateTableHeader
                title="From Date"
                propName="fromDate"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "10em" }}
              />
              <DateTableHeader
                title="To Date"
                propName="toDate"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "10em" }}
              />
              <DateTableHeader
                title="Trade Date"
                propName="tradeDate"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "10em" }}
              />
              <TextTableHeader
                title="Counterparty Internal"
                propName="counterpartyInternal"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
              />
              <TextTableHeader
                title="Portfolio"
                propName="portfolio"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
              <TextTableHeader
                title="Counterparty External"
                propName="counterpartyExternal"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
              />
              <SelectTableHeader
                title="Underlying"
                propName="underlying"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "14em" }}
                options={R.map(
                  x => ({ value: Underlying_Parse(x), title: Underlying_Parse(x) }),
                  enums.UnderlyingType
                )}
              />
              <TextTableHeader
                title="Buy/Sell"
                propName="buySell"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
              <TextTableHeader
                title="Operation Type"
                propName="operationType"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
              <TextTableHeader
                title="Guarantee Volume"
                propName="guaranteeVolume"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
              <TextTableHeader
                title="Percentuale Sharing Airbag"
                propName="percentualeSharingAirbag"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
              />
              <TextTableHeader
                title="Strike"
                propName="strike"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
              <TextTableHeader
                title="Spread"
                propName="spread"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
                style={{ width: "11em" }}
              />
            </tr>
          </thead>
          <tbody>
            {R.isEmpty(tableData) ? (
              <NoData havePermission={havePermission} cols={15} />
            ) : (
              tableData.map((row: any, i: number) => {
                return (
                  <tr key={i} className={rowClass(row.mod || "")}>
                    <EditCol
                      havePermission={havePermission}
                      row={row}
                      openEditRow={() => editRow(row)}
                      deleteRow={() => deleteRow({ row, data })}
                      cancelModification={() =>
                        cancelModifiction({ row, data })
                      }
                    />
                    <td>{row.pvi}</td>
                    <td>{row.type}</td>
                    <td>{format(new Date(row.fromDate), "dd-MM-yyyy")}</td>
                    <td>{format(new Date(row.toDate), "dd-MM-yyyy")}</td>
                    <td>{format(new Date(row.tradeDate), "dd-MM-yyyy")}</td>
                    <td>{row.counterpartyInternal}</td>
                    <td>{row.portfolio}</td>
                    <td>{row.counterpartyExternal}</td>
                    <td>{row.underlying}</td>
                    <td>{row.buySell}</td>
                    <td>{row.operationType}</td>
                    <td>{row.guaranteeVolume}</td>
                    <td>{row.percentualeSharingAirbag}</td>
                    <td>{row.strike}</td>
                    <td>{row.spread}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const configModalInfo = ({
  type,
  modalType,
  value,
  modalRow,
  enums,
  configEnums,
  corePVI,
  update
}: ConfigModalInfoType) => {
  const objUpdater = R.compose(
    update,
    R.assoc(type, R.__, modalRow)
  );
  let pviList = corePVI != undefined ? corePVI : [];
  switch (type) {
    case "pvi":
      return (
        <SelectInput
          update={objUpdater}
          requiredField
          labelName="PVI"
          readOnly={modalType === "Update Mapping"}
          options={R.map(
            x  => ({ value: x.pvi, title: x.pvi }),
            pviList
          )}
          value={value || ""}
          containsFilter = {true}
        />
      );
    case "type":
      return (
        <SelectInput
          update={objUpdater}
          requiredField
          labelName="Type"
          readOnly={modalType === "Update Mapping"}
          options={R.map(
            x => ({ value: x, title: x }),
            enums.TipoPrezzoProduttori
          )}
          value={value || ""}
        />
      );
    case "fromDate":
      return (
        <DateInput
          update={objUpdater}
          requiredField={modalType === "Add Mapping"}
          readOnly={modalType === "Update Mapping"}
          labelName="From Date"
          value={value}
        />
      );
    case "toDate":
      return (
        <DateInput
          update={objUpdater}
          requiredField={modalType === "Add Mapping"}
          readOnly={modalType === "Update Mapping"}
          labelName="To Date"
          value={value}
        />
      );
    case "tradeDate":
      return (
        <DateInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Trade Date"
          value={value}
        />
      );
    case "counterpartyInternal":
      return (
        <TextInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Counterparty Internal"
          value={value || ""}
        />
      );
    case "portfolio":
      return (
        <TextInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Portfolio"
          value={value || ""}
        />
      );
    case "counterpartyExternal":
      return (
        <TextInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Counterparty External"
          value={value || ""}
        />
      );
    case "underlying":
      return (
        <SelectInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Underlying"
          options={R.map(
            x => ({ value: Underlying_Parse(x), title: Underlying_Parse(x) }),
            enums.UnderlyingType
          )}
          value={value || ""}
        />
      );
    case "buySell":
      return (
        <TextInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Buy/Sell"
          value={value || ""}
        />
      );
    case "operationType":
      return (
        <TextInput
          update={objUpdater}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Operation Type"
          value={value || ""}
        />
      );
    case "guaranteeVolume":
      return (
        <NumericInput
          update={x => objUpdater(parseFloat(x))}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Guarantee Volume"
          value={value}
          min={1}
        />
      );
    case "percentualeSharingAirbag":
      return (
        <NumericInput
          update={x => objUpdater(parseFloat(x))}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Percentuale Sharing Airbag"
          value={value}
          min={1}
        />
      );
    case "strike":
      return (
        <NumericInput
          update={x => objUpdater(parseFloat(x))}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Strike"
          value={value}
          min={1}
        />
      );
    case "spread":
      return (
        <NumericInput
          update={x => objUpdater(parseFloat(x))}
          requiredField={
            modalType === "Add Mapping" || modalType === "Update Mapping"
          }
          labelName="Spread"
          value={value}
          min={0}
        />
      );
    default:
      return null;
  }
};
