import * as R from "ramda";

const border = {
  border: {
    right: {
      style: "thick",
      color: "#000000"
    }
  }
};

type GetCodiceDataType = {
  upData: any;
  market: any;
};
const getCodiceupData = ({ upData, market }: GetCodiceDataType) =>
  R.pipe<any, any, any, any>(
    R.prop("timeSerie"),
    R.map((timeSerie: any) => ({
      ...timeSerie,
      codiceUp: upData.codiceUp,
      market
    })),
    R.values
  )(upData);

type CreatePVIType = {
  sheet: any;
  data: any;
  market: any;
};
export const createUPData = ({ sheet, data, market }: CreatePVIType) => {
  const orderedData = R.pipe(
    R.map((upData: any) => getCodiceupData({ upData, market })),
    R.values,
    R.flatten,
    R.sortBy(
      R.compose<any, any, any>(
        R.prop<any>("codiceUp"),
        R.prop<any>("dateTimeOffset")
      )
    )
  )(data);

  orderedData.map((rowData: any, i: any) =>
    setPVIRowData({
      sheet,
      rowData,
      rowIndex: R.add(i, 3)
    })
  );
  return sheet;
};

type StringCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const stringCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: StringCellType) =>
  sheet
    .cell(rowIndex, colIndex)
    .string(R.pathOr("", path, rowData))
    .style(style);

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: NumberCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .number(val)
        .style(style);
};

type SetPVIRowType = { sheet: any; rowData: any; rowIndex: number };
const setPVIRowData = ({ sheet, rowData, rowIndex }: SetPVIRowType) => {
  const info = { sheet, rowData, rowIndex };

  stringCell({ ...info, colIndex: 1, path: ["codiceUp"] });
  stringCell({ ...info, colIndex: 2, path: ["market"] });
  stringCell({ ...info, colIndex: 3, path: ["dateTimeOffset"], style: border });
  stringCell({
    ...info,
    colIndex: 4,
    path: ["misurePrezziBiddingUP", "provider"]
  });
  stringCell({
    ...info,
    colIndex: 5,
    path: ["misurePrezziBiddingUP", "mercatiBidding"]
  });
  numberCell({
    ...info,
    colIndex: 6,
    path: ["misurePrezziBiddingUP", "q_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 7,
    path: ["misurePrezziBiddingUP", "q_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 8,
    path: ["misurePrezziBiddingUP", "q_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 9,
    path: ["misurePrezziBiddingUP", "q_GR4"]
  });
  numberCell({
    ...info,
    colIndex: 10,
    path: ["misurePrezziBiddingUP", "p_GR1"]
  });
  numberCell({
    ...info,
    colIndex: 11,
    path: ["misurePrezziBiddingUP", "p_GR2"]
  });
  numberCell({
    ...info,
    colIndex: 11,
    path: ["misurePrezziBiddingUP", "p_GR3"]
  });
  numberCell({
    ...info,
    colIndex: 12,
    path: ["misurePrezziBiddingUP", "p_GR4"]
  });
  numberCell({
    ...info,
    colIndex: 13,
    path: ["misurePrezziBiddingUP", "p_GR3"],
    style: border
  });
  numberCell({
    ...info,
    colIndex: 14,
    path: ["intermediate", "capacitaInstallataMW"],
    style: border
  });
  numberCell({ ...info, colIndex: 15, path: ["esitoBiddingUP", "mgP_Q"] });
  numberCell({ ...info, colIndex: 16, path: ["esitoBiddingUP", "mI1_Q"] });
  numberCell({ ...info, colIndex: 17, path: ["esitoBiddingUP", "mI2_Q"] });
  numberCell({ ...info, colIndex: 18, path: ["esitoBiddingUP", "mI3_Q"] });
  numberCell({ ...info, colIndex: 19, path: ["esitoBiddingUP", "mI4_Q"] });
  numberCell({ ...info, colIndex: 20, path: ["esitoBiddingUP", "mI5_Q"] });
  numberCell({ ...info, colIndex: 21, path: ["esitoBiddingUP", "mI6_Q"] });
  numberCell({
    ...info,
    colIndex: 22,
    path: ["esitoBiddingUP", "mI7_Q"],
    style: border
  });
  numberCell({ ...info, colIndex: 23, path: ["coefficientiUP", "uploadId"] });
  numberCell({
    ...info,
    colIndex: 24,
    path: ["coefficientiUP", "coefficienteA"]
  });
  numberCell({
    ...info,
    colIndex: 25,
    path: ["coefficientiUP", "coefficienteB"],
    style: border
  });
  stringCell({ ...info, colIndex: 26, path: ["spreadMGP", "zona"] });
  numberCell({ ...info, colIndex: 27, path: ["spreadMGP", "ora"] });
  numberCell({ ...info, colIndex: 28, path: ["spreadMGP", "spreadBuy"] });
  numberCell({
    ...info,
    colIndex: 29,
    path: ["spreadMGP", "spreadSell"],
    style: border
  });
  numberCell({ ...info, colIndex: 30, path: ["offertatoBiddingUP", "q_GR1"] });
  numberCell({ ...info, colIndex: 31, path: ["offertatoBiddingUP", "q_GR2"] });
  numberCell({ ...info, colIndex: 32, path: ["offertatoBiddingUP", "q_GR3"] });
  numberCell({ ...info, colIndex: 33, path: ["offertatoBiddingUP", "q_GR4"] });
  numberCell({ ...info, colIndex: 34, path: ["offertatoBiddingUP", "p_GR1"] });
  numberCell({ ...info, colIndex: 35, path: ["offertatoBiddingUP", "p_GR2"] });
  numberCell({ ...info, colIndex: 36, path: ["offertatoBiddingUP", "p_GR3"] });
  numberCell({
    ...info,
    colIndex: 37,
    path: ["offertatoBiddingUP", "p_GR4"],
    style: border
  });
  numberCell({
    ...info,
    colIndex: 38,
    path: ["intermediate", "eccedenza"],
    style: border
  });
  numberCell({ ...info, colIndex: 39, path: ["result", "q_GR1"] });
  numberCell({ ...info, colIndex: 40, path: ["result", "q_GR2"] });
  numberCell({ ...info, colIndex: 41, path: ["result", "q_GR3"] });
  numberCell({ ...info, colIndex: 42, path: ["result", "q_GR4"] });
  numberCell({ ...info, colIndex: 43, path: ["result", "p_GR1"] });
  numberCell({ ...info, colIndex: 44, path: ["result", "p_GR2"] });
  numberCell({ ...info, colIndex: 45, path: ["result", "p_GR3"] });
  numberCell({
    ...info,
    colIndex: 46,
    path: ["result", "p_GR4"],
    style: border
  });

  return sheet;
};
