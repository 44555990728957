import React from "react";
import * as R from "ramda";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type LoaderTypes = {
  startDate: Date | null;
  startDatelabel?: string | JSX.Element | null;
  onStartDateChange: (a: any) => void;
  endDate: Date | null;
  endDatelabel?: string | JSX.Element | null;
  onEndDateChange: (a: any) => void;
  placeholderText?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
};

const Datepicker = ({
  startDate,
  startDatelabel = null,
  onStartDateChange,
  endDate,
  endDatelabel = null,
  onEndDateChange,
  placeholderText = "",
  maxDate = null,
  minDate = null
}: LoaderTypes) => {
  const placeHolder = R.isEmpty(placeholderText)
    ? "Inserire valore"
    : placeholderText;

  return (
    <div className="dateRangeContainer">
      da:
      <div className="input">
        {R.unless(R.isNil, () => (
          <span className="inputLabel">{startDatelabel}</span>
        ))(startDatelabel)}
        <div>
          <DatePicker
            className="dateInput-NotRounded date"
            selected={startDate}
            selectsStart
            dateFormat="dd-MM-yyyy"
            monthsShown={2}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate as any}
            maxDate={endDate ? endDate : null}
            onChange={onStartDateChange}
            placeholderText={placeHolder}
          />
        </div>
      </div>
      a:
      <div className="input">
        {R.unless(R.isNil, () => (
          <span className="inputLabel">{endDatelabel}</span>
        ))(endDatelabel)}
        <div>
          <DatePicker
            className="dateInput-NotRounded date"
            selected={endDate}
            selectsEnd
            dateFormat="dd-MM-yyyy"
            monthsShown={2}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate ? startDate : null}
            maxDate={maxDate as any}
            onChange={onEndDateChange}
            placeholderText={placeHolder}
          />
        </div>
      </div>
      <div className="calendar-icon">
        <i className="fas fa-calendar-alt"></i>
      </div>
    </div>
  );
};

export default Datepicker;
