import * as R from "ramda";
import { combineReducers } from "redux";
import { combineEpics, ofType } from "redux-observable";
import { Observable, defer, of, EMPTY, timer } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import * as A from "fp-ts/lib/Array";
import * as TE from "fp-ts/lib/TaskEither";
import { Dependancies } from "../../storeTypes";
import { searchBuilder, getAllRequests } from "../../helper";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import download from "./download";
import { pipe } from "fp-ts/lib/pipeable";
import { addDays, format, subDays } from "date-fns";
import { permissionCheck } from "../../../helper/permissions";
import permissions from "../../../permissions";

const taskSequence = A.array.sequence(TE.taskEither);

export const key = "executedOperations";
export type Operations = "MGP&MI" | "Disponibilita" | "Combustibili";

export enum actionTypes {
  EMPTY_DATA = "rtm/audits/executedOperations/EMPTY_DATA",
  CANCEL = "rtm/audits/executedOperations/CANCEL",
  FETCHING_OFF = "rtm/audits/executedOperations/FETCHING_OFF",
  FETCHING_OPERATIONS_DATA = "rtm/audits/executedOperations/FETCHING_OPERATIONS_DATA",
  RECIEVED_OPERATIONS_DATA = "rtm/audits/executedOperations/RECIEVED_OPERATIONS_DATA",
  SET_PAGE_OPERATIONS_DATA = "rtm/audits/executedOperations/SET_PAGE_OPERATIONS_DATA",
  SET_FILTER_OPERATIONS_DATA = "rtm/audits/executedOperations/SET_FILTER_OPERATIONS_DATA",
  SET_SELECTED_OPERATIONS = "rtm/audits/executedOperations/SET_SELECTED_OPERATIONS",
  DOWNLOAD_OPERATION = "rtm/audits/executedOperations/DOWNLOAD_OPERATION",
  DOWNLOAD_OPERATION_SUCCEED = "rtm/audits/executedOperations/DOWNLOAD_OPERATION_SUCCEED",
  FETCHING_AUDIT_LIST_DATA = "rtm/audits/executedOperations/FETCHING_AUDIT_LIST_DATA",
  RECIEVED_USERS_LIST_DATA = "rtm/audits/executedOperations/RECIEVED_USERS_LIST_DATA",
  SET_PVI_DATA = "rtm/audits/executedOperations/SET_PVI_DATA",
  RECIEVED_PVI_DATA = "rtm/audits/executedOperations/RECIEVED_PVI_DATA",
}

type Actions =
  | {
      type: actionTypes.CANCEL;
    }
  | { type: actionTypes.FETCHING_OFF }
  | {
      type: actionTypes.FETCHING_OPERATIONS_DATA;
    }
  | {
      type: actionTypes.RECIEVED_OPERATIONS_DATA;
      count: number;
      data: any;
    }
  | {
      type: actionTypes.SET_PAGE_OPERATIONS_DATA;
      page: number;
    }
  | {
      type: actionTypes.SET_FILTER_OPERATIONS_DATA;
      filters: any;
    }
  | {
      type: actionTypes.SET_SELECTED_OPERATIONS;
      operation: Operations;
    }
  | {
      type: actionTypes.DOWNLOAD_OPERATION;
      id: string;
    }
  | {
      type: actionTypes.DOWNLOAD_OPERATION_SUCCEED;
    }
  | { type: actionTypes.FETCHING_AUDIT_LIST_DATA }
  | { type: actionTypes.RECIEVED_USERS_LIST_DATA; count: number; data: any }
  | { type: actionTypes.SET_PVI_DATA }
  | { type: actionTypes.RECIEVED_PVI_DATA; data: any }
  | { type: actionTypes.EMPTY_DATA };

export type FilterOperationsTablesStateType = {
  uploadedFrom: string | null;
  uploadedTo: string | null;
  from: string | null;
  to: string | null;
  user: string | null;
  pvi: string | null;
  competenzaFrom: string | null;
  competenzaTo: string | null;
  mercatiBidding: string | null;
};

export type OperationsTablesStateType = {
  isFetching: boolean;
  data: any;
  count: number;
  page: number;
  pageSize: number;
  filters: FilterOperationsTablesStateType;
};

const dMinus3 = subDays(new Date(), 0);
const dPlus1 = addDays(new Date(), 0);
const operationsTablesState = {
  isFetching: false,
  data: [],
  count: 0,
  page: 1,
  pageSize: 50,
  filters: {
    uploadedFrom: format(dMinus3, "yyyy-MM-dd"),
    uploadedTo: format(dPlus1, "yyyy-MM-dd"),
    user: null,
    pvi: null,
    from: null,
    to: null,
    competenzaFrom: null,
    competenzaTo: null,
    mercatiBidding: null,
  },
};

export function operationsTableReducer(
  state = operationsTablesState as OperationsTablesStateType,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.FETCHING_OPERATIONS_DATA:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.RECIEVED_OPERATIONS_DATA:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        count: action.count,
      };
    case actionTypes.SET_PAGE_OPERATIONS_DATA:
      return {
        ...state,
        page: action.page,
      };
    case actionTypes.SET_FILTER_OPERATIONS_DATA:
      return {
        ...state,
        filters: action.filters,
      };
    case actionTypes.SET_SELECTED_OPERATIONS:
      return operationsTablesState;
    default:
      return state;
  }
}

function selectedOperationReducer(
  state: Operations | null = null,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_OPERATIONS:
      return action.operation;
    default:
      return state;
  }
}
function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.DOWNLOAD_OPERATION:
      return true;
    case actionTypes.FETCHING_OFF:
    case actionTypes.DOWNLOAD_OPERATION_SUCCEED:
      return false;
    default:
      return state;
  }
}

export type PVIType = {
  isFetching: boolean;
  data: any;
};
const pviState = {
  isFetching: false,
  data: [],
};
function pviReducer(state: PVIType = pviState, action: Actions) {
  switch (action.type) {
    case actionTypes.SET_PVI_DATA:
      return { ...state, isFetching: true };
    case actionTypes.RECIEVED_PVI_DATA:
      return { ...state, isFetching: false, data: action.data };
    case actionTypes.CANCEL:
    case actionTypes.EMPTY_DATA:
      return pviState;
    default:
      return state;
  }
}

export function userListReducer(state = [] as string[], action: Actions) {
  switch (action.type) {
    case actionTypes.RECIEVED_USERS_LIST_DATA:
      return action.data;
    default:
      return state;
  }
}

export type reducerType = {
  isFetching: any;
  selectedOperation:  Operations | null;
  operationsTable: OperationsTablesStateType;
  pvi: PVIType;
  userList: any;
};
export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  selectedOperation: selectedOperationReducer,
  operationsTable: operationsTableReducer,
  pvi: pviReducer,
  userList: userListReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA,
});

export const setSelectedOperationAction = (operation: Operations): Actions => ({
  type: actionTypes.SET_SELECTED_OPERATIONS,
  operation,
});

export const fetchingOperationsList = (): Actions => ({
  type: actionTypes.FETCHING_OPERATIONS_DATA,
});

export const recievedOperationsList = ({
  data,
  count,
}: {
  data: any;
  count: number;
}): Actions => ({
  type: actionTypes.RECIEVED_OPERATIONS_DATA,
  data,
  count,
});

export const setPageOperationsList = (page: number): Actions => ({
  type: actionTypes.SET_PAGE_OPERATIONS_DATA,
  page,
});
export const setFiltersOperationsList = (
  filters: FilterOperationsTablesStateType
): Actions => ({
  type: actionTypes.SET_FILTER_OPERATIONS_DATA,
  filters,
});

const requestOperationsListDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FETCHING_OPERATIONS_DATA ||
        action.type === actionTypes.SET_PAGE_OPERATIONS_DATA ||
        action.type === actionTypes.SET_FILTER_OPERATIONS_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const info = R.path(
            ["audits", key, "operationsTable", "filters"],
            state
          ) as FilterOperationsTablesStateType;

          const anyNull = R.pipe<any, any, any, any, any>(
            R.pick(["uploadedFrom", "uploadedTo"]),
            R.map((x) => R.isEmpty(x) || R.isNil(x)),
            R.values,
            R.any(R.equals(true))
          )(info);
          return anyNull ? EMPTY : of(state);
        }),
        RxOp.switchMap(() =>
          action$.pipe(
            RxOp.filter(
              (action) =>
                action.type === actionTypes.SET_PAGE_OPERATIONS_DATA ||
                action.type === actionTypes.SET_SELECTED_OPERATIONS ||
                action.type === actionTypes.SET_FILTER_OPERATIONS_DATA
            ),
            RxOp.startWith(1),
            RxOp.switchMapTo(
              timer(0, 10000).pipe(
                RxOp.exhaustMap(() =>
                  state$.pipe(
                    RxOp.first(),
                    RxOp.switchMap((state) => {
                      const operation = R.path(
                        ["audits", key, "selectedOperation"],
                        state
                      ) as Operations;
                      const info = R.path(
                        ["audits", key, "operationsTable"],
                        state
                      ) as OperationsTablesStateType;
                      const skipVal = R.multiply(
                        R.subtract(info.page, 1),
                        info.pageSize
                      );

                      const toDateInclusive = R.pipe(
                        (x: any) => addDays(x, 1),
                        (x: any) => format(x, "yyyy-MM-dd")
                      )(new Date(info.filters.uploadedTo as string));
                      const queryString = searchBuilder({
                        filters: {
                          ...info.filters,
                          uploadedTo: toDateInclusive,
                        },
                      });
                      return defer(
                        deps.request.get(
                          `${operationsUrl(operation)}?skip=${skipVal}&limit=${
                            info.pageSize
                          }${queryString}`,
                          t.any
                        )
                      );
                    }),
                    RxOp.map(
                      E.fold<any, any, any>(
                        dispatchNetworkError,
                        recievedOperationsList
                      )
                    )
                  )
                )
              )
            ),
            RxOp.takeUntil(action$.pipe(ofType(actionTypes.EMPTY_DATA)))
          )
        )
      )
    )
  );

const operationsUrl = (operation: Operations) => {
  switch (operation) {
    case "MGP&MI":
      return "producer/misurePrezziBiddingPVI/upload";
    case "Disponibilita":
      return "producer/disponibilitaCapacitaMassima/upload";
    case "Combustibili":
      return "producer/datiCombustibili/report";
  }
};

export const downloadOperationsAction = (id: string): Actions => ({
  type: actionTypes.DOWNLOAD_OPERATION,
  id,
});
const downloadOperationsSucceededAction = (): Actions => ({
  type: actionTypes.DOWNLOAD_OPERATION_SUCCEED,
});

const downloadOperationsEpc = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.DOWNLOAD_OPERATION),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state: any) => {
          const operation = R.path(
            ["audits", key, "selectedOperation"],
            state
          ) as Operations;
          return R.equals("Combustibili", operation)
            ? pipe(
                [
                  deps.request.getXML(
                    `${operationsUrl(operation)}/${action.id}/emissioni`,
                    t.any
                  ),
                  deps.request.getXML(
                    `${operationsUrl(operation)}/${action.id}/costiSmaltimento`,
                    t.any
                  ),
                  deps.request.getXML(
                    `${operationsUrl(operation)}/${action.id}/ecotasse`,
                    t.any
                  ),
                  deps.request.getXML(
                    `${operationsUrl(operation)}/${
                      action.id
                    }/consumoCombustibile`,
                    t.any
                  ),
                ],
                taskSequence,
                TE.map((data: any[]) => {
                  return download({
                    fileName: `${operation} ${action.id}`,
                    data,
                    operation,
                  });
                }),
                defer
              )
            : defer(
                deps.request.get(
                  `${operationsUrl(operation)}/${action.id}`,
                  t.any
                )
              ).pipe(
                RxOp.map(
                  E.fold<any, any, any>(dispatchNetworkError, (data) => {
                    return download({
                      fileName: `${operation} ${action.id}`,
                      data,
                      operation,
                    });
                  })
                )
              );
        }),
        RxOp.map(
          E.fold(dispatchNetworkError, downloadOperationsSucceededAction)
        )
      )
    )
  );

export const fetchingAuditusers = (): Actions => ({
  type: actionTypes.FETCHING_AUDIT_LIST_DATA,
});

const recievedAuditUsers = (data: any) => ({
  type: actionTypes.RECIEVED_USERS_LIST_DATA,
  data,
});

const requestAuditUsersEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FETCHING_AUDIT_LIST_DATA ||
        action.type === actionTypes.FETCHING_OPERATIONS_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) =>
          permissionCheck({
            state,
            permission: permissions.auditRead,
          })
            ? of(state)
            : EMPTY
        ),
        RxOp.switchMap(() => defer(deps.request.get(`audit/users`, t.any))),
        RxOp.map(
          E.fold<any, any, any>(dispatchNetworkError, recievedAuditUsers)
        )
      )
    ),
    RxOp.switchMap(() => defer(deps.request.get(`audit/users`, t.any))),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, recievedAuditUsers))
  );

export const getPVIAction = (): Actions => ({
  type: actionTypes.SET_PVI_DATA,
});
const setPVIDataAction = (data: any): Actions => ({
  type: actionTypes.RECIEVED_PVI_DATA,
  data,
});
const pviEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.SET_PVI_DATA ||
        action.type === actionTypes.FETCHING_OPERATIONS_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state) => {
          return getAllRequests({
            deps,
            api: "core/ragionesocialepvi",
            filters: {
              partitaIva: R.path(
                ["productionData", key, "filters", "ragioneSociale"],
                state
              ),
            },
            dispatchError: dispatchNetworkError,
          });
        }),
        RxOp.map((x) => setPVIDataAction(R.map(R.pathOr(null, ["pvi"]), x)))
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  requestOperationsListDataEpic,
  downloadOperationsEpc,
  requestAuditUsersEpic,
  pviEpic
);

export const Selectors = {
  all: (s: any): reducerType => s.audits[key],
};
