import * as R from "ramda";
import { CurvesTypes, getCurveName } from "../production";

const style = {
  alignment: {
    horizontal: ["center"]
  },
  font: {
    bold: true
  },
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#99CC00",
    fgColor: "#99CC00"
  }
};

type StringCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const stringCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: StringCellType) =>
  sheet
    .cell(rowIndex, colIndex)
    .string(R.pathOr("", path, rowData))
    .style(style);

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {}
}: NumberCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .number(val)
        .style(style);
};

export const getAllPlants = (x: any) => R.ifElse(
  x => R.isEmpty(x) || R.isNil(x),
  () => [],
  R.pipe(
    R.toPairs,
    R.map(([curve, val]) => {
      return R.isNil(curve) || R.isEmpty(val)
        ? []
        : R.pipe(
            R.pathOr([], ["statistics"]),
            R.map(x => {return {pvi: R.pathOr(null, ["pvi"])(x), partitaIva: R.pathOr(null, ["partitaIva"])(x)}}),
          )(val);
    }),
    R.flatten,
    R.uniq
  )
)(x);

type GetCurveDataType = { data: any; plant: any; curve: CurvesTypes };
export const getCurveData = ({ data, plant, curve }: GetCurveDataType) =>
  R.pipe(
    R.pathOr(null, [curve]),
    R.pathOr([], ["statistics"]),
    R.filter(R.propEq("pvi", plant)),
    x =>
      R.isEmpty(x) || R.isNil(x)
        ? null
        : x.reduce((a: any, b: any) => a + b.sum, 0)
  )(data);

type BodyType = {
  sheet: any;
  data: any;
  pvis: any;
  ragioneSociale: any;
};

type SetRowType = { sheet: any; rowData: any; rowIndex: number };
const setRowData = ({ sheet, rowData, rowIndex }: SetRowType) => {
  const info = { sheet, rowData, rowIndex };

  stringCell({ ...info, colIndex: 1, path: ["ragioneSociale"] });
  stringCell({ ...info, colIndex: 2, path: ["plantName"] });
  numberCell({
    ...info,
    colIndex: 3,
    path: ["production"]
  });
  numberCell({
    ...info,
    colIndex: 4,
    path: ["forecast"]
  });
  numberCell({
    ...info,
    colIndex: 5,
    path: ["unbalance"]
  });

  return sheet;
};

const getPviName = ({ id, list }: { id: any; list: any }) =>
  R.pipe<any, any, any, any>(
    R.map(R.prop("pvi")),
    R.filter(R.propEq("pvi", id)),
    R.pathOr(id, [0, "nome"])
  )(list);

export const statisticsBody = ({ sheet, data, pvis, ragioneSociale }: BodyType) => {
  const tableData = R.ifElse(
    R.isEmpty,
    () => [],
    R.map((plant: any) => {
      const ragioneSocialeName = R.find(R.propEq('partitaIva', plant.partitaIva))(ragioneSociale).ragioneSociale
      plant = plant.pvi;
      return ({ 
        plant,
        plantName: getPviName({ id: plant, list: pvis }),
        ragioneSociale: ragioneSocialeName,
        production: getCurveData({ data, plant, curve: "production" }),
        forecast: getCurveData({ data, plant, curve: "forecast" }),
        unbalance: getCurveData({ data, plant, curve: "unbalance" })
      })
    })
  )(getAllPlants(data));

  tableData.map((rowData: any, i: any) =>
    setRowData({
      sheet,
      rowData,
      rowIndex: R.add(i, 2)
    })
  );
  return sheet;
};

export const statisticsHeader = ({ sheet }: { sheet: any }) => {
  sheet
    .cell(1, 1)
    .string("Ragione Sociale")
    .style(style);
  sheet
    .cell(1, 2)
    .string("Impianto")
    .style(style);
  sheet
    .cell(1, 3)
    .string(getCurveName("production"))
    .style(style);
  sheet
    .cell(1, 4)
    .string(getCurveName("forecast"))
    .style(style);
  sheet
    .cell(1, 5)
    .string(getCurveName("unbalance"))
    .style(style);
  return sheet;
};
