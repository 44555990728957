import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, defer, EMPTY, of, throwError } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError
} from "../errorHandler";
import { pagination } from "../../../utils/pagination";
import { searchBuilder } from "../../helper";
import { createComputedExcel } from "./coefficientiUP/excel";
import { formJson } from "./coefficientiUP/upload";
import { format as tzFormat } from "date-fns-tz";
import { format, addDays } from "date-fns";
import {
  NetworkRequest,
  notStarted,
  fail,
  success,
  inProgress
} from "../../../utils/request";
import { wrapExhaustiveReducer } from "../../../utils/redux";

export const key = "coefficientiUP";

export enum actionTypes {
  EMPTY_DATA = "rtm/market/coefficientiUP/EMPTY_DATA",
  CANCEL = "rtm/market/coefficientiUP/CANCEL",
  EXPORT_DATA = "rtm/market/coefficientiUP/EXPORT_DATA",
  EXPORT_DATA_DOWNLOADED = "rtm/market/coefficientiUP/EXPORT_DATA_DOWNLOADED",
  UPLOAD_DATA = "rtm/market/coefficientiUP/UPLOAD_DATA",
  UPLOAD_DATA_SUCCEEDED = "rtm/market/coefficientiUP/UPLOAD_DATA_SUCCEEDED",
  UPLOAD_DATA_SUCCEEDED_CLOSE = "rtm/market/coefficientiUP/UPLOAD_DATA_SUCCEEDED_CLOSE",
  SET_FILTER_DATA = "rtm/market/coefficientiUP/SET_FILTER_DATA"
}

type Actions =
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.EXPORT_DATA }
  | { type: actionTypes.EXPORT_DATA_DOWNLOADED }
  | { type: actionTypes.UPLOAD_DATA; file: any }
  | { type: actionTypes.UPLOAD_DATA_SUCCEEDED }
  | { type: actionTypes.UPLOAD_DATA_SUCCEEDED_CLOSE }
  | { type: actionTypes.EMPTY_DATA }
  | {
      type: actionTypes.SET_FILTER_DATA;
      filters: any;
    };

export type FilterTablesStateType = {
  from: string | null;
  to: string | null;
  zona: string[] | null;
  codiceUp: any;
};

const dPlus1 = addDays(new Date(), 1);
const filtersState = {
  from: format(dPlus1, "yyyy-MM-dd"),
  to: format(dPlus1, "yyyy-MM-dd"),
  zona: null,
  codiceUp: null
};

export function filtersReducer(
  state = filtersState as FilterTablesStateType,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA:
      return {
        ...action.filters
      };
    default:
      return state;
  }
}

function uploadSucceedReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.UPLOAD_DATA_SUCCEEDED_CLOSE:
      return false;
    case actionTypes.UPLOAD_DATA_SUCCEEDED:
      return true;
    default:
      return state;
  }
}
function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.EXPORT_DATA_DOWNLOADED:
    case actionTypes.CANCEL:
    case actionTypes.UPLOAD_DATA_SUCCEEDED:
      return false;
    case actionTypes.UPLOAD_DATA:
    case actionTypes.EXPORT_DATA:
      return true;
    default:
      return state;
  }
}

export const getUpProviderConfig = {
  type: "rtm/market/coefficientiUP/GET_UP_PROVIDER_CONFIG"
} as const;
export const resetUpProviderConfig = {
  type: "rtm/market/coefficientiUP/RESET_UP_PROVIDER_CONFIG"
} as const;
const receivedProviderConfig = (data: CoefficientiUPResponse) =>
  ({
    type: "rtm/market/coefficientiUP/RECEIVED_UP_PROVIDER_CONFIG",
    data
  } as const);
const failedUpProviderConfig = () =>
  ({
    type: "rtm/market/coefficientiUP/FAILED_UP_PROVIDER_CONFIG"
  } as const);

type UPProviderAction =
  | typeof getUpProviderConfig
  | typeof resetUpProviderConfig
  | ReturnType<typeof failedUpProviderConfig>
  | ReturnType<typeof receivedProviderConfig>;

function upConfigReducer(
  state: NetworkRequest<string, CoefficientiUPResponse>,
  action: UPProviderAction
): NetworkRequest<string, CoefficientiUPResponse> {
  switch (action.type) {
    case "rtm/market/coefficientiUP/GET_UP_PROVIDER_CONFIG":
      return inProgress;
    case "rtm/market/coefficientiUP/RESET_UP_PROVIDER_CONFIG":
      return notStarted;
    case "rtm/market/coefficientiUP/FAILED_UP_PROVIDER_CONFIG":
      return fail("Fetch failed");
    case "rtm/market/coefficientiUP/RECEIVED_UP_PROVIDER_CONFIG":
      return success(action.data);
  }
}

export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  filters: filtersReducer,
  uploadSucceed: uploadSucceedReducer,
  upConfig: wrapExhaustiveReducer(upConfigReducer, notStarted)
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA
});

export const setFiltersTableData = (
  filters: FilterTablesStateType
): Actions => ({
  type: actionTypes.SET_FILTER_DATA,
  filters
});

type GetCoefficientiUPType = {
  deps: any;
  page?: number;
  pageSize?: number;
  filters?: any;
};
const getCoefficientiUP = ({
  deps,
  page = 1,
  pageSize = 300,
  filters
}: GetCoefficientiUPType) => {
  const skipVal = R.multiply(R.subtract(page, 1), pageSize);
  const queryString = searchBuilder({
    filters: { ...filters }
  });
  return of(
    `bidding/coefficientiUP/timeserie?skip=${skipVal}&limit=${pageSize}${queryString}`
  ).pipe(
    RxOp.switchMap(url => defer(deps.request.get(url, pagination(t.any)))),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, x => x))
  );
};

const getAllCoefficientiUP = ({
  deps,
  filters
}: GetCoefficientiUPType): any => {
  return getCoefficientiUP({
    deps,
    filters
  }).pipe(
    RxOp.expand((x: any) => {
      return x.isCountPartial || x.limit + x.skip < x.count
        ? getCoefficientiUP({
            deps,
            filters,
            page: 1 + (x.skip + x.limit) / x.limit
          })
        : EMPTY;
    }),
    RxOp.map((x: any) => x.data),
    RxOp.concatMap(x => x),
    RxOp.toArray()
  );
};

export const exportDataAction = (): Actions => ({
  type: actionTypes.EXPORT_DATA
});

const exportDataDownloaded = (): Actions => ({
  type: actionTypes.EXPORT_DATA_DOWNLOADED
});

const exportDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_DATA),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state: any) => {
          const info = R.path(
            ["management", key, "filters"],
            state
          ) as FilterTablesStateType;
          const allZones = R.map(R.prop("zona"), info.codiceUp) as any;
          return of(allZones).pipe(
            RxOp.flatMap(x => x),
            RxOp.flatMap((zona: any) => {
              const allZonaCodice = R.pipe<any, any, any>(
                R.filter(R.propEq("zona", zona)),
                R.path([0, "list"])
              )(info.codiceUp);

              const toDateInclusive = addDays(new Date(info.to as string), 1);

              const filters = {
                zona,
                codiceUp: allZonaCodice,
                to: tzFormat(toDateInclusive, "yyyy-MM-dd'T'00:00:00XXX", {
                  timeZone: "Europe/Rome"
                }),
                from: tzFormat(
                  new Date(info.from as any),
                  "yyyy-MM-dd'T'00:00:00XXX",
                  {
                    timeZone: "Europe/Rome"
                  }
                )
              };
              return getAllCoefficientiUP({
                deps,
                filters
              });
            }),
            RxOp.toArray(),
            RxOp.map(data => {
              return createComputedExcel({
                fileName: `CoefficientiUP`,
                data: R.flatten(data)
              });
            }),
            RxOp.map(exportDataDownloaded)
          );
        }),
        RxOp.catchError(dispatchNetworkError)
      )
    )
  );

export const uploadDataAction = (file: any): Actions => ({
  type: actionTypes.UPLOAD_DATA,
  file
});
const uploadSucceededAction = (): Actions => ({
  type: actionTypes.UPLOAD_DATA_SUCCEEDED
});
export const uploadSucceededCloseAction = (): Actions => ({
  type: actionTypes.UPLOAD_DATA_SUCCEEDED_CLOSE
});

const uploadDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.UPLOAD_DATA),
    RxOp.switchMap((action: any) => {
      return R.isNil(action.file) ? EMPTY : of(action);
    }),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap(() => formJson({ file: action.file })),
        RxOp.flatMap((x: any) => x),
        RxOp.switchMap((data: any) => {
          const headVals = [
            "up",
            "zona",
            "date",
            "ora",
            "coefficientea",
            "coefficienteb"
          ];
          const fromDate = data[0].zona;
          const toDate = data[1].zona;
          const headers = R.pipe<any, any, any, any>(
            R.values,
            R.map((r: string) => R.contains(R.toLower(r), headVals)),
            R.all(R.equals(true))
          )(data[2]);

          if (R.isNil(fromDate)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "From Date Invalid"
            });
          }
          if (R.isNil(toDate)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "To Date Invalid"
            });
          }

          if (R.equals(headers, false)) {
            return throwError({
              customError: true,
              status: "File Upload",
              errorMessage: "Missing header or incorrect"
            });
          }
          return of(data);
        }),
        RxOp.map((x: any) => {
          return {
            from: `${format(dateFormatHandler(x[0].zona), "yyyy-MM-dd")}`,
            to: `${format(dateFormatToHandler(x[1].zona), "yyyy-MM-dd")}`,
            records: recordData(x)
          };
        }),
        RxOp.switchMap((body: any) => {
          return defer(
            deps.request.post(`bidding/coefficientiUP/upload`, body, t.any)
          );
        }),
        RxOp.map(E.fold<any, any, any>(x => x, x => x)),
        RxOp.toArray(),
        RxOp.map((x: any) => {
          const anyFail = R.filter((r: any) => r.status >= 400, x);
          if (R.isEmpty(anyFail)) {
            return uploadSucceededAction();
          }
          return dispatchNetworkError(anyFail[0]);
        }),
        RxOp.catchError((err: any) =>
          of(err).pipe(RxOp.map(dispatchNetworkError))
        )
      )
    )
  );

const getUpProviderEpic = (
  action$: Observable<UPProviderAction>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.mergeMap(x =>
      x.type === "rtm/market/coefficientiUP/GET_UP_PROVIDER_CONFIG"
        ? of(x)
        : EMPTY
    ),
    RxOp.mergeMap(
      deps.request.get("configuration/upProvider", ConfigUpProvider)
    ),
    RxOp.map(
      E.fold<string, CoefficientiUPResponse, UPProviderAction>(
        failedUpProviderConfig,
        receivedProviderConfig
      )
    )
  );

const dateFormatHandler = R.pipe<any, any, any, any>(
  R.split(/\\|\/|-/g),
  R.map((x: any) => parseInt(x)),
  (x: any) => {
    const year = x[2].toString().length > 2 ? x[2] : `20${x[2]}`;
    const t = [year, x[1], x[0]] as any;
    return new Date(t);
  }
);

const dateFormatToHandler = R.pipe<any, any, any, any>(
  R.split(/\\|\/|-/g),
  R.map((x: any) => {
    return parseInt(x);
  }),
  (x: any) => {
    const year = x[2].toString().length > 2 ? x[2] : `20${x[2]}`;
    const t = [year, x[1], x[0]] as any;
    const q = addDays(new Date(t), 1);
    return q;
  }
);
const recordData = R.pipe<any, any, any, any, any, any>(
  R.remove(0, 3),
  R.groupBy((x: any) => `${x.codiceUp}*-*${x.zona}*-*${x.date}`),
  R.toPairs,
  R.map(([key, data]: any) => {
    const keyData = R.split("*-*", key);

    return data.map((row: any) => {
      const coefficienteAVal = R.pathOr(1, ["coefficienteA"], row) as any;
      const coefficienteBVal = R.pathOr(0, ["coefficienteB"], row) as any;

      return {
        codiceUp: keyData[0],
        zona: keyData[1],
        dateTimeOffset: getDatetimeOffsetTest(row.date, row.hour),
        coefficienteA: parseFloat(coefficienteAVal),
        coefficienteB: parseFloat(coefficienteBVal)
      };
    });
  }),
  R.flatten
);

const dateFormatHourHandler = (date: any, h: any) =>
  R.pipe<any, any, any>(
    R.split(/\\|\/|-/g),
    (x: any) => {
      return `${x[2]}-${x[1]}-${x[0]}T${h.length > 1 ? h : `0${h}`}:00:00`;
    }
  )(date);

const getDatetimeOffsetTest = (date: string, hour: number) => {
  return `${tzFormat(
    new Date(dateFormatHourHandler(date, hour)),
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    {
      timeZone: "Europe/Rome"
    }
  )}`;
};

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  exportDataEpic,
  uploadDataEpic,
  getUpProviderEpic
);

export const Selectors: { all: (a: any) => State } = {
  all: x => x.management[key]
};

const ConfigUpProvider = t.type({
  type: t.string,
  eTag: t.string,
  table: t.array(
    t.type({
      up: t.string,
      providerName: t.string,
      providerNameBackup: t.string
    })
  )
});

type CoefficientiUPResponse = t.TypeOf<typeof ConfigUpProvider>;
export type CoefficientiUP = CoefficientiUPResponse["table"][0];
type State = ReturnType<typeof reducer>;
