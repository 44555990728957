import * as R from "ramda";
import { combineReducers } from "redux";
import { combineEpics, ofType } from "redux-observable";
import { Observable, defer, of, EMPTY, timer, merge } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import { option } from "../../../components/singleSelectMain/helper";
import { searchBuilder, downloadXml } from "../../helper";
import { createComputedExcel } from "./excel";
import { addDays, format } from "date-fns";
import { pipe } from "fp-ts/lib/pipeable";

export const key = "processBidding";

export enum actionTypes {
  EMPTY_DATA = "rtm/market/processBidding/EMPTY_DATA",
  PROCESS_BIDDING_LIST_REQUEST = "rtm/market/processBidding/PROCESS_BIDDING_LIST_REQUEST",
  PROCESS_BIDDING_LIST_FETCHINNG = "rtm/market/processBidding/PROCESS_BIDDING_LIST_FETCHINNG",
  PROCESS_BIDDING_LIST_DATA = "rtm/market/processBidding/PROCESS_BIDDING_LIST_DATA",
  PROCESS_BIDDING_LIST_PAGE = "rtm/market/processBidding/PROCESS_BIDDING_LIST_PAGE",
  PROCESS_BIDDING_LIST_FILTERS = "rtm/market/processBidding/PROCESS_BIDDING_LIST_FILTERS",
  CHECK_BID_RUNNING = "rtm/market/processBidding/CHECK_BID_RUNNING",
  BID_RUNNING_INFO = "rtm/market/processBidding/BID_RUNNING_INFO",
  GET_BID_INFO_DATA = "rtm/market/processBidding/GET_BID_INFO_DATA",
  SET_BID_INFO_DATA = "rtm/market/processBidding/SET_BID_INFO_DATA",
  CLEAR_BID_INFO_DATA = "rtm/market/processBidding/CLEAR_BID_INFO_DATA",
  OPEN_PROCESS_MODAL = "rtm/market/processBidding/OPEN_PROCESS_MODAL",
  CLOSE_PROCESS_MODAL = "rtm/market/processBidding/CLOSE_PROCESS_MODAL",
  UPDATE_PROCESS_MODAL_INFO = "rtm/market/processBidding/UPDATE_PROCESS_MODAL_INFO",
  CREATE_NEW_PROCESS = "rtm/market/processBidding/CREATE_NEW_PROCESS",
  EXPORT_COMPUTED_BIDDING = "rtm/market/processBidding/EXPORT_COMPUTED_BIDDING",
  COMPUTED_BIDDING_DOWNLOADED = "rtm/market/processBidding/COMPUTED_BIDDING_DOWNLOADED",
  NEW_BID_SENT = "rtm/market/processBidding/NEW_BID_SENT",
  NEW_BID_CLOSE = "rtm/market/processBidding/NEW_BID_CLOSE",
  BIDDING_RUN_STATUS = "rtm/market/processBidding/BIDDING_RUN_STATUS",
  EXPORT_IPEX = "rtm/market/processBidding/EXPORT_IPEX",
  EXPORT_IPEX_COMPLETE = "rtm/market/processBidding/EXPORT_IPEX_COMPLETE",
  CANCEL = "rtm/market/processBidding/CANCEL",
}

type Actions =
  | { type: actionTypes.PROCESS_BIDDING_LIST_REQUEST }
  | { type: actionTypes.PROCESS_BIDDING_LIST_FETCHINNG }
  | { type: actionTypes.PROCESS_BIDDING_LIST_DATA; data: any; count: number }
  | { type: actionTypes.PROCESS_BIDDING_LIST_PAGE; page: number }
  | {
      type: actionTypes.PROCESS_BIDDING_LIST_FILTERS;
      filters: ProcessBiddingListFilterStateType;
    }
  | { type: actionTypes.CHECK_BID_RUNNING }
  | { type: actionTypes.BID_RUNNING_INFO; info: ProcessRunningType }
  | { type: actionTypes.GET_BID_INFO_DATA; id: string }
  | { type: actionTypes.SET_BID_INFO_DATA; data: any }
  | { type: actionTypes.CLEAR_BID_INFO_DATA }
  | { type: actionTypes.OPEN_PROCESS_MODAL }
  | { type: actionTypes.CLOSE_PROCESS_MODAL }
  | { type: actionTypes.UPDATE_PROCESS_MODAL_INFO; info: ProcessModalInfoType }
  | { type: actionTypes.CREATE_NEW_PROCESS; info: ProcessModalInfoType }
  | { type: actionTypes.EXPORT_COMPUTED_BIDDING }
  | { type: actionTypes.COMPUTED_BIDDING_DOWNLOADED }
  | { type: actionTypes.NEW_BID_SENT }
  | { type: actionTypes.NEW_BID_CLOSE }
  | { type: actionTypes.BIDDING_RUN_STATUS; status: any }
  | { type: actionTypes.EXPORT_IPEX; id: string }
  | { type: actionTypes.EXPORT_IPEX_COMPLETE; res: any }
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.EMPTY_DATA };

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.CHECK_BID_RUNNING:
    case actionTypes.GET_BID_INFO_DATA:
    case actionTypes.EXPORT_COMPUTED_BIDDING:
    case actionTypes.EXPORT_IPEX:
      return true;
    case actionTypes.CANCEL:
    case actionTypes.BID_RUNNING_INFO:
    case actionTypes.SET_BID_INFO_DATA:
    case actionTypes.COMPUTED_BIDDING_DOWNLOADED:
    case actionTypes.EXPORT_IPEX_COMPLETE:
      return false;
    default:
      return state;
  }
}

function newBidSucceedReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.NEW_BID_CLOSE:
      return false;
    case actionTypes.NEW_BID_SENT:
      return true;
    default:
      return state;
  }
}

export type ProcessBiddingListFilterStateType = {
  from: string | null;
  to: string | null;
  state: option[] | null;
};
export type ProcessBiddingListStateType = {
  fetching: boolean;
  data: any;
  count: number;
  page: number;
  pageSize: number;
  sort: string | null;
  filters: ProcessBiddingListFilterStateType;
};

const processBiddingListState = {
  fetching: false,
  data: [],
  count: 0,
  page: 1,
  pageSize: 50,
  sort: null,
  filters: {
    from: format(new Date(), "yyyy-MM-dd'T'00:00:00"),
    to: format(new Date(), "yyyy-MM-dd'T'00:00:00"),
    state: null,
  },
};

export function processBiddingListReducer(
  state = processBiddingListState as ProcessBiddingListStateType,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.PROCESS_BIDDING_LIST_FETCHINNG:
      return { ...state, fetching: true };
    case actionTypes.PROCESS_BIDDING_LIST_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        count: action.count,
      };
    case actionTypes.PROCESS_BIDDING_LIST_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case actionTypes.PROCESS_BIDDING_LIST_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case actionTypes.CANCEL:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.EMPTY_DATA:
      return processBiddingListState;
    default:
      return state;
  }
}

export type ProcessRunningType = {
  isRunning: boolean;
  exceptionMessage: string | null;
  biddingRunId: number | null;
};
const processRunningState = {
  isRunning: false,
  exceptionMessage: null,
  biddingRunId: null,
};
const processRunningReducer = (
  state: ProcessRunningType = processRunningState,
  action: Actions
): any => {
  switch (action.type) {
    case actionTypes.BID_RUNNING_INFO:
      return action.info;
    case actionTypes.EMPTY_DATA:
      return false;
    default:
      return state;
  }
};

export type BidRunType =
  | "Starting"
  | "Computing"
  | "SendingBidEvo"
  | "SentToBidEvo"
  | "SentToBidEvoWithWarning"
  | "SendingXDM"
  | "SentToXDM"
  | "SentToXDMWithWarning"
  | "Failed"
  | null;

const bidRunStatusReducer = (
  state: BidRunType = null,
  action: Actions
): any => {
  switch (action.type) {
    case actionTypes.BIDDING_RUN_STATUS:
      return action.status;
    case actionTypes.EMPTY_DATA:
      return null;
    default:
      return state;
  }
};

const bidInfoDataReducer = (state: any = {}, action: Actions) => {
  switch (action.type) {
    case actionTypes.SET_BID_INFO_DATA:
      return action.data;
    case actionTypes.CLEAR_BID_INFO_DATA:
    case actionTypes.EMPTY_DATA:
      return {};
    default:
      return state;
  }
};

export type ProcessModalType = {
  open: boolean;
  info: ProcessModalInfoType;
};
const processModalState = {
  open: false,
  info: {
    date: null,
    market: null,
    biddingBidEvoTarget: null,
    applyCoefficientiUp: null,
  },
};
const processModalReducer = (
  state: ProcessModalType = processModalState,
  action: Actions
) => {
  switch (action.type) {
    case actionTypes.OPEN_PROCESS_MODAL:
      return {
        ...state,
        open: true,
      };
    case actionTypes.UPDATE_PROCESS_MODAL_INFO:
      return {
        ...state,
        info: action.info,
      };
    case actionTypes.CREATE_NEW_PROCESS:
      return {
        ...state,
        open: false,
        info: action.info,
      };
    case actionTypes.CLOSE_PROCESS_MODAL:
    case actionTypes.NEW_BID_SENT:
      return processModalState;
    default:
      return state;
  }
};

export type reducerType = {
  isFetching: boolean;
  processBiddingList: ProcessBiddingListStateType;
  processRunning: ProcessRunningType;
  bidInfoData: any;
  processModal: ProcessModalType;
  newBidSucceed: boolean;
  bidRunStatus: BidRunType;
};
export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  processBiddingList: processBiddingListReducer,
  processRunning: processRunningReducer,
  bidInfoData: bidInfoDataReducer,
  processModal: processModalReducer,
  newBidSucceed: newBidSucceedReducer,
  bidRunStatus: bidRunStatusReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });
export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA,
});

export const requestProcessBiddingListAction = (): Actions => ({
  type: actionTypes.PROCESS_BIDDING_LIST_REQUEST,
});
const fetchingProcessBiddingListAction = (): Actions => ({
  type: actionTypes.PROCESS_BIDDING_LIST_FETCHINNG,
});
export const setProcessBiddingListPageAction = (page: number): Actions => ({
  type: actionTypes.PROCESS_BIDDING_LIST_PAGE,
  page,
});
export const setProcessBiddingListFiltersAction = (
  filters: ProcessBiddingListFilterStateType
): Actions => ({
  type: actionTypes.PROCESS_BIDDING_LIST_FILTERS,
  filters,
});
const setProcessBiddingListDataAction = ({
  data,
  count,
}: {
  data: any;
  count: number;
}): Actions => ({
  type: actionTypes.PROCESS_BIDDING_LIST_DATA,
  data,
  count,
});

const processBiddingListEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.NEW_BID_SENT ||
        action.type === actionTypes.PROCESS_BIDDING_LIST_REQUEST ||
        action.type === actionTypes.PROCESS_BIDDING_LIST_PAGE ||
        action.type === actionTypes.PROCESS_BIDDING_LIST_FILTERS
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const tableInfo = R.path(
            ["management", key, "processBiddingList", "filters"],
            state
          ) as ProcessBiddingListStateType;

          const anyNull = R.pipe<any, any, any, any, any>(
            R.pick(["from", "to"]),
            R.map(R.isNil),
            R.values,
            R.any(R.equals(true))
          )(tableInfo);
          return anyNull ? EMPTY : of(state);
        }),
        RxOp.mergeMap((state: any) =>
          of(state).pipe(
            RxOp.switchMap(() => processBiddingRun(state, deps)),
            RxOp.startWith(fetchingProcessBiddingListAction())
          )
        )
      )
    )
  );

export const checkBidRunningAction = (): Actions => ({
  type: actionTypes.CHECK_BID_RUNNING,
});
const isBidRunningAction = (info: ProcessRunningType): Actions => ({
  type: actionTypes.BID_RUNNING_INFO,
  info,
});

const requestCheckBidRunningEpic = (
  action$: Observable<Actions>,
  _state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.CHECK_BID_RUNNING),
    RxOp.switchMap(() => {
      return timer(100, 10000).pipe(
        RxOp.takeUntil(action$.pipe(ofType(actionTypes.EMPTY_DATA))),
        RxOp.exhaustMap(() =>
          defer(deps.request.get(`bidding/process`, t.any))
        ),
        RxOp.map(E.fold<any, any, any>(() => cancel(), isBidRunningAction))
      );
    })
  );

const bidRunStatusAction = (status: BidRunType): Actions => ({
  type: actionTypes.BIDDING_RUN_STATUS,
  status,
});
const requestBidRunStatusEpic = (
  action$: Observable<Actions>,
  _state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.BID_RUNNING_INFO),
    RxOp.exhaustMap((action: any) =>
      merge(
        pipe(
          defer(
            deps.request.get(`bidding/run/${action.info.biddingRunId}`, t.any)
          ),
          RxOp.map(
            E.fold<any, any, any>(
              () => cancel(),
              (x) => bidRunStatusAction(R.pathOr(null, ["state"], x))
            )
          )
        ),
        pipe(
          _state$,
          RxOp.first(),
          RxOp.exhaustMap((state) => processBiddingRun(state, deps))
        )
      )
    )
  );

export const openProcessModalAction = (): Actions => ({
  type: actionTypes.OPEN_PROCESS_MODAL,
});
export const closeProcessModalAction = (): Actions => ({
  type: actionTypes.CLOSE_PROCESS_MODAL,
});
export const sentBidSentAction = (): Actions => ({
  type: actionTypes.NEW_BID_SENT,
});
export const sentBidCloseAction = (): Actions => ({
  type: actionTypes.NEW_BID_CLOSE,
});
export type ProcessModalInfoType = {
  date: string | null;
  market: "MGP" | "MI1" | "MI2" | "MI3" | "MI4" | "MI5" | "MI6" | "MI7" | null;
  biddingBidEvoTarget: "Auto" | "Manual1" | "Manual2" | null;
  applyCoefficientiUp: "PVIProviderOnly" | "Never" | "Always" | null;
};

export const updateProcessModalAction = (
  info: ProcessModalInfoType
): Actions => ({
  type: actionTypes.UPDATE_PROCESS_MODAL_INFO,
  info,
});
export const createProcessModalAction = (
  info: ProcessModalInfoType
): Actions => ({
  type: actionTypes.CREATE_NEW_PROCESS,
  info,
});

const createProcessEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.CREATE_NEW_PROCESS),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state: any) =>
          defer(deps.request.post(`bidding/run`, action.info, t.any))
        ),
        RxOp.map(E.fold<any, any, any>(dispatchNetworkError, sentBidSentAction))
      )
    )
  );

export const requestBidInfoDataAction = (id: any): Actions => ({
  type: actionTypes.GET_BID_INFO_DATA,
  id,
});
const setBidInfoDataAction = (data: any): Actions => ({
  type: actionTypes.SET_BID_INFO_DATA,
  data,
});
export const clearBidInfoDataAction = (): Actions => ({
  type: actionTypes.CLEAR_BID_INFO_DATA,
});

const requestBidInfoDataEpic = (
  action$: Observable<Actions>,
  _state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.GET_BID_INFO_DATA),
    RxOp.switchMap((action: any) =>
      defer(deps.request.get(`bidding/run/${action.id}`, t.any))
    ),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, setBidInfoDataAction))
  );
export const exportComputedBiddingAction = (): Actions => ({
  type: actionTypes.EXPORT_COMPUTED_BIDDING,
});

const computedBiddingDownloaded = (): Actions => ({
  type: actionTypes.COMPUTED_BIDDING_DOWNLOADED,
});

const exportComputedBiddingEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_COMPUTED_BIDDING),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state) => {
          const bidInfoData = R.path(
            ["management", key, "bidInfoData"],
            state
          ) as any;
          return createComputedExcel({
            fileName: `Computed Bidding ${bidInfoData.id}`,
            data: bidInfoData,
            market: bidInfoData.parameters.market,
          });
        }),
        RxOp.map(computedBiddingDownloaded)
      )
    )
  );

export const exportIpexAction = (id: string): Actions => ({
  type: actionTypes.EXPORT_IPEX,
  id,
});
const exportIpexCompletedAction = (res: any): Actions => ({
  type: actionTypes.EXPORT_IPEX_COMPLETE,
  res,
});

const exportIpexEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_IPEX),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state: any) => {
          const id = R.path(
            ["management", key, "bidInfoData", "id"],
            state
          ) as any;
          return defer(
            deps.request.postXML(`bidding/run/${id}/ipex`, { id }, t.any)
          );
        }),
        RxOp.map(
          E.fold<any, any, any>(dispatchNetworkError, exportIpexCompletedAction)
        )
      )
    )
  );
const exportIpexCompletedEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_IPEX_COMPLETE),
    RxOp.exhaustMap(({ res }: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state: any) => {
          const id = R.path(
            ["management", key, "bidInfoData", "id"],
            state
          ) as any;
          return downloadXml({
            data: res,
            fileName: `IPEX ${id}`,
            fileType: "xml",
          });
        }),
        RxOp.switchMapTo(EMPTY)
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  processBiddingListEpic,
  requestCheckBidRunningEpic,
  requestBidInfoDataEpic,
  createProcessEpic,
  exportComputedBiddingEpic,
  requestBidRunStatusEpic,
  exportIpexEpic,
  exportIpexCompletedEpic
);

export const Selectors = {
  all: (s: any): reducerType => s.management[key],
};

function processBiddingRun(state: any, deps: Dependancies) {
  const tableInfo = R.path(
    ["management", key, "processBiddingList"],
    state
  ) as ProcessBiddingListStateType;

  const skipVal = R.multiply(R.subtract(tableInfo.page, 1), tableInfo.pageSize);

  const toDateInclusive = R.pipe(
    (x: any) => addDays(x, 1),
    (x: any) => format(x, "yyyy-MM-dd'T'00:00:00")
  )(new Date(tableInfo.filters.to as string));

  const queryString = searchBuilder({
    filters: {
      from: `${tableInfo.filters.from}Z`,
      to: `${toDateInclusive}Z`,
      state: tableInfo.filters.state,
    },
  });
  return pipe(
    defer(
      deps.request.get(
        `bidding/run?limit=${tableInfo.pageSize}&skip=${skipVal}&${queryString}`,
        t.any
      )
    ),
    RxOp.map(
      E.fold<any, any, any>(
        dispatchNetworkError,
        setProcessBiddingListDataAction
      )
    )
  );
}
