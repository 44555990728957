import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  key as AuditKey,
  reducer as AuditReducer,
  epic as AuditEpic
} from "./audit";
import {
  key as ExecutedOperationsKey,
  reducer as ExecutedOperationsReducer,
  epic as ExecutedOperationsEpic
} from "./executedOperations";

export const reducer = combineReducers({
  [AuditKey]: AuditReducer,
  [ExecutedOperationsKey]: ExecutedOperationsReducer
});

export const epic = combineEpics(AuditEpic, ExecutedOperationsEpic);
