import React from "react";
import * as R from "ramda";
import { withRouter, match } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Selectors } from "../redux/modules/audits/executedOperations";
import Protected from "../protectedComponent";
import permissions from "../permissions";
import ForecastDeal from "./forecastDeal";
import ActualDeal from "./actualDeal";

type ExecutedOperationType = { match: match };

const ExecutedOperation = ({ match }: ExecutedOperationType) => {
  const deal = R.pipe<any, any, any, any>(
    R.prop("path"),
    R.split("/Gestione/ProcessoInserimentoContratti/"),
    R.path([1])
  )(match);

  return (
    <div className="pageContainer">
      <h1>Processo Inserimento Contratti</h1>
      <ExecutedOperationHeader />
      <Deals deal={deal} />
    </div>
  );
};

export default R.compose<any, any, any>(
  withRouter,
  connect(
    (s: any) => ({
      ...Selectors.all(s)
    }),
    {}
  )
)(ExecutedOperation);

const ExecutedOperationHeader = () => {
  return (
    <div className="componentNav d-flex justify-content-center flex-row">
      <div className="componentNavHolder contratti">
        <Protected
          permission={[permissions.dealCaptureRead]}
          component={() => (
            <NavLink
              activeClassName="active"
              className="selection-label"
              isActive={(match: any, location: any) => {
                return (
                  match && (R.contains(match.url, location.pathname) as any)
                );
              }}
              to={`/Gestione/ProcessoInserimentoContratti/ForecastDeal`}
            >
              Forecast Deal
            </NavLink>
          )}
        />
        <Protected
          permission={[permissions.dealCaptureRead]}
          component={() => (
            <NavLink
              activeClassName="active"
              className="selection-label"
              isActive={(match: any, location: any) => {
                return (
                  match && (R.contains(match.url, location.pathname) as any)
                );
              }}
              to={`/Gestione/ProcessoInserimentoContratti/ActualDeal`}
            >
              Actual Deal
            </NavLink>
          )}
        />
      </div>
    </div>
  );
};

type Deals = "ForecastDeal" | "ActualDeal";

type DealsType = {
  deal: Deals;
};

const Deals = ({ deal }: DealsType): any => {
  switch (deal) {
    case "ActualDeal":
      return <ActualDeal />;
    case "ForecastDeal":
    default:
      return <ForecastDeal />;
  }
};
