import React from "react";

export const AddBtn = ({
  havePermission,
  addNewRow
}: {
  havePermission: boolean;
  addNewRow: () => void;
}) => {
  return havePermission ? (
    <th className="buttonTh" style={{ width: "150px", minWidth: "150px" }}>
      <button type="button" className="addButton" onClick={addNewRow}>
        <i className="fas fa-plus" />
      </button>
    </th>
  ) : null;
};

export default AddBtn;
