import * as R from "ramda";
import { combineReducers } from "redux";
import { combineEpics, ofType } from "redux-observable";
import { Observable, defer } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { DiffTools } from "ark-tools";
import { format, addDays } from "date-fns";
import { Dependancies } from "../../storeTypes";
import { pagination } from "../../../utils/pagination";
import { simpleSetReducerCreator } from "../../reducerCreators";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import * as fuel from "../configs/fuel";
import * as internalLegs from "../configs/internalLegs";
import * as pviProvider from "../configs/pviProvider";
import * as spreadmgp from "../configs/spreadmgp";
import * as tensione from "../configs/tensione";
import * as upbackup from "../configs/upbackup";
import * as upProvider from "../configs/upProvider";

const { diff, diffExcelBuilder } = DiffTools;

export const key = "audit";
export const kindOptions = [
  "PVIProvider",
  "UPProvider",
  "Tensione",
  "UPBackup",
  "InternalLegs",
  "Fuel",
  "SpreadMGP",
];

export enum actionTypes {
  EMPTY_DATA = "rtm/audits/audit/EMPTY_DATA",
  CANCEL = "rtm/audits/audit/CANCEL",
  FETCHING_OFF = "rtm/audits/audit/FETCHING_OFF",
  FETCHING_USERS_LIST_DATA = "rtm/audits/audit/FETCHING_USERS_LIST_DATA",
  RECIEVED_USERS_LIST_DATA = "rtm/audits/audit/RECIEVED_USERS_LIST_DATA",
  FETCHING_AUDIT_LIST_DATA = "rtm/audits/audit/FETCHING_AUDIT_LIST_DATA",
  RECIEVED_AUDIT_LIST_DATA = "rtm/audits/audit/RECIEVED_AUDIT_LIST_DATA",
  SET_PAGE_AUDIT_LIST_DATA = "rtm/audits/audit/SET_PAGE_AUDIT_LIST_DATA",
  ORDER_BY_AUDIT_LIST_DATA = "rtm/audits/audit/ORDER_BY_AUDIT_LIST_DATA",
  SET_PAGE_SIZE_AUDIT_LIST_DATA = "rtm/audits/audit/SET_PAGE_SIZE_AUDIT_LIST_DATA",
  SET_FILTER_AUDIT_LIST_DATA = "rtm/audits/audit/SET_FILTER_AUDIT_LIST_DATA",
  SET_SELECTED_AUDIT_ID = "rtm/audits/audit/SET_SELECTED_AUDIT_ID",
  RECIEVED_SELECTED_AUDIT_ID_DATA = "rtm/audits/audit/SET_SELECTED_AUDIT_ID_DATA",
  SET_AUDIT_ID_DIFF_DATA = "rtm/audits/audit/SET_AUDIT_ID_DIFF_DATA",
  EXPORT_AUDIT_ID_DIFF_DATA = "rtm/audits/audit/EXPORT_AUDIT_ID_DIFF_DATA",
  EXPORT_AUDIT_ID_DIFF_CREATED = "rtm/audits/audit/EXPORT_AUDIT_ID_DIFF_CREATED",
}

type Actions =
  | {
      type: actionTypes.FETCHING_USERS_LIST_DATA;
    }
  | {
      type: actionTypes.CANCEL;
    }
  | {
      type: actionTypes.RECIEVED_USERS_LIST_DATA;
      count: number;
      data: any;
    }
  | {
      type: actionTypes.FETCHING_AUDIT_LIST_DATA;
    }
  | {
      type: actionTypes.RECIEVED_AUDIT_LIST_DATA;
      count: number;
      data: any;
    }
  | {
      type: actionTypes.SET_PAGE_AUDIT_LIST_DATA;
      page: number;
    }
  | {
      type: actionTypes.SET_PAGE_SIZE_AUDIT_LIST_DATA;
      pageSize: number;
    }
  | {
      type: actionTypes.ORDER_BY_AUDIT_LIST_DATA;
      orderBy: string;
    }
  | {
      type: actionTypes.SET_FILTER_AUDIT_LIST_DATA;
      filters: any;
    }
  | { type: actionTypes.FETCHING_OFF }
  | {
      type: actionTypes.SET_SELECTED_AUDIT_ID;
      auditId: string;
      auditKind: AuditKindsType;
    }
  | { type: actionTypes.RECIEVED_SELECTED_AUDIT_ID_DATA; data: any }
  | { type: actionTypes.SET_AUDIT_ID_DIFF_DATA; data: any }
  | { type: actionTypes.EXPORT_AUDIT_ID_DIFF_DATA }
  | { type: actionTypes.EXPORT_AUDIT_ID_DIFF_CREATED }
  | { type: actionTypes.EMPTY_DATA };

export type AuditKindsType =
  | "PVIProvider"
  | "UPProvider"
  | "Tensione"
  | "UPBackup"
  | "InternalLegs"
  | "Fuel"
  | "Acl"
  | "MisurePrezziBiddingPVIUpload"
  | "DisponibilitaCapacitaMassimaUpload"
  | "SpreadMGP"
  | null;

export type FilterTablesStateType = {
  fromDateTime: Date | null;
  toDateTime: Date | null;
  users: string[] | null;
  auditKinds: AuditKindsType;
};

export type TablesStateType = {
  data: any;
  count: number;
  page: number;
  pageSize: number;
  orderBy: string | null;
  filters: FilterTablesStateType;
};

const tablesState = {
  data: [],
  count: 0,
  page: 1,
  pageSize: 10,
  orderBy: null,
  filters: {
    fromDateTime: null,
    toDateTime: null,
    users: null,
    auditKinds: null,
  },
};

export function auditListTableReducer(
  state = tablesState as TablesStateType,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.RECIEVED_AUDIT_LIST_DATA:
      return {
        ...state,
        data: action.data,
        count: action.count,
      };
    case actionTypes.SET_PAGE_AUDIT_LIST_DATA:
      return {
        ...state,
        page: action.page,
      };
    case actionTypes.SET_PAGE_SIZE_AUDIT_LIST_DATA:
      return {
        ...state,
        page: tablesState.page,
        pageSize: action.pageSize,
      };
    case actionTypes.ORDER_BY_AUDIT_LIST_DATA:
      return {
        ...state,
        orderBy: action.orderBy,
      };
    case actionTypes.SET_FILTER_AUDIT_LIST_DATA:
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return state;
  }
}

export function userListTableReducer(state = [] as string[], action: Actions) {
  switch (action.type) {
    case actionTypes.RECIEVED_USERS_LIST_DATA:
      return action.data;
    default:
      return state;
  }
}
export function selectedAuditTableReducer(
  state = {} as string[],
  action: Actions
) {
  switch (action.type) {
    case actionTypes.RECIEVED_SELECTED_AUDIT_ID_DATA:
      return action.data;
    default:
      return state;
  }
}

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.RECIEVED_AUDIT_LIST_DATA:
    case actionTypes.RECIEVED_USERS_LIST_DATA:
    case actionTypes.RECIEVED_SELECTED_AUDIT_ID_DATA:
    case actionTypes.FETCHING_OFF:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FETCHING_AUDIT_LIST_DATA:
    case actionTypes.FETCHING_USERS_LIST_DATA:
    case actionTypes.SET_SELECTED_AUDIT_ID:
    case actionTypes.SET_FILTER_AUDIT_LIST_DATA:
      return true;
    default:
      return state;
  }
}

export type reducerType = {
  isFetching: boolean;
  userListTable: string[];
  auditListTable: TablesStateType;
  selectedAuditID: any;
  selectedAuditKind: any;
  selectedAuditTable: string[];
  selectedAuditDiff: any;
};
export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  userListTable: userListTableReducer,
  auditListTable: auditListTableReducer,
  selectedAuditID: simpleSetReducerCreator({
    setAction: actionTypes.SET_SELECTED_AUDIT_ID,
    prop: "auditId",
    initialState: null,
  }),
  selectedAuditKind: simpleSetReducerCreator({
    setAction: actionTypes.SET_SELECTED_AUDIT_ID,
    prop: "auditKind",
    initialState: null,
  }),
  selectedAuditTable: selectedAuditTableReducer,
  selectedAuditDiff: simpleSetReducerCreator({
    setAction: actionTypes.SET_AUDIT_ID_DIFF_DATA,
    prop: "data",
    initialState: null,
  }),
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const fetchingAuditList = (): Actions => ({
  type: actionTypes.FETCHING_AUDIT_LIST_DATA,
});

export const recievedAuditList = ({
  data,
  count,
}: {
  data: any;
  count: number;
}): Actions => ({
  type: actionTypes.RECIEVED_AUDIT_LIST_DATA,
  data,
  count,
});

export const setPageAuditList = (page: number): Actions => ({
  type: actionTypes.SET_PAGE_AUDIT_LIST_DATA,
  page,
});
export const setPageSizeAuditList = (pageSize: number): Actions => ({
  type: actionTypes.SET_PAGE_SIZE_AUDIT_LIST_DATA,
  pageSize,
});

export type OrderByAuditListType = {
  col: string | null;
  dir: string | null;
};
export const setOrderByAuditList = ({
  col,
  dir,
}: OrderByAuditListType): Actions => ({
  type: actionTypes.ORDER_BY_AUDIT_LIST_DATA,
  orderBy: `${col} ${dir}`,
});

export const setFiltersAuditList = (
  filters: FilterTablesStateType
): Actions => ({
  type: actionTypes.SET_FILTER_AUDIT_LIST_DATA,
  filters,
});

const dateRangeRequestBuilder = (r: any, t: any) => {
  if (r && t) {
    return `&fromDateTime=${format(
      new Date(r),
      "yyyy-MM-dd'T'00:00:00"
    )}&toDateTime=${format(new Date(t), "yyyy-MM-dd'T'00:00:00")}`;
  }
  return "";
};

const propRequestArr = (propName: any, propValue: any) => {
  if (propValue) {
    return R.compose(
      R.join(""),
      R.map((val) => `&${propName}=${val}`)
    )(propValue);
  }
  return "";
};

const propRequest = (propName: any, propValue: any) => {
  if (propValue) {
    return `&${propName}=${propValue}`;
  }
  return "";
};

const auditListType = t.type({
  auditId: t.string,
  userid: t.string,
  kind: t.string,
  info: t.string,
  sysStartTime: t.string,
  sysEndTime: t.string,
});

const requestAuditListDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FETCHING_AUDIT_LIST_DATA ||
        action.type === actionTypes.SET_PAGE_AUDIT_LIST_DATA ||
        action.type === actionTypes.SET_PAGE_SIZE_AUDIT_LIST_DATA ||
        action.type === actionTypes.ORDER_BY_AUDIT_LIST_DATA ||
        action.type === actionTypes.SET_FILTER_AUDIT_LIST_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const options = R.path(
            ["audits", key, "auditListTable"],
            state
          ) as any;
          const skipVal = R.multiply(
            R.subtract(options.page as number, 1),
            options.pageSize
          );
          const auditKind =
            R.isNil(options.filters.auditKinds) ||
            R.isEmpty(options.filters.auditKinds)
              ? kindOptions
              : [options.filters.auditKinds];

          const toDateInclusive = R.pipe(
            (x: any) => addDays(x, 1),
            (x: any) => format(x, "yyyy-MM-dd")
          )(new Date(options.filters.toDateTime as string));

          const dateFilter = dateRangeRequestBuilder(
            options.filters.fromDateTime,
            toDateInclusive
          );
          const auditKindFilter = propRequestArr("auditKinds", auditKind);
          const usersFilter = propRequest("users", options.filters.users);
          return defer(
            deps.request.get(
              `audit?skip=${skipVal}&limit=${options.pageSize}${dateFilter}${auditKindFilter}${usersFilter}`,
              pagination(auditListType)
            )
          );
        }),
        RxOp.map(E.fold<any, any, any>(dispatchNetworkError, recievedAuditList))
      )
    )
  );

export const fetchingAuditusers = (): Actions => ({
  type: actionTypes.FETCHING_AUDIT_LIST_DATA,
});

const recievedAuditUsers = (data: any) => ({
  type: actionTypes.RECIEVED_USERS_LIST_DATA,
  data,
});

const requestAuditUsersEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) => action.type === actionTypes.FETCHING_AUDIT_LIST_DATA
    ),
    RxOp.switchMap(() => defer(deps.request.get(`audit/users`, t.any))),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, recievedAuditUsers))
  );

export type SelectedAuditIdType = {
  auditId: string;
  auditKind: AuditKindsType;
};
export const setSelectedAuditId = ({
  auditId,
  auditKind,
}: SelectedAuditIdType): Actions => ({
  type: actionTypes.SET_SELECTED_AUDIT_ID,
  auditId,
  auditKind,
});

const recievedSelectedAuditId = (data: any) => ({
  type: actionTypes.RECIEVED_SELECTED_AUDIT_ID_DATA,
  data: R.pathOr(null, ["changes", 0], data),
});

const requestSelectedAuditIdEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.SET_SELECTED_AUDIT_ID),
    RxOp.switchMap((action: any) =>
      defer(deps.request.get(`audit/${action.auditId}/changes`, t.any))
    ),
    RxOp.map(
      E.fold<any, any, any>(dispatchNetworkError, recievedSelectedAuditId)
    )
  );

const configPrimaryKeys = (type: AuditKindsType) => {
  switch (type) {
    case "PVIProvider":
      return pviProvider.pks;
    case "UPProvider":
      return upProvider.pks;
    case "Tensione":
      return tensione.pks;
    case "UPBackup":
      return upbackup.pks;
    case "InternalLegs":
      return internalLegs.pks;
    case "Fuel":
      return fuel.pks;
    case "SpreadMGP":
      return spreadmgp.pks;
    case "Acl":
    case "MisurePrezziBiddingPVIUpload":
    case "DisponibilitaCapacitaMassimaUpload":
    default:
      return [];
  }
};

const setAuditDiffData = (data: string): Actions => ({
  type: actionTypes.SET_AUDIT_ID_DIFF_DATA,
  data,
});

const setAuditDiffDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>
) =>
  action$.pipe(
    RxOp.filter(
      (action) => action.type === actionTypes.RECIEVED_SELECTED_AUDIT_ID_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state) => {
          const auditKind = R.path(
            ["audits", key, "selectedAuditKind"],
            state
          ) as any;
          const prev = R.path(
            ["audits", key, "selectedAuditTable", "pre", "table"],
            state
          );
          const current = R.path(
            ["audits", key, "selectedAuditTable", "cur", "table"],
            state
          );
          const res = diff({
            prev,
            current,
            primaryKeys: configPrimaryKeys(auditKind),
          }) as any;
          return res;
        }),
        RxOp.map(setAuditDiffData)
      )
    )
  );

export const getAuditList = (type: AuditKindsType) => {
  switch (type) {
    case "PVIProvider":
      return pviProvider.propCols;
    case "UPProvider":
      return upProvider.propCols;
    case "Tensione":
      return tensione.propCols;
    case "UPBackup":
      return upbackup.propCols;
    case "InternalLegs":
      return internalLegs.propCols;
    case "Fuel":
      return fuel.propCols;
    case "SpreadMGP":
      return spreadmgp.propCols;
    case "Acl":
    case "MisurePrezziBiddingPVIUpload":
    case "DisponibilitaCapacitaMassimaUpload":
    default:
      return [];
  }
};

export const exportAuditDiff = (): Actions => ({
  type: actionTypes.EXPORT_AUDIT_ID_DIFF_DATA,
});

const exportAuditDiffCreated = (): Actions => ({
  type: actionTypes.EXPORT_AUDIT_ID_DIFF_CREATED,
});

const exportAuditDiffEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>
) =>
  action$.pipe(
    RxOp.filter(
      (action) => action.type === actionTypes.EXPORT_AUDIT_ID_DIFF_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state) => {
          const auditKind = R.path(
            ["audits", key, "selectedAuditKind"],
            state
          ) as any;
          const prev = R.path(
            ["audits", key, "selectedAuditTable", "pre", "table"],
            state
          );
          const curr = R.path(
            ["audits", key, "selectedAuditTable", "cur", "table"],
            state
          );

          const res = diffExcelBuilder({
            prev,
            curr,
            list: getAuditList(auditKind),
            fileName: auditKind,
          }) as any;
          return res;
        }),
        RxOp.map(exportAuditDiffCreated)
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  requestAuditListDataEpic,
  requestAuditUsersEpic,
  requestSelectedAuditIdEpic,
  setAuditDiffDataEpic,
  exportAuditDiffEpic
);

export const Selectors = {
  all: (s: any): reducerType => s.audits[key],
};
