import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, defer, EMPTY, of } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { format, subDays, addDays } from "date-fns";
import { Dependancies } from "../../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import { pagination } from "../../../utils/pagination";
import { searchBuilder } from "../../helper";
import { createExcel } from "./createExcel";
import { format as tzFormat } from "date-fns-tz";

export const key = "sportMarket";

export enum actionTypes {
  EMPTY_DATA = "rtm/market/sportMarket/EMPTY_DATA",
  SET_MARKET = "rtm/market/sportMarket/SET_MARKET",
  FIRST_LOAD = "rtm/market/sportMarket/FIRST_LOAD",
  SET_MARKET_ZONES = "rtm/market/sportMarket/SET_MARKET_ZONES",
  SET_PERIOD = "rtm/market/sportMarket/SET_PERIOD",
  SET_GRANULARITY = "rtm/market/sportMarket/SET_GRANULARITY",
  FETCHING_MARKETS_DATA = "rtm/market/sportMarket/FETCHING_MARKETS_DATA",
  RECIEVED_MARKETS_DATA = "rtm/market/sportMarket/RECIEVED_MARKETS_DATA",
  CANCEL = "rtm/market/sportMarket/CANCEL",
  EXPORT_DATA = "rtm/market/sportMarket/EXPORT_DATA",
  EXPORT_DATA_DOWNLOADED = "rtm/market/sportMarket/EXPORT_DATA_DOWNLOADED",
}

type Actions =
  | { type: actionTypes.SET_MARKET; zone: string | null }
  | { type: actionTypes.SET_MARKET_ZONES; zones: string[] | null }
  | { type: actionTypes.SET_PERIOD; start: string | null; end: string | null }
  | { type: actionTypes.SET_GRANULARITY; gran: GranularityType }
  | { type: actionTypes.FETCHING_MARKETS_DATA }
  | { type: actionTypes.RECIEVED_MARKETS_DATA; data: any }
  | { type: actionTypes.FIRST_LOAD }
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.EXPORT_DATA }
  | { type: actionTypes.EXPORT_DATA_DOWNLOADED }
  | { type: actionTypes.EMPTY_DATA };

const selectedMarketReducer = (
  state: string | null = null,
  action: Actions
) => {
  switch (action.type) {
    case actionTypes.SET_MARKET:
      return action.zone;
    case actionTypes.EMPTY_DATA:
      return null;
    default:
      return state;
  }
};
const selectedMarketZonesReducer = (
  state: string[] | null = ["Italy"],
  action: Actions
) => {
  switch (action.type) {
    case actionTypes.SET_MARKET_ZONES:
      return action.zones;
    case actionTypes.EMPTY_DATA:
      return ["Italy"];
    default:
      return state;
  }
};

const dMinus10 = subDays(new Date(), 10);
const dPlus2 = addDays(new Date(), 2);
const periodReducer = (
  state: any = {
    start: format(dMinus10, "yyyy-MM-dd"),
    end: format(dPlus2, "yyyy-MM-dd"),
  },
  action: Actions
) => {
  switch (action.type) {
    case actionTypes.SET_PERIOD:
      return { start: action.start, end: action.end };
    case actionTypes.EMPTY_DATA:
      return {
        start: format(dMinus10, "yyyy-MM-dd"),
        end: format(dPlus2, "yyyy-MM-dd"),
      };
    default:
      return state;
  }
};

export type GranularityType = "hourly" | "daily" | null;
const granularityReducer = (state: GranularityType = null, action: Actions) => {
  switch (action.type) {
    case actionTypes.SET_GRANULARITY:
      return action.gran;
    case actionTypes.EMPTY_DATA:
      return null;
    default:
      return state;
  }
};

const marketsDataReducer = (state: any = [], action: Actions) => {
  switch (action.type) {
    case actionTypes.RECIEVED_MARKETS_DATA:
      return action.data;
    case actionTypes.SET_MARKET:
    case actionTypes.EMPTY_DATA:
      return null;
    default:
      return state;
  }
};

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.RECIEVED_MARKETS_DATA:
    case actionTypes.EXPORT_DATA_DOWNLOADED:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FETCHING_MARKETS_DATA:
    case actionTypes.EXPORT_DATA:
    case actionTypes.FIRST_LOAD:
      return true;
    default:
      return state;
  }
}

export type reducerType = {
  isFetching: boolean;
  selectedMarket: string | null;
  selectedMarketZones: string[] | null;
  period: any;
  granularity: GranularityType;
  marketsData: any;
};

export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  selectedMarket: selectedMarketReducer,
  selectedMarketZones: selectedMarketZonesReducer,
  period: periodReducer,
  granularity: granularityReducer,
  marketsData: marketsDataReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const firstLoadAction = (): Actions => ({
  type: actionTypes.FIRST_LOAD,
});
export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA,
});
export const setSelectedMarketAction = (zone: string): Actions => ({
  type: actionTypes.SET_MARKET,
  zone,
});
export const setSelectedMarketZonesAction = (zones: string[]): Actions => ({
  type: actionTypes.SET_MARKET_ZONES,
  zones,
});
export type PeriodType = {
  start: string | null;
  end: string | null;
};
export const setPeriodAction = ({ start, end }: PeriodType): Actions => ({
  type: actionTypes.SET_PERIOD,
  start,
  end,
});
export const setGranularityAction = (gran: GranularityType): Actions => ({
  type: actionTypes.SET_GRANULARITY,
  gran,
});

const requestMarketsDataAction = (): Actions => ({
  type: actionTypes.FETCHING_MARKETS_DATA,
});
const setMarketsDataAction = ({ data }: { data: any }): Actions => ({
  type: actionTypes.RECIEVED_MARKETS_DATA,
  data,
});

const PrezziType = t.type({
  mercatoPrezzi: t.string,
  tipoCurvaPrezzi: t.string,
  dateTimeOffset: t.string,
  currency: t.string,
  currencyUnit: t.string,
  loadProfile: t.string,
  price: t.number,
});
type GetPrezziType = {
  deps: any;
  page?: number;
  pageSize?: number;
  filters?: any;
};
const getPrezzi = ({
  deps,
  page = 1,
  pageSize = 200,
  filters,
}: GetPrezziType) => {
  const skipVal = R.multiply(R.subtract(page, 1), pageSize);
  const queryString = searchBuilder({ filters: { ...filters } });

  return of(
    `prices/prezzi?skip=${skipVal}&limit=${pageSize}${queryString}`
  ).pipe(
    RxOp.switchMap((url) =>
      defer(deps.request.get(url, pagination(PrezziType)))
    ),
    RxOp.map(E.fold<any, any, any>(dispatchNetworkError, (x) => x))
  );
};

const getAllPrezzi = ({ deps, filters }: GetPrezziType): any => {
  return getPrezzi({
    deps,
    filters,
  }).pipe(
    RxOp.expand((x: any) =>
      x.isCountPartial || x.limit + x.skip < x.count
        ? getPrezzi({
            deps,
            filters,
            page: 1 + (x.skip + x.limit) / x.limit,
          })
        : EMPTY
    ),
    RxOp.map((x: any) => x.data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};

const applyChangesEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FIRST_LOAD ||
        action.type === actionTypes.SET_MARKET_ZONES ||
        action.type === actionTypes.SET_PERIOD
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const info = Selectors.all(state) as any;
          const periodNull = R.pipe<any, any, any, any, any>(
            R.prop("period"),
            R.values,
            R.map(R.isNil),
            R.any(R.equals(true))
          )(info);
          const anyNull = R.pipe<any, any, any, any, any, any>(
            R.pick(["selectedMarket", "selectedMarketZones", "granularity"]),
            R.map(R.isNil),
            R.values,
            R.concat(R.__, [periodNull]),
            R.any(R.equals(true))
          )(info);

          return anyNull || R.isEmpty(info.selectedMarketZones)
            ? EMPTY
            : of(state);
        }),
        RxOp.mergeMap((state: any) =>
          of(state).pipe(
            RxOp.switchMap((state: any) => {
              const info = Selectors.all(state) as any;

              const endDateInclusive = R.pipe(
                (x: any) => addDays(x, 1),
                (x: any) =>
                  tzFormat(x, "yyyy-MM-dd'T'00:00:00XXX", {
                    timeZone: "Europe/Rome",
                  })
              )(new Date(info.period.end as string));

              const filters = {
                mercatoPrezzi: info.selectedMarket,
                tipoCurvaPrezzi: info.selectedMarketZones,
                start: tzFormat(
                  new Date(info.period.start),
                  "yyyy-MM-dd'T'00:00:00XXX",
                  {
                    timeZone: "Europe/Rome",
                  }
                ),
                end: endDateInclusive,
              };
              return getAllPrezzi({ deps, filters });
            }),
            RxOp.map((data: any) => setMarketsDataAction({ data })),
            RxOp.startWith(requestMarketsDataAction())
          )
        ),
        RxOp.catchError(dispatchNetworkError)
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const exportDataAction = (): Actions => ({
  type: actionTypes.EXPORT_DATA,
});

const exportDataDownloaded = (): Actions => ({
  type: actionTypes.EXPORT_DATA_DOWNLOADED,
});

const exportDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>
) =>
  action$.pipe(
    ofType(actionTypes.EXPORT_DATA),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.map((state) => {
          const info = Selectors.all(state) as any;
          return createExcel({
            fileName: `${info.selectedMarket}-${getGranName(info.granularity)}`,
            info,
          });
        }),
        RxOp.map(exportDataDownloaded)
      )
    )
  );

export const epic = combineEpics(
  errorHandlerEpic,
  applyChangesEpic,
  exportDataEpic
);

export const Selectors = {
  all: (s: any): reducerType => s.markets[key],
};

export const getMarketName = (market: string): any => {
  switch (market) {
    case "Italy":
      return "Italy";
    case "ItalyCNOR":
      return "Italy CNOR";
    case "ItalyCSUD":
      return "Italy CSUD";
    case "ItalyNord":
      return "Italy Nord";
    case "ItalySard":
      return "Italy Sard";
    case "ItalySici":
      return "Italy Sici";
    case "ItalySud":
      return "Italy Sud";
    case "NORDAcq":
      return "NORD Acq";
    case "NORDVen":
      return "NORD Ven";
    case "SARDAcq":
      return "SARD Acq";
    case "SARDVen":
      return "SARD Ven";
    case "SICIAcq":
      return "SICI Acq";
    case "SICIVen":
      return "SICI Ven";
    case "SUDAcq":
      return "SUD Acq";
    case "SUDVen":
      return "SUD Ven";
    case "PSV":
      return "PSV";
    case "ItalyCALA":
      return "Italy CALA";
  }
};

export const getGranName = (gran: string): any => {
  switch (gran) {
    case "daily":
      return "Daily";
    case "hourly":
      return "Hourly";
  }
};
