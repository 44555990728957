import * as R from "ramda";
import { format } from "date-fns";
import { getCurveName, getGranName } from "../production";

const getDailyDates = R.pipe<any, any, any>(
  R.map((row: any) => format(new Date(row.dateTimeOffset), "dd-MM-yyyy")),
  R.uniq
);
const getHourlyDates = R.pipe<any, any, any>(
  R.map((row: any) => row.dateTimeOffset),
  R.uniq
);

const getDailyInfo = R.pipe<any, any, any, any, any>(
  R.map((row: any) =>
    R.assoc("Date", format(new Date(row.dateTimeOffset), "dd-MM-yyyy"), row)
  ),
  R.groupBy((x: any) => `${x.Date}-${x.curveName}`),
  R.map((x: any) => {
    const val = x.reduce(
      (a: any, b: any) => ({
        ...b,
        value: a.value + R.pathOr(0, ["value"], b),
      }),
      {
        value: 0,
      }
    );
    return {
      ...val,
      value: val.value,
    };
  }),
  R.values
);
const getHourlyInfo = R.map((row: any) =>
  R.assoc("Date", row.dateTimeOffset, row)
);

const style = {
  alignment: {
    horizontal: ["center"],
  },
  font: {
    bold: true,
  },
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#99CC00",
    fgColor: "#99CC00",
  },
};

const getTimeseriesData = (data: any, curves: any) =>
  R.ifElse(
    (x) => R.isEmpty(x) || R.isNil(x),
    () => [],
    R.pipe<any, any, any, any, any, any>(
      R.toPairs,
      R.map(([curveName, val]) => {
        return {
          curveName,
          data: R.map((row) => ({ ...row, curveName }), val.timeseries),
        };
      }),
      R.reject((row: any) => !R.contains(row.curveName, curves || [])),
      R.map(R.prop("data")),
      R.flatten
    )
  )(data);

export const timeseriesHeaders = ({
  curves,
  granularity,
  sheet,
}: {
  curves: any;
  granularity: any;
  sheet: any;
}) => {
  sheet.row(1).freeze();
  sheet.row(2).freeze();
  sheet
    .cell(1, 1)
    .string("Granularity:")
    .style(style);
  sheet
    .cell(1, 2)
    .string(getGranName(granularity))
    .style(style);

  sheet
    .cell(2, 1)
    .string("Date")
    .style(style);
  return curves.map((title: any, i: number) =>
    sheet
      .cell(2, R.add(i, 2))
      .string(getCurveName(title))
      .style(style)
  );
};

const getDates = (data: any) =>
  data.reduce(
    (acc: any, cv: any) =>
      cv.timeseries.length > acc.length ? cv.timeseries : acc,
    []
  );

type BodyType = {
  curves: any;
  sheet: any;
  data: any;
  gran: any;
};
export const timeseriesBody = ({ curves, sheet, data, gran }: BodyType) => {
  const maxDatesList = getDates([
    data.forecast,
    data.production,
    data.realizedForecast,
    data.unbalance,
  ]);
  const timeseriesData = getTimeseriesData(data, curves);
  const allDates = R.equals(gran, "daily")
    ? getDailyDates(maxDatesList)
    : getHourlyDates(maxDatesList);
  const uniformData = R.equals(gran, "daily")
    ? getDailyInfo(timeseriesData)
    : getHourlyInfo(timeseriesData);

  return R.pipe(
    R.map((rd) => R.filter(R.propEq("Date", rd), uniformData)),
    R.toPairs,
    (x) => {
      return x.map(([row, arr]: any, ri: number) => {
        const dateVal = R.equals(gran, "daily")
          ? allDates[row]
          : format(new Date(allDates[row]), "dd-MM-yyyy'T'HH:mm:ss");
          
        sheet.cell(R.add(ri, 3), 1).string(dateVal);
        return curves.map((col: any, ci: number) => {
          const d = R.pipe<any, any, any>(
            R.filter(
              (x: any) =>
                R.equals(x.curveName, col) && R.equals(x.Date, allDates[row])
            ),
            R.pathOr(null, [0, "value"])
          )(arr);
          return R.isNil(d)
            ? sheet
            : sheet.cell(R.add(ri, 3), R.add(ci, 2)).number(d);
        });
      });
    }
  )(allDates);
};
