import React from "react";
import { ProductType } from "../../redux/modules/markets/forwardMarket";
import { CheckBox } from "../../components/checkBox";
import { getProductName } from "../helper";

type RelativeCheckboxType = {
  name: ProductType;
  products: ProductType[];
  setProductsAction: (a: any) => void;
};
const RelativeCheckbox = ({
  name,
  products,
  setProductsAction
}: RelativeCheckboxType) => {
  return (
    <CheckBox
      label={getProductName(name)}
      name={name}
      selected={products}
      update={setProductsAction}
    />
  );
};

export default RelativeCheckbox;
