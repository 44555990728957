import React from "react";
import * as R from "ramda";
import { ProductType } from "../../redux/modules/markets/forwardMarket";
import { min, format } from "date-fns";

type ProductValueType = {
  date: string;
  data: any;
  product: ProductType;
};

const ProductValue = ({ date, data, product }: ProductValueType) => {
  const val = R.pipe(
    R.filter(R.propEq("date", date)),
    R.filter(R.propEq("relativeProduct", product)),
    R.pathOr(null, [0, "price"])
  )(data);

  return <Direction date={date} data={data} product={product} cellVal={val} />;
};

export default ProductValue;

const Direction = ({
  date,
  data,
  product,
  cellVal
}: {
  date: any;
  data: any;
  product: ProductType;
  cellVal: any;
}) => {
  if (R.isNil(cellVal)) {
    return <td>{cellVal}</td>;
  }
  return R.pipe<any, any, any, any, any>(
    R.filter(R.propEq("relativeProduct", product)),
    R.map(R.prop("date")),
    R.map((x: any) => new Date(x)),
    dateList => {
      const minDate = min(dateList);
      if (R.isNil(minDate) || R.equals(minDate, new Date(date))) {
        return <td>{cellVal}</td>;
      }
      const res = R.pipe(
        R.findIndex(R.equals(new Date(date))),
        x => R.subtract(x, 1),
        x => {
          return R.filter(
            row =>
              R.propEq("relativeProduct", product, row) &&
              R.propEq(
                "date",
                format(new Date(dateList[x]), "yyyy-MM-dd"),
                row
              ),
            data
          );
        },
        R.path([0, "price"])
      )(dateList) as any;
      if (R.equals(res, cellVal)) {
        return (
          <td className="cellEquals">
            {cellVal}
            <i className="fas fa-equals" />
          </td>
        );
      }
      return (
        <td className={res < cellVal ? "cellPositive" : "cellNegative"}>
          {cellVal}
          <i
            className={`fas fa-long-arrow-alt-${res < cellVal ? "up" : "down"}`}
          />
        </td>
      );
    }
  )(data);
};
