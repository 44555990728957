import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, defer, EMPTY, of, timer } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { addDays, format, addMonths } from "date-fns";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError,
} from "../errorHandler";
import { getAllRequests, searchBuilder } from "../../helper";
import { actuaLDealCaptureTypesStatusArr } from "./helper";

export const key = "actualDeal";

export enum actionTypes {
  EMPTY_DATA = "rtm/market/actualDeal/EMPTY_DATA",
  FIRST_LOAD = "rtm/market/actualDeal/FIRST_LOAD",
  SET_RAGIONE_SOCIALE_DATA = "rtm/market/actualDeal/SET_RAGIONE_SOCIALE_DATA",
  RECIEVED_RAGIONE_SOCIALE_DATA = "rtm/market/actualDeal/RECIEVED_RAGIONE_SOCIALE_DATA",
  CANCEL = "rtm/market/actualDeal/CANCEL",
  ACTUAL_DEAL_REQUEST = "rtm/market/actualDeal/ACTUAL_DEAL_REQUEST",
  ACTUAL_DEAL_FETCHINNG = "rtm/market/actualDeal/ACTUAL_DEAL_FETCHINNG",
  ACTUAL_DEAL_DATA = "rtm/market/actualDeal/ACTUAL_DEAL_DATA",
  ACTUAL_DEAL_PAGE = "rtm/market/actualDeal/ACTUAL_DEAL_PAGE",
  ACTUAL_DEAL_FILTERS = "rtm/market/actualDeal/ACTUAL_DEAL_FILTERS",
  ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE = "rtm/market/actualDeal/ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE",
  DEAL_CAPTURE_RUN_OPEN = "rtm/market/actualDeal/DEAL_CAPTURE_RUN_OPEN",
  DEAL_CAPTURE_RUN_DEALTYPES = "rtm/market/actualDeal/DEAL_CAPTURE_RUN_DEALTYPES",
  DEAL_CAPTURE_RUN_START = "rtm/market/actualDeal/DEAL_CAPTURE_RUN_START",
  DEAL_CAPTURE_RUN_COMPLETED = "rtm/market/actualDeal/DEAL_CAPTURE_RUN_COMPLETED",
  DEAL_CAPTURE_RUN_CLOSE = "rtm/market/actualDeal/DEAL_CAPTURE_RUN_CLOSE",
}

type Actions =
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.FIRST_LOAD }
  | { type: actionTypes.SET_RAGIONE_SOCIALE_DATA }
  | { type: actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA; data: any }
  | { type: actionTypes.ACTUAL_DEAL_REQUEST }
  | { type: actionTypes.ACTUAL_DEAL_FETCHINNG }
  | { type: actionTypes.ACTUAL_DEAL_DATA; data: any; count: number }
  | { type: actionTypes.ACTUAL_DEAL_PAGE; page: number }
  | {
      type: actionTypes.ACTUAL_DEAL_FILTERS;
      filters: ActualDealFilterStateType;
    }
  | {
      type: actionTypes.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE;
      sociale: any;
    }
  | { type: actionTypes.DEAL_CAPTURE_RUN_OPEN; info: any }
  | { type: actionTypes.DEAL_CAPTURE_RUN_DEALTYPES; dealTypes: any }
  | { type: actionTypes.DEAL_CAPTURE_RUN_START }
  | { type: actionTypes.DEAL_CAPTURE_RUN_COMPLETED; result: any }
  | { type: actionTypes.DEAL_CAPTURE_RUN_CLOSE }
  | { type: actionTypes.EMPTY_DATA };

export type RagioneSocialeType = {
  isFetching: boolean;
  data: any;
};
export const ragioneSocialeState = {
  isFetching: false,
  data: [],
};
function ragioneSocialeReducer(
  state: RagioneSocialeType = ragioneSocialeState,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.SET_RAGIONE_SOCIALE_DATA:
      return { ...state, isFetching: true };
    case actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA:
      return { ...state, isFetching: false, data: action.data };
    case actionTypes.EMPTY_DATA:
      return ragioneSocialeState;
    default:
      return state;
  }
}

function isFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.ACTUAL_DEAL_DATA:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.ACTUAL_DEAL_FILTERS:
    case actionTypes.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE:
      return true;
    default:
      return state;
  }
}

export type ActualDealFilterStateType = {
  ragioneSociale: string | null;
  pvis: string[] | null;
  yearMonth: string | null;
};
export type ActualDealStateType = {
  fetching: boolean;
  data: any;
  count: number;
  page: number;
  pageSize: number;
  sort: string | null;
  filters: ActualDealFilterStateType;
};

const dPlus1 = addDays(new Date(), 1);
export const actualDealState = {
  fetching: false,
  data: [],
  count: 0,
  page: 1,
  pageSize: 50,
  sort: null,
  filters: {
    ragioneSociale: null,
    pvis: null,
    yearMonth: format(dPlus1, "yyyy-MM-01"),
  },
};

export function actualDealReducer(
  state = actualDealState as ActualDealStateType,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.ACTUAL_DEAL_FETCHINNG:
      return { ...state, fetching: true };
    case actionTypes.ACTUAL_DEAL_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        count: action.count,
      };
    case actionTypes.ACTUAL_DEAL_PAGE:
      return {
        ...state,
        fetching: true,
        page: action.page,
      };
    case actionTypes.ACTUAL_DEAL_FILTERS:
      return {
        ...state,
        fetching: true,
        filters: action.filters,
      };
    case actionTypes.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE:
      return {
        ...state,
        fetching: true,
        page: 1,
        filters: {
          ...state.filters,
          ragioneSociale: action.sociale,
        },
      };
    case actionTypes.CANCEL:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.EMPTY_DATA:
      return actualDealState;
    default:
      return state;
  }
}

export type DealCaptureType = {
  isFetching: boolean;
  info: any;
  dealTypes: string[];
  open: boolean;
  data: any;
};
export const dealCaptureState = {
  isFetching: false,
  info: null,
  dealTypes: [],
  open: false,
  data: null,
};
function dealCaptureReducer(
  state: DealCaptureType = dealCaptureState,
  action: Actions
) {
  switch (action.type) {
    case actionTypes.DEAL_CAPTURE_RUN_OPEN:
      return {
        ...state,
        open: true,
        info: action.info,
        data: action.info.dealType,
        dealTypes: actuaLDealCaptureTypesStatusArr(action.info.dealType),
      };
    case actionTypes.DEAL_CAPTURE_RUN_DEALTYPES:
      return {
        ...state,
        dealTypes: action.dealTypes,
      };
    case actionTypes.DEAL_CAPTURE_RUN_START:
      return {
        ...state,
        isFetching: true,
        open: false,
      };
    case actionTypes.DEAL_CAPTURE_RUN_COMPLETED: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case actionTypes.DEAL_CAPTURE_RUN_CLOSE:
    case actionTypes.EMPTY_DATA:
      return dealCaptureState;
    default:
      return state;
  }
}

export type reducerType = {
  isFetching: boolean;
  ragioneSociale: RagioneSocialeType;
  actualDeal: ActualDealStateType;
  dealCapture: DealCaptureType;
};
export const reducer = combineReducers({
  isFetching: isFetchingReducer,
  ragioneSociale: ragioneSocialeReducer,
  actualDeal: actualDealReducer,
  dealCapture: dealCaptureReducer,
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const clearAllDataAction = (): Actions => ({
  type: actionTypes.EMPTY_DATA,
});

export const firstLoadAction = (): Actions => ({
  type: actionTypes.FIRST_LOAD,
});

export const getRagioneSocialeAction = (): Actions => ({
  type: actionTypes.SET_RAGIONE_SOCIALE_DATA,
});
const setRagioneSocialeDataAction = (data: any): Actions => ({
  type: actionTypes.RECIEVED_RAGIONE_SOCIALE_DATA,
  data,
});

const ragioneSocialeEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.FIRST_LOAD ||
        action.type === actionTypes.SET_RAGIONE_SOCIALE_DATA
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state) => {
          return getAllRequests({
            deps,
            api: "core/ragionesociale",
            dispatchError: dispatchNetworkError,
            filters: {
              sort: "RagioneSociale asc",
            },
          });
        }),
        RxOp.map(setRagioneSocialeDataAction)
      )
    )
  );

export const requestActualDealAction = (): Actions => ({
  type: actionTypes.ACTUAL_DEAL_REQUEST,
});

export const setActualDealPageAction = (page: number): Actions => ({
  type: actionTypes.ACTUAL_DEAL_PAGE,
  page,
});
export const setActualDealFiltersAction = (
  filters: ActualDealFilterStateType
): Actions => ({
  type: actionTypes.ACTUAL_DEAL_FILTERS,
  filters,
});
export const setActualDealFilterSocialeAction = (sociale: any): Actions => ({
  type: actionTypes.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE,
  sociale,
});
const setActualDealDataAction = ({
  data,
  count,
}: {
  data: any;
  count: number;
}): Actions => ({
  type: actionTypes.ACTUAL_DEAL_DATA,
  data,
  count,
});

type RequestDealsType = { state: any; deps: Dependancies };
const requestDeals = ({ state, deps }: RequestDealsType) => {
  const info = R.path(
    ["management", key, "actualDeal"],
    state
  ) as ActualDealStateType;
  const queryString = searchBuilder({
    filters: {
      partitaIva: info.filters.ragioneSociale,
      competenceFrom: info.filters.yearMonth,
      competenceTo: format(
        addMonths(new Date(info.filters.yearMonth as string), 1),
        "yyyy-MM-01"
      ),
    },
  });

  const skipVal = R.multiply(R.subtract(info.page, 1), info.pageSize);

  return of(state).pipe(
    RxOp.switchMap(() =>
      defer(
        deps.request.get(
          `settlement/actualDeal?limit=${info.pageSize}&skip=${skipVal}&${queryString}`,
          t.any
        )
      )
    ),
    RxOp.map(
      E.fold<any, any, any>(dispatchNetworkError, setActualDealDataAction)
    )
  );
};

const actualDealDataEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      (action) =>
        action.type === actionTypes.ACTUAL_DEAL_REQUEST ||
        action.type === actionTypes.ACTUAL_DEAL_FILTERS ||
        action.type === actionTypes.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE ||
        action.type === actionTypes.DEAL_CAPTURE_RUN_COMPLETED ||
        action.type === actionTypes.ACTUAL_DEAL_PAGE
    ),
    RxOp.exhaustMap(() =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => {
          const info = R.path(
            ["management", key, "actualDeal", "filters"],
            state
          ) as ActualDealFilterStateType;

          const anyNull = R.pipe<any, any, any, any, any>(
            R.pick(["ragioneSociale", "yearMonth"]),
            R.map((x) => R.isEmpty(x) || R.isNil(x)),
            R.values,
            R.any(R.equals(true))
          )(info);

          return anyNull ? EMPTY : of(state);
        }),
        RxOp.switchMap(() =>
          action$.pipe(
            RxOp.filter(
              (action) =>
                action.type === actionTypes.ACTUAL_DEAL_REQUEST ||
                action.type === actionTypes.ACTUAL_DEAL_FILTERS ||
                action.type ===
                  actionTypes.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE ||
                action.type === actionTypes.DEAL_CAPTURE_RUN_COMPLETED ||
                action.type === actionTypes.ACTUAL_DEAL_PAGE
            ),
            RxOp.startWith(1),
            RxOp.switchMapTo(
              timer(0, 30000).pipe(
                RxOp.exhaustMap(() =>
                  state$.pipe(
                    RxOp.first(),
                    RxOp.switchMap((state) => requestDeals({ state, deps }))
                  )
                )
              )
            ),
            RxOp.takeUntil(action$.pipe(ofType(actionTypes.EMPTY_DATA)))
          )
        )
      )
    )
  );

export const sendDealCaptureAction = (): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_START,
});
export const openDealCaptureAction = (info: any): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_OPEN,
  info,
});
export const setDealCaptureDealTypesAction = (dealTypes: any): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_DEALTYPES,
  dealTypes,
});
export const closeDealCaptureAction = (): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_CLOSE,
});
const dealCaptureCompletedAction = (result: any): Actions => ({
  type: actionTypes.DEAL_CAPTURE_RUN_COMPLETED,
  result,
});

type DealCaptureEpicRequestType = {
  deps: Dependancies;
  contractId: string;
  dealType: string;
  dealTypeData: any;
  yearMonth: string | null;
};
const dealCaptureEpicPostRequest = ({
  deps,
  contractId,
  dealType,
  dealTypeData,
  yearMonth,
}: DealCaptureEpicRequestType) =>
  of(dealTypeData).pipe(
    RxOp.switchMap((data) =>
      defer(
        deps.request.post(
          `settlement/dealCapture/process/${contractId}/dealType/${dealType}/${yearMonth}/run`,
          {},
          t.unknown
        )
      )
    ),
    RxOp.map(
      E.fold<any, any, any>(
        (data) => dealCaptureEpicRequestFailure(data, contractId, dealType),
        (x) => {
          return {
            contractId,
            dealType,
            status: true,
            result: x,
          };
        }
      )
    )
  );

const dealCaptureEpicRequestFailure = (
  data: any,
  contractId: any,
  dealType: any
) => {
  if (R.hasPath(["response", "title"], data)) {
    return {
      contractId,
      dealType,
      status: false,
      result: R.path(["response", "title"], data),
    };
  }
  return {
    contractId,
    dealType,
    status: false,
    result: data,
  };
};

const dealCapturePostEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter((action) => action.type === actionTypes.DEAL_CAPTURE_RUN_START),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state: any) => {
          const info = Selectors.all(state);
          const contractInfo = info.dealCapture.info;
          const contractId = R.pathOr("", ["contractId"], contractInfo);
          return of(info.dealCapture.dealTypes).pipe(
            RxOp.flatMap((x) => x, 6),
            RxOp.mergeMap((dealType) =>
              dealCaptureEpicPostRequest({
                deps,
                contractId,
                dealType,
                dealTypeData: R.pathOr(
                  null,
                  ["dealType", dealType],
                  contractInfo
                ),
                yearMonth: info.actualDeal.filters.yearMonth,
              })
            ),
            RxOp.toArray()
          );
        }),
        RxOp.map(dealCaptureCompletedAction)
      )
    )
  );


const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  errorHandlerEpic,
  ragioneSocialeEpic,
  actualDealDataEpic,
  dealCapturePostEpic
);

export const Selectors = {
  all: (s: any): reducerType => s.management[key],
};
