import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  key as ProcessBiddingKey,
  reducer as ProcessBiddingReducer,
  epic as ProcessBiddingEpic
} from "./processBidding";
import {
  key as CoefficientiUPKey,
  reducer as CoefficientiUPReducer,
  epic as CoefficientiUPEpic
} from "./coefficientiUP";
import {
  key as ForecastDealPKey,
  reducer as ForecastDealReducer,
  epic as ForecastDealPEpic
} from "./forecastDeal";
import {
  key as ActualDealPKey,
  reducer as ActualDealReducer,
  epic as ActualDealPEpic
} from "./actualDeal";

export const reducer = combineReducers({
  [ProcessBiddingKey]: ProcessBiddingReducer,
  [CoefficientiUPKey]: CoefficientiUPReducer,
  [ForecastDealPKey]: ForecastDealReducer,
  [ActualDealPKey]: ActualDealReducer
});

export const epic = combineEpics(
  ProcessBiddingEpic,
  CoefficientiUPEpic,
  ForecastDealPEpic,
  ActualDealPEpic
);
