import React from "react";

const ErrorList = ({ failures }: { failures: any }) => (
  <>
    <div>
      {failures.map((row: any, i: number) => (
        <>
          <div key={i}>
            <b>{row.contractId}:</b> {row.info.detail}
          </div>
          <br />
        </>
      ))}
    </div>
  </>
);

export default ErrorList;
