import * as R from "ramda";
import xl from "excel4node";
import { format } from "date-fns-tz";
import { getMarketName, getGranName } from "./spotMarket";

type NumberCellType = {
  sheet: any;
  rowData: any;
  rowIndex: number;
  colIndex: number;
  path: string[];
  style?: any;
};

const numberCell = ({
  sheet,
  rowData,
  rowIndex,
  colIndex,
  path,
  style = {},
}: NumberCellType) => {
  const val = R.pathOr(null, path, rowData);
  return R.isNil(val)
    ? sheet
    : sheet
        .cell(rowIndex, colIndex)
        .number(val)
        .style(style);
};

const getDailyDates = R.pipe<any, any, any>(
  R.map((row: any) =>
    format(new Date(row.dateTimeOffset), "dd-MM-yyyy", {
      timeZone: "Europe/Rome",
    })
  ),
  R.uniq
);
const getHourlyDates = R.pipe<any, any, any>(
  R.map((row: any) => row.dateTimeOffset),
  R.uniq
);

const getDailyInfo = R.pipe<any, any, any, any, any>(
  R.map((row: any) =>
    R.assoc("Date", format(new Date(row.dateTimeOffset), "dd-MM-yyyy"), row)
  ),
  R.groupBy((x: any) => `${x.Date}-${x.tipoCurvaPrezzi}`),
  R.map((x: any) => {
    const val = x.reduce(
      (a: any, b: any) => ({ ...b, price: a.price + b.price }),
      {
        price: 0,
      }
    );

    return {
      ...val,
      price: val.price / x.length,
    };
  }),
  R.values
);
const getHourlyInfo = R.map((row: any) =>
  R.assoc("Date", row.dateTimeOffset, row)
);
const style = {
  alignment: {
    horizontal: ["center"],
  },
  font: {
    bold: true,
  },
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#99CC00",
    fgColor: "#99CC00",
  },
};
const headers = ({ zones, sheet }: { zones: any; sheet: any }) => {
  sheet
    .cell(2, 1)
    .string("Date")
    .style(style);
  return zones.map((title: string, i: number) =>
    sheet
      .cell(2, R.add(i, 2))
      .string(getMarketName(title))
      .style(style)
  );
};

const headerSatistics = ({ sheet }: { sheet: any }) => {
  sheet
    .cell(1, 1)
    .string("Mercato")
    .style(style);
  sheet
    .cell(1, 2)
    .string("Min")
    .style(style);
  sheet
    .cell(1, 3)
    .string("Avg")
    .style(style);
  sheet
    .cell(1, 4)
    .string("Max")
    .style(style);
  return sheet;
};

const bodySatistics = ({ sheet, data }: { sheet: any; data: any }) => {
  const tableData = R.pipe<any, any, any, any>(
    R.groupBy(R.prop<any, any>("tipoCurvaPrezzi")),
    R.toPairs,
    R.map(([market, val]) => {
      const prices = R.map(R.prop("price"), val) as any;
      return {
        market,
        min: Math.min(...prices),
        max: Math.max(...prices),
        avg: prices.reduce((a: any, b: any) => a + b, 0) / prices.length,
      };
    })
  )(data);

  tableData.map((rowData: any, ri: number) => {
    const info = { sheet, rowData, rowIndex: R.add(ri, 2) };

    sheet
      .cell(R.add(ri, 2), 1)
      .string(getMarketName(R.pathOr("", ["market"], rowData)))
      .style({});
    numberCell({
      ...info,
      colIndex: 2,
      path: ["min"],
    });
    numberCell({
      ...info,
      colIndex: 3,
      path: ["avg"],
    });
    numberCell({
      ...info,
      colIndex: 4,
      path: ["max"],
    });

    return true;
  });
  return sheet;
};

type BodyType = {
  zones: any;
  sheet: any;
  data: any;
  gran: any;
};

const body = ({ zones, sheet, data, gran }: BodyType) => {
  const allDates = R.equals(gran, "daily")
    ? getDailyDates(data)
    : getHourlyDates(data);

  const uniformData = R.equals(gran, "daily")
    ? getDailyInfo(data)
    : getHourlyInfo(data);

  return R.pipe(
    R.map((rd) => R.filter(R.propEq("Date", rd), uniformData)),
    R.toPairs,
    (x) =>
      x.map(([row, arr]: any, ri: number) => {
        sheet.cell(R.add(ri, 3), 1).string(allDates[row]);
        return zones.map((col: any, ci: number) => {
          const d = R.pipe<any, any, any>(
            R.filter(
              (x: any) =>
                R.equals(x.tipoCurvaPrezzi, col) &&
                R.equals(x.Date, allDates[row])
            ),
            R.pathOr(null, [0, "price"])
          )(arr);

          return R.isNil(d)
            ? sheet
            : sheet.cell(R.add(ri, 3), R.add(ci, 2)).number(d);
        });
      })
  )(allDates);
};

type CreateExcelType = { fileName: string; info: any };

export const createExcel = ({ fileName, info }: CreateExcelType) => {
  var wb = new xl.Workbook();
  var ws = wb.addWorksheet("Timeseries");

  ws.row(1).freeze();
  ws.row(2).freeze();
  ws.cell(1, 1)
    .string("Granularity:")
    .style(style);
  ws.cell(1, 2)
    .string(getGranName(info.granularity))
    .style(style);

  headers({ zones: info.selectedMarketZones, sheet: ws });
  body({
    zones: info.selectedMarketZones,
    sheet: ws,
    data: info.marketsData,
    gran: info.granularity,
  });

  var wsSatistics = wb.addWorksheet("Satistics");
  headerSatistics({ sheet: wsSatistics });
  bodySatistics({ sheet: wsSatistics, data: info.marketsData });

  return wb.writeToBuffer().then(function(buffer: any) {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([buffer], { type: "application/octet-stream" })
    );
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  });
};
