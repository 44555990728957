import React from "react";
import "./select.scss";

type FilterType = {
  value: string | null;
  update: (a: any) => any;
  placeholder?: string;
};
const Filter = ({
  value,
  update,
  placeholder = "Filter items..."
}: FilterType) => {
  return (
    <div className="selectFilter">
      <input
        value={value || ""}
        placeholder={placeholder}
        onChange={e => update(e.target.value)}
      />
    </div>
  );
};

export default Filter;
