import React from "react";
import * as R from "ramda";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type DatePickersTypes = {
  date: Date | null;
  onDateChange: (a: Date | null) => void;
  minDate?: any;
  maxDate?: any;
  tabIndex?: number;
  disabled?: boolean;
  format?: string;
  className?: string;
  placeholderText?: string;
  showTimeSelect?: boolean;
  timeFormat?: string | undefined;
  timeIntervals?: number | undefined;
};

const DatePickers = ({
  date,
  onDateChange,
  minDate = null,
  maxDate = null,
  disabled = false,
  tabIndex = undefined,
  format = "dd-MM-yyyy",
  className = "",
  placeholderText = "",
  showTimeSelect = false,
  timeFormat = undefined,
  timeIntervals = undefined
}: DatePickersTypes) => {
  const placeHolder = R.isEmpty(placeholderText)
    ? "Inserire valore"
    : placeholderText;

  const minDateValue = R.isNil(minDate) ? null : new Date(minDate);
  const maxDateValue = R.isNil(maxDate) ? null : new Date(maxDate);

  return (
    <>
      <DatePicker
        className={`dateInput date ${className}`}
        calendarClassName="calendar"
        selected={date}
        onChange={onDateChange}
        tabIndex={tabIndex}
        disabled={disabled}
        minDate={minDateValue}
        maxDate={maxDateValue}
        dateFormat={format}
        placeholderText={disabled ? undefined : placeHolder}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
      />
    </>
  );
};

export default DatePickers;
