import xl from "excel4node";
import { FiltersType, getGranName } from "../settlement";
import { timeseriesBody, timeseriesHeaders } from "./timeseriesExcel";
import { statisticsBody, statisticsHeader } from "./statisticsExcel";

type CreateExcelType = {
  fileName: string;
  data: any;
  filters: FiltersType;
  pvis: any;
  ragioneSociale: any;
};

export const createExcel = ({ fileName, data, filters, pvis, ragioneSociale }: CreateExcelType) => {
  var wb = new xl.Workbook();
  var wsTimeseries = wb.addWorksheet(
    `${getGranName(filters.granularity)} Timeseries`
  );

  timeseriesHeaders({
    curves: filters.curves,
    sheet: wsTimeseries,
    granularity: filters.granularity
  });
  timeseriesBody({
    curves: filters.curves,
    sheet: wsTimeseries,
    data,
    gran: filters.granularity
  });

  var wsSatistics = wb.addWorksheet(`Statistics`);
  statisticsHeader({ sheet: wsSatistics });
  statisticsBody({ sheet: wsSatistics, data, pvis, ragioneSociale });

  return wb.writeToBuffer().then(function(buffer: any) {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([buffer], { type: "application/octet-stream" })
    );
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  });
};
