import React from "react";
import * as R from "ramda";
import SingleSelect from "../../components/singleSelectMain";
import { option } from "../../components/singleSelectMain/helper";

type SelectInputType = {
  value: any;
  update: (a: any) => void;
  options: option[];
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
  containsFilter?: boolean;
};

const SelectInput = ({
  update,
  value,
  options,
  labelName = null,
  readOnly = false,
  requiredField = false,
  containsFilter = false
}: SelectInputType) => {
  const val = R.isNil(value) ? [] : [{ value, title: value }];
  return (
    <>
      {labelName ? (
        <label htmlFor="select">
          <b>
            {requiredField ? (
              <div className="d-flex">
                <div>{labelName}</div>
                <div className="required">&nbsp;(Required)</div>
              </div>
            ) : (
              labelName
            )}
          </b>
        </label>
      ) : null}
      <SingleSelect
        value={val}
        update={(x: option[]) => {
          const res = R.pathOr(null, [0, "value"], x);
          return update(res);
        }}
        options={options}
        disabled={readOnly}
        className="config"
        containsFilter = {containsFilter}
      />
    </>
  );
};
export default SelectInput;
