import React from "react";

export const ApplyChangesBtn = ({
  hasChanges,
  open
}: {
  hasChanges: boolean;
  open: () => void;
}) => {
  return hasChanges ? (
    <button
      type="button"
      className="btn btn-danger rounded-pill mx-2 margin-bottom-18"
      onClick={() => open()}
    >
      Apply changes
    </button>
  ) : null;
};

export default ApplyChangesBtn;
