import { EMPTY, Observable, of, defer, from } from "rxjs";
import * as RxOp from "rxjs/operators";
import {
  NetworkRequest,
  notStarted,
  success,
  inProgress,
  fail,
  isSuccess
} from "../../utils/request";
import { pagination, getAllPages } from "../../utils/pagination";
import { Dependancies } from "../storeTypes";
import * as R from "ramda";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/pipeable";
import { sequenceT } from "fp-ts/lib/Apply";
import * as A from "fp-ts/lib/Array";
import {
  RagioneSociale,
  PVI,
  RagioneSocialePVI,
  DataCombustibiliReport
} from "./ragioneSocialePvi/types";
import { combineEpics, ofType } from "redux-observable";
import * as t from "io-ts";
import { dispatchNetworkError } from "./errorHandler";
import {
  format,
  subMonths,
  eachDayOfInterval,
  endOfMonth,
  startOfMonth,
  addMonths
} from "date-fns";
import { flow } from "fp-ts/lib/function";
import { searchBuilder } from "../helper";
import download from "./audits/download";

const taskSequence = A.array.sequence(T.taskEither);

const optionZip = sequenceT(O.option);
export const key = "COMBUSTIBILI";

export const refreshRagioneSociale = {
  type: "rtm/COMBUSTIBILI/REFRESH_RAGIONE_SOCIALE"
} as const;

const refreshRagioneSocialeFail = (error: string) =>
  ({
    type: "rtm/COMBUSTIBILI/REFRESH_RAGIONE_SOCIALE_FAILED",
    error
  } as const);

const setRagioneSocialeOptions = (ragioneSociale: RagioneSociale[]) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_RAGIONE_SOCIALE_OPTIONS",
    ragioneSociale
  } as const);

export const selectRagioneSociale = (ragioneSociale: RagioneSociale) =>
  ({
    type: "rtm/COMBUSTIBILI/SELECT_RAGIONE_SOCIALE",
    ragioneSociale
  } as const);

const fectchReportsFail = (error: string) => {
  return {
    type: "rtm/COMBUSTIBILI/FETCH_REPORTS_FAIL",
    error
  } as const;
};

const setReports = (reports: any) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_REPORTS",
    reports
  } as const);

const fectchPVIsFail = (error: string) =>
  ({
    type: "rtm/COMBUSTIBILI/FETCH_PVI_FAIL",
    error
  } as const);

const setPvis = (pvis: PVI[]) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_PVIS",
    pvis
  } as const);

export const selectPVIs = (pvis: PVI[]) =>
  ({
    type: "rtm/COMBUSTIBILI/SELECT_PVI",
    pvis
  } as const);

const fetchContractInfoFail = (error: string) =>
  ({
    type: "rtm/COMBUSTIBILI/FETCH_COMBUSTIBILI_FAIL",
    error
  } as const);

const setCombustibili = (combustibili: DataCombustibiliReport[]) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_COMBUSTIBILI",
    combustibili
  } as const);

const setDefaultDate = (date: string) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_DEFAULT_DATE",
    date
  } as const);
export const selectMGPDate = (date: string) =>
  ({
    type: "rtm/COMBUSTIBILI/SELECT_MGP_DATE",
    date
  } as const);

export const exportExcel = {
  type: "rtm/COMBUSTIBILI/EXPORT_EXCEL"
} as const;

export const uploadExcel = (file: File) =>
  ({
    type: "rtm/COMBUSTIBILI/UPLOAD_EXCEL",
    file
  } as const);

export const getPVIData = {
  type: "rtm/COMBUSTIBILI/GET_PVI"
} as const;
const getPVIFail = (error: string) =>
  ({
    type: "rtm/COMBUSTIBILI/GET_PVI_FAIL",
    error
  } as const);

const setPVIData = (data: any) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_PVI_DATA",
    data
  } as const);

export const modifyOnline = {
  type: "rtm/COMBUSTIBILI/MODIFY_ONLINE"
} as const;

export const cancelModifyOnline = {
  type: "rtm/COMBUSTIBILI/CANCEL_MODIFY_ONLINE"
} as const;

const modifyOnlineDataFail = (error: string) =>
  ({
    type: "rtm/COMBUSTIBILI/MODIFY_ONLINE_DATA_FAIL",
    error
  } as const);

const setModifyOnlineData = (data: any) =>
  ({
    type: "rtm/COMBUSTIBILI/SET_MODIFY_ONLINE_DATA",
    data
  } as const);
const cancel = () =>
  ({
    type: "rtm/COMBUSTIBILI/CANCEL"
  } as const);

export const uploadModifyOnline = (data: any[][]) =>
  ({
    type: "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE",
    data
  } as const);

export const uploadModifyOnlineSucceed = () =>
  ({
    type: "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_SUCCEED"
  } as const);

export const uploadModifyOnlineClose = () =>
  ({
    type: "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_CLOSE"
  } as const);

export const checkReports = {
  type: "rtm/COMBUSTIBILI/CHECK_REPORT"
} as const;
const checkReportsSucceed = (data: any) =>
  ({
    type: "rtm/COMBUSTIBILI/CHECK_REPORT_SUCCEED",
    data
  } as const);
const checkReportsFail = (error: any) =>
  ({
    type: "rtm/COMBUSTIBILI/CHECK_REPORT_FAIL",
    error
  } as const);

export const downloadXML = {
  type: "rtm/COMBUSTIBILI/DOWNLOAD_XML"
} as const;
const downloadXMLSucceed = () =>
  ({
    type: "rtm/COMBUSTIBILI/DOWNLOAD_XML_SUCCEED"
  } as const);
const downloadXMLFail = (error: any) =>
  ({
    type: "rtm/COMBUSTIBILI/DOWNLOAD_XML_FAIL",
    error: R.pipe<any, any, any, any>(
      R.pathOr("{}", ["response"]),
      x => JSON.parse(x),
      R.path(["detail"])
    )(error)
  } as const);

export const uploadModifyOnlineFail = (error: any) =>
  ({
    type: "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_FAIL",
    error
  } as const);

export const clearData = {
  type: "rtm/COMBUSTIBILI/CLEAR_DATA"
} as const;

type Action =
  | typeof clearData
  | typeof refreshRagioneSociale
  | typeof exportExcel
  | typeof modifyOnline
  | typeof getPVIData
  | typeof downloadXML
  | typeof cancelModifyOnline
  | typeof checkReports
  | ReturnType<
      | typeof uploadModifyOnlineClose
      | typeof selectRagioneSociale
      | typeof refreshRagioneSocialeFail
      | typeof setRagioneSocialeOptions
      | typeof setPvis
      | typeof fectchReportsFail
      | typeof setReports
      | typeof fectchPVIsFail
      | typeof selectPVIs
      | typeof fetchContractInfoFail
      | typeof setCombustibili
      | typeof selectMGPDate
      | typeof uploadExcel
      | typeof modifyOnlineDataFail
      | typeof setModifyOnlineData
      | typeof uploadModifyOnline
      | typeof setDefaultDate
      | typeof getPVIFail
      | typeof setPVIData
      | typeof uploadModifyOnlineSucceed
      | typeof uploadModifyOnlineFail
      | typeof downloadXMLSucceed
      | typeof downloadXMLFail
      | typeof checkReportsSucceed
      | typeof checkReportsFail
      | typeof cancel
    >;

export type State = {
  ragioneSociale: NetworkRequest<string, RagioneSociale[]>;
  selectedRagioneSociale: O.Option<RagioneSociale>;
  pvis: NetworkRequest<string, PVI[]>;
  reports: NetworkRequest<string, any>;
  selectedPVIs: PVI[];
  combustibili: NetworkRequest<string, DataCombustibiliReport[]>;
  dateSelect: O.Option<string>;
  modifyOnline: NetworkRequest<string, DataCombustibiliReport>;
  pviData: NetworkRequest<string, any>;
  uploadOngoing: NetworkRequest<string, any>;
  uploadModify: any;
  downloadXML: NetworkRequest<any, any>;
  checkReports: NetworkRequest<any, any>;
};

export const initialState: State = {
  ragioneSociale: notStarted,
  selectedRagioneSociale: O.none,
  pvis: notStarted,
  reports: notStarted,
  selectedPVIs: [],
  combustibili: notStarted,
  dateSelect: O.some(format(subMonths(new Date(), 1), "yyyy-MM-01")),
  modifyOnline: notStarted,
  pviData: notStarted,
  uploadOngoing: notStarted,
  uploadModify: false,
  downloadXML: notStarted,
  checkReports: notStarted
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case "rtm/COMBUSTIBILI/CLEAR_DATA":
      return initialState;
    case "rtm/COMBUSTIBILI/REFRESH_RAGIONE_SOCIALE":
      return {
        ...state,
        ragioneSociale: inProgress
      };
    case "rtm/COMBUSTIBILI/REFRESH_RAGIONE_SOCIALE_FAILED":
      return {
        ...state,
        ragioneSociale: fail(action.error)
      };
    case "rtm/COMBUSTIBILI/SET_RAGIONE_SOCIALE_OPTIONS":
      return {
        ...state,
        ragioneSociale: success(action.ragioneSociale),
        selectedRagioneSociale: pipe(
          state.selectedRagioneSociale,
          O.alt(() => A.head(action.ragioneSociale))
        )
      };
    case "rtm/COMBUSTIBILI/SELECT_RAGIONE_SOCIALE":
      return {
        ...state,
        selectedRagioneSociale: O.some(action.ragioneSociale),
        pvis: inProgress,
        selectedPVIs: []
        // dateSelect: O.none
      };
    case "rtm/COMBUSTIBILI/FETCH_REPORTS_FAIL":
      return { ...state, reports: fail(action.error) };
    case "rtm/COMBUSTIBILI/SET_REPORTS":
      return {
        ...state,
        reports: success(action.reports)
      };
    case "rtm/COMBUSTIBILI/FETCH_PVI_FAIL":
      return { ...state, pvis: fail(action.error) };
    case "rtm/COMBUSTIBILI/SET_PVIS":
      return {
        ...state,
        pvis: success(action.pvis),
        selectedPVIs: action.pvis.slice(0, 1),
        checkReports: action.pvis.length >= 1 ? inProgress : notStarted
      };
    case "rtm/COMBUSTIBILI/SELECT_PVI":
      return {
        ...state,
        selectedPVIs: action.pvis,
        combustibili: inProgress,
        checkReports: action.pvis.length >= 1 ? inProgress : notStarted
        // dateSelect: O.none
      };
    case "rtm/COMBUSTIBILI/FETCH_COMBUSTIBILI_FAIL":
      return {
        ...state,
        combustibili: fail(action.error)
      };
    case "rtm/COMBUSTIBILI/SET_COMBUSTIBILI":
      return {
        ...state,
        combustibili: success(action.combustibili)
      };
    case "rtm/COMBUSTIBILI/SET_DEFAULT_DATE":
      return {
        ...state,
        dateSelect: O.some(action.date)
      };
    case "rtm/COMBUSTIBILI/SELECT_MGP_DATE":
      return {
        ...state,
        dateSelect: O.some(action.date)
      };
    case "rtm/COMBUSTIBILI/GET_PVI":
      return { ...state, pviData: inProgress };
    case "rtm/COMBUSTIBILI/GET_PVI_FAIL":
      return { ...state, pviData: fail(action.error) };
    case "rtm/COMBUSTIBILI/SET_PVI_DATA": {
      return {
        ...state,
        pviData: success(action.data)
      };
    }
    case "rtm/COMBUSTIBILI/MODIFY_ONLINE":
      return { ...state, modifyOnline: inProgress, pviData: inProgress };
    case "rtm/COMBUSTIBILI/CANCEL_MODIFY_ONLINE":
      return { ...state, modifyOnline: notStarted, pviData: notStarted };
    case "rtm/COMBUSTIBILI/MODIFY_ONLINE_DATA_FAIL": {
      const competenza = R.pathOr("", ["dateSelect", "value"], state);
      var combustibiliData = eachDayOfInterval({
        start: startOfMonth(new Date(competenza)),
        end: endOfMonth(new Date(competenza))
      }).map(d => [format(d, "yyyy-MM-dd"), []]) as any;

      return {
        ...state,
        modifyOnline: success({
          partitaIva: R.pathOr(
            "",
            ["selectedRagioneSociale", "value", "partitaIva"],
            state
          ),
          pvi: R.pathOr("", ["selectedPVIs", 0, "pvi"], state),
          competenza,
          emissioni: 0,
          smaltimento: 0,
          ecotasse: 0,
          combustibili: R.fromPairs(combustibiliData)
        })
      };
    }
    case "rtm/COMBUSTIBILI/SET_MODIFY_ONLINE_DATA": {
      return {
        ...state,
        modifyOnline: success(action.data)
      };
    }
    case "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_SUCCEED":
      return {
        ...state,
        uploadModify: true,
        uploadOngoing: success([])
      };
    case "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_FAIL":
      return {
        ...state,
        uploadModify: false,
        uploadOngoing: fail(action.error)
      };
    case "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_CLOSE":
      return {
        ...state,
        uploadModify: false
      };
    case "rtm/COMBUSTIBILI/CANCEL":
      return {
        ...state
      };
    case "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE":
      return {
        ...state,
        uploadOngoing: inProgress
      };
    case "rtm/COMBUSTIBILI/DOWNLOAD_XML":
      return {
        ...state,
        downloadXML: inProgress
      };
    case "rtm/COMBUSTIBILI/DOWNLOAD_XML_SUCCEED":
      return {
        ...state,
        downloadXML: success([])
      };
    case "rtm/COMBUSTIBILI/DOWNLOAD_XML_FAIL":
      return {
        ...state,
        downloadXML: fail(action.error)
      };
    case "rtm/COMBUSTIBILI/CHECK_REPORT":
      return {
        ...state,
        checkReports: success([])
      };
    case "rtm/COMBUSTIBILI/CHECK_REPORT_SUCCEED":
      return {
        ...state,
        checkReports: success(action.data)
      };
    case "rtm/COMBUSTIBILI/CHECK_REPORT_FAIL":
      return {
        ...state,
        checkReports: fail(action.error)
      };
    case "rtm/COMBUSTIBILI/EXPORT_EXCEL":
    case "rtm/COMBUSTIBILI/UPLOAD_EXCEL":
      return state;
  }
}

const refreshRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    ofType("rtm/COMBUSTIBILI/REFRESH_RAGIONE_SOCIALE"),
    RxOp.exhaustMap(() => {
      return defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesociale?skip=${skip}&limit=${limit}&sort=RagioneSociale%20asc`,
            pagination(RagioneSociale)
          )
        )
      );
    }),
    RxOp.map(
      E.fold<string, RagioneSociale[], Action>(
        refreshRagioneSocialeFail,
        setRagioneSocialeOptions
      )
    )
  );

const setRagioneSocialeOptionsEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap(a =>
      a.type === "rtm/COMBUSTIBILI/SET_RAGIONE_SOCIALE_OPTIONS"
        ? pipe(
            from(a.ragioneSociale),
            RxOp.take(1)
          )
        : EMPTY
    ),
    RxOp.exhaustMap(a =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${a.partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      )
    ),
    RxOp.map(E.map(x => x.map(x => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const selectRagioneSocialeEpic = (
  action$: Observable<Action>,
  _: any,
  deps: Dependancies
) =>
  pipe(
    action$,
    RxOp.mergeMap(a =>
      a.type === "rtm/COMBUSTIBILI/SELECT_RAGIONE_SOCIALE" ? of(a) : EMPTY
    ),
    RxOp.exhaustMap(a =>
      defer(
        getAllPages((skip = 0, limit = 999) =>
          deps.request.get(
            `core/ragionesocialepvi?partitaIva=${a.ragioneSociale.partitaIva}&skip=${skip}&limit=${limit}`,
            pagination(RagioneSocialePVI)
          )
        )
      )
    ),
    RxOp.map(E.map(x => x.map(x => x.pvi))),
    RxOp.map(E.fold<string, PVI[], Action>(fectchPVIsFail, setPvis))
  );

const getUserSelections = (
  state$: Observable<any>
): Observable<O.Option<[RagioneSociale, PVI[], string]>> =>
  pipe(
    state$,
    RxOp.first(),
    RxOp.map(Selectors.all),
    RxOp.map(s =>
      optionZip(s.selectedRagioneSociale, O.some(s.selectedPVIs), s.dateSelect)
    )
  );

const getCombustibiliData = (state$: Observable<any>, deps: Dependancies) =>
  pipe(
    getUserSelections(state$),
    RxOp.map(
      O.chain(([ragione, pvis, date]) =>
        pipe(
          A.head(pvis),
          O.map(pvi => [ragione, pvi, date] as const)
        )
      )
    ),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([ragione, pvi, date]) =>
          defer(
            deps.request.get(
              `producer/datiCombustibili/report/${ragione.partitaIva}/${pvi.pvi}/${date}`,
              DataCombustibiliReport
            )
          )
      )
    )
  );

const modifyOnlineEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    ofType("rtm/COMBUSTIBILI/MODIFY_ONLINE"),
    RxOp.mergeMapTo(getCombustibiliData(state$, deps)),
    RxOp.map(
      E.fold(modifyOnlineDataFail, data => setModifyOnlineData(data) as Action)
    )
  );

const getrequestPVIData = (state$: Observable<any>, deps: Dependancies) =>
  pipe(
    getUserSelections(state$),
    RxOp.map(
      O.chain(([ragione, pvis, date]) =>
        pipe(
          A.head(pvis),
          O.map(pvi => [pvi] as const)
        )
      )
    ),
    RxOp.mergeMap(
      O.fold(
        () => defer(T.left("Missing user selections")),
        ([pvi]) => defer(deps.request.get(`core/pvi/${pvi.pvi}`, t.any))
      )
    )
  );

const pviDataEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    ofType("rtm/COMBUSTIBILI/MODIFY_ONLINE"),
    RxOp.mergeMapTo(getrequestPVIData(state$, deps)),
    RxOp.map(E.fold(getPVIFail, data => setPVIData(data) as Action))
  );

const modifyOnlineUploadEpic = (
  action$: Observable<Action>,
  state$: Observable<any>,
  deps: Dependancies
): Observable<Action> =>
  pipe(
    action$,
    RxOp.mergeMap(a =>
      a.type === "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE" ? of(a) : EMPTY
    ),
    RxOp.map((x: any) => x.data),
    RxOp.mergeMap((a: any) => {
      return defer(
        deps.request.put(
          `producer/datiCombustibili/report/${a.partitaIva}/${a.pvi}/${a.competenza}`,
          a,
          t.unknown
        )
      );
    }),
    RxOp.map(
      E.fold<any, any, Action>(
        uploadModifyOnlineFail,
        uploadModifyOnlineSucceed
      )
    )
  );

type getDateFuncType = {
  func: any;
  val: number;
  fmt: string;
  append?: string;
};
const getDateFunc = ({ func, val, fmt, append = "" }: getDateFuncType) => {
  const headerVal = R.pipe<Date, Date, string>(
    x => func(x, val),
    x => format(x, fmt)
  )(new Date());

  return `${append}${headerVal}`;
};

const getReportsEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      action =>
        action.type === "rtm/COMBUSTIBILI/SET_PVIS" ||
        action.type === "rtm/COMBUSTIBILI/CANCEL_MODIFY_ONLINE" ||
        action.type === "rtm/COMBUSTIBILI/SELECT_PVI"
    ),
    RxOp.switchMap(a => {
      return R.isEmpty(a.pvis)
        ? of(a).pipe(
            RxOp.map(() => {
              return checkReportsSucceed([]);
            }),
            RxOp.mergeMapTo(EMPTY)
          )
        : of(a);
    }),
    RxOp.exhaustMap(a => {
      return state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state: any) => {
          const selectedPVIs = R.pathOr(
            [],
            ["COMBUSTIBILI", "selectedPVIs"],
            state
          ) as any;
          return R.length(selectedPVIs) >= 1
            ? of(state)
            : of(state).pipe(
                RxOp.map(() => {
                  return checkReportsSucceed([]);
                }),
                RxOp.mergeMapTo(EMPTY)
              );
        }),
        RxOp.mergeMap((state: any) => {
          const info = R.path(["COMBUSTIBILI"], state) as any;

          const queryString = searchBuilder({
            filters: {
              pvi: R.pipe(
                R.pathOr([], ["selectedPVIs"]),
                R.map(R.prop("pvi"))
              )(info),
              partitaIva: R.pathOr(
                null,
                ["selectedRagioneSociale", "value", "partitaIva"],
                info
              ),
              competenzaFrom: getDateFunc({
                func: subMonths,
                val: 6,
                fmt: "yyyy-MM-01"
              }),
              competenzaTo: getDateFunc({
                func: subMonths,
                val: 0,
                fmt: "yyyy-MM-01"
              })
            }
          });
          return defer(
            getAllPages((skip = 0, limit = 999) =>
              deps.request.get(
                `/producer/datiCombustibili/report?skip=${skip}&limit=${limit}&${queryString}`,
                pagination(t.any)
              )
            )
          );
        })
      );
    }),
    RxOp.map(E.fold<any, any, Action>(fectchReportsFail, setReports))
  );

const checkReportEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(
      action =>
        action.type === "rtm/COMBUSTIBILI/SELECT_PVI" ||
        action.type === "rtm/COMBUSTIBILI/SELECT_MGP_DATE" ||
        action.type === "rtm/COMBUSTIBILI/SET_PVIS"
    ),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state: any) => {
          const selectedPVIs = R.pathOr(
            [],
            ["COMBUSTIBILI", "selectedPVIs"],
            state
          ) as any;
          return R.length(selectedPVIs) >= 1
            ? of(state)
            : of(state).pipe(
                RxOp.map(() => {
                  return checkReportsSucceed([]);
                }),
                RxOp.mergeMapTo(EMPTY)
              );
        }),
        RxOp.mergeMap((state: any) => {
          const partitaIva = R.path(
            ["COMBUSTIBILI", "selectedRagioneSociale", "value", "partitaIva"],
            state
          ) as any;
          const pvi = R.path(
            ["COMBUSTIBILI", "selectedPVIs", 0, "pvi"],
            state
          ) as any;
          const date = R.path(
            ["COMBUSTIBILI", "dateSelect", "value"],
            state
          ) as any;

          const queryString = searchBuilder({
            filters: {
              partitaIva,
              pvi,
              competenzaFrom: date,
              competenceTo: format(
                addMonths(new Date(date as string), 1),
                "yyyy-MM-01"
              )
            }
          });

          return defer(
            getAllPages((skip = 0, limit = 999) =>
              deps.request.get(
                `/producer/datiCombustibili/report?skip=${skip}&limit=${limit}&${queryString}`,
                pagination(t.any)
              )
            )
          );
        }),
        RxOp.map(
          E.fold<any, any, Action>(checkReportsFail, checkReportsSucceed)
        )
      )
    )
  );
const downloadXMLEpic = (
  action$: Observable<any>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(action => action.type === "rtm/COMBUSTIBILI/DOWNLOAD_XML"),
    RxOp.exhaustMap((action: any) =>
      state$.pipe(
        RxOp.first(),
        RxOp.mergeMap((state: any) => {
          const partitaIva = R.path(
            ["COMBUSTIBILI", "selectedRagioneSociale", "value", "partitaIva"],
            state
          ) as any;
          const pvi = R.path(
            ["COMBUSTIBILI", "selectedPVIs", 0, "pvi"],
            state
          ) as any;
          const date = R.path(
            ["COMBUSTIBILI", "dateSelect", "value"],
            state
          ) as any;
          const codiceUp = R.path(
            ["COMBUSTIBILI", "selectedPVIs", 0, "codiceUp"],
            state
          ) as any;

          return pipe(
            [
              deps.request.getXML(
                `producer/datiCombustibili/report/${partitaIva}/${pvi}/${date}/emissioni`,
                t.any
              ),
              deps.request.getXML(
                `producer/datiCombustibili/report/${partitaIva}/${pvi}/${date}/costiSmaltimento`,
                t.any
              ),
              deps.request.getXML(
                `producer/datiCombustibili/report/${partitaIva}/${pvi}/${date}/ecotasse`,
                t.any
              ),
              deps.request.getXML(
                `producer/datiCombustibili/report/${partitaIva}/${pvi}/${date}/consumoCombustibile`,
                t.any
              )
            ],
            taskSequence,
            T.map((data: any[]) => {
              return download({
                fileName: `_${codiceUp}_${date.substring(0, 7).replace(/-/g,"")}`,
                data,
                operation: "Combustibili"
              });
            }),
            defer
          );
        }),
        RxOp.map(E.fold<any, any, Action>(downloadXMLFail, downloadXMLSucceed))
      )
    )
  );

const failureEpic = (action$: Observable<Action>) =>
  action$.pipe(
    RxOp.filter(
      (action: any) =>
        action.type === "rtm/COMBUSTIBILI/UPLOAD_MODIFY_ONLINE_FAIL" ||
        action.type === "rtm/COMBUSTIBILI/FETCH_REPORTS_FAIL" ||
        action.type === "rtm/COMBUSTIBILI/FETCH_PVI_FAIL" ||
        action.type === "rtm/COMBUSTIBILI/REFRESH_RAGIONE_SOCIALE_FAILED" ||
        action.type === "rtm/COMBUSTIBILI/DOWNLOAD_XML_FAIL" ||
        action.type === "rtm/COMBUSTIBILI/CHECK_REPORT_FAIL" ||
        action.type === "rtm/COMBUSTIBILI/FETCH_COMBUSTIBILI_FAIL"
    ),
    RxOp.map(x => dispatchNetworkError(x.error))
  );

export const epic = combineEpics(
  refreshRagioneSocialeEpic,
  getReportsEpic,
  setRagioneSocialeOptionsEpic,
  selectRagioneSocialeEpic,
  modifyOnlineEpic,
  modifyOnlineUploadEpic,
  pviDataEpic,
  failureEpic,
  downloadXMLEpic,
  checkReportEpic,
  checkReportEpic
);

export const Selectors = {
  all: (s: any): State => s[key],
  modifyOnline: flow(
    (s: any): State => s[key],
    s => s.modifyOnline,
    isSuccess
  )
};
