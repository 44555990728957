import React from "react";
import * as R from "ramda";
import DateFilter from "../../components/tableComponents/dateFilter";
import Sort, { SortValType } from "../../components/tableComponents/sort";

export type TextTableHeaderType = {
  title: string;
  propName: string;
  sortFilter?: SortValType | null;
  setSortFilter?: (a: SortValType) => void;
  filters?: any;
  style?: any;
  setFilter?: (a: any) => void;
};

export const TextTableHeader = ({
  title,
  propName,
  sortFilter = null,
  setSortFilter = () => true,
  filters = null,
  style = {},
  setFilter = () => true
}: TextTableHeaderType) => {
  return (
    <th className="tableFilterHeader">
      <div style={style}>
        <span>
          {title}
          {sortFilter ? (
            <Sort
              propName={propName}
              sortColumn={
                sortFilter || {
                  column: null,
                  direction: null
                }
              }
              sortColumnFunc={setSortFilter}
            />
          ) : null}
        </span>
        {R.has(propName, filters) ? (
          <DateFilter
            propKey={propName}
            filters={filters}
            onChange={setFilter}
            outPutFormat="yyyy-MM-dd"
          />
        ) : null}
      </div>
    </th>
  );
};

export default TextTableHeader;
