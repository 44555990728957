import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { option } from "./helper";
import Filter from "./filter";
import "./select.scss";

type SingleSelectTypes = {
  value: option[];
  update: (a: option[]) => void;
  options: option[];
  containsFilter?: boolean;
  disabled?: boolean;
  clearSelection?: boolean;
  className?: string;
};
const SingleSelect = ({
  value = [],
  update,
  options,
  containsFilter = false,
  disabled = false,
  clearSelection = false,
  className = ""
}: SingleSelectTypes) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string | null>(null);
  const opts = R.ifElse(
    x => R.isEmpty(x) || R.isNil(x),
    () => options,
    x =>
      R.filter(
        (row: option) => R.contains(R.toLower(x), R.toLower(row.title) as any),
        options
      )
  )(filter) as any;

  const elId = R.isEmpty(value) ? "" : `${value[0].value}${value[0].title}`;
  const filterId =
    R.isEmpty(opts) || R.isEmpty(filter) || R.isNil(filter)
      ? ""
      : `${opts[0].value as any}${opts[0].title as any}`;

  useEffect(() => {
    scrollToId(R.isEmpty(filter) || R.isNil(filter) ? elId : filterId);
  }, [isOpen, filter]);

  const onUpdate = (row: option) => {
    const res = R.contains(row, value) ? [] : [row];
    update(res);
    setOpen(false);
    setFilter(null);
  };
  const clear = () => {
    update([]);
    setOpen(false);
    setFilter(null);
  };

  const blurHandler = () => {
    setOpen(false);
    setFilter(null);
  };

  return (
    <div
      className={`dropdown selectStyling ${className} ${
        disabled ? "disabled" : ""
      }`}
      onBlur={R.unless(
        (e: any) =>
          e.currentTarget === e.relatedTarget ||
          e.target.contains((e as any).relatedTarget),
        blurHandler
      )}
      onKeyDown={onEnter(blurHandler)}
      tabIndex={0}
    >
      <div className="toggle" onClick={() => setOpen(R.not)}>
        <span>{disabled && R.isEmpty(value) ? "" : displayResult(value)}</span>
        <div className="spacer" />

        {R.unless(R.equals(true), () => {
          return <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`} />;
        })(disabled)}
      </div>

      {isOpen ? (
        <div className="dropdown-menu menuList">
          {containsFilter ? <Filter value={filter} update={setFilter} /> : null}

          {R.when(R.equals(true), () => {
            return (
              <div className="item clearItem" onClick={clear}>
                <span>Clear Selected</span>
                <div className="spacer" />
                <label className="fa fa-times" />
              </div>
            );
          })(!R.isEmpty(value) && clearSelection)}
          <div className="list">
            {R.isEmpty(opts) ? (
              <div className="item">
                <label className="checkmark" />
                <span>No items</span>
              </div>
            ) : (
              opts.map((row: option, i: number) => {
                const rowVal = R.dissoc("info", row);
                return (
                  <div
                    className={`item ${
                      R.contains(rowVal, value) ? "selected" : ""
                    }`}
                    key={`${i}${row.title}`}
                    id={`${row.value}${row.title}`}
                    onClick={() =>
                      R.unless(s => R.contains(s, value), onUpdate)(row)
                    }
                  >
                    <label className="checkmark" />
                    <span>{row.title}</span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const displayResult = (val: option[]) => {
  if (R.isEmpty(val)) {
    return "Inserire valore";
  }

  if (val === null) {
    return "wait";
  }
  return R.isEmpty(val[0].title) ? "Inserire valore" : val[0].title;
};

const onEnter = (fn: () => void) =>
  R.when(
    (event: React.KeyboardEvent<HTMLDivElement>) => event.key === "Enter",
    fn
  );

const scrollToId = (val: string) => {
  const elmnt = document.getElementById(val) as HTMLElement;
  return R.unless(R.isNil, () => {
    elmnt.scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "start"
    });
  })(elmnt);
};

export default SingleSelect;
