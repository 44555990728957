import React from "react";
import { format } from "date-fns/esm";

type DateValueType = {
  date: string;
};
const DateValue = ({ date }: DateValueType) => {
  const val = format(new Date(date), "MMM dd, yyyy");
  return (
    <td>
      <b>{val}</b>
    </td>
  );
};
export default DateValue;
