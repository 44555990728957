import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { permissionCheck } from "../helper/permissions";
import permissions from "../permissions";
import {
  Selectors,
  requestDataAction,
  closeModalAction,
  openMappingsModalAction,
  closeMappingsModalAction,
  openModalAction,
  updateModalRowAction,
  deleteRowAction,
  DeleteRowSendType,
  cancelModifictionAction,
  CancelChangesType,
  commitChangesAction,
  CommitChangesType,
  applyChangesAction,
  clearAllStoredDataAction,
  exportDataAction,
  uploadDataAction,
  uploadSucceededCloseAction,
  nullArr
} from "../redux/modules/configs/upProvider";
import {
  Selectors as EnumsSelectors,
  requestDynamicEnumsAction
} from "../redux/modules/enums";
import {
  modCheck,
  rowClass,
  ConfigModalInfoType,
  getConfigModalInfo,
  TableType,
  filterOptionsFunc,
  filterTextFunc,
  sortbyFunc,
  DownloadUpload
} from "./helper";
import Loader from "../components/loader";
import { SortValType } from "../components/tableComponents/sort";
import ConfigModal from "./components/configModal";
import ConfirmationModal from "./components/confirmationModal";
import SelectInput from "./components/selectInput";
import EditCol from "./components/editCol";
import AddBtn from "./components/addBtnHeader";
import NoData from "./components/noData";
import ApplyChangesBtn from "./components/applyChangesBtn";
import TextTableHeader from "./components/textTableHeader";
import SelectTableHeader from "./components/selectTableHeader";
import NotifcationModal from "../components/notifcationModal";
import { anyNull } from "../redux/modules/configs/helperts";
import PageOnLoad from "../components/pageOnLoad";

type CrudTableType = {
  state: any;
  data: any;
  modal: any;
  isFetching: boolean;
  uploadSucceed: boolean;
  applyMappingsModal: boolean;
  enums: any;
  dynamicEnums: any;
  requestDataAction: () => void;
  closeModalAction: () => void;
  openMappingsModalAction: () => void;
  closeMappingsModalAction: () => void;
  openModalAction: (a: any) => void;
  updateModalRowAction: (a: any) => void;
  deleteRowAction: (a: DeleteRowSendType) => void;
  cancelModifictionAction: (a: CancelChangesType) => void;
  commitChangesAction: (a: CommitChangesType) => void;
  applyChangesAction: () => void;
  clearAllStoredDataAction: () => void;
  exportDataAction: () => void;
  uploadDataAction: (a: any) => void;
  uploadSucceededCloseAction: () => void;
  requestDynamicEnumsAction: () => void;
};

const CrudTable = ({
  state,
  data,
  modal,
  isFetching,
  uploadSucceed,
  requestDataAction,
  applyMappingsModal,
  enums,
  dynamicEnums,
  closeModalAction,
  updateModalRowAction,
  openMappingsModalAction,
  closeMappingsModalAction,
  openModalAction,
  deleteRowAction,
  cancelModifictionAction,
  commitChangesAction,
  applyChangesAction,
  clearAllStoredDataAction,
  exportDataAction,
  uploadDataAction,
  uploadSucceededCloseAction,
  requestDynamicEnumsAction
}: CrudTableType) => {
  useEffect(() => {
    R.when(R.isEmpty, () => requestDataAction())(data);
    R.when(R.isEmpty, () => requestDynamicEnumsAction())(dynamicEnums.up);
    return () => clearAllStoredDataAction();
  }, [requestDataAction, clearAllStoredDataAction]);
  return (
    <>
      <PageOnLoad />
      <div className="pageContainer">
        <h1>UP Provider</h1>
        <Table
          state={state}
          data={data}
          enums={enums}
          openModal={openModalAction}
          deleteRow={deleteRowAction}
          cancelModifiction={cancelModifictionAction}
        />
        <DownloadUpload
          exportData={exportDataAction}
          uploadData={uploadDataAction}
        />
        <ApplyChangesBtn
          hasChanges={modCheck(data)}
          open={() => openMappingsModalAction()}
        />
        <Prompt
          when={modCheck(data)}
          message="Are you sure you want to leave without saving changes?"
        />
        <Loader load={isFetching} />
        <ConfirmationModal
          openModal={applyMappingsModal}
          modalTitle="Apply Changes"
          modalBody={<span>Are you sure you want to apply changes?</span>}
          closeModal={() => closeMappingsModalAction()}
          cofirmModal={() => applyChangesAction()}
        />
        <ConfigModal
          openModal={modal.open}
          modalTitle={modal.type}
          modalBody={getConfigModalInfo({
            modalRow: modal.row,
            modalType: modal.type,
            update: updateModalRowAction,
            enums,
            dynamicEnums,
            func: configModalInfo
          })}
          bodyClassName="modalOverflowVisisble"
          disableApplyBtn={disableApplyBtn(modal)}
          closeModal={() => closeModalAction()}
          commitChanges={() =>
            commitChangesAction({
              modalData: modal,
              tableData: data
            })
          }
        />

        <NotifcationModal
          open={uploadSucceed && !isFetching}
          close={uploadSucceededCloseAction}
          title="Upload Successful"
          message="UP Provider Upload Successful"
        />
      </div>
    </>
  );
};

export default R.compose(
  connect(
    (s: any) => ({
      state: s,
      ...Selectors.all(s),
      enums: EnumsSelectors.enums(s),
      dynamicEnums: EnumsSelectors.dynamicEnums(s)
    }),
    {
      requestDataAction,
      closeModalAction,
      updateModalRowAction,
      openMappingsModalAction,
      closeMappingsModalAction,
      openModalAction,
      deleteRowAction,
      cancelModifictionAction,
      commitChangesAction,
      applyChangesAction,
      clearAllStoredDataAction,
      exportDataAction,
      uploadDataAction,
      uploadSucceededCloseAction,
      requestDynamicEnumsAction
    }
  )
)(CrudTable);

const disableApplyBtn = (modal: any) => {
  return (
    (modal.type === "Update Mapping" &&
      (modal.orig ? R.equals(modal.row, modal.orig) : true)) ||
    anyNull({ row: modal.row, nullArr })
  );
};

export const Table = ({
  state,
  data,
  enums = null,
  openModal,
  deleteRow,
  cancelModifiction
}: TableType) => {
  const havePermission = permissionCheck({
    state,
    permission: permissions.configurationWrite
  });

  const addNewRow = () =>
    openModal({
      type: "Add Mapping",
      row: {
        up: null,
        providerName: null,
        providerNameBackup: null
      }
    });

  const editRow = (row: any) =>
    openModal({
      type: "Update Mapping",
      row: {
        up: row.up,
        providerName: row.providerName,
        providerNameBackup: row.providerNameBackup
      }
    });

  const [sortFilter, setSortFilter] = useState<SortValType>({
    column: null,
    direction: null
  });

  const [filters, setFilter] = useState<any>({
    up: null,
    providerName: null,
    providerNameBackup: null
  });

  const tableData = R.pipe<any, any, any, any>(
    filterOptionsFunc,
    d => filterTextFunc({ key: "up", data: d, filters }),
    d => sortbyFunc({ sort: sortFilter, data: d })
  )({
    data,
    filters: R.omit(["up"], filters)
  });

  return (
    <div className="tableContainer">
      <div className="scroll">
        <table>
          <thead>
            <tr>
              <AddBtn havePermission={havePermission} addNewRow={addNewRow} />
              <TextTableHeader
                title="UP"
                propName="up"
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
              />
              <SelectTableHeader
                title="Provider Name"
                propName="providerName"
                options={R.map(x => ({ value: x, title: x }), enums.ProviderUp)}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
              />
              <SelectTableHeader
                title="Provider Name Backup"
                propName="providerNameBackup"
                options={R.map(x => ({ value: x, title: x }), enums.ProviderUp)}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                filters={filters}
                setFilter={setFilter}
              />
            </tr>
          </thead>
          <tbody>
            {R.isEmpty(tableData) ? (
              <NoData havePermission={havePermission} cols={3} />
            ) : (
              tableData.map((row: any, i: number) => {
                return (
                  <tr key={i} className={rowClass(row.mod || "")}>
                    <EditCol
                      havePermission={havePermission}
                      row={row}
                      openEditRow={() => editRow(row)}
                      deleteRow={() => deleteRow({ row, data })}
                      cancelModification={() =>
                        cancelModifiction({ row, data })
                      }
                    />
                    <td>{row.up}</td>
                    <td>{row.providerName}</td>
                    <td>{row.providerNameBackup}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const configModalInfo = ({
  type,
  modalType,
  value,
  modalRow,
  enums,
  dynamicEnums = null,
  update
}: ConfigModalInfoType) => {
  const objUpdater = R.compose(
    update,
    R.assoc(type, R.__, modalRow)
  );
  switch (type) {
    case "up":
      return (
        <SelectInput
          update={objUpdater}
          requiredField={modalType === "Add Mapping"}
          readOnly={modalType === "Update Mapping"}
          labelName="UP"
          options={R.map(
            x => ({ value: x.codiceUp, title: x.codiceUp }),
            dynamicEnums.up
          )}
          value={value || ""}
        />
      );
    case "providerName":
      return (
        <SelectInput
          update={objUpdater}
          requiredField
          labelName="Provider Name"
          options={R.map(x => ({ value: x, title: x }), enums.ProviderUp)}
          value={value || ""}
        />
      );
    case "providerNameBackup":
      return (
        <SelectInput
          update={objUpdater}
          requiredField
          labelName="Provider Name Backup"
          options={R.map(x => ({ value: x, title: x }), enums.ProviderUp)}
          value={value || ""}
        />
      );
    default:
      return null;
  }
};
