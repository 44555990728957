import XLSX from "xlsx";
import { Observable, of } from "rxjs";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";
import { infoType } from "../excel";

export const readFile = ({ file, headers }: { file: any; headers: string[] }) =>
  Observable.create((obs: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const dataResult = e.target.result;
      const workbook = XLSX.read(dataResult, { type: "array" });
      const sheets = workbook.SheetNames;
      const res = [] as any;
      sheets.map(sheet => {
        return res.push({
          sheet,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
            header: headers,
            defval: null
          })
        });
      });
      obs.next(res);
      obs.complete();
    };
    reader.readAsArrayBuffer(file);
  });

type FormJsonType = { file: any; info: infoType[]; sheetName: string };
export const formJson = ({ file, info, sheetName }: FormJsonType) =>
  of(file).pipe(
    RxOp.flatMap(file => file),
    RxOp.flatMap(file =>
      readFile({ file, headers: R.map(R.prop("propName"), info) })
    ),
    RxOp.map((x: any) => R.filter(R.propEq("sheet", sheetName), x)),
    RxOp.flatMap((x: any) => x),
    RxOp.map(R.pathOr(null, ["data"])),
    RxOp.toArray()
  );

export default formJson;
