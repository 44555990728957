import React from "react";
import * as R from "ramda";
import { CheckBox } from "../../components/checkBox";
import styles from "../coefficientiUP.module.scss";
import Accordion from "../../components/accordion/accordion";

type SectionType = {
  zona: string;
  codiceList: any;
  selectedCodiceList: any;
  update: any;
};

const Section = ({
  zona,
  codiceList,
  selectedCodiceList,
  update
}: SectionType) => {
  const list = R.map(R.prop("codiceUp"), codiceList) as any;
  const selectedList = R.pipe<any, any, any>(
    R.filter(R.propEq("zona", zona)),
    R.pathOr([], [0, "list"])
  )(selectedCodiceList || []);

  const allSelected = R.equals(list, selectedList);

  const setData = (list: any) =>
    R.pipe<any, any, any>(
      R.findIndex(R.propEq("zona", zona)),
      (i: any) => {
        const res = { zona, list };
        return update(
          R.equals(i, -1)
            ? R.append(res, selectedCodiceList || [])
            : R.update(i || 0, res, selectedCodiceList)
        );
      }
    )(selectedCodiceList || []);
  return (
    <div>
      <Accordion
        title={
          <label className="form-group text-uppercase">
            <CheckBox
              label={zona}
              name={zona}
              selected={selectedList}
              partialSelected={
                selectedList.length < list.length && selectedList.length > 0
              }
              allSeleted={allSelected || selectedList.length === list.length}
              update={() => setData(allSelected ? [] : list)}
            />
          </label>
        }
        content={
          <div className={`${styles.spotRegionSelect}`}>
            {list.map((item: any, i: number) => (
              <CheckBox
                key={i}
                label={item}
                name={item}
                selected={selectedList}
                update={setData}
              />
            ))}
          </div>
        }
      />
    </div>
  );
};

export default Section;
