import React from "react";
import * as R from "ramda";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";
import {
  Selectors,
  cancelModifyOnline,
  uploadModifyOnline,
  uploadModifyOnlineClose
} from "../redux/modules/Combustibili";
import { useDispatch, useSelector } from "react-redux";
import { pipe } from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import { toOption, anyInProgess } from "../utils/request";
import { eachDayOfInterval, startOfMonth, endOfMonth, format } from "date-fns";
import Loader from "../components/loader";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";

const EditCombustibili = () => {
  const dispatch = useDispatch();
  const combustibili = useSelector(Selectors.all);

  return R.isEmpty(getPlantFuelTypes(combustibili)) ? (
    <div className="container d-flex justify-content-center jexcel_hidden_index flex-column">
      <div className="backButton" onClick={() => dispatch(cancelModifyOnline)}>
        <i className="fas fa-arrow-circle-left" />
        Combustibili
      </div>
      <div className="d-flex align-items-center flex-column">
        <div className="d-flex">
          Non sono configurati combustibili per questo impianto, quindi non si
          può procedere alla loro modifica
        </div>
      </div>
    </div>
  ) : (
    <Edit />
  );
};
export default EditCombustibili;

const Edit = () => {
  const dispatch = useDispatch();
  const combustibili = useSelector(Selectors.all);

  const plantFuels = getPlantFuelTypes(combustibili);
  const excelContainer = React.useRef(null);
  const excelInstance = React.useRef({
    destroy: () => {},
    setData: (a: any) => {},
    getData: () => []
  });

  React.useEffect(() => {
    const container = excelContainer.current;
    excelInstance.current = jexcel(container, {
      allowInsertRow: false,
      allowInsertColumn: false,
      nestedHeaders: [nestedHeader(plantFuels)],
      columns: nestedColumn(plantFuels),
      onbeforepaste: (el: any, val: any) => {
        return R.replace(/,/g, ".", val);
      }
    });
    return () => jexcel.destroy(container);
  }, [combustibili.pviData]);

  const [mods, setMods] = React.useState<any>({
    emissioni: 0,
    smaltimento: 0,
    ecotasse: 0
  });

  var allCompetenzaDates = (date: string) =>
    eachDayOfInterval({
      start: startOfMonth(new Date(date)),
      end: endOfMonth(new Date(date))
    }).map(d => format(d, "yyyy-MM-dd")) as any;

  React.useEffect(() => {
    pipe(
      toOption(combustibili.modifyOnline),

      O.map(s => {
        return {
          dates: allCompetenzaDates(s.competenza),
          combustibili: s.combustibili
        };
      }),
      O.map(s => {
        const pvi = R.pathOr(null, ["selectedPVIs", 0, "nome"], combustibili);
        return R.map((d: any) => {
          const data = R.pathOr([], ["combustibili", d], s);
          return R.concat(
            [d, pvi, format(new Date(d), "dd-MM-yyyy")],
            R.flatten(bodyData({ data, fuels: plantFuels }))
          );
        }, s.dates);
      }),
      O.fold(() => {}, excelInstance.current.setData)
    );

    pipe(
      toOption(combustibili.modifyOnline),
      O.fold(
        () =>
          setMods({
            emissioni: 0,
            smaltimento: 0,
            ecotasse: 0
          }),
        x =>
          setMods({
            emissioni: x.emissioni,
            smaltimento: x.smaltimento,
            ecotasse: x.ecotasse
          })
      )
    );
  }, [combustibili.modifyOnline, combustibili.pviData]);
  return (
    <>
      <PageOnLoad customPath="ModifyOnline" />
      <NotifcationModal
        open={combustibili.uploadModify}
        close={() => dispatch(uploadModifyOnlineClose())}
        title="Upload Successful"
        message="Combustibili Upload Successful"
      />
      <div className="d-flex flex-column pageContainer">
        <div
          className="backButton"
          onClick={() => dispatch(cancelModifyOnline)}
        >
          <i className="fas fa-arrow-circle-left" />
          Combustibili
        </div>

        <div className="containerHeader"> Modifica Report Combustibili </div>
        <div className="d-flex">
          {pipe(
            toOption(combustibili.modifyOnline),
            O.fold(
              () => null,
              m => (
                <>
                  <label className="form-group mx-3 full-width form-label">
                    {"Emissioni [Ton]"}
                    <input
                      className="form-control"
                      type="number"
                      step=".01"
                      value={mods.emissioni}
                      onChange={e =>
                        setMods({ ...mods, emissioni: e.target.value })
                      }
                    />
                  </label>
                  <label className="form-group mx-3 full-width form-label">
                    Smaltimento
                    <input
                      className="form-control"
                      type="number"
                      step=".01"
                      value={mods.smaltimento}
                      onChange={e =>
                        setMods({ ...mods, smaltimento: e.target.value })
                      }
                    />
                  </label>
                  <label className="form-group mx-3 full-width form-label">
                    Ecotasse
                    <input
                      className="form-control"
                      type="number"
                      step=".01"
                      value={mods.ecotasse}
                      onChange={e =>
                        setMods({ ...mods, ecotasse: e.target.value })
                      }
                    />
                  </label>
                </>
              )
            )
          )}
        </div>
      </div>
      <div className="container d-flex justify-content-center jexcel_hidden_index flex-column">
        <div className="d-flex align-items-center flex-column">
          <div className="d-flex flex-column">
            <div className="centerInFlexContainer">
              <div ref={excelContainer}></div>
            </div>
            <div className="align-self-baseline">
              <button
                className="btn btn-danger rounded-pill mb-5"
                onClick={() => {
                  dispatch(
                    uploadModifyOnline(
                      setModData({
                        defaultData: pipe(
                          toOption(combustibili.modifyOnline),
                          O.fold(() => null, x => x)
                        ),
                        excelData: excelInstance.current.getData(),
                        modData: mods,
                        fuels: plantFuels
                      })
                    )
                  );
                }}
              >
                Invia dati inseriti
              </button>
            </div>
          </div>
        </div>
      </div>
      <Loader
        load={anyInProgess([
          combustibili.modifyOnline,
          combustibili.pviData,
          combustibili.uploadOngoing
        ])}
      />
    </>
  );
};

const setModData = ({
  defaultData,
  excelData,
  modData,
  fuels
}: {
  defaultData: any;
  excelData: any;
  modData: any;
  fuels: any;
}) => {
  const res = R.pipe<any, any, any, any, any>(
    R.map((data: any) => sortData({ data, fuels })),
    R.map((row: any) => {
      return R.isEmpty(row) ? null : [row[0].date, row];
    }),
    R.filter((x: any) => x),
    R.fromPairs
  )(excelData);
  return {
    ...defaultData,
    ...modData,
    combustibili: res
  };
};

function parseNumber(a: string) {
  return a ? Number(a) : 0;
}

const sortData = ({ data, fuels }: { data: any; fuels: any }): any => {
  const date = R.nth(0, data);
  const sorted = R.pipe(
    R.remove(0, 3),
    R.splitEvery(2)
  )(data);

  return fuels
    .map((fuelType: any, i: number) => {
      const fuelTypeData = sorted[i];
      return R.all(R.isEmpty)(fuelTypeData)
        ? null
        : {
            date,
            fuelType,
            value: parseNumber(fuelTypeData[0] as string),
            pci: parseNumber(fuelTypeData[1] as string)
          };
    })
    .filter((x: any) => x);
};

const nestedHeader = (fuelType: any) => {
  const list = R.map(title => ({ title, colspan: "2" }), fuelType);
  return R.concat(
    [{ title: "", colspan: "1" }, { title: "", colspan: "1" }],
    list
  );
};

const getFuelUnit = (fuel: string) =>
  R.pipe<any, any, any>(
    R.filter(R.propEq("title", fuel)),
    R.pathOr("", [0, "unit"])
  )(unitInfo);

const nestedColumn = (fuelType: any) => {
  const list = R.pipe<any, any, any>(
    R.map((fuel: string) => [
      {
        title: getFuelUnit(fuel),
        type: "numeric",
        width: 100,
        decimal: ","
      },
      {
        type: "numeric",
        title: "PCI" + " [Kcal/" + getFuelUnit(fuel) +"]",
        width: 100,
        decimal: ","
      }
    ]),
    R.flatten
  )(fuelType);

  return R.concat(
    [
      { title: "Data", width: 150, readOnly: true, type: "hidden" },
      { title: "PVI", width: 150, readOnly: true },
      { title: "Data", width: 150, readOnly: true }
    ],
    list
  );
};

const bodyData = ({ data, fuels }: { data: any; fuels: any }) => {
  return R.map(title => {
    return R.pipe<any, any, any>(
      R.filter(R.propEq("fuelType", title)),
      x => [R.pathOr(null, [0, "value"], x), R.pathOr(null, [0, "pci"], x)]
    )(data);
  }, fuels);
};

export const getPlantFuelTypes = R.pipe<any, any, any>(
  R.pathOr([], ["pviData", "value", "fuelInfo"]),
  R.map(R.prop("fuelType"))
);

const unitInfo = [
  { title: "ABL", unit: "kg" },
  { title: "ACF", unit: "kg" },
  { title: "ACG", unit: "m3" },
  { title: "ACS", unit: "kg" },
  { title: "BDA", unit: "m3" },
  { title: "BDF", unit: "m3" },
  { title: "BDG", unit: "m3" },
  { title: "BDR", unit: "m3" },
  { title: "BDS", unit: "kg" },
  { title: "BFR", unit: "m3" },
  { title: "BNZ", unit: "kg" },
  { title: "BRC", unit: "kg" },
  { title: "BRS", unit: "m3" },
  { title: "BSO", unit: "kg" },
  { title: "CDC", unit: "kg" },
  { title: "CDG", unit: "kg" },
  { title: "CDP", unit: "kg" },
  { title: "CDR", unit: "kg" },
  { title: "CER", unit: "kg" },
  { title: "CHE", unit: "kg" },
  { title: "CRB", unit: "kg" },
  { title: "CSB", unit: "kg" },
  { title: "CTC", unit: "kg" },
  { title: "GAO", unit: "m3" },
  { title: "GDA", unit: "m3" },
  { title: "GDC", unit: "m3" },
  { title: "GDE", unit: "m3" },
  { title: "GDO", unit: "m3" },
  { title: "GDR", unit: "m3" },
  { title: "GNA", unit: "m3" },
  { title: "GNI", unit: "m3" },
  { title: "GPG", unit: "m3" },
  { title: "GPL", unit: "m3" },
  { title: "GRP", unit: "m3" },
  { title: "GSL", unit: "kg" },
  { title: "GSS", unit: "m3" },
  { title: "HDR", unit: "m3" },
  { title: "INB", unit: "kg" },
  { title: "LGN", unit: "kg" },
  { title: "LIG", unit: "kg" },
  { title: "MDL", unit: "kg" },
  { title: "NFT", unit: "kg" },
  { title: "OBZ", unit: "kg" },
  { title: "OMZ", unit: "kg" },
  { title: "ORI", unit: "kg" },
  { title: "OSZ", unit: "kg" },
  { title: "OVG", unit: "kg" },
  { title: "PTG", unit: "kg" },
  { title: "RLB", unit: "kg" },
  { title: "RSU", unit: "kg" },
  { title: "TRB", unit: "kg" }
];
