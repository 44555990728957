import * as R from "ramda";
import { combineReducers } from "redux";
import { Observable, of, defer } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { Dependancies } from "../storeTypes";
import {
  actionTypes as ErrorActions,
  dispatchNetworkError
} from "./errorHandler";
import { getAllRequests } from "../helper";

export const key = "enums";
export enum actionTypes {
  CANCEL = "rtm/enums/CANCEL",
  SET_ENUMS = "rtm/enums/SET_ENUMS",
  FETCHING_ENUMS = "rtm/enums/FETCHING_ENUMS",
  RECEIVED_ENUMS = "rtm/enums/RECEIVED_ENUMS",
  SET_CONFIG_ENUMS = "rtm/enums/SET_CONFIG_ENUMS",
  FETCHING_CONFIG_ENUMS = "rtm/enums/FETCHING_CONFIG_ENUMS",
  RECEIVED_CONFIG_ENUMS = "rtm/enums/RECEIVED_CONFIG_ENUMS",
  SET_DYNAMIC_ENUMS = "rtm/enums/SET_DYNAMIC_ENUMS",
  FETCHING_DYNAMIC_ENUMS = "rtm/enums/FETCHING_DYNAMIC_ENUMS",
  RECEIVED_DYNAMIC_ENUMS = "rtm/enums/RECEIVED_DYNAMIC_ENUMS"
}

type Actions =
  | { type: actionTypes.CANCEL }
  | { type: actionTypes.FETCHING_ENUMS }
  | { type: actionTypes.RECEIVED_ENUMS }
  | {
      type: actionTypes.SET_ENUMS;
      enum: string;
      enumData: any;
    }
  | { type: actionTypes.FETCHING_CONFIG_ENUMS }
  | { type: actionTypes.RECEIVED_CONFIG_ENUMS }
  | {
      type: actionTypes.SET_CONFIG_ENUMS;
      enum: string;
      enumData: any;
    }
  | { type: actionTypes.FETCHING_DYNAMIC_ENUMS }
  | { type: actionTypes.RECEIVED_DYNAMIC_ENUMS }
  | {
      type: actionTypes.SET_DYNAMIC_ENUMS;
      enum: string;
      enumData: any;
    };

const EnumDefaultState = {
  AuditKind: [],
  Category: [],
  Commodity: [],
  ConfigurationType: [],
  Currency: [],
  CurrencyUnit: [],
  CurveSubType: [],
  FuelType: [],
  Granularity: [],
  Macrozona: [],
  Mercato: [],
  MercatoPrezzi: [],
  ProviderPVI: [],
  ProviderUp: [],
  Segmento: [],
  Tensione: [],
  TipoCurvaPrezzi: [],
  TipoPrezzoProduttori: [],
  Unit: [],
  Zona: [],
  BiddingRunState: [],
  ApplyCoefficientiUp: [],
  BiddingBidEvoTarget: [],
  UnderlyingType: []
};
function EnumsReducer(state = EnumDefaultState, action: Actions) {
  switch (action.type) {
    case actionTypes.SET_ENUMS: {
      return {
        ...state,
        [action.enum]: action.enumData
      };
    }
    default:
      return state;
  }
}

const ConfigEnumState = { pviProvider: [] };
function ConfigEnumsReducer(state = ConfigEnumState, action: Actions) {
  switch (action.type) {
    case actionTypes.SET_CONFIG_ENUMS: {
      return {
        ...state,
        [action.enum]: action.enumData
      };
    }
    default:
      return state;
  }
}

const DynamicEnumState = {
  up: []
};
function DynamicEnumsReducer(state = DynamicEnumState, action: Actions) {
  switch (action.type) {
    case actionTypes.SET_DYNAMIC_ENUMS: {
      return {
        ...state,
        [action.enum]: action.enumData
      };
    }
    default:
      return state;
  }
}

function enumFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.RECEIVED_ENUMS:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FETCHING_ENUMS:
      return true;
    default:
      return state;
  }
}
function configEnumFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.RECEIVED_CONFIG_ENUMS:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FETCHING_CONFIG_ENUMS:
      return true;
    default:
      return state;
  }
}
function dynamicEnumFetchingReducer(state: boolean = false, action: Actions) {
  switch (action.type) {
    case actionTypes.RECEIVED_DYNAMIC_ENUMS:
    case actionTypes.CANCEL:
      return false;
    case actionTypes.FETCHING_DYNAMIC_ENUMS:
      return true;
    default:
      return state;
  }
}

export const reducer = combineReducers({
  enumFetching: enumFetchingReducer,
  configEnumFetching: configEnumFetchingReducer,
  enumData: EnumsReducer,
  configEnumData: ConfigEnumsReducer,
  dynamicEnumFetching: dynamicEnumFetchingReducer,
  dynamicEnumData: DynamicEnumsReducer
});

const cancel = (): Actions => ({ type: actionTypes.CANCEL });

export const requestEnumsAction = (): Actions => ({
  type: actionTypes.FETCHING_ENUMS
});

const receivedEnumsData = (): Actions => ({
  type: actionTypes.RECEIVED_ENUMS
});

type EnumAPIType = {
  type: string;
  table: any;
};
const setEnum = ({ type, table }: EnumAPIType): Actions => ({
  type: actionTypes.SET_ENUMS,
  enum: type,
  enumData: table
});

const setEnumEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(action => action.type === actionTypes.FETCHING_ENUMS),
    RxOp.switchMap(() =>
      of(EnumDefaultState).pipe(
        RxOp.flatMap(R.keys),
        RxOp.mergeMap(type =>
          defer(deps.request.get(`enum/${type}`, t.any)).pipe(
            RxOp.map(
              E.map(table => ({
                type,
                table
              }))
            )
          )
        ),
        RxOp.map(E.fold<any, any, Actions>(dispatchNetworkError, setEnum)),
        RxOp.concat(of(receivedEnumsData()))
      )
    )
  );

export const requestConfigEnumsAction = (): Actions => ({
  type: actionTypes.FETCHING_CONFIG_ENUMS
});

const receivedConfigEnumsData = (): Actions => ({
  type: actionTypes.RECEIVED_CONFIG_ENUMS
});

const setConfigEnum = ({ type, table }: EnumAPIType): Actions => ({
  type: actionTypes.SET_CONFIG_ENUMS,
  enum: type,
  enumData: table
});

const setConfigEnumEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(action => action.type === actionTypes.FETCHING_CONFIG_ENUMS),
    RxOp.switchMap(() =>
      of(ConfigEnumState).pipe(
        RxOp.flatMap(R.keys),
        RxOp.mergeMap(type =>
          defer(deps.request.get(`configuration/${type}`, t.any)).pipe(
            RxOp.map(
              E.map(({ table }) => ({
                type,
                table
              }))
            )
          )
        ),
        RxOp.map(
          E.fold<any, any, Actions>(dispatchNetworkError, setConfigEnum)
        ),
        RxOp.concat(of(receivedConfigEnumsData()))
      )
    )
  );

const getDynamicEnumsURL = (type: any): any => {
  switch (type) {
    case "up":
      return `core/up`;
    case "upProvider":
      return `configuration/upProvider`;
    default:
      return null;
  }
};

export const requestDynamicEnumsAction = (): Actions => ({
  type: actionTypes.FETCHING_DYNAMIC_ENUMS
});

const receivedDynamicEnumsData = (): Actions => ({
  type: actionTypes.RECEIVED_DYNAMIC_ENUMS
});

const setDynamicEnum = ({ type, table }: EnumAPIType): Actions => ({
  type: actionTypes.SET_DYNAMIC_ENUMS,
  enum: type,
  enumData: table
});

const setDynamicEnumEpic = (
  action$: Observable<Actions>,
  state$: Observable<any>,
  deps: Dependancies
) =>
  action$.pipe(
    RxOp.filter(action => action.type === actionTypes.FETCHING_DYNAMIC_ENUMS),
    RxOp.switchMap(() =>
      of(DynamicEnumState).pipe(
        RxOp.flatMap(R.keys),
        RxOp.mergeMap((type: any) => {
          return getAllRequests({
            deps,
            api: getDynamicEnumsURL(type),
            dispatchError: dispatchNetworkError
          }).pipe(RxOp.map(table => setDynamicEnum({ type, table })));
        }),
        RxOp.concat(of(receivedDynamicEnumsData()))
      )
    )
  );

const errorHandlerEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(ErrorActions.SET_ERROR),
    RxOp.map(cancel)
  );

export const epic = combineEpics(
  setEnumEpic,
  setConfigEnumEpic,
  errorHandlerEpic,
  setDynamicEnumEpic
);

export const Selectors = {
  all: R.prop(key),
  enums: R.path([key, "enumData"]),
  configEnums: R.path([key, "configEnumData"]),
  dynamicEnums: R.path([key, "dynamicEnumData"]),
  allEnumsFetching: (s: any) =>
    R.path([key, "enumFetching"], s) ||
    R.path([key, "configEnumFetching"], s) ||
    R.path([key, "dynamicEnumFetching"], s)
};
