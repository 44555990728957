import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { format } from "date-fns";
import Datepicker from "react-datepicker";
import { Selectors as EnumsSelectors } from "../redux/modules/enums";
import "../styles/highcharts.module.scss";
import { NoData } from "../components/tableComponents/noData";
import Loader from "../components/loader";
import SingleSelect from "../components/singleSelectMain";
import { ragioneSocialeVal } from "./helper";
import { option } from "../components/singleSelect/helper";
import Pagination from "../components/tableComponents/paginationNew";
import {
  Selectors,
  RagioneSocialeType,
  firstLoadAction,
  clearAllDataAction,
  ragioneSocialeState,
  actualDealState,
  ActualDealStateType,
  ActualDealFilterStateType,
  setActualDealFiltersAction,
  setActualDealPageAction,
  setActualDealFilterSocialeAction,
  sendDealCaptureAction,
  openDealCaptureAction,
  setDealCaptureDealTypesAction,
  dealCaptureState,
  DealCaptureType,
  closeDealCaptureAction,
} from "../redux/modules/management/actualDeal";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";
import ToggleSwitch from "../components/toggleSwitch/view";

type ProduzioneType = {
  isFetching?: boolean;
  ragioneSociale?: RagioneSocialeType;
  actualDeal?: ActualDealStateType;
  dealCapture?: DealCaptureType;
  firstLoadAction: () => void;
  clearAllDataAction: () => void;
  setActualDealFiltersAction: (a: ActualDealFilterStateType) => void;
  setActualDealPageAction: (a: number) => void;
  setActualDealFilterSocialeAction: (a: any) => void;
  sendDealCaptureAction: () => void;
  openDealCaptureAction: (a: any) => void;
  setDealCaptureDealTypesAction: (a: any) => void;
  closeDealCaptureAction: () => void;
};

const Produzione = ({
  isFetching = false,
  ragioneSociale = ragioneSocialeState,
  actualDeal = actualDealState,
  dealCapture = dealCaptureState,
  clearAllDataAction,
  firstLoadAction,
  setActualDealFiltersAction,
  setActualDealPageAction,
  setActualDealFilterSocialeAction,
  sendDealCaptureAction,
  openDealCaptureAction,
  setDealCaptureDealTypesAction,
  closeDealCaptureAction,
}: ProduzioneType) => {
  useEffect(() => {
    firstLoadAction();
    return () => clearAllDataAction();
  }, []);

  return (
    <>
      <PageOnLoad />
      <div>
        <div className="pageContainer">
          <ProduzioneFilters
            ragioneSociale={ragioneSociale}
            filters={actualDeal.filters}
            update={setActualDealFiltersAction}
            updateSociale={setActualDealFilterSocialeAction}
          />
          <div className="spacer"></div>
          <Loader
            load={
              isFetching ||
              ragioneSociale.isFetching ||
              dealCapture.isFetching ||
              actualDeal.fetching
            }
          />
        </div>
        <ProduzioneTable
          info={actualDeal}
          setPage={setActualDealPageAction}
          openDealCapture={openDealCaptureAction}
        />
        <NotifcationModal
          open={dealCapture.open && !actualDeal.fetching}
          close={closeDealCaptureAction}
          title="Processo Inserimento Contratti Risultato"
          message={
            <DealCaptureResult
              setDealCaptureDealTypes={setDealCaptureDealTypesAction}
              dealCaptureInfo={dealCapture}
            />
          }
          footer={
            <>
              <button
                className="btn btn-danger rounded-pill mx-2"
                onClick={() => sendDealCaptureAction()}
                disabled={R.isEmpty(dealCapture.dealTypes)}
              >
                Send Deals
              </button>
              <button
                className="btn btn-danger rounded-pill mx-2"
                onClick={() => closeDealCaptureAction()}
              >
                Close
              </button>
            </>
          }
        />
      </div>
    </>
  );
};

export default connect(
  (s: any) => ({ ...Selectors.all(s), enums: EnumsSelectors.enums(s) }),
  {
    firstLoadAction,
    clearAllDataAction,
    setActualDealFiltersAction,
    setActualDealPageAction,
    setActualDealFilterSocialeAction,
    sendDealCaptureAction,
    openDealCaptureAction,
    setDealCaptureDealTypesAction,
    closeDealCaptureAction,
  }
)(Produzione);

type ProduzioneFiltersType = {
  ragioneSociale: RagioneSocialeType;
  filters: ActualDealFilterStateType;
  update: (a: ActualDealFilterStateType) => void;
  updateSociale: (a: any) => void;
};
const ProduzioneFilters = ({
  ragioneSociale,
  filters,
  update,
  updateSociale,
}: ProduzioneFiltersType) => {
  useEffect(() => {
    R.when(R.equals(true), () =>
      updateSociale(ragioneSociale.data[0].partitaIva)
    )(R.isNil(filters.ragioneSociale) && !R.isEmpty(ragioneSociale.data));
  }, [ragioneSociale.data]);

  const updateFilters = ({ key, val }: { key: string; val: any }) =>
    update({ ...filters, [key]: val });

  return (
    <>
      <div className="container noPadding contratti-main-panel">
        <div className="left-panel">
          <div>
            <span className="form-label">Ragione Sociale</span>
          </div>
          <SingleSelect
            containsFilter
            value={ragioneSocialeVal({
              filters: filters.ragioneSociale,
              data: ragioneSociale.data,
            })}
            update={(x: option[]) =>
              updateSociale(R.pathOr(null, [0, "value"], x))
            }
            options={R.map(
              (x: any) => ({
                value: x.partitaIva,
                title: x.ragioneSociale,
              }),
              ragioneSociale.data
            )}
          />
        </div>
      </div>
      <div className="container d-flex flex-column">
        <div className="form-group text-uppercase">
          <div className="center-vertical">
            <div className="actualYM">
              <span className="form-label">Seleziona Periodo</span>
              <Datepicker
                className="dateInput-NotRounded date margin-bottom-18"
                onChange={(date: any) =>
                  updateFilters({
                    key: "yearMonth",
                    val: R.equals(null, date)
                      ? null
                      : format(date, "yyyy-MM-01"),
                  })
                }
                selected={
                  R.equals(null, filters.yearMonth)
                    ? null
                    : new Date(filters.yearMonth as any)
                }
                dateFormat="MM-yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type ProduzioneTableType = {
  info: ActualDealStateType;
  setPage: (a: number) => void;
  openDealCapture: (a: any) => void;
};
const ProduzioneTable = ({
  info,
  setPage,
  openDealCapture,
}: ProduzioneTableType) => {
  return (
    <>
      <div className="tableContainer">
        <div className="scroll">
          <table className="table">
            <thead>
              <tr>
                <th>Invia CONS</th>
                <th>Ragione Sociale</th>
                <th>Impianto</th>
                <th>Id SFS</th>
                <th>Year Month</th>
                <th colSpan={2}>Volume</th>
                <th colSpan={2}>Volume Internal</th>
                <th colSpan={2}>Volume Perdite</th>
                <th colSpan={2}>Spread</th>
                <th colSpan={2}>Sbilancio</th>
                <th colSpan={2}>CCT</th>
                <th colSpan={2}>CCTFixed</th>
                <th colSpan={2}>Committed</th>
                <th colSpan={2}>CommittedInternal</th>
                <th colSpan={2}>Eccedenza</th>
                <th colSpan={2}>EccedenzaInternal</th>
                <th colSpan={2}>VolumeFixed</th>
                <th colSpan={2}>VolumeIndexed</th>
                <th colSpan={2}>VolumePerditeFixed</th>
                <th colSpan={2}>VolumePerditeIndexed</th>
                <th colSpan={2}>VolumeFixedInternal</th>
                <th colSpan={2}>VolumeIndexedInternal</th>
              </tr>
            </thead>
            <tbody>
              {R.isEmpty(info.data) || R.isNil(info.data) ? (
                <NoData cols={17} />
              ) : (
                info.data.map((row: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div
                          className="bidding-arrow"
                          onClick={() => openDealCapture(row)}
                        >
                          <i className="fas fa-share-square"></i>
                        </div>
                      </td>
                      <td>
                        {R.pathOr(
                          null,
                          ["ragioneSociale", "ragioneSociale"],
                          row
                        )}
                      </td>
                      <td>{R.pathOr(null, ["pvi", "nome"], row)}</td>
                      <td>{R.pathOr(null, ["contratto", "codiceCRM"], row)}</td>
                      <td>
                        {R.isNil(row.competence)
                          ? null
                          : format(new Date(row.competence), "yyyy-MM")}
                      </td>
                      <TdStatus row={row} type="volume" />
                      <TdStatus row={row} type="volumeInternal" />
                      <TdStatus row={row} type="volumePerdite" />
                      <TdStatus row={row} type="spread" />
                      <TdStatus row={row} type="feeSbilancio" />
                      <TdStatus row={row} type="cct" />
                      <TdStatus row={row} type="cctFixed" />
                      <TdStatus row={row} type="committed" />
                      <TdStatus row={row} type="committedInternal" />
                      <TdStatus row={row} type="eccedenza" />
                      <TdStatus row={row} type="eccedenzaInternal" />
                      <TdStatus row={row} type="volumeFixed" />
                      <TdStatus row={row} type="volumeIndexed" />
                      <TdStatus row={row} type="volumePerditeFixed" />
                      <TdStatus row={row} type="volumePerditeIndexed" />
                      <TdStatus row={row} type="volumeFixedInternal" />
                      <TdStatus row={row} type="volumeIndexedInternal" />
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="pagingContainer">
          <Pagination
            count={info.count}
            pageSize={info.pageSize}
            page={info.page}
            onPageChange={setPage}
          />
        </div>
      </div>
    </>
  );
};

type TdStatusType = { row: any; type: string };
const TdStatus = ({ row, type }: TdStatusType) => {
  const status = R.pathOr(null, ["dealType", type, "check", "status"], row);
  const state = R.pathOr(
    null,
    ["dealType", type, "process", "dealCaptureProcessRun", "state"],
    row
  );
  const isRunning = R.pathOr(
    false,
    ["dealType", type, "process", "isRunning"],
    row
  );
  const processRunState = R.pathOr(
    null,
    ["dealType", type, "process", "dealCaptureProcessRun", "state "],
    row
  );

  const cell = cellClass({ status, isRunning, processRunState });
  return (
    <>
      <td className={`contrattiCell ${cell}`}>{statusName(status)}</td>
      <td className={`contrattiCell ${cell}`}>{processRunName(state)}</td>
    </>
  );
};

type StatusType =
  | "NA"
  | "NoMeasures"
  | "ToBeSent"
  | "ContractNotAligned"
  | "Aligned"
  | null;

type ProcessRunStateType =
  | "LoadingInEnrichment"
  | "LoadedInEnrichment"
  | "StagingInEnrichment"
  | "Error"
  | "SentToElviz"
  | "RemovedInEnrichment"
  | null;

type CellClassType = {
  status: StatusType;
  isRunning: boolean;
  processRunState: ProcessRunStateType;
};

const cellClass = ({ status, isRunning, processRunState }: CellClassType) => {
  if (R.contains(status, ["NA"])) {
    return "blackCell";
  }

  if (R.contains(status, ["Aligned"])) {
    return "greenCell";
  }

  if (
    R.contains(status, ["NoMeasures", "ToBeSent", "ContractNotAligned"]) &&
    isRunning
  ) {
    return "yellowCell";
  }

  if (
    R.contains(status, ["NoMeasures", "ToBeSent", "ContractNotAligned"]) &&
    R.contains(processRunState, ["Error"])
  ) {
    return "redCell";
  }

  if (
    R.contains(status, ["NoMeasures", "ContractNotAligned"]) &&
    !isRunning &&
    R.isNil(processRunState)
  ) {
    return "orangeCell";
  }

  return "";
};

const rowProps = [
  "volume",
  "volumeInternal",
  "volumePerdite",
  "spread",
  "feeSbilancio",
  "cct",
  "cctFixed",
  "committed",
  "committedInternal",
  "eccedenza",
  "eccedenzaInternal",
  "volumeFixed",
  "volumeIndexed",
  "volumePerditeFixed",
  "volumePerditeIndexed",
  "volumeFixedInternal",
  "volumeIndexedInternal",
];

const DealCaptureResult = ({
  dealCaptureInfo,
  setDealCaptureDealTypes,
}: {
  dealCaptureInfo: DealCaptureType;
  setDealCaptureDealTypes: (a: any) => void;
}) => {
  return (
    <div className="modal-body-margin">
      <div className="table">
        <div className="scroll">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Deal Type</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rowProps.map((type) => (
                <DealCaptureResultRow
                  key={type}
                  type={type}
                  dealCaptureInfo={dealCaptureInfo}
                  setDealCaptureDealTypes={setDealCaptureDealTypes}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const DealCaptureResultRow = ({
  type,
  dealCaptureInfo,
  setDealCaptureDealTypes,
}: {
  type: string;
  dealCaptureInfo: DealCaptureType;
  setDealCaptureDealTypes: (a: any) => void;
}) => {
  const status = dealCaptureInfo.data[type].check.status;
  return (
    <tr>
      <td>{dealTypes(type)}</td>
      <td title={(status as any) || ""}>
        <i
          className={`fa fa-${
            status === "Aligned" ? "check" : "times"
          }-circle ${status === "Aligned" ? "add" : "remove"}`}
        />
      </td>
      <td>
        <ToggleSwitch
          value={R.includes(type, dealCaptureInfo.dealTypes || [])}
          disabled={status === "NA"}
          update={(val: any) =>
            setDealCaptureDealTypes(
              updateDealTypes({
                val,
                item: type,
                list: dealCaptureInfo.dealTypes,
              })
            )
          }
        />
      </td>
    </tr>
  );
};

const updateDealTypes = ({
  val,
  item,
  list,
}: {
  val: any;
  item: any;
  list: any;
}) => {
  return val
    ? R.append(item, list || [])
    : R.reject((r) => r === item, list || []);
};

const dealTypes = (type: string) => {
  switch (type) {
    case "volume":
      return "Volume";
    case "volumeInternal":
      return "Volume Internal";
    case "volumePerdite":
      return "Volume Perdite";
    case "spread":
      return "Spread";
    case "feeSbilancio":
      return "Fee Sbilancio";
    case "cct":
      return "CCT";
    case "cctFixed":
      return "CCT Fixed";
    case "committed":
      return "Committed";
    case "committedInternal":
      return "Committed Internal";
    case "eccedenza":
      return "Eccedenza";
    case "eccedenzaInternal":
      return "Eccedenza Internal";
    case "volumeFixed":
      return "Volume Fixed";
    case "volumeIndexed":
      return "Volume Indexed";
    case "volumePerditeFixed":
      return "Volume Perdite Fixed";
    case "volumePerditeIndexed":
      return "Volume Perdite Indexed";
    case "volumeFixedInternal":
      return "Volume Fixed Internal";
    case "volumeIndexedInternal":
      return "Volume Indexed Internal";
    default:
      return type;
  }
};

const statusName = (type: StatusType) => {
  switch (type) {
    case "NA":
      return "NA";
    case "NoMeasures":
      return "No Measures";
    case "ToBeSent":
      return "To Be Sent";
    case "ContractNotAligned":
      return "Contract Not Aligned";
    case "Aligned":
      return "Aligned";
    default:
      return null;
  }
};
const processRunName = (type: ProcessRunStateType) => {
  switch (type) {
    case "LoadingInEnrichment":
      return "Loading In Enrichment";
    case "LoadedInEnrichment":
      return "Loaded In Enrichment";
    case "StagingInEnrichment":
      return "Staging In Enrichment";
    case "Error":
      return "Error";
    case "SentToElviz":
      return "Sent To Elviz";
    case "RemovedInEnrichment":
      return "Removed In Enrichment";
    default:
      return null;
  }
};
