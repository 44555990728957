import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  key as UpProviderKey,
  reducer as UpProviderReducer,
  epic as UpProviderEpic,
} from "./upProvider";
import {
  key as PVIProviderKey,
  reducer as PVIProviderReducer,
  epic as PVIProviderEpic,
} from "./pviProvider";
import {
  key as TensioneKey,
  reducer as TensioneReducer,
  epic as TensioneEpic,
} from "./tensione";
import {
  key as UpbackupKey,
  reducer as UpbackupReducer,
  epic as UpbackupEpic,
} from "./upbackup";
import {
  key as SpreadmgpKey,
  reducer as SpreadmgpReducer,
  epic as SpreadmgpEpic,
} from "./spreadmgp";
import {
  key as FuelKey,
  reducer as FuelReducer,
  epic as FuelEpic,
} from "./fuel";
import {
  key as InternalLegsKey,
  reducer as InternalLegsReducer,
  epic as InternalLegsEpic,
} from "./internalLegs";

export const reducer = combineReducers({
  [UpProviderKey]: UpProviderReducer,
  [PVIProviderKey]: PVIProviderReducer,
  [TensioneKey]: TensioneReducer,
  [UpbackupKey]: UpbackupReducer,
  [SpreadmgpKey]: SpreadmgpReducer,
  [FuelKey]: FuelReducer,
  [InternalLegsKey]: InternalLegsReducer,
});

export const epic = combineEpics(
  UpProviderEpic,
  PVIProviderEpic,
  TensioneEpic,
  UpbackupEpic,
  SpreadmgpEpic,
  FuelEpic,
  InternalLegsEpic
);
