import React from "react";
import InputText from "../../components/inputTextField";

type TextInputType = {
  value: any;
  update: (a: any) => void;
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
};

const TextInput = ({
  update,
  value,
  labelName = null,
  readOnly = false,
  requiredField = false
}: TextInputType) => (
  <>
    {labelName ? (
      <label htmlFor="select">
        <b>
          {requiredField ? (
            <div className="d-flex">
              <div>{labelName}</div>
              <div className="required">&nbsp;(Required)</div>
            </div>
          ) : (
            labelName
          )}
        </b>
      </label>
    ) : null}
    <InputText value={value || ""} update={update} readOnly={readOnly} />
  </>
);
export default TextInput;
