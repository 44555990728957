import * as R from "ramda";
import {
  actionTypes as ProductionHandler,
  Selectors as ProductionSelectors,
} from "../../productionData/production";
import {
  actionTypes as SettlementHandler,
  Selectors as SettlementSelectors,
} from "../../productionData/settlement";
import {
  actionTypes as SpotMarketHandler,
  Selectors as SpotMarketSelectors,
} from "../../markets/spotMarket";
import {
  actionTypes as ForwardMarketHandler,
  Selectors as ForwardMarketSelectors,
} from "../../markets/forwardMarket";
import * as MGP from "../../MGP";
import * as Disponibilitia from "../../Disponibilitia";
import * as Combustibili from "../../Combustibili";
import * as Esiti from "../../Esiti";
import {
  actionTypes as ProcessiBiddingHandler,
  Selectors as ProcessiBiddingSelectors,
} from "../../management/processBidding";
import {
  actionTypes as CoefficientiUPHandler,
  Selectors as CoefficientiUPSelectors,
} from "../../management/coefficientiUP";
import {
  actionTypes as ForecastDealHandler,
  Selectors as ForecastDealSelectors,
} from "../../management/forecastDeal";
import {
  actionTypes as ActualDealHandler,
  Selectors as ActualDealSelectors,
} from "../../management/actualDeal";
import { actionTypes as UpProviderHandler } from "../../configs/upProvider";
import { actionTypes as PviProviderHandler } from "../../configs/pviProvider";
import { actionTypes as TensioneHandler } from "../../configs/tensione";
import { actionTypes as UpbackupHandler } from "../../configs/upbackup";
import { actionTypes as SpreadMGPHandler } from "../../configs/spreadmgp";
import { actionTypes as InternalLegsHandler } from "../../configs/internalLegs";
import { actionTypes as FuelHandler } from "../../configs/fuel";
import {
  actionTypes as AuditHandler,
  Selectors as AuditSelectors,
} from "../../audits/audit";
import {
  actionTypes as ExecutedOperationsHandler,
  Selectors as ExecutedOperationsSelectors,
} from "../../audits/executedOperations";

//Name : {location}-{Event}_{Event}
//Name : {location}_{location}-{Event}_{Event}
export const eventTracker = ({ action, state }: any) => {
  switch (action.type) {
    case ProductionHandler.SELECTED_RAGIONE_SOCIALE:
    case ProductionHandler.SELECTED_PERIOD:
    case ProductionHandler.SELECTED_PVI: {
      const info = ProductionSelectors.all(state);
      return {
        name: "DatiProduzione_Produzione-Data_Request",
        properties: {
          ...info.filters,
        },
      };
    }
    case ProductionHandler.EXPORT_DATA_DOWNLOADED:
      return {
        name: "DatiProduzione_Produzione-Export_Excel_Data",
        properties: {},
      };
    case SettlementHandler.SELECTED_RAGIONE_SOCIALE:
    case SettlementHandler.SELECTED_PERIOD:
    case SettlementHandler.SELECTED_PVI: {
      const info = SettlementSelectors.all(state);
      return {
        name: "DatiProduzione_Settlement-Data_Request",
        properties: {
          ...info.filters,
        },
      };
    }
    case SettlementHandler.EXPORT_DATA_DOWNLOADED:
      return {
        name: "DatiProduzione_Settlement-Export_Excel_Data",
        properties: {},
      };
    case SpotMarketHandler.FIRST_LOAD:
    case SpotMarketHandler.SET_MARKET_ZONES:
    case SpotMarketHandler.SET_PERIOD: {
      const info = SpotMarketSelectors.all(state);
      return {
        name: "AccessoAlMercato_MercatiSpot-Data_Request",
        properties: {
          selectedMarket: info.selectedMarket,
          selectedMarketZones: info.selectedMarketZones,
          period: info.period,
        },
      };
    }
    case SpotMarketHandler.EXPORT_DATA_DOWNLOADED:
      return {
        name: "AccessoAlMercato_MercatiSpot-Export_Excel_Data",
        properties: {},
      };
    case ForwardMarketHandler.SET_COMMODITY: {
      const info = ForwardMarketSelectors.all(state);
      return {
        name: "AccessoAlMercato_MercatiForward-Data_Request",
        properties: {
          commodity: info.commodity,
        },
      };
    }
    case ForwardMarketHandler.SELECT_PRODUCTS: {
      const info = ForwardMarketSelectors.all(state);
      return {
        name: "AccessoAlMercato_MercatiForward-Set_Products",
        properties: {
          products: info.products,
        },
      };
    }
    case MGP.refreshRagioneSociale.type:
      return {
        name: "MarketBid_MGP_MI-Data_Request",
        properties: {},
      };
    case MGP.exportExcel.type: {
      const info = MGP.Selectors.all(state);
      return {
        name: "MarketBid_MGP_MI-Export_Excel_Data",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          selectedMercati: R.path(["value"])(info.selectedMercati),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case "rtm/MGP/UPLOAD_EXCEL": {
      return {
        name: "MarketBid_MGP_MI-Upload_Excel_Data",
        properties: {},
      };
    }
    case MGP.modifyOnline.type: {
      const info = MGP.Selectors.all(state);
      return {
        name: "MarketBid_MGP_MI-Modify_Online",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          selectedMercati: R.path(["value"])(info.selectedMercati),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case "rtm/MGP/UPLOAD_MODIFY_ONLINE":
      return {
        name: "MarketBid_MGP_MI-Upload_Modify_Online",
        properties: {},
      };
    case Disponibilitia.refreshRagioneSociale.type:
      return {
        name: "MarketBid_Disponibilita-Data_Request",
        properties: {},
      };
    case Disponibilitia.exportExcel: {
      const info = Disponibilitia.Selectors.all(state);
      return {
        name: "MarketBid_Disponibilita-Export_Excel_Data",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case "rtm/DISPONIBILITIA/UPLOAD_EXCEL":
      return {
        name: "MarketBid_Disponibilita-Upload_Excel_Data",
        properties: {},
      };
    case Disponibilitia.modifyOnline.type: {
      const info = Disponibilitia.Selectors.all(state);
      return {
        name: "MarketBid_Disponibilita-Modify_Online",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case "rtm/DISPONIBILITIA/UPLOAD_MODIFY_ONLINE":
      return {
        name: "MarketBid_Disponibilita-Upload_Modify_Online",
        properties: {},
      };
    case Combustibili.refreshRagioneSociale.type:
      return {
        name: "MarketBid_Combustibili-Data_Request",
        properties: {},
      };
    case Combustibili.exportExcel.type: {
      const info = Disponibilitia.Selectors.all(state);
      return {
        name: "MarketBid_Combustibili-Export_Excel_Data",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case "rtm/Combustibili/UPLOAD_EXCEL":
      return {
        name: "MarketBid_Combustibili-Upload_Excel_Data",
        properties: {},
      };
    case Combustibili.modifyOnline.type: {
      const info = Disponibilitia.Selectors.all(state);
      return {
        name: "MarketBid_Combustibili-Modify_Online",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case "rtm/Combustibili/UPLOAD_MODIFY_ONLINE":
      return {
        name: "MarketBid_Combustibili-Upload_Modify_Online",
        properties: {},
      };
    case Esiti.refreshRagioneSociale.type:
      return {
        name: "MarketBid_Esiti-Data_Request",
        properties: {},
      };
    case Esiti.exportExcel.type: {
      const info = Disponibilitia.Selectors.all(state);
      return {
        name: "MarketBid_Esiti-Export_Excel_Data",
        properties: {
          selectedRagioneSociale: R.path(["value"])(
            info.selectedRagioneSociale
          ),
          selectedPVIs: R.path(["value"])(info.selectedPVIs),
          dateSelect: R.path(["value"])(info.dateSelect),
        },
      };
    }
    case ProcessiBiddingHandler.PROCESS_BIDDING_LIST_REQUEST:
    case ProcessiBiddingHandler.PROCESS_BIDDING_LIST_FILTERS: {
      const info = ProcessiBiddingSelectors.all(state);
      return {
        name: "Gestione_ProcessiBidding_Riepilogo-Data_Request",
        properties: {
          ...info.processBiddingList.filters,
        },
      };
    }
    case ProcessiBiddingHandler.CREATE_NEW_PROCESS: {
      const info = ProcessiBiddingSelectors.all(state);
      return {
        name: "Gestione_ProcessiBidding_Riepilogo-Create_New_Process",
        properties: {
          ...info.processModal.info,
        },
      };
    }
    case ProcessiBiddingHandler.GET_BID_INFO_DATA: {
      return {
        name: "Gestione_ProcessiBidding_Offerta-Request_Offer",
        properties: {
          id: action.id,
        },
      };
    }
    case ProcessiBiddingHandler.COMPUTED_BIDDING_DOWNLOADED: {
      const info = ProcessiBiddingSelectors.all(state);
      return {
        name: "Gestione_ProcessiBidding_Offerta-Export_Computed_Excel_Data",
        properties: {
          id: info.bidInfoData.id,
        },
      };
    }
    case ProcessiBiddingHandler.EXPORT_IPEX: {
      const info = ProcessiBiddingSelectors.all(state);
      return {
        name: "Gestione_ProcessiBidding_Offerta-Export_IPEX_Excel_Data",
        properties: {
          id: info.bidInfoData.id,
        },
      };
    }
    case CoefficientiUPHandler.EXPORT_DATA: {
      const info = CoefficientiUPSelectors.all(state);
      return {
        name: "Gestione_CoefficientiUP-Export_Excel_Data",
        properties: {
          ...info.filters,
        },
      };
    }
    case CoefficientiUPHandler.UPLOAD_DATA: {
      const info = CoefficientiUPSelectors.all(state);
      return {
        name: "Gestione_CoefficientiUP-Upload_Excel_Data",
        properties: {
          ...info.filters,
        },
      };
    }
    case ForecastDealHandler.FORECAST_DEAL_REQUEST:
    case ForecastDealHandler.FORECAST_DEAL_FILTERS_RAGIONE_SOCIALE:
    case ForecastDealHandler.FORECAST_DEAL_FILTERS: {
      const info = ForecastDealSelectors.all(state);
      return {
        name: "Gestione_ProcessoInserimentoContratti_ForecastDeal-Request_Data",
        properties: {
          ...info.forecastDeal.filters,
        },
      };
    }
    case ForecastDealHandler.UPLOAD_CONTRACT:
      return {
        name:
          "Gestione_ProcessoInserimentoContratti_ForecastDeal-Upload_Excel_Data",
        properties: {},
      };
    case ForecastDealHandler.UPLOAD_BULK_CONTRACT:
      return {
        name:
          "Gestione_ProcessoInserimentoContratti_ForecastDeal-Upload_Bulk_Excel_Data",
        properties: {},
      };
    case ForecastDealHandler.DEAL_CAPTURE_RUN_START:
      return {
        name:
          "Gestione_ProcessoInserimentoContratti_ForecastDeal-Run_Deal_Capture",
        properties: {
          id: action.info.contractId,
        },
      };
    case ForecastDealHandler.CONTRACT_RUN_START:
      return {
        name: "Gestione_ProcessoInserimentoContratti_ForecastDeal-Run_Contract",
        properties: {
          id: action.info.contractId,
        },
      };
    case ActualDealHandler.ACTUAL_DEAL_REQUEST:
    case ActualDealHandler.ACTUAL_DEAL_FILTERS:
    case ActualDealHandler.ACTUAL_DEAL_FILTERS_RAGIONE_SOCIALE: {
      const info = ActualDealSelectors.all(state);
      return {
        name: "Gestione_ProcessoInserimentoContratti_ActualDeal-Request_Data",
        properties: {
          ...info.actualDeal.filters,
        },
      };
    }
    case ActualDealHandler.DEAL_CAPTURE_RUN_START: {
      const info = ActualDealSelectors.all(state);
      return {
        name:
          "Gestione_ProcessoInserimentoContratti_ActualDeal-Run_Deal_Capture",
        properties: {
          id: info.dealCapture.info.contractId,
        },
      };
    }

    case UpProviderHandler.FETCHING:
      return {
        name: "Configurazioni_UPProvider-Request_Data",
        properties: {},
      };
    case UpProviderHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_UPProvider-Apply_Modify_Changes",
        properties: {},
      };
    case UpProviderHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_UPProvider-Export_Excel_Data",
        properties: {},
      };
    case UpProviderHandler.UPLOAD_DATA:
    case PviProviderHandler.FETCHING:
      return {
        name: "Configurazioni_PviProvider-Request_Data",
        properties: {},
      };
    case PviProviderHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_PviProvider-Apply_Modify_Changes",
        properties: {},
      };
    case PviProviderHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_PviProvider-Export_Excel_Data",
        properties: {},
      };
    case PviProviderHandler.UPLOAD_DATA:
      return {
        name: "Configurazioni_PviProvider-Upload_Excel_Data",
        properties: {},
      };
    case TensioneHandler.FETCHING:
      return {
        name: "Configurazioni_Tensione-Request_Data",
        properties: {},
      };
    case TensioneHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_Tensione-Apply_Modify_Changes",
        properties: {},
      };
    case TensioneHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_Tensione-Export_Excel_Data",
        properties: {},
      };
    case TensioneHandler.UPLOAD_DATA:
      return {
        name: "Configurazioni_Tensione-Upload_Excel_Data",
        properties: {},
      };
    case UpbackupHandler.FETCHING:
      return {
        name: "Configurazioni_UpBackup-Request_Data",
        properties: {},
      };
    case UpbackupHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_UpBackup-Apply_Modify_Changes",
        properties: {},
      };
    case UpbackupHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_UpBackup-Export_Excel_Data",
        properties: {},
      };
    case UpbackupHandler.UPLOAD_DATA:
      return {
        name: "Configurazioni_UpBackup-Upload_Excel_Data",
        properties: {},
      };
    case SpreadMGPHandler.FETCHING:
      return {
        name: "Configurazioni_SpreadMGP-Request_Data",
        properties: {},
      };
    case SpreadMGPHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_SpreadMGP-Apply_Modify_Changes",
        properties: {},
      };
    case SpreadMGPHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_SpreadMGP-Export_Excel_Data",
        properties: {},
      };
    case SpreadMGPHandler.UPLOAD_DATA:
      return {
        name: "Configurazioni_SpreadMGP-Upload_Excel_Data",
        properties: {},
      };
    case InternalLegsHandler.FETCHING:
      return {
        name: "Configurazioni_InternalLegs-Request_Data",
        properties: {},
      };
    case InternalLegsHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_InternalLegs-Apply_Modify_Changes",
        properties: {},
      };
    case InternalLegsHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_InternalLegs-Export_Excel_Data",
        properties: {},
      };
    case InternalLegsHandler.UPLOAD_DATA:
      return {
        name: "Configurazioni_InternalLegs-Upload_Excel_Data",
        properties: {},
      };
    case FuelHandler.FETCHING:
      return {
        name: "Configurazioni_Fuel-Request_Data",
        properties: {},
      };
    case FuelHandler.APPLY_CHANGES:
      return {
        name: "Configurazioni_Fuel-Apply_Modify_Changes",
        properties: {},
      };
    case FuelHandler.EXPORT_DATA:
      return {
        name: "Configurazioni_Fuel-Export_Excel_Data",
        properties: {},
      };
    case FuelHandler.UPLOAD_DATA:
      return {
        name: "Configurazioni_Fuel-Upload_Excel_Data",
        properties: {},
      };
    case AuditHandler.RECIEVED_AUDIT_LIST_DATA:
    case AuditHandler.SET_FILTER_AUDIT_LIST_DATA: {
      const info = AuditSelectors.all(state);
      return {
        name: "AttivitaEseguite_AuditTrail-Data_Request",
        properties: {
          ...info.auditListTable.filters,
        },
      };
    }
    case AuditHandler.RECIEVED_SELECTED_AUDIT_ID_DATA: {
      const info = AuditSelectors.all(state);
      return {
        name: "AttivitaEseguite_AuditTrail-Audit_ID_Data_Request",
        properties: {
          id: info.selectedAuditID,
        },
      };
    }
    case AuditHandler.EXPORT_AUDIT_ID_DIFF_CREATED: {
      const info = AuditSelectors.all(state);
      return {
        name: "AttivitaEseguite_AuditTrail-Export_Excel_Data",
        properties: {
          id: info.selectedAuditID,
        },
      };
    }
    case ExecutedOperationsHandler.FETCHING_OPERATIONS_DATA:
    case ExecutedOperationsHandler.SET_FILTER_OPERATIONS_DATA: {
      const info = ExecutedOperationsSelectors.all(state);
      return {
        name: `AttivitaEseguite_OperazioniEseguite_${info.selectedOperation}-Data_Request`,
        properties: {
          id: info.operationsTable.filters,
        },
      };
    }
    case ExecutedOperationsHandler.DOWNLOAD_OPERATION: {
      const info = ExecutedOperationsSelectors.all(state);
      return {
        name: `AttivitaEseguite_OperazioniEseguite_${info.selectedOperation}-Export_Data`,
        properties: {
          id: action.id,
        },
      };
    }
    default:
      return false;
  }
};
