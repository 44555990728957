import * as R from "ramda";

const font = {
  font: {
    bold: true
  }
};

const dateHeaderStyle = {
  ...font,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#00b0f0",
    fgColor: "#00b0f0"
  }
};

const mainHeaderStyle = {
  ...font,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#00b050",
    fgColor: "#00b050"
  }
};

const coefficienteStyle = {
  ...font,
  fill: {
    type: "pattern",
    patternType: "solid",
    bgColor: "#fbd4b4",
    fgColor: "#fbd4b4"
  }
};

type CreateHeadersType = { sheet: any; data: any };
export const createHeaders = ({ sheet, data }: CreateHeadersType) => {
  sheet.row(1).freeze();
  sheet.row(2).freeze();
  sheet.row(3).freeze();
  sheet.row(3).filter();

  sheet
    .cell(1, 1)
    .string("DataDa")
    .style(dateHeaderStyle);
  sheet.cell(1, 2).string(R.pathOr("", ["from"], data));
  sheet
    .cell(2, 1)
    .string("DataA")
    .style(dateHeaderStyle);
  sheet.cell(2, 2).string(R.pathOr("", ["to"], data));
  sheet
    .cell(3, 1)
    .string("UP")
    .style(mainHeaderStyle);
  sheet
    .cell(3, 2)
    .string("Zona")
    .style(mainHeaderStyle);
  sheet
    .cell(3, 3)
    .string("Date")
    .style(mainHeaderStyle);
  sheet
    .cell(3, 4)
    .string("Ora")
    .style(mainHeaderStyle);
  sheet
    .cell(3, 5)
    .string("CoefficienteA")
    .style(coefficienteStyle);
  sheet
    .cell(3, 6)
    .string("CoefficienteB")
    .style(coefficienteStyle);

  return sheet;
};
