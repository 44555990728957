import * as R from "ramda";

const actuaLDealCaptureTypesStatus = (key: any, row: any) =>
  (row.check.status === "ToBeSent" ||
    row.check.status === "ContractNotAligned") &&
  !row.process.isRunning;

export const actuaLDealCaptureTypesStatusArr = (list: any) => {
  const keyList = R.toPairs(list);
  return keyList
    .map(([k, v]: any) => (actuaLDealCaptureTypesStatus(k, v) ? k : null))
    .filter((x) => x);
};
