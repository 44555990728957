import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import "./styles/main.scss";
import "./styles/avenir-font-file.css";
import main from "./styles/main.module.scss";
import Header from "./header/view";
import Footer from "./footer/view";
import SpotMarket from "./markets/spotMarket";
import ForwardMarket from "./markets/forwardMarket";
import Esiti from "./esiti/Esiti";
import MarketBid from "./marketBid/view";
import ErrorModal from "./errorModal";
import UpProviderComponent from "./config/upProvider";
import PVIProviderComponent from "./config/pviProvider";
import TensioneComponent from "./config/tensione";
import UpBackupComponent from "./config/upbackup";
import SpreadMGPComponent from "./config/spreadmgp";
import FuelComponent from "./config/fuel";
import InternalLegComponent from "./config/internalLegs";
import AuditTrailComponent from "./audits/audit";
import ExecutedOperations from "./audits/executedOperations";
import ProcessBiddingComponent from "./management/processBidding";
import CoefficientiUP from "./management/coefficientiUP";
import ProductionComponent from "./productionData/production";
import SettlementComponent from "./productionData/settlement";
import ProcessoInserimentoContratti from "./management/processoInserimentoContratti";
import CustomPrompt from "./components/customPrompt";
import Loader from "./components/loader";
import Protected from "./protectedRoute";
import PermissionsAwareRedirect from "./protectedRedirect";
import {
  Selectors as EnumsSelectors,
  requestEnumsAction
} from "./redux/modules/enums";
import permissions from "./permissions";
import { User } from './redux/modules/authentication'

const AccessoAlMercatoComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Route path={`${match.url}/MercatiSpot`} component={SpotMarket} />
      <Route path={`${match.url}/MercatiForward`} component={ForwardMarket} />
      <Redirect to={`${match.url}/MercatiSpot`} />
    </Switch>
  );
};

const EsitiComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Route path={`${match.url}`} component={Esiti} />
    </Switch>
  );
};
const MarketBidComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Protected
        permission={permissions.mgpMiPermissions}
        path={`${match.url}/MGP&MI`}
        component={MarketBid}
      />
      <Protected
        permission={permissions.disponibilitaPermissions}
        path={`${match.url}/Disponibilita`}
        component={MarketBid}
      />
      <Protected
        permission={permissions.datiCombustibiliPermissions}
        path={`${match.url}/Combustibili`}
        component={MarketBid}
      />
      <PermissionsAwareRedirect
        routes={{
          [`${match.url}/MGP&MI`]: permissions.mgpMiPermissions,
          [`${match.url}/Disponibilita`]: permissions.disponibilitaPermissions,
          [`${match.url}/Combustibili`]: permissions.datiCombustibiliPermissions
        }}
      />
    </Switch>
  );
};

const ProcessoInserimentoContrattiComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Protected
        permission={[permissions.dealCaptureRead]}
        path={`${match.url}/ForecastDeal`}
        component={ProcessoInserimentoContratti}
      />
      <Protected
        permission={[permissions.dealCaptureRead]}
        path={`${match.url}/ActualDeal`}
        component={ProcessoInserimentoContratti}
      />

      <PermissionsAwareRedirect
        routes={{
          [`${match.url}/ForecastDeal`]: [permissions.dealCaptureRead],
          [`${match.url}/ActualDeal`]: [permissions.dealCaptureRead]
        }}
      />
    </Switch>
  );
};

const GestioneComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Protected
        permission={permissions.processiBiddingPermissions}
        path={`${match.url}/ProcessiBidding`}
        component={ProcessBiddingComponent}
      />
      <Protected
        permission={permissions.coefficientiPermissions}
        path={`${match.url}/CoefficientiUP`}
        component={CoefficientiUP}
      />
      <Protected
        permission={[permissions.dealCaptureRead]}
        path={`${match.url}/ProcessoInserimentoContratti`}
        component={ProcessoInserimentoContrattiComponent}
      />

      <PermissionsAwareRedirect
        routes={{
          [`${match.url}/ProcessiBidding`]: permissions.processiBiddingPermissions,
          [`${match.url}/CoefficientiUP`]: permissions.coefficientiPermissions,
          [`${match.url}/ProcessoInserimentoContratti`]: [
            permissions.dealCaptureRead
          ]
        }}
      />
    </Switch>
  );
};

const ConfigurazioniComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/UPProvider`}
        component={UpProviderComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/PVIProvider`}
        component={PVIProviderComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/Tensione`}
        component={TensioneComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/UpBackup`}
        component={UpBackupComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/SpreadMGP`}
        component={SpreadMGPComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/InternalLegs`}
        component={InternalLegComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`${match.url}/Fuel`}
        component={FuelComponent}
      />

      <PermissionsAwareRedirect
        routes={{
          [`${match.url}/UPProvider`]: permissions.configurationPermissions,
          [`${match.url}/PVIProvider`]: permissions.configurationPermissions,
          [`${match.url}/Tensione`]: permissions.configurationPermissions,
          [`${match.url}/UpBackup`]: permissions.configurationPermissions,
          [`${match.url}/SpreadMGP`]: permissions.configurationPermissions,
          [`${match.url}/InternalLegs`]: permissions.configurationPermissions,
          [`${match.url}/Fuel`]: permissions.configurationPermissions,
        }}
      />
    </Switch>
  );
};

const ExecutedOperationsComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Protected
        permission={permissions.disponibilitaWritePermissions}
        path={`${match.url}/MGP&MI`}
        component={ExecutedOperations}
      />
      <Protected
        permission={permissions.mgpMiWritePermissions}
        path={`${match.url}/Disponibilita`}
        component={ExecutedOperations}
      />
      <Protected
        permission={permissions.datiCombustibiliWritePermissions}
        path={`${match.url}/Combustibili`}
        component={ExecutedOperations}
      />

      <PermissionsAwareRedirect
        routes={{
          [`${match.url}/MGP&MI`]: permissions.disponibilitaWritePermissions,
          [`${match.url}/Disponibilita`]: permissions.mgpMiWritePermissions,
          [`${match.url}/Combustibili`]: permissions.datiCombustibiliWritePermissions
        }}
      />
    </Switch>
  );
};

const AttivitaEseguiteComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Protected
        permission={[permissions.auditRead]}
        path={`${match.url}/AuditTrail`}
        component={AuditTrailComponent}
      />
      <Protected
        permission={permissions.operazioniEseguite}
        path={`${match.url}/OperazioniEseguite`}
        component={ExecutedOperationsComponent}
      />

      <PermissionsAwareRedirect
        routes={{
          [`${match.url}/AuditTrail`]: [permissions.auditRead],
          [`${match.url}/OperazioniEseguite`]: permissions.operazioniEseguite
        }}
      />
    </Switch>
  );
};

const DatiProduzioneComponent = ({ match }: { match: any }) => {
  return (
    <Switch>
      <Route path={`${match.url}/Produzione`} component={ProductionComponent} />
      <Route path={`${match.url}/Settlement`} component={SettlementComponent} />
      <Redirect to={`${match.url}/Produzione`} />
    </Switch>
  );
};

const Component = () => {
  return (
    <Switch>
      <Route path={`/DatiProduzione`} component={DatiProduzioneComponent} />
      <Route path={`/AccessoAlMercato`} component={AccessoAlMercatoComponent} />
      <Protected
        permission={permissions.marketBidPermissions}
        path={`/MarketBid`}
        component={MarketBidComponent}
      />
      <Protected
        permission={permissions.esitiPermissions}
        path={`/Esiti`}
        component={EsitiComponent}
      />
      <Protected
        permission={permissions.gestionePermissions}
        path={`/Gestione`}
        component={GestioneComponent}
      />
      <Protected
        permission={permissions.configurationPermissions}
        path={`/Configurazioni`}
        component={ConfigurazioniComponent}
      />
      <Protected
        permission={permissions.attivitaEseguite}
        path={`/AttivitaEseguite`}
        component={AttivitaEseguiteComponent}
      />

      <Redirect to="/DatiProduzione" />
    </Switch>
  );
};

const Main = ({
  profile,
  enumFetching,
  requestEnumsAction
}: {
  profile: User;
  enumFetching: any;
  requestEnumsAction: () => void;
}) => {
  useEffect(() => {
    requestEnumsAction();
  }, [requestEnumsAction]);
  return (
    <>
      <CustomPrompt />
      <div className={main.appBackground}>
        <Header profile={profile} />
        <div className={main.main}>
          <Component />
        </div>
        <Footer />
      </div>
      <ErrorModal />
      <Loader load={enumFetching} />
    </>
  );
};

export default R.compose(
  connect(
    (s: any) => ({ enumFetching: EnumsSelectors.allEnumsFetching(s) }),
    {
      requestEnumsAction
    }
  )
)(Main);
