import * as R from "ramda";
import {
  getTime,
  format,
  eachDayOfInterval,
  subDays,
  addMonths,
  addQuarters,
  addYears,
} from "date-fns";
import { getMarketName } from "../redux/modules/markets/spotMarket";
import { ProductType } from "../redux/modules/markets/forwardMarket";

const getDailyPrice = R.pipe<any, any, any>(
  R.map(R.prop("price")),
  (x) => x.reduce((a: any, b: any) => a + b, 0) / x.length
);
const getDailyInfo = R.pipe<any, any, any, any, any>(
  R.map((row: any) =>
    R.assoc("date", format(new Date(row.dateTimeOffset), "yyyy-MM-dd"), row)
  ),
  R.groupBy(R.prop<any, any>("date")),
  R.toPairs,
  R.map(([date, val]) => ({
    x: getTime(new Date(date)),
    y: getDailyPrice(val),
  }))
);

const getHourlyInfo = R.map((x: any) => {
  return {
    x: getTime(new Date(x.dateTimeOffset)),
    y: x.price,
  };
});
export const getSeries = ({
  data,
  granularity,
}: {
  data: any;
  granularity: any;
}) => {
  return R.ifElse(
    (x) => R.isEmpty(x) || R.isNil(x),
    () => [],
    R.pipe<any, any, any, any, any>(
      R.map((row: any) => R.assoc("date", row.dateTimeOffset, row)),
      R.groupBy(R.prop<any, any>("tipoCurvaPrezzi")),
      R.toPairs,
      R.map(([market, val]) => {
        return {
          type: "line",
          marker: {
            enabled: false,
            symbol: "circle",
          },
          name: getMarketName(market),
          data: R.equals(granularity, "daily")
            ? getDailyInfo(val)
            : getHourlyInfo(val),
        };
      })
    )
  )(data);
};

export const getCurrencyUnit = (currencyUnit: string): any => {
  switch (currencyUnit) {
    case "EUR_MWh":
      return "€/MWh";
    default:
      return "";
  }
};

export const getMarketZones = (market: string | null, enums: any): any => {
  switch (market) {
    case "Electricity":
      return R.filter(
        (x) =>
          R.contains(x, [
            "Italy",
            "ItalyCNOR",
            "ItalyCSUD",
            "ItalyNord",
            "ItalySard",
            "ItalySici",
            "ItalySud",
            "ItalyCALA",
          ]),
        enums
      );
    case "Gas":
      return R.filter((x) => R.contains(x, ["PSV"]), enums);
    case "MSD_Med":
      return R.filter(
        (x) => R.contains(x, ["NORDAcq", "NORDVen", "SUDAcq", "SUDVen"]),
        enums
      );
    default:
      return [];
  }
};

export const lastNoOfDaysList = (number: number) =>
  R.pipe<Date, Date, Date[], any>(
    (x) => subDays(x, number),
    (start) =>
      eachDayOfInterval({
        start,
        end: new Date(),
      }),
    R.map((x) => format(x, "yyyy-MM-dd"))
  )(new Date());

const getChartData = ({
  date,
  product,
  data,
}: {
  date: any;
  product: any;
  data: any;
}) => {
  const val = R.pipe<any, any, any>(
    R.filter(
      (row: any) =>
        R.equals(date, row.date) && R.equals(product, row.relativeProduct)
    ),
    R.pathOr(null, [0, "price"])
  )(data);
  const time = getTime(new Date(date));
  return [time, val];
};

export const getColor = (name: any) => {
  switch (name) {
    case "M+1":
      return "#e10019";
    case "M+2":
      return "#761613";
    case "M+3":
      return "#cfa2a7";
    case "Q+1":
      return "#ed6675";
    case "Q+2":
      return "#9e9294";
    case "Y+1":
    default:
      return "#000000";
  }
};

export const chartData = ({
  data,
  products,
}: {
  data: any;
  products: ProductType[];
}) => {
  const dates = lastNoOfDaysList(6);
  return products.map((product) => ({
    name: getProductName(product),
    color: getColor(product),
    data: dates.map((date: any) => getChartData({ date, product, data })),
  }));
};

type ProductNameFuncType = {
  func: any;
  add: number;
  fmt: string;
  append?: string;
};
const productNameFunc = ({
  func,
  add,
  fmt,
  append = "",
}: ProductNameFuncType) => {
  const headerVal = R.pipe<Date, Date, string>(
    (x) => func(x, add),
    (x) => format(x, fmt)
  )(new Date());

  return `${append}${headerVal}`;
};

export const getProductName = (name: ProductType) => {
  switch (name) {
    case "M+1":
      return productNameFunc({ func: addMonths, add: 1, fmt: "MMM-yy" });
    case "M+2":
      return productNameFunc({ func: addMonths, add: 2, fmt: "MMM-yy" });
    case "M+3":
      return productNameFunc({ func: addMonths, add: 3, fmt: "MMM-yy" });
    case "Q+1":
      return productNameFunc({
        func: addQuarters,
        add: 1,
        fmt: "Qyy",
        append: "Q",
      });
    case "Q+2":
      return productNameFunc({
        func: addQuarters,
        add: 2,
        fmt: "Qyy",
        append: "Q",
      });
    case "Y+1":
      return productNameFunc({ func: addYears, add: 1, fmt: "yyyy" });
  }
};
