import React from "react";
import * as R from "ramda";
import { NavLink } from "react-router-dom";
import main from "../styles/header.module.scss";
import Protected from "../protectedComponent";
import permissions from "../permissions";

export const Header = ({ locationPath }: { locationPath: string }) => {
  return (
    <div className={main.menu}>
      <MenuHeaderItem title="Dati Produzione" locationPath={locationPath} />
      <MenuHeaderItem title="Accesso Al Mercato" locationPath={locationPath} />
      <Protected
        permission={permissions.marketBidPermissions}
        component={() => (
          <MenuHeaderItem title="Market Bid" locationPath={locationPath} />
        )}
      />
      <Protected
        permission={permissions.esitiPermissions}
        component={() => (
          <MenuMainHeaderItem title="Esiti" locationPath={locationPath} />
        )}
      />
      <Protected
        permission={permissions.gestionePermissions}
        component={() => (
          <MenuHeaderItem title="Gestione" locationPath={locationPath} />
        )}
      />
      <Protected
        permission={permissions.configurationPermissions}
        component={() => (
          <MenuHeaderItem title="Configurazioni" locationPath={locationPath} />
        )}
      />
      <Protected
        permission={permissions.attivitaEseguite}
        component={() => (
          <MenuHeaderItem
            title="Attivita Eseguite"
            locationPath={locationPath}
          />
        )}
      />
    </div>
  );
};

const MenuHeaderItem = ({
  title,
  locationPath
}: {
  title: string;
  locationPath: string;
}) => {
  const path = R.replace(/\s/g, "", title);
  return (
    <NavLink
      activeClassName={main.menuItemActive}
      className={main.menuItem}
      to={`/${path}/${R.replace(/\s/g, "", title)}`}
      isActive={(match: any, location: any) => R.equals(locationPath, path)}
    >
      {title}
    </NavLink>
  );
};
const MenuMainHeaderItem = ({
  title,
  locationPath
}: {
  title: string;
  locationPath: string;
}) => {
  const path = R.replace(/\s/g, "", title);
  return (
    <NavLink
      activeClassName={main.menuItemActive}
      className={main.menuItem}
      to={`/${R.replace(/\s/g, "", title)}`}
      isActive={(match: any, location: any) => R.equals(locationPath, path)}
    >
      {title}
    </NavLink>
  );
};

export default Header;
