import React from "react";
import * as R from "ramda";
import { format } from "date-fns";
import DateRange from "../../components/dateRange";

export type TextTableHeaderType = {
  title: string;
  propNameTo: string;
  propNameFrom: string;
  filters?: any;
  style?: any;
  setFilter?: (a: any) => void;
  colSpan?: number;
};

export const TextTableHeader = ({
  title,
  propNameTo,
  propNameFrom,
  filters = null,
  style = {},
  setFilter = () => true,
  colSpan = 1
}: TextTableHeaderType) => {
  const valTo = R.prop(propNameTo, filters);
  const valFrom = R.prop(propNameFrom, filters);

  const toDateTime = R.equals(null, valTo) ? null : new Date(valTo);
  const fromDateTime = R.equals(null, valFrom) ? null : new Date(valFrom);

  return (
    <th className="tableFilterHeader tableFilterDateRange" colSpan={colSpan}>
      <div style={style}>
        <span>{title}</span>
        {R.has(propNameTo, filters) && R.has(propNameFrom, filters) ? (
          <DateRange
            startDate={fromDateTime}
            onStartDateChange={(e: Date) => {
              return setFilter({
                ...filters,
                [propNameFrom]: R.equals(null, e)
                  ? null
                  : format(e, "yyyy-MM-dd")
              });
            }}
            endDate={toDateTime}
            onEndDateChange={(e: Date) =>
              setFilter({
                ...filters,
                [propNameTo]: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
              })
            }
          />
        ) : null}
      </div>
    </th>
  );
};

export default TextTableHeader;
