import React from "react";
import * as R from "ramda";
import SingleSelect from "../singleSelectMain";
import { option } from "../singleSelectMain/helper";

type SelectFilterTypes = {
  propKey: string;
  filters: any;
  onChange: any;
  options: option[];
  selectClassName?: string;
  containsFilter?: boolean;
};

const SelectFilter = ({
  propKey,
  filters,
  onChange,
  options,
  selectClassName = "",
  containsFilter = false
}: SelectFilterTypes) => {
  const val = R.prop(propKey, filters);

  const res = R.isNil(val)
    ? []
    : [
        {
          title: val,
          value: val
        }
      ];

  return (
    <SingleSelect
      value={res}
      clearSelection
      update={(x: any) => {
        return onChange({
          ...filters,
          [propKey]: R.isEmpty(x) ? null : x[0].value
        });
      }}
      options={options}
      className={selectClassName}
      containsFilter={containsFilter}
    />
  );
};

export default SelectFilter;
