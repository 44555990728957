import React from "react";
import * as R from "ramda";
import { format } from "date-fns";
import DateRange from "../../components/dateRange";

type TextInputType = {
  value: { from: any; to: any };
  update: (a: any) => void;
  labelName?: string | null;
  readOnly?: boolean;
  requiredField?: boolean;
};

const TextInput = ({
  update,
  value,
  labelName = null,
  requiredField = false
}: TextInputType) => {
  const fromDateTime = R.equals(null, value.from) ? null : new Date(value.from);
  const toDateTime = R.equals(null, value.to) ? null : new Date(value.to);
  return (
    <>
      {labelName ? (
        <label className="form-label" htmlFor="select">
          {requiredField ? (
            <span className="d-flex">
              <div>{labelName}</div>
              <div className="required">&nbsp;(Required)</div>
            </span>
          ) : (
            labelName
          )}
        </label>
      ) : null}
      <DateRange
        startDate={fromDateTime}
        onStartDateChange={(e: Date) => {
          return update({
            ...value,
            from: R.equals(null, e) ? null : format(e, "yyyy-MM-dd'T'00:00:00")
          });
        }}
        endDate={toDateTime}
        onEndDateChange={(e: Date) =>
          update({
            ...value,
            to: R.equals(null, e) ? null : format(e, "yyyy-MM-dd'T'23:59:59")
          })
        }
      />
    </>
  );
};
export default TextInput;
