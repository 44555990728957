import * as R from "ramda";
import permissions from "../permissions";

const stateToPermissions = R.pipe<any, any, any, any>(
  R.pathOr(
    [],
    ["auth", "profile", "accessToken", "scope"]
  ),
  R.split(" "),
  R.map(R.trim)
);

export const permissionCheck = ({
  state,
  permission
}: {
  state: any;
  permission: string;
}) =>
  R.pipe(
    stateToPermissions,
    R.includes(permission)
  )(state);

export default { permissionCheck };
