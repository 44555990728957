import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { format } from "date-fns";
import {
  Selectors,
  setSelectedMarketAction,
  setSelectedMarketZonesAction,
  GranularityType,
  setPeriodAction,
  setGranularityAction,
  PeriodType,
  exportDataAction,
  getMarketName,
  firstLoadAction,
  clearAllDataAction
} from "../redux/modules/markets/spotMarket";
import { Selectors as EnumsSelectors } from "../redux/modules/enums";
import RadioBtn from "../components/radioButton";
import { CheckBox } from "../components/checkBox";
import styles from "./spotMarkets.module.scss";
import "../styles/highcharts.module.scss";
import DateRange from "../components/dateRange";
import { NoData } from "../components/tableComponents/noData";
import Loader from "../components/loader";
import { getSeries, getCurrencyUnit, getMarketZones } from "./helper";
import PageOnLoad from "../components/pageOnLoad";

NoDataToDisplay(Highcharts);

type SpotMarketType = {
  selectedMarket: string | null;
  selectedMarketZones: string[] | null;
  period: PeriodType;
  granularity: GranularityType;
  marketsData: any;
  isFetching: any;
  enums: any;
  setSelectedMarketAction: (a: string) => void;
  setSelectedMarketZonesAction: (a: string[]) => void;
  setPeriodAction: (a: PeriodType) => void;
  setGranularityAction: (a: GranularityType) => void;
  exportDataAction: () => void;
  firstLoadAction: () => void;
  clearAllDataAction: () => void;
};

const SpotMarket = ({
  selectedMarket,
  selectedMarketZones,
  period,
  granularity,
  marketsData,
  isFetching,
  enums,
  setSelectedMarketAction,
  setSelectedMarketZonesAction,
  setPeriodAction,
  setGranularityAction,
  exportDataAction,
  firstLoadAction,
  clearAllDataAction
}: SpotMarketType) => {
  useEffect(() => {
    firstLoadAction();
    return () => clearAllDataAction();
  }, []);
  return (
    <>
      <PageOnLoad />
      <div className="pageContainer">
        <h1>Mercati Spot</h1>
        <div className="pageContent">
          <MarketFilters
            selectedMarket={selectedMarket}
            selectedMarketZones={selectedMarketZones}
            period={period}
            granularity={granularity}
            enums={enums}
            setSelectedMarketAction={setSelectedMarketAction}
            setSelectedMarketZonesAction={setSelectedMarketZonesAction}
            setPeriodAction={setPeriodAction}
            setGranularityAction={setGranularityAction}
          />
        </div>
        <div className="spacer"></div>
        <MarketChart
          data={marketsData}
          granularity={granularity}
          selectedMarketZones={selectedMarketZones}
        />
        <div className="spacer"></div>
        <MarketTable
          data={marketsData}
          exportDataAction={exportDataAction}
          selectedMarketZones={selectedMarketZones}
        />
        <Loader load={isFetching} />
      </div>
    </>
  );
};

export default connect(
  (s: any) => ({ ...Selectors.all(s), enums: EnumsSelectors.enums(s) }),
  {
    setSelectedMarketAction,
    setSelectedMarketZonesAction,
    setPeriodAction,
    setGranularityAction,
    exportDataAction,
    firstLoadAction,
    clearAllDataAction
  }
)(SpotMarket);

type MarketTableType = {
  data: any;
  selectedMarketZones: string[] | null;
  exportDataAction: () => void;
};

const MarketTable = ({
  data,
  selectedMarketZones,
  exportDataAction
}: MarketTableType) => {
  const tableData = R.ifElse(
    x =>
      R.isEmpty(x) ||
      R.isNil(x) ||
      R.isEmpty(selectedMarketZones) ||
      R.isNil(selectedMarketZones),
    x => [],
    R.pipe<any, any, any, any>(
      R.groupBy(R.prop<any, any>("tipoCurvaPrezzi")),
      R.toPairs,
      R.map(([market, val]) => {
        const prices = R.map(R.prop("price"), val) as any;
        return {
          market,
          min: Math.min(...prices),
          max: Math.max(...prices),
          avg: prices.reduce((a: any, b: any) => a + b, 0) / prices.length,
          currencyUnit: val[0].currencyUnit
        };
      })
    )
  )(data);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <button
          className={`btn btn-danger rounded-pill mx-2 ${
            R.isEmpty(data) || R.isNil(data) ? "disabled" : ""
          }`}
          onClick={() =>
            R.unless(R.equals(true), () => exportDataAction())(
              R.isEmpty(data) || R.isNil(data)
            )
          }
        >
          Esporta il file XLSX
        </button>
      </div>
      <div className="spacer"></div>
      <table className="table">
        <thead>
          <tr>
            <th>Mercato</th>
            <th>Min</th>
            <th>Avg</th>
            <th>Max</th>
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(tableData) || R.isNil(tableData) ? (
            <NoData cols={4} />
          ) : (
            tableData.map((row: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{getMarketName(row.market)}</td>
                  <td>{`${row.min.toFixed(2)} ${getCurrencyUnit(
                    row.currencyUnit
                  )}`}</td>
                  <td>{`${row.avg.toFixed(2)} ${getCurrencyUnit(
                    row.currencyUnit
                  )}`}</td>
                  <td>{`${row.max.toFixed(2)} ${getCurrencyUnit(
                    row.currencyUnit
                  )}`}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};

type MarketChartType = {
  data: any;
  selectedMarketZones: string[] | null;
  granularity: any;
};

const MarketChart = ({
  data,
  selectedMarketZones,
  granularity
}: MarketChartType) => {
  const opts = {
    ...options,
    series:
      R.isEmpty(selectedMarketZones) || R.isNil(selectedMarketZones)
        ? []
        : getSeries({ data, granularity }),
    tooltip: {
      ...options.tooltip,
      xDateFormat: R.equals(granularity, "daily")
        ? "%b %e, %Y"
        : "%b %e, %Y %H:%M",
      valueSuffix: ` ${getCurrencyUnit(R.pathOr(
        null,
        [0, "currencyUnit"],
        data
      ) as any)}`
    }
  } as any;
  return (
    <div className="chart">
      <div className="alignRight">
        <div
          title="Seleziona la parte del grafico che ti
          interessa &#10;con il mouse per ingrandirla 
          e visualizzarne &#10;i dettagli."
          className="infoHover"
        >
          Zoom
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={opts} />
    </div>
  );
};

type MarketFiltersType = {
  selectedMarket: string | null;
  selectedMarketZones: string[] | null;
  period: PeriodType;
  granularity: GranularityType;
  enums: any;
  setSelectedMarketAction: (a: string) => void;
  setSelectedMarketZonesAction: (a: string[]) => void;
  setPeriodAction: (a: PeriodType) => void;
  setGranularityAction: (a: any) => void;
};

const MarketFilters = ({
  selectedMarket,
  selectedMarketZones,
  period,
  granularity,
  enums,
  setSelectedMarketAction,
  setSelectedMarketZonesAction,
  setPeriodAction,
  setGranularityAction
}: MarketFiltersType) => {
  const fEnum = getMarketZones(selectedMarket, enums.TipoCurvaPrezzi);
  useEffect(() => {
    setSelectedMarketAction("Electricity");
    setGranularityAction("daily");
  }, []);
  useEffect(() => {
    R.unless(R.isEmpty, () => setSelectedMarketZonesAction([fEnum[0]]))(fEnum);
  }, [fEnum[0]]);


  return (
    <>
      <div className={styles.spotSelector}>
        <div>
          <div className="form-group form-label">Categoria mercati</div>
          <div className="d-flex flex-column">
            <div className="form-group">
              <RadioBtn
                checked={R.equals(selectedMarket, "Electricity")}
                label="Electricity"
                name="Electricity"
                update={setSelectedMarketAction}
              />
              <RadioBtn
                checked={R.equals(selectedMarket, "Gas")}
                label="Gas"
                name="Gas"
                update={setSelectedMarketAction}
              />
              <RadioBtn
                checked={R.equals(selectedMarket, "MSD_Med")}
                label="MSD_Med"
                name="MSD_Med"
                update={setSelectedMarketAction}
              />
            </div>
          </div>
        </div>
        <div className={styles.regioneSelector}>
          <label className="form-group form-label">
            {" "}
            {R.equals(selectedMarket, "MSD_Med") ? "MACROZONA" : "Zona"}
          </label>
          <div className={`${styles.spotRegionSelect}`}>
            {getMarketZones(selectedMarket, enums.TipoCurvaPrezzi).map(
              (item: any, i: number) => (
                <CheckBox
                  key={i}
                  label={getMarketName(item)}
                  name={item}
                  selected={selectedMarketZones}
                  update={setSelectedMarketZonesAction}
                />
              )
            )}
          </div>
        </div>
      </div>
      <hr className="horizontalRule" />
      <div className="spacer"></div>
      <label className="form-label">Seleziona Periodo</label>
      <div className="d-flex align-items-center">
        <DateRange
          startDate={
            R.equals(null, period.start) ? null : new Date(period.start as any)
          }
          onStartDateChange={(e: Date) => {
            return setPeriodAction({
              ...period,
              start: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
            });
          }}
          endDate={
            R.equals(null, period.end) ? null : new Date(period.end as any)
          }
          onEndDateChange={(e: Date) =>
            setPeriodAction({
              ...period,
              end: R.equals(null, e) ? null : format(e, "yyyy-MM-dd")
            })
          }
        />
        <div className="form-check mx-3 checkbox-inline-margin">
          <RadioBtn
            checked={R.equals(granularity, "daily")}
            label="Daily"
            name="daily"
            update={setGranularityAction}
          />
        </div>
        {R.ifElse(
          R.equals("Gas"),
          () => null,
          () => (
            <div className="form-check mx-3 checkbox-inline-margin">
              <RadioBtn
                checked={R.equals(granularity, "hourly")}
                label="Hourly"
                name="hourly"
                update={setGranularityAction}
              />
            </div>
          )
        )(selectedMarket as any)}
      </div>
    </>
  );
};

const options: Highcharts.Options = {
  colors: [
    "#e10019",
    "#761613",
    "#cfa2a7",
    "#f5a623",
    "#67cd00",
    "#0198E1",
    "#8E44AD"
  ],
  chart: {
    type: "spline",
    zoomType: "x",
    style: {
      fontFamily: "Avenir-Roman"
    }
  },
  title: {
    text: ""
  },
  tooltip: {
    shared: true,
    valueDecimals: 2,
    valueSuffix: "€/MWh"
  },
  credits: {
    enabled: false
  },
  xAxis: {
    title: {
      text: ""
    },
    type: "datetime",
    dateTimeLabelFormats: {
      day: { main: "%b %e, %Y" },
      hour: { main: "%H:%M" }
    }
  },
  yAxis: {
    opposite: true,
    title: {
      text: " €/MWH"
    }
  },
  legend: {
    align: "left",
    verticalAlign: "top"
  },
  lang: {
    noData: "Nessun Dato Disponibile"
  },
  time: {
    useUTC: false
  },
  plotOptions: {
    series: {
      events: {
        legendItemClick: function() {
          return false;
        }
      }
    }
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#303030"
    }
  }
};
