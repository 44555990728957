import * as R from "ramda";
import React, { useEffect, useState } from "react";

import "../../styles/highcharts.module.scss";
import styles from "../../productionData/production.module.scss";
import { CheckBox } from "../checkBox";
import InputTextField from "../inputTextField";

export const CheckboxList = ({
  ragioneSocialeListPvi,
  setSelectedPVI,
  filters,
  disponibilita,
}: any) => {
  const pvis = disponibilita ? filters : filters.pvis;
  const [filter, setFilter] = useState("");
  const [
    ragioneSocialeListPviFiltered,
    setragioneSocialeListPviFiltered,
  ] = useState(ragioneSocialeListPvi);
  useEffect(() => {
    setragioneSocialeListPviFiltered(
      R.filter((x: any) =>
        R.contains(R.toLower(filter), R.toLower(x.pvi.nome))
      )(R.sortBy(R.pathOr("", ["pvi", "nome"]))(ragioneSocialeListPvi) || [])
    );
  }, [filter]);
  return (
    <div className={`${styles.spotPlantSelect}`}>
      {ragioneSocialeListPvi.length > 10 ? (
        <div className={styles.pviFilter}>
          <InputTextField
            name="value"
            value={filter}
            placeholder="Inserire PVI..."
            update={(x: string) => {
              setFilter(x);
            }}
          ></InputTextField>
        </div>
      ) : (
        <></>
      )}
      <div className={`${styles.spotPlantGrid}`}>
        {R.map(
          (y: any) => (
            <CheckBox
              key={`${y.pvi.nome}-${y.id}`}
              label={y.pvi.nome}
              name={y.id}
              selected={pvis}
              update={setSelectedPVI}
            />
          ),
          ragioneSocialeListPviFiltered
        )}
      </div>
      {R.length(ragioneSocialeListPviFiltered) === 0 ? (
        <div className={styles.pviFilter}>
          Nessun PVI disponibile con questo filtro
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
